import React, { useState } from "react";
import Slider from "react-slick";
import {
  Box,
  ToggleButton,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import leftarrow from "../../../images/productlanding/leftarrow.png";
import rightarrow from "../../../images/productlanding/rightarrow.png";
import prevArrowBlack from "../../../images/A70/prevArrow.png";
import nextArrowBlack from "../../../images/A70/nextArrow.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ColorOption = ({
  color,
  selectedColor,
  handleClick,
  showName,
  colorNames,
  colorStyles,
  colornametextcolor,
  selectedBgColor,
}) => {
  const isSelected = selectedColor === color;

  return (
    <ToggleButton
      value={color}
      onClick={() => handleClick(color)}
      aria-label={color}
      selected={isSelected}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "25px",
        p: 1,
        textAlign: "center",
        mx: 0.5,
        height: 36,
        minWidth: 50,
        border: "2px solid",
        borderColor: isSelected ? "red" : "transparent",
        backgroundColor: isSelected ? selectedBgColor : "transparent",
        flexDirection: "row",
        "&.Mui-selected": {
          backgroundColor: `${selectedBgColor} !important`,
        },
      }}
    >
      <Box
        sx={{
          background: colorStyles[color],
          width: 24,
          height: 24,
          borderRadius: "50%",
          mr: showName ? 1 : 0,
          fontFamily:"MarkOT-Medium",
          "&:hover": {
            border: !isSelected && "2px solid red"  // hover border only if not selected
          },
        }}
      />
      {(isSelected || showName) && (
        <Typography
          variant="caption"
          sx={{
            color: colornametextcolor,
            fontFamily:"MarkOT-Bold",
            px: 1,
          }}
        >
          {colorNames[color]}
        </Typography>
      )}
    </ToggleButton>
  );
};

const ProductColor = ({
  images,
  smallImages,
  colorNames,
  colorStyles,
  colortextcolor,
  initialColor,
  isNextArrowBlack,
  isPrevArrowBlack,
  selectedBgColor, // New prop for selected background color
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [color, setColor] = useState(initialColor);
  //const [activeIndex, setActiveIndex] = useState(0);
  // const [isPrevArrowBlack, setIsPrevArrowBlack] = useState(false);
  // const [isNextArrowBlack, setIsNextArrowBlack] = useState(false);


  const handleColorChange = (newColor) => {
    if (newColor !== null) {
      setColor(newColor);
    }
  };

  const CustomPrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: "5%",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
    >
      <img style={{width:isSmallScreen ? "1.5rem" : "2.5rem"}}
        src={isPrevArrowBlack ? prevArrowBlack : leftarrow} alt="arrow" />
    </IconButton>
  );

  const CustomNextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: "5%",
        transform: "translateY(-50%)",
        zIndex: 2,
      }}
    >
      <img style={{width:isSmallScreen ? "1.5rem" : "2.5rem"}}
      src={isNextArrowBlack ? nextArrowBlack : rightarrow} alt="arrow" />
    </IconButton>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    
  };

  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Slider {...settings}>
      {(isSmallScreen ? smallImages[color] : images[color]).map((image, index) => (
          <Box key={index}>
            <img
              src={image}
              alt={`${color} phone ${index + 1}`}
              style={{ width: "100%", height:isSmallScreen ? "500px" : "650px" }}
            />
          </Box>
        ))}
      </Slider>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          pb: "6%",
          px: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: colortextcolor, fontFamily:"MarkOT-Medium", mb: isSmallScreen ? 2 : 0 }}
        >
          COLOR
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "start",
            p: 1,
          }}
        >
          {Object.keys(images).map((colorKey) => (
            <ColorOption
              key={colorKey}
              color={colorKey}
              selectedColor={color}
              handleClick={handleColorChange}
              showName={isSmallScreen}
              colorNames={colorNames}
              colorStyles={colorStyles}
              colornametextcolor={colortextcolor}
              selectedBgColor={selectedBgColor} // Pass the selected background color prop
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductColor;
