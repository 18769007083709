import React from "react";
import { Grid, Box, Typography,AppBar,IconButton,Toolbar,useMediaQuery } from "@mui/material";
import first from "../../../images/P55 5G/tech1Big.png";
import second from "../../../images/P55 5G/tech2Big.png";
import third from "../../../images/P55 5G/tech3Big.png";
import fourth from "../../../images/P55 5G/tech4Big.png";
import fifth from "../../../images/P55 5G/tech5Big.png";
import sixth from "../../../images/P55 5G/tech6Big.png";
import first1 from "../../../images/P55 5G/tech1small.png";
import second2 from "../../../images/P55 5G/tech2small.png";
import third3 from "../../../images/P55 5G/tech3small.png";
import fourth4 from "../../../images/P55 5G/tech4small.png";
import fifth5 from "../../../images/P55 5G/tech5small.png";
import sixth6 from "../../../images/P55 5G/tech6small.png";
import Battery from "../../../images/P55 5G/vertical1Big.png";
import Storage from "../../../images/P55 5G/vertical2Big.png";
import Design from "../../../images/P55 5G/vertical3Big.png";
import Processor from "../../../images/P55 5G/vertical4Big.png";
import vertical5 from "../../../images/P55 5G/vertical5Big.png";
import vertical6 from "../../../images/P55 5G/vertical6Big.png";
import vertical7 from "../../../images/P55 5G/vertical7Big.png";
import Batterysmall from "../../../images/P55 5G/vertical1small.png";
import Storagesmall from "../../../images/P55 5G/vertical2small.png";
import Designsmall from "../../../images/P55 5G/vertical3small.png";
import Processorsmall from "../../../images/P55 5G/vertical4small.png";
import vertical5small from "../../../images/P55 5G/vertical5small.png";
import vertical6small from "../../../images/P55 5G/vertical6small.png";
import vertical7small from "../../../images/P55 5G/vertical7small.png";




// const carouselItems = [
//   {
//     image: slide1,
//     alt: "Game",
//     title: "Chic & Timeless",
//     subtitle: "Dawn White and Starry Black",
//   },
//   {
//     image: slide2,
//     alt: "Game",
//     title: "Gaze Capture",
//     subtitle: "Elevate your portraits eye-tracking model",
//   },
// ];

 

const TechSpecs = ({ appBarColor, menuIcons, }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  
  const images = {
    Fabcamera: isSmallScreen ? first1 : first,
    Connectivity: isSmallScreen ? second2 : second,
    Ram: isSmallScreen ? third3 : third,
    Fabbattery: isSmallScreen ? fourth4 : fourth,
    Fabdisplay: isSmallScreen ? fifth5 : fifth,
    sixth: isSmallScreen ? sixth6 : sixth,
  };

  return (

    <Box>
      <img
        src={isSmallScreen ? Batterysmall : Battery}
        alt="Battery"
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
          
        }}
      />

    <Box sx={{padding: isSmallScreen ? "10% 8% 10% 8%" : "6% 4% 6% 4%", backgroundColor: "#FFFFFF"}}>
      <img
        src= {isSmallScreen ? Storagesmall : Storage}
        alt="storage"
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
        }}
      />
    </Box>

      <img
        src= {isSmallScreen ? Designsmall : Design}
        alt="storage"
        style={{
          width: "100%",
          display: "block",
        }}
      />

    
    <Box sx={{ padding: "6% 4% 6% 4%", backgroundColor: "#D0E1FC"}}>
      <img
        src= {isSmallScreen ? Processorsmall : Processor}
        alt="camera"
        style={{
          width: "100%",
          display: "block",
          
          
        }}
      />
      </Box>

      <img
        src={isSmallScreen ? vertical5small : vertical5}
        alt="Battery"
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
          
        }}
      />

      <img
        src= {isSmallScreen ? vertical6small : vertical6}
        alt="storage"
        style={{
          width: "100%",
          display: "block",
          objectFit: "cover",
        }}
      />
      <img
        src= {isSmallScreen ? vertical7small : vertical7}
        alt="storage"
        style={{
          width: "100%",
          display: "block",
        }}
      />

      <AppBar position="static" sx={{ bgcolor: appBarColor }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: isSmallScreen ? "1%" : "3%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <Box
                component="img"
                src={menuIcons}
                alt="Menu Icon"
                sx={{ width: "60px", height: "60px" }}
              />
            </IconButton>
            
          </Box>
          
          <Box sx={{ pr: isSmallScreen ? "2%" : "3%" }}>
            <Typography
              variant="contained"
              sx={{
                fontWeight:'400',
                fontSize: "15px",
                color: "white",
                textAlign: isSmallScreen ? "right" : "center",
                fontFamily:"MarkOT",
                display: "flex",
                
              }}
            >
              GET  2 YEARS OF WARRANTY BY AUTHORIZED SERVICE CENTRES
             
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ flexGrow: 1, pl: "4%", pr: "4%", pb: "6%" , pt:"6%", backgroundColor: "#181818" }}>
        {/* <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
          Tech Specs
        </Typography> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={images.Fabcamera}
                alt="Fab Camera"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <img
                        src={images.Connectivity}
                        alt="Connectivity"
                        style={{
                          width: "100%",
                          height: "calc(59vh - 10px)",
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <img
                        src={images.Ram}
                        alt="Ram"
                        style={{
                          width: "100%",
                          height: "calc(59vh - 10px)",
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box>
                      <img
                        src={images.Fabbattery}
                        alt="Connectivity"
                        style={{
                          width: "100%",
                          height: "calc(59vh - 10px)",
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <img
                        src={images.Fabdisplay}
                        alt="Ram"
                        style={{
                          width: "100%",
                          height: "calc(59vh - 10px)",
                          borderRadius: "16px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <Box>
                  <img
                    src={images.Fabbattery}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <img
            src={images.sixth}
            alt="last"
            style={{
              width: "100%",
              height: "60%",
              borderRadius: "16px",
            }}
          />
        </Box>
      </Box>

      

      
    </Box>
  );
};

export default TechSpecs;
