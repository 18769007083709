import React from "react";
import landing1 from "../../images/contactus/landing.png";
import landing2 from "../../images/contactus/landing2.png";
import chat from "../../images/contactus/chat.png";
import call from "../../images/contactus/call.png";
import message from "../../images/contactus/message.png";
import buisness from "../../images/contactus/buisness.png";
import bgimg from "../../images/contactus/bgimg.png";
import rightarrow from "../../images/contactus/arrowright.png";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Link,
} from "@mui/material";

function ContactUs() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {/* Landing */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          overflow: "hidden",
          
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landing2 : landing1}
          alt="Banner"
          sx={{
            width: "100%",
            height: "auto",
            display: "block",
          }}
        />
        <Typography
          variant="h2"
          sx={{
            position: "absolute",
            top: "50%",
            left: { xs: "5%", sm: "10%", md: "9%" },
            transform: "translateY(-50%)",
            color: "white",
            fontSize: {
              xs: "36px",
              sm: "40px",
              md: "60px",
              lg: "70px",
              xl: "80px",
            },
          }}
        >
          Contact Us
        </Typography>
      </Box>

      {/* Contact Info and Form */}
      <Grid container spacing={4} p={"6% 4% 8% 4%"}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "7%",
              mt:"1%"
            }}
          >
            <img src={call} alt="phone" style={{ marginRight: "16px" }} />
            <Typography variant="body1" sx={{ color: "#666666" }}>
              {" "}
              CALL US{" "}
              <Link
                href="tel:911206650888"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
                  display:"flex"
                }}
              >
                91-120-6650888
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "6%",
            }}
          >
            <img src={chat} alt="phone" style={{ marginRight: "16px" }} />
            <Typography variant="body1" sx={{ color: "#666666" }}>
              CHAT WITH US{" "}
              <Link
                href="mailto:info.in@transsion.com"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
                  display:"flex"
                }}
              >
                info.in@transsion.com
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "6%",
            }}
          >
            <img src={buisness} alt="phone" style={{ marginRight: "16px" }} />
            <Typography variant="body1" sx={{ color: "#666666" }}>
              BUSINESS COOPERATION{" "}
              <Link
                href="mailto:itelmarketing.in@transsion.com"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
                  display:"flex"
                }}
              >
                itelmarketing.in@transsion.com
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "9%",
            }}
          >
            <img src={message} alt="phone" style={{ marginRight: "16px" }} />
            <Typography variant="body1" sx={{ color: "#666666" }}>
              SUPPORT{" "}
              <Link
                href="mailto:itel.care@transsion.com"
                sx={{
                  color: "primary.main",
                  textDecoration: "none",
                  fontSize: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
                  display:"flex"
                }}
              >
                itel.care@transsion.com
              </Link>
            </Typography>
          </Box>
          <hr
            style={{
              border: "none",
              borderTop: "1px solid #ccc",
              marginRight: "5%",
            }}
          />
                      <Typography sx={{color:"var(--Smoky-Black, rgba(14, 14, 14, 1))",paddingTop:"8%",fontSize:{md:"24px",xs:"20px",sm:"20px"},fontWeight:"700"}}>S Mobile Devices Private Limited</Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "6%",
              marginTop: "6%",
            }}
          >
            <Typography variant="body1" sx={{ color: "var(--Colors-Grey-Grey-4, rgba(102, 102, 102, 1))" ,fontSize:{md:"20px"},fontWeight:"500"}}>
              Corporate Identity Number (CIN):
              <br />
              <span style={{ color: "black" ,fontSize:"14px"}}>U32204UP2012PTC051324</span>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography variant="body1" sx={{ color: "var(--Colors-Grey-Grey-4, rgba(102, 102, 102, 1))" ,fontSize:{md:"20px"},fontWeight:"500"}}>
              Registered Office: <br />{" "}
              <span style={{ color: "black",fontSize:"14px" }}>
                Unit No. B – 303, Third Floor, Tower – B, Advant IT Park, <br />
                Plot No. – 7, Sector – 142, Noida, Uttar Pradesh 201301
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              backgroundImage: `url(${bgimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "6%",
              borderRadius: "16px",
              
              height: "100%",
            }}
          >
            <Box textAlign={"center"} >
              <Typography variant="h4" gutterBottom sx={{fontSize:{md:"48px",xs:"24px"},fontWeight:"500"}}>
              Inquiry Form
              </Typography>
              <Typography
  variant="body1"
  gutterBottom
  sx={{
    fontSize: { md: "14px", xs: "14px" },
    fontWeight: "400",
    lineHeight: "22.4px",
    color: "var(--Colors-Grey-Grey-5, rgba(37, 37, 37, 1))", 
    
    textAlign: "center", 
    marginX: {md:"18%",xs:"0"}, 
  }}
>
  Complete the form, and our dedicated team will swiftly reach out to you. 
  We're committed to providing prompt assistance to you.
</Typography>
            </Box>
            <form noValidate autoComplete="off" >
              <Grid container spacing={2} mt={"4%"}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    First Name
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id="firstName"
                    label="Enter first name"
                    variant="outlined"
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Last Name
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Enter last name"
                    variant="outlined"
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    E-mail
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Enter your e-mail"
                    type="email"
                    variant="outlined"
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormLabel component="legend">Subject</FormLabel>
                    <RadioGroup
                      row
                      aria-label="subject"
                      name="subject"
                      defaultValue="Battery"
                    >
                      <FormControlLabel
                        value="Product"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Product"
                      />
                      <FormControlLabel
                        value="Battery"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Battery"
                      />
                      <FormControlLabel
                        value="Software"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Software"
                      />
                      <FormControlLabel
                        value="Service Issue"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Service Issue"
                      />
                      <FormControlLabel
                        value="Warranty"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Warranty"
                      />
                      <FormControlLabel
                        value="Other"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        }
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Message
                  </Typography>
                  <TextField
                    fullWidth
                    id="message"
                    label="Enter your message here"
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{
                      mb: 2,
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: 1.5,
                      borderRadius: "24px",
                    }}
                  >
                    Submit
                    <img
                      src={rightarrow}
                      alt="Submit Icon"
                      style={{ marginLeft: "8px", height: "24px" }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ContactUs;
