import React , { useEffect } from "react";
import gsap from "gsap";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import Specifications from "../../../components/productcomponents/FeaturedPhone/Specifications";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import ProductReview from "../../../components/ProductReview/ProductReview";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import landing1 from "../../../images/Power450/landingBig.png";
import landing2 from "../../../images/Power450/landingsmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/Power450/avatar.png";
import icon1 from "../../../images/Power450/icon1.png";
import icon2 from "../../../images/Power450/icon22.png";
import icon3 from "../../../images/Power450/icon33.png";
import background from "../../../images/Power450/background.png";
import landingbg1 from "../../../images/Power450/landingBgBig.png";
import landingbg2 from "../../../images/Power450/landingBgSmall.png";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/Power450/productBig.png";
import blue2 from "../../../images/Power450/productBig.png";
import blue3 from "../../../images/Power450/productBig.png";
import MyIcon from "../../../images/Power450/star.png";
import vertical1 from "../../../images/Power450/vertical1.png";
import vertical2 from "../../../images/Power450/vertical2.png";
import vertical3 from "../../../images/Power450/vertical3.png";
import vertical4 from "../../../images/Power450/vertical4.png";
import verticalsmall1 from "../../../images/Power450/vertical1small.png";
import verticalsmall2 from "../../../images/Power450/vertical2small.png";
import verticalsmall3 from "../../../images/Power450/vertical3small.png";
import verticalsmall4 from "../../../images/Power450/vertical4small.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import blacksmall1 from "../../../images/Power450/productsmall.png";
import blacksmall2 from "../../../images/Power450/productsmall.png";
import blacksmall3 from "../../../images/Power450/productsmall.png";
import soha from "../../../images/Review/soha.png";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import compare11 from "../../../images/Power120/compare1.png";
import compare2 from "../../../images/Power120/compare2.png";
import compare3 from "../../../images/Power120/compar3.png";
import similar1 from "../../../images/Power120/similar1.png";
import similar2 from "../../../images/Power120/similar2.png";
import similar33 from "../../../images/Power120/similar3.png";
import similar4 from "../../../images/Power120/similar4.png";
import { Box ,Typography,useMediaQuery} from "@mui/material";

  const landingTexts = [
    {
      text1: "6.6” HD+ DISPLAY DYNAMIC BAR | 12GB* RAM | 13MP AI REAR Camera",
    },
    {
      text2: "Starting from ₹6,299/-",
    },
  ];

  const features = [
    {
      title: "Voice of Authority!",
      description:
        "Enjoy the King Voice feature on itel Power450, allowing it to speak for you with auto call recording capabilities, ensuring you never miss a word.",
      icon: icon1,
    },
    {
      title: "Vivid Viewing Experience!",
      description:
        "Immerse yourself in content on the 6.1cm(2.4) QVGA large display, delivering crisp visuals for an enhanced viewing experience.",
      icon: icon2,
    },
    {
      title: "Power That Lasts!",
      description:
        "With a robust 2500mAh battery, experience up to 15 days of uninterrupted usage, ensuring you stay connected for longer without worrying about charging.",
      icon: icon3,
    },
  ];

  const mainTexts = [
    {
      
      heading:"Power Up Your Life with itel Power 450! ",
      text: "Meet the itel Power 450, your ultimate feature phone for staying charged and connected on the go! With Type-C Charging and up to 15 days of battery backup, you can kiss low battery anxiety goodbye. Enjoy a crystal-clear view on the large 6.1cm(2.4) display, perfect for browsing, texting, and more. Tune in to your favourite radio stations wherever you are with the built-in wireless FM radio. Slim and stylish, in three eye-catching colours, the itel Power 450 is sure to turn heads wherever you go. Stay powered up, stay connected, stay stylish with itel Power 450!",
    },
  ];

  const images = {
    blue: [blue1, blue2, blue3],
    black: [black1, black2, black3],
    green: [blue1, blue2, blue3],
  };
  
  const smallImages={
    blue: [blacksmall1, blacksmall2, blacksmall3],
    black: [bluesmall1, bluesmall2, bluesmall3],
    green: [orangesmall1, orangesmall2, orangesmall3],
    
  }

  const colorNames = {
    blue: " Blue",
    green: "Light Green",
    black: "Black",
  };
  
  const colorStyles = {
    blue: "#D2C8ED",
    green: "#AFE1C1",
    black: "#3B4455",
  };

  const initialReviewsData = [
    {
      name: "Radha",
      date: "15th April 2024",
      rating: 4,
      title: "Value for Money",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: radha,
    },
    {
      name: "Krishna",
      date: "15th April 2024",
      rating: 4,
      title: "Best Battery Life",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: krishna,
    },
    {
      name: "Soha Ali Khan",
      date: "8th Aug 2023",
      rating: 3,
      title: "Professional Camera",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: soha,
    },
  ];
  const additionalReviewsData = [
    {
      name: "Akshay",
      date: "1st Jan 2024",
      rating: 5,
      title: "Excellent Product",
      content: "This is a new dummy review added for testing purposes.",
      image: radha,
    },
    {
      name: "Arya",
      date: "2nd Jan 2024",
      rating: 4,
      title: "Great Value",
      content: "Another dummy review added for testing purposes.",
      image: krishna,
    },
  ];

  
  const products2 = [
    {
      name: "it5262",
      price: "₹1,269.00",
  
      imageUrl: similar1,
    },
    {
      name: "it5330",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar2,
    },
    {
      name: "Circle 1",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar33,
    },
    {
      name: "Super Guru",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar4,
    },
  ];

  const specs = {
    generalSpecs: {
      Model: "Power 450",
      colors: "Black, Light Green, Blue",
    },
    display: {
      displatSize: "(5.08 cm)2'",
      resolution: "128*160 ",
      resolutionType: "QQVGA",
      size:"(5.08 cm)2'",
      pixelDensity:"102.45 PPI"
    },
    osProcessor: {
      operatingSystem: "Android 13 GO Edition",
      chipSet : "UNISOC SC9863A",
      processorCore: "Octa-core",
      
    },
    camera: {
      rearCamera: "5 MP",
      rearFlash: "Yes",
      frontCamera: "5 MP",
      FrontFlash: "No",
    },
    battery: {
      batteryCapacity: "4000 mAh",
      batteryType: "Li-Polymer",
      fastCharging:"5W",
      USB: "Type-C",
      standBy: "32 Days",
      talkTime: "7.5 Hrs",
      mediaPlaybackTime:"112 Hours"
    },
    memoryStorage: {
      ram:"4GB*(2GB + 2 GB*)",
      rom:"32GB",
      userMemory:"23GB",
      expandable:"Upto 1TB",
    },
    connectivity: {
      networkType: "4G",
      GSM: "2G:B3|5|8",
      EDGE: "Support Download",
      WCDMA: "3G:B1|5|8",
      LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
      slimSlot: "Slot-1 & Solt-2 Nano",
      dualActiveOrStandyBy:"Dual Active",
      bluetoothSupport: "Yes",
      wiFi : "Yes",
      GPS : "Yes",
      usbConnectivity:"Yes",
    },
    additional: {
      earphoneJack: "3.5 mm",
      sensors: "Fingerprint | Face Unlock | G Sensor",
      fingerprint:"Side",
      accessories: "5W Adapter(Support 10W) | USB Cable | Protective Cover",
      thickness: "9.18 mm"
      
    },
  };

  const models = [
    {
      name: "SuperGuru 4G",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.5 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare11,
    },
    {
      name: "it5262",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare2,
    },
    {
      name: "it5330",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.9 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare3,
    },
  ];

  

  const callFaqs = [
    
    {
      question: "Cannot make a call after activating data.",
      answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
      \n 3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      \n 4. Try to reset factory settings, but remember to take data back up first.
      \n 5. Visit the nearest customer service center for further check.`,
      },
    {
      question: "How to set SIM card 2 as default mode when sending messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Phone cannot display recent calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "When calling, both sides cannot hear each other.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
  ];
  
  const cameraFaqs = [
    {
      question: "How to take high-quality photos.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      question: "How to switch between different camera modes.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
  ];
  
  const dummyFaqs = [
    {
      question: "Dummy question 1",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 2",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 3",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  
  const tabLabels = [
    "CALLS & CONTACTS",
    "CAMERA & PICTURE",
    "CHARGING",
    "INTERNET & NETWORK",
  ];
  const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

  const Power450 = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));


    const maxWidthLandingImage = {
      xs: "49%",
      sm:"55%",
      md: "65%",
      lg: "30%",
    }

    useEffect(() => {
      
    const xValue = isSmallScreen ? "0vw" : "30vw";
    const yValue = isSmallScreen ? "-5vh" : "0vh";

    gsap.set(".landing-image", { opacity: 0, x: xValue ,y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
    <Box sx={{bgColor:"#fff"}} > 
      
      <ProductLanding
      landingImages={[landing1, landing2]}
      maxWidthLandingImage={maxWidthLandingImage}
      landingTexts={landingTexts}
      appBarColor="#1E1E1E"
      mainTexts={mainTexts}
      features={features}
      buyNowButtonImage={buyNowButton}
      arrowIcon={arrow}
      awesomeSeriesIcon={awesomeSeriesIcon}
      menuIcon={menuIcon}
      backgroundImageFeatures={background}
      buyNowButtonLabel="Buy Now"
      headbuttontext="superseries"
      productname="SuperGURU 4G"
      ProductnamefontSize={isSmallScreen ? "16px" : "20px"}
      Productnamecolor="#FFFFF"
      linearcolor="linear-gradient(to left, #FF0037,#FF0037)"
      backgroundImages={[landingbg1, landingbg2]}
      OverviewSpecificationcolor="#EBE8E2"
      SpecificationsORTechSpecs= "Specifications"
      buynowbuttonbgcolor="#FFFFFF"
      buynowbuttoncolor="#1A1A1A"
      featuresborder="none" // Added border here
      rightsideTextColorFeatures="#252525"
      featuresubheadingcolor="#1C1B1B" // Remove this line to use linear color
      featurediscriptiontextcolor="#181818"
    />

      <Marquee
        appBarColor="#EBE8E2"
        textColor="#666666"
        iconSrc={MyIcon}
        text1="CALL BACK TO THE FUTURE"
        text2="SNAP BACK TO THE REALITY"
        // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/193b/2409/-d534-45fc-9deb-dc56392a90ce?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HdhpZIZ4r~NDbZlTF6q2K9A0I6zk1P6SpsjtZ3bXbRuTudRMOO7ozOPKY2WPuhhGHI8BAYvJCbNlhy50HMmWCCkhvz5Ur5iYbCgL825vBgcRiG9Dvd9Ts5wIS7n4PJwZ5t1dWYW2c3vwgtw2fQjW2YOvmQAD1U~NsFzoyB-gKgKSPM-VEgRXxEmbxK-~uaKwugD2rCU~VguG7a9u61KLOIQ4pvSpA2Zlz6bXgmK8SjqZidGiNxFSjqDePwvtpgOboISnI7KBg4PxwNhTZ9nvzhUVo0kNLzCzSJUln0IVocvStzRJoBsr3IxpF8rWkblneXHTbYiqa0S-A4NeTF6How__"
        // playIconSrc={play}
        // frameSrc={patterns}
      />
      
      <Box sx={{
        paddingLeft: "4%",
        paddingRight:"4%",
        paddingTop:"4%",
        }}>
        <Typography 
          variant='h4'
          marginBottom={"4%"}
          marginTop={"4%"}
          fontSize={{xs: "24px", sm: "33px",md:"36px", lg:"40px"
          }}> 
          Product Versions
        </Typography> 

        <ProductColor
          images={images}
          colorNames={colorNames}
          colorStyles={colorStyles}
          smallImages={smallImages}
          initialColor="blue"
          colornametextcolor= "#181818"
          selectedBgColor="white"
          isNextArrowBlack={false}
          isPrevArrowBlack={false}
        />
      </Box>

      <VerticalSection/>

    
      <Specifications 
        specs={specs} 
        bgColor={"#fff"} 
      />
      <ProductReview
        initialReviews={initialReviewsData}
        additionalReviews={additionalReviewsData}
        isDarkMode={false}
      />
      
      <CompareModels 
        models={models}
        mode="light" bgcolor="white"
        isTwoMapView= "true"
      />
      <PhoneCard 
        heading="Similar Products" 
        products={products2} 
      />
      <SupportChannels 
      />
      <CheckOutProducts 
        bgColor={"#fff"} 
        heading ="Peep into our cool gadgets "
      />
      <FollowUs mode="light" bgcolor="white"/>
      <ProductFaqs
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
      />

    </Box>
    </>
  );
};

const VerticalSection = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return(
    <div 
      style={{
        //paddingBottom:"4%",
        paddingTop:"4%",
      }}>
        <Box>
          <img src={isSmallScreen ? verticalsmall1 : vertical1}
              alt="first"
              style={{ 
                width :"100%",
                //height:"100%",
                display:"block"
              }}
          />  
          <img src={isSmallScreen ? verticalsmall2 : vertical2}
              alt="second"
              style={{
                width :"100%",
                display:"block"
              }}
          /> 
          <Box style= {{
            paddingLeft:"4%", 
            paddingRight:"4%",
            paddingTop:"4%",
            paddingBottom:"4%"
            }} >
            <img src={isSmallScreen ? verticalsmall3 : vertical3}
                alt="third"
                style={{
                  width :"100%",
                }}
            />  
          </Box>
          <img src={isSmallScreen ? verticalsmall4 : vertical4}
              alt="fourth"
              style={{
                width :"100%",
              }}
          />  
          
        </Box>
    </div>
  )
}

export default Power450;


