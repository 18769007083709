import React , { useEffect } from "react";
import gsap from "gsap";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import ProductReview from "../../../components/ProductReview/ProductReview";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import landing1 from "../../../images/Aura05i/landingAuraBig.png";
import landing2 from "../../../images/Aura05i/landingAurasmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/A70/avatar.png";
import icon1 from "../../../images/Aura05i/icon1.png";
import icon2 from "../../../images/Aura05i/icon2.png";
import icon3 from "../../../images/Aura05i/icon3.png";
import landingbg1 from "../../../images/Aura05i/landingBgAuraBig.png";
import landingbg2 from "../../../images/Aura05i/landingBgAuraSmall.png";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/Aura05i/product color.png";
import blue2 from "../../../images/Aura05i/product color.png";
import blue3 from "../../../images/Aura05i/product color.png";
import orange1 from "../../../images/A70/orange.png";
import orange2 from "../../../images/A70/orange.png";
import orange3 from "../../../images/A70/orange.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import blacksmall1 from "../../../images/P55/productColorsmall2.png";
import blacksmall2 from "../../../images/P55/productColorsmall2.png";
import blacksmall3 from "../../../images/P55/productColorsmall2.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import level1 from "../../../images/Aura05i/level1.png";
import level2 from "../../../images/Aura05i/level2.png";
import level3 from "../../../images/Aura05i/level3.png";
import level4 from "../../../images/Aura05i/level4.png";
import MyIcon from "../../../images/A70/staricon.png";
import tech1 from "../../../images/Aura05i/tech1.png";
import tech2 from "../../../images/Aura05i/tech2.png";
import tech3 from "../../../images/Aura05i/tech3.png";
import techsmall1 from "../../../images/Aura05i/tech1small.png";
import techsmall2 from "../../../images/Aura05i/tech2small.png";
import techsmall3 from "../../../images/Aura05i/tech3small.png";
import vertical1 from "../../../images/Aura05i/vertical1.png";
import vertical2 from "../../../images/Aura05i/vertical2.png";
import vertical3 from "../../../images/Aura05i/vertical3.png";
import vertical4 from "../../../images/Aura05i/vertical4.png";
import vertical5 from "../../../images/Aura05i/vertical5.png";
import verticalsmall1 from "../../../images/Aura05i/vertical1small.png";
import verticalsmall2 from "../../../images/Aura05i/vertical2small.png";
import verticalsmall3 from "../../../images/Aura05i/vertical3small.png";
import verticalsmall4 from "../../../images/Aura05i/vertical4small.png";
import verticalsmall5 from "../../../images/Aura05i/vertical5small.png";
import epicfullimage from "../../../images/Aura05i/epicfullImage.png";
import epicsmall from "../../../images/Aura05i/epicsmall.png";
import soha from "../../../images/Review/soha.png";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import compare11 from "../../../images/Aura05i/compare1.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import similar1 from "../../../images/Aura05i/similar1.png";
import similar2 from "../../../images/Aura05i/similar2.png";
import similar33 from "../../../images/Aura05i/similar3.png";
import similar4 from "../../../images/Aura05i/similar4.png";
import { Box ,useMediaQuery, Grid} from "@mui/material";

  const landingTexts = [
    {
      text1: "6.6” HD+ DISPLAY DYNAMIC BAR | 12GB* RAM | 13MP AI REAR Camera",
    },
    {
      text2: "Starting from ₹6,299/-",
    },
  ];

  const features = [
    {
      title: "6.6' HD+ Waterdrop Display",
      description:
        "Enjoy edge-to-edge screen that offers vibrant colors and crisp details, perfect for streaming, gaming, and browsing.",
      icon: icon1,
    },
    {
      title: "Stay Powered All Day: 4000 mAh Battery & Type-C Charging",
      description:
        "Use your itel Aura 05i for long hours with 4000 mAh battery and Type-C fast charging for quick recharge.",
      icon: icon2,
    },
    {
      title: "Peak Awesomeness with 4GB* (2GB + 2GB*) RAM + 32GB ROM",
      description:
        "Get high performance with 4GB* (2GB + 2GB*) RAM for efficient multitasking and 32GB of storage for your apps and media.",
      icon: icon3,
    },
  ];

  const mainTexts = [
    {
      
      heading:"Elevate Your Everyday Experience with 32GB ROM",
      text: "Presenting itel Aura 05i with 6.6-inch HD+ display, a 4000 mAh battery, 10W Type-C fast charging support, and 32GB ROM. Snap pics with its 5 MP dual rear camera and 5 MP front camera. Running on Android 13, it has fingerprint and Face unlock for extra security."
    },
  ];

  const images = {
    blue: [blue1, blue2, blue3],
    black: [black1, black2, black3],
    green: [blue1, blue2, blue3],
    orange: [orange1, orange2, orange3]
  };
  
  const smallImages={
    blue: [bluesmall1, bluesmall2, bluesmall3],
    black: [blacksmall1, blacksmall2, blacksmall3],
    green: [bluesmall1, bluesmall2, bluesmall3],
    orange: [orangesmall1, orangesmall2, orangesmall3]
  }

  const colorNames = {
    blue: "Crystal Blue",
    black: "Nebula Black",
    green: "Meadow Green",
    orange:"Glorious Orange"
  };
  
  const colorStyles = {
    blue: "#96CAE5",
    black: "#475266",
    green: "#CDE4DA",
    orange:"#FFA423"
  };

  const initialReviewsData = [
    {
      name: "Radha",
      date: "15th April 2024",
      rating: 4,
      title: "Value for Money",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: radha,
    },
    {
      name: "Krishna",
      date: "15th April 2024",
      rating: 4,
      title: "Best Battery Life",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: krishna,
    },
    {
      name: "Soha Ali Khan",
      date: "8th Aug 2023",
      rating: 3,
      title: "Professional Camera",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: soha,
    },
  ];
  const additionalReviewsData = [
    {
      name: "Akshay",
      date: "1st Jan 2024",
      rating: 5,
      title: "Excellent Product",
      content: "This is a new dummy review added for testing purposes.",
      image: radha,
    },
    {
      name: "Arya",
      date: "2nd Jan 2024",
      rating: 4,
      title: "Great Value",
      content: "Another dummy review added for testing purposes.",
      image: krishna,
    },
  ];

  const products = [
    {
      name: "Earbuds T1pro",
      price: "₹1,269.00",
  
      imageUrl: level1,
    },
    {
      name: "A60",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: level2,
    },
    {
      name: "Roar 75",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: level3,
    },
    {
      name: "Smartwear T11",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: level4,
    },
  ];
  const products2 = [
    {
      name: "S24",
      price: "₹10,999.00",
  
      imageUrl: similar1,
    },
    {
      name: "P55+",
      price: "₹9,499.00",
      originalPrice: "₹13,999.00",
      imageUrl: similar2,
    },
    {
      name: "P55 5G",
      price: "₹9,999.00",
      originalPrice: "₹12,999.00",
      imageUrl: similar33,
    },
    {
      name: "A70",
      price: "₹6,799.00",
      originalPrice: "₹7,299.00",
      imageUrl: similar4,
    },
  ];

  const specs = {
    generalSpecs: {
      Model: "Aura 05i",
      colors: "Nebula Black, Meadow Green, Crystal Blue, Glorious Orange",
    },
    display: {
      displatSize: "6.56 inch",
      resolution: "720*1612 Pixels",
      resolutionType: "HD + IPS",
      technology: "G+F, Incell",
      refreshRate : "60 Hz",
      houchSamplingRate:"120 Hz",
      pixelDensity:"270 PPI"
    },
    osProcessor: {
      operatingSystem: "Android 13 GO Edition",
      chipSet : "UNISOC SC9863A",
      processorCore: "Octa-core",
      
    },
    camera: {
      rearCamera: "5 MP",
      rearFlash: "Yes",
      frontCamera: "5 MP",
      FrontFlash: "No",
    },
    battery: {
      batteryCapacity: "4000 mAh",
      batteryType: "Li-Polymer",
      fastCharging:"5W",
      USB: "Type-C",
      standBy: "32 Days",
      talkTime: "7.5 Hrs",
      mediaPlaybackTime:"112 Hours"
    },
    memoryStorage: {
      ram:"4GB*(2GB + 2 GB*)",
      rom:"32GB",
      userMemory:"23GB",
      expandable:"Upto 1TB",
    },
    connectivity: {
      networkType: "4G",
      GSM: "2G:B3|5|8",
      EDGE: "Support Download",
      WCDMA: "3G:B1|5|8",
      LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
      slimSlot: "Slot-1 & Solt-2 Nano",
      dualActiveOrStandyBy:"Dual Active",
      bluetoothSupport: "Yes",
      wiFi : "Yes",
      GPS : "Yes",
      usbConnectivity:"Yes",
    },
    additional: {
      earphoneJack: "3.5 mm",
      sensors: "Fingerprint | Face Unlock | G Sensor",
      fingerprint:"Side",
      accessories: "5W Adapter(Support 10W) | USB Cable | Protective Cover",
      thickness: "9.18 mm"
      
    },
  };

  const models = [
    {
      name: "A05s",
      price: "₹6,499.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.5 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare11,
    },
    {
      name: "S24",
      price: "₹10,999.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare2,
    },
    {
      name: "A70",
      price: "₹6,799.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.9 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare3,
    },
  ];

  

  const callFaqs = [
    
    {
      question: "How to set music as a ringtone?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Cannot make a call after activating data.",
      answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
      \n 3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      \n 4. Try to reset factory settings, but remember to take data back up first.
      \n 5. Visit the nearest customer service center for further check.`,
      },
    {
      question: "Cannot add new contacts to phone book or view phone book.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Copy contacts to another phone.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
  ];
  
  const cameraFaqs = [
    {
      question: "How to take high-quality photos.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      question: "How to switch between different camera modes.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
  ];
  
  const dummyFaqs = [
    {
      question: "Dummy question 1",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 2",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 3",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  
  const tabLabels = [
    "CALLS & CONTACTS",
    "CAMERA & PICTURE",
    "CHARGING",
    "INTERNET & NETWORK",
  ];
  const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

  const Aura05i = () => {
    
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const maxWidthLandingImage = {
      xs: "75%",
      sm:"55%",
      md: "37%",
      lg: "35%",
    }
  

    useEffect(() => {
      // Define the x value based on screen size
      const xValue = isSmallScreen ? "0vw" : "25vw";
      const yValue = isSmallScreen ? "1vh" : "0vh";
  
      // Define the animations you want for different pages here
      gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue });

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
    <Box sx={{bgColor:"#fff"}} > 
      
      <ProductLanding
      landingImages={[landing1, landing2]}
      maxWidthLandingImage={maxWidthLandingImage}
      landingTexts={landingTexts}
      appBarColor="#FFFFFF"
      appBarBorder= "1px solid #D9D9D9"
      mainTexts={mainTexts}
      features={features}
      buyNowButtonImage={buyNowButton}
      arrowIcon={arrow}
      awesomeSeriesIcon={awesomeSeriesIcon}
      menuIcon={menuIcon}
      //backgroundImage={backgroundImage}
      buyNowButtonLabel="Buy Now"
      headbuttontext="superseries"
      productname="Aura 05i"
      ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
      Productnamecolor="#181818"
      linearcolor="linear-gradient(to left, #FF0037,#FF0037)"
      backgroundImages={[landingbg1, landingbg2]}
      OverviewSpecificationcolor="#181818"
      SpecificationsORTechSpecs= "Tech Specs"
      buynowbuttonbgcolor="#1A1A1A"
      buynowbuttoncolor="#FFFFFF"
      featuresborder="none" // Added border here
      rightsideTextColorFeatures="#252525"
      featuresubheadingcolor="#1C1B1B" // Remove this line to use linear color
      featurediscriptiontextcolor="#181818"
    />

      <Marquee
        appBarColor="#EBE8E2"
        textColor="#6B6682"
        iconSrc={MyIcon}
        text1="AWESOME SERIES"
        text2="IMPRESSIVELY AWESOME"
        // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/193b/2409/-d534-45fc-9deb-dc56392a90ce?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HdhpZIZ4r~NDbZlTF6q2K9A0I6zk1P6SpsjtZ3bXbRuTudRMOO7ozOPKY2WPuhhGHI8BAYvJCbNlhy50HMmWCCkhvz5Ur5iYbCgL825vBgcRiG9Dvd9Ts5wIS7n4PJwZ5t1dWYW2c3vwgtw2fQjW2YOvmQAD1U~NsFzoyB-gKgKSPM-VEgRXxEmbxK-~uaKwugD2rCU~VguG7a9u61KLOIQ4pvSpA2Zlz6bXgmK8SjqZidGiNxFSjqDePwvtpgOboISnI7KBg4PxwNhTZ9nvzhUVo0kNLzCzSJUln0IVocvStzRJoBsr3IxpF8rWkblneXHTbYiqa0S-A4NeTF6How__"
        // playIconSrc={play}
        // frameSrc={patterns}
      />
      
      <VerticalSection/>

      <TechSpecs/>
      <Box sx={{
        paddingLeft: "4%",
        paddingRight:"4%",
        }}>
        <ProductColor
          images={images}
          colorNames={colorNames}
          smallImages={smallImages}
          colorStyles={colorStyles}
          initialColor="blue"
          colornametextcolor= "#181818"
          selectedBgColor="#ffffff"
          isNextArrowBlack={true}
          isPrevArrowBlack={true}
        />
      </Box>
      
      <Specifications 
        specs={specs} 
        bgColor={"#fff"} 
      />
      <ProductReview
        initialReviews={initialReviewsData}
        additionalReviews={additionalReviewsData}
        isDarkMode={false}
      />
      <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="light" bgcolor="#ffffff"
      />
      <CompareModels 
        models={models}
        mode="light" bgcolor="#ffffff"
      />
      <PhoneCard 
        heading="Similar Products" 
        products={products2} 
      />
      <SupportChannels 
      />
      <CheckOutProducts 
        bgColor={"#fff"} 
        heading ="Check out our other Products"
      />
      <FollowUs />
      <ProductFaqs
           
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
      />

    </Box>
    </>
  );
};
const TechSpecs = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
    <img
    src={isSmallScreen ? epicsmall : epicfullimage}
    alt="epic pics"
    style={{
      width: "100%",
      display: "block",
      cursor:"pointer",
      padding: isSmallScreen ? "0% 4% 6% 4%" : "0% 0% 6% 0%"
    }}
  />
  
    <div 
      style={{bgcolor: " #fff",
      textColor:"#181818",
      paddingLeft: "4%",
      paddingRight:"4%",
      paddingBottom:"6%",
      }}
    >
      {/* <Box sx={{ display: "flex",
                marginBottom:"2%"}}>
      <img src= {redStar} alt="redstar" />
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{
          fontSize: { xs: "30px", sm: "40px", lg: "48px" },
          }}>
          Tech Specs
        </Typography>
        </Box> */}
    <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box>
            <img
              src={isSmallScreen ? techsmall1 : tech1}
              alt="double vision"
              style={{
                width: "100%",
                height: "calc(121vh - 20px)",
                borderRadius: "16px",
                }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box>
                <img
                  src={isSmallScreen ? techsmall2 : tech2}
                  alt="slim glossy"
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <img
                  src={isSmallScreen ? techsmall3 : tech3}
                  alt="energy Performance "
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  </div>
 
</>
);
};

const VerticalSection = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return(
    <div 
      style={{
        paddingLeft:"4%",
        paddingRight:"4%",
        paddingBottom:"6%",
        paddingTop:"6%",
      }}>
        <Box
          sx={{
            border: {
              xs:"none",
              sm:"1px solid #00000033" 
            },
            borderRadius: "23px",
            padding:"10px",
            }} >
          <img src={isSmallScreen ? verticalsmall1 : vertical1}
              alt="first"
              style={{ 
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall2 : vertical2}
              alt="second"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall3 : vertical3}
              alt="third"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall4 : vertical4}
              alt="fourth"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall5 : vertical5}
              alt="fifth"
              style={{
                width :"100%",
                borderRadius:"14px",
              }}
          />  
        </Box>
    </div>
  )
}

export default Aura05i;


