import React, { useEffect} from "react";
import { Box, useMediaQuery} from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/it5262/landingimg1.png";
import landing2 from "../../../images/it5262/landingimg2.png";
import menuIcon from "../../../images/productlanding/superguruicon.png";
import backgroundImage from "../../../images/productlanding/supergurufeaturesbg.png";
import icon1 from "../../../images/it5262/icon1.png";
import icon2 from "../../../images/it5262/icon2.png";
import icon3 from "../../../images/it5262/icon3.png";
import MyIcon from "../../../images/productlanding/supergurumarqueeicon.png";
// import play from "../../../images/productlanding/play.png";
// import patterns from "../../../images/P55/patterns.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import blacksmall1 from "../../../images/it5262/productsmall.png";
import blacksmall2 from "../../../images/it5262/productsmall.png";
import blacksmall3 from "../../../images/it5262/productsmall.png";
import compare11 from "../../../images/Power120/compare1.png";
import compare2 from "../../../images/Power120/compare2.png";
import compare3 from "../../../images/Power120/compar3.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import black1 from "../../../images/productlanding/productversionsuperguru.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/productlanding/blueproduct.png";
import blue2 from "../../../images/productlanding/blueproduct.png";
import blue3 from "../../../images/productlanding/blueproduct.png";
import green1 from "../../../images/productlanding/green.png";
import green2 from "../../../images/productlanding/green.png";
import green3 from "../../../images/productlanding/green.png";
import similar1 from "../../../images/productlanding/featuredsimiler1.png";
import similar2 from "../../../images/productlanding/featuredsimiler2.png";
import similar3 from "../../../images/productlanding/featuredsimiler3.png";
import similar4 from "../../../images/productlanding/featuredsimiler4.png";
import landingbg1 from "../../../images/it5262/landingbg1.png";
import landingbg2 from "../../../images/it5262/landingbg2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/productcomponents/FeaturedPhone/Specifications";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import ProductColor from "../../../components/productcomponents/FeaturedPhone/ProductColor";
import charge from "../../../images/it5262/battery.png";
import vision from "../../../images/it5262/typec.png";
import gaming from "../../../images/it5262/display.png";
import charge2 from "../../../images/it5262/battery22.png";
import vision2 from "../../../images/it5262/typec2.png";
import gaming2 from "../../../images/it5262/display2.png";


const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
  {
    question: "Cannot make a call after activating data.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "How to set SIM card 2 as default mode when sending messages.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Phone cannot display recent calls.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "When calling, both sides cannot hear each other.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
// const images = {
//   starryBlack: [black1, black2, black3],
//   green: [green1, green2, green3],
//   blue: [blue1, blue2, blue3],
// };

// const colorNames = {
//   starryBlack: "blue",
//   green: "Royal Green",
//   blue: "Aurora Blue",
  
// };

// const colorStyles = {
//   starryBlack: "#D2C8ED",
//   green: "#AFE1C1",
//   blue: "#3B4455",
 
// };
const productVersions = [
  {
    label: "SUPER GURU 4G",
    images: {
      blue: [black1, black2, black3],
      royalgreen: [blue1, blue2, blue3],
      black: [green1, green2, green3],
    },
    colors: ["blue", "royalgreen", "black"],
  },
  {
    label: "SG200",
    images: {
      red: [green1, green2, green3],
      yellow: [blue1, blue2, blue3],
      black: [black1, black2, black3],
    },
    colors: ["red", "yellow", "black"],
  },
  {
    label: "SG600",
    images: {
      red: [black1, black2, black3],
      yellow: [green1, green2, green3],
      black:[blue1, blue2, blue3] ,
    },
    colors: ["red", "yellow", "black"],
  },
  {
    label: "SG400",
    images: {
      red: [blue1, blue2, blue3],
      yellow:[black1, black2, black3] ,
      black: [green1, green2, green3],
    },
    colors: ["red", "yellow", "black"],
  },
  // Add more versions as needed
];

const smallImages={
  blue: [blacksmall1, blacksmall2, blacksmall3],
  royalgreen: [orangesmall1, orangesmall2, orangesmall3],
  black: [bluesmall1, bluesmall2, bluesmall3],
}

const colorNames = {
  blue: "Blue",
  royalgreen: " Royal Green",
  black: "Black",
  red: "Red",
  yellow: "Yellow",
  
};

const colorStyles = {
  blue: "#D2C8ED",
  royalgreen: "#AFE1C1",
  black: "#3B4455",
  red: "#FF0000",
  yellow: "#FFFF00",
  
};

const products2 = [
  {
    name: "it5262",
    price: "₹1,269.00",

    imageUrl: similar1,
  },
  {
    name: "it5330",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar2,
  },
  {
    name: "Circle 1",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar3,
  },
  {
    name: "SuperGuru",
    price: "₹1,269.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "2000mAh Long Lasting Battery",
    description:
      "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
    icon: icon1,
  },
  {
    title: "6.1cm (2.4) bright display",
    description:
      "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
    icon: icon2,
  },
  {
    title: "Wireless FM radio with recording",
    description:
      "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "YOUR PERFECT COMPANION",
    text: "Introducing the it2175 Pro, the stylish feature phone with a big and bright 5.1cm (2) display, impressive 1200mAh long-lasting battery and Record your memories with the rear camera. Stay connected for longer with up to 25 days of standby time. Store up to 2000 contacts with their photos to quickly identify them. Choose from three attractive colors to suit your style. The it2175 Pro is the perfect phone for anyone who wants a stylish and reliable feature phone with a long battery life.",
  },
];

const specs = {
  generalSpecs: {
    Model: "it5330",
    colors: " Black, Blue, Royal Green",
  },
  display: {
    displatSize: "4.57cm(1.8)",
    resolution: "128*160 Pixels",
    resolutionType: "QQVGA",
    size: "4.57cm(1.8)",
    pixelDensity:"166 PPI"
  },
  osProcessor: {
    operatingSystem: "Android 12 GO Edition)",
    chipSet : "Unisoc SC9832E",
    processorCore: "Quadore",
    
  },
  camera: {
    rearCamera: "2 MP",
    rearFlash: "Yes",
    frontCamera: "VGA",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "3020 mAh",
    batteryType: "Li-Polymer",
    USB: "Miro",
    standBy: "25 Days",
    talkTime: "23.6 Hrs",
  },
  memoryStorage: {
    ram:"4GB(2GB + 2 GB*)",
    rom:"32GB",
    userMemory:"Approx 25GB",
    expandable:"32GB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3|8",
    EDGE: "Support Download",
    WCDMA: "3G:B1|5|8",
    LTE: "FDD B1/3/5/8 , TDD B40/B41)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "sUPPORT 4.2",
    wiFi : "Yes",
    GPS : "Yes",
    usbConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Proximity Sensor | Face Unlock | G Sensor",
    accessories: "Adapter | USB Cable | Protective Cover",
    thickness: "10.5 mm"
    
  },
};

const models = [
  {
    name: "SuperGuru 4G",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "it5262",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "it5330",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];


const It5262 = () => {

  // State to manage selected tab
  //const [selectedTab, setSelectedTab] = useState(0);

  // Images to display for each tab
  // const tabImages = [
  //   black1,
  //   blue1,
  //   green1,
  //   similar1, // Add more images as needed
  // ];

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTab(newValue);
  // };

  //const linkText = "Check out the support page for more.";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "100%",
    sm:"55%",
    md: "65%",
    lg: "30%",
  }

  const stylish = isSmallScreen ? charge2 : charge;

  const upi = isSmallScreen ? vision2 : vision;
  const battery = isSmallScreen ? gaming2 : gaming;

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "30vw";
    const yValue = isSmallScreen ? "-5vh" : "0vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue ,y:yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "white" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          //appBarBorder= "1px solid #111111"
          appBarColor="#181818" // Remove this line to use linear color
          mainTexts={mainTexts}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="IT 2175 Pro"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor="white"
          OverviewSpecificationcolor="#fff"
          SpecificationsORTechSpecs= "Tech Specs"
          buynowbuttonbgcolor="#fff"
          buynowbuttoncolor="#181818"
          featuresborder="none" // Added border here
          headingColor="#FF0037" // Remove this line to use linear color
          rightsideTextColorFeatures="#181818"
          featuresubheadingcolor="#181818" // Remove this line to use linear color
          featurediscriptiontextcolor="#5C5B5A"
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#EBE8E2"
          textColor="#666666" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="SNAP BACK TO REALITY"
          text2="CALL BACK TO THE FUTURE"
          linearTextColor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          // Uncomment the below lines if you need the video and pattern image under that video
          // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/5788/d738/-fccb-4db7-8c57-7b119e59778c?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QXb1Vb6GpLhFQmG82IkV5QcJOZi4aZLFAtIqk40t6fa5cpGVzxhfbpprJ7wzI4pgX8geLBtPDBiCCBpekUJD-72lScn9Ladzs6E3ZVhw8ZlSk313vOmQTJ0TnpVvGt4FYOyOAxMOsSc39~Cn-8bm65lEgfwMAMhs17O~b-zVA5FqHNXX7SboFqdX1Dxkcm0v7CgxrrZeOnWOEcTuw~Xy-8ov4iTN9p1az8q-HRok3a~zsJlgA8fNuyVgjfgesjj~2LjcwUKvV9nmfGgwqal0tEZup~Xc9yOT-Kglyyxh3hSJ89H9JbIausdOwA4rDm35Vit4xdPGL6rm9fx49XmgdQ__"
          // playIconSrc={play}
          // frameSrc={patterns}
        />

      <Box sx={{ backgroundColor: "white" }}>
        
        <Box sx={{ pr: "5%", pl: "5%",pt:"4%" }}>
        <ProductColor
      colorNames={colorNames}
      colorStyles={colorStyles}
      smallImages={smallImages}
      colortextcolor="black"
      initialColor="blue" // set the initial color
      colornametextcolor="#181818"
      selectedBgColor="#fff"
      productVersions={productVersions}
      isNextArrowBlack={false}
      isPrevArrowBlack={false}
    />
        </Box>
      </Box>


        {/* //--------------------------------------------------Battery--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block", height: "650px" }}
            src={stylish}
            alt="stylish"
          />
        </Box>

        {/* //--------------------------------------------------Type C Charging--------------------------------------// */}
        <Box >
          <img style={{ width: "100%", height: "700px", padding: isSmallScreen ? "6% 4% 6% 4%" : "0% 0% 0% 0%"}} src={upi} alt="upi" />
        </Box>

       

        {/* //--------------------------------------------------Battery image--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block", height: "650px"}}
            src={battery}
            alt="charge"
          />
        </Box>

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#fff"} />

        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={false}
          bgColor="white"
        />
        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        <CompareModels mode="light" bgcolor="white" models={models} isTwoMapView= "true" />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          mode="light"
          bgcolor="white"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="light" bgcolor="white" />

        {/* Check out Products */}
        <CheckOutProducts bgColor={"#fff"} heading ="Check out our other Products"/>

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="light" bgcolor="white" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // mode="light"
          // bgcolor="white"
          // accordionBackgroundColor="white"
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
        />
      </Box>
    </>
  );
};

export default It5262;