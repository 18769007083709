import React, { useState } from "react";
import landing1 from "../../images/media/mediaLanding.png";
import landing2 from "../../images/media/mediresp.png";
import filterIcon from "../../images/media/filter.png";
import arrowIcon from "../../images/media/arrowright.png";
import {
  Box,
  Tabs,
  Tab,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import news1 from "../../images/media/news1.png";
import news2 from "../../images/media/news2.png";
import news3 from "../../images/media/news3.png";
import news4 from "../../images/media/news4.png";
import news5 from "../../images/media/news5.png";
import news6 from "../../images/media/news6.png";
import news7 from "../../images/media/news7.png";
import news8 from "../../images/media/news8.png";
import news9 from "../../images/media/news9.png";
import { CustomButton } from "../../components/Buttons/CustomButton";

const items = [
  {
    category: "NEWS",
    title:
      "itel India's Heartwarming World Cup Campaign: Connecting India through the...",
    img: news1,
  },
  {
    category: "NEWS",
    title:
      "Exclusive reveal: itel to unveil A60s, India's First 8GB* RAM Smartphone for Less Th...",
    img: news2,
  },
  {
    category: "NEWS",
    title:
      "Revolutionize Your Wearable Tech: Unveiling the itel Smartwatch 2 Ultra wit...",
    img: news3,
  },
  {
    category: "BLOG",
    title: "How to secure your smartphone without killing the fun?",
    img: news4,
  },
  {
    category: "BLOG",
    title: "Imagine a day with uninterrupted HiFi Sound.",
    img: news5,
  },
  {
    category: "NEWS",
    title:
      "itel cements position in India: Launches Super Guru Series: The Premium Phone w...",
    img: news6,
  },
  {
    category: "NEWS",
    title:
      "itel cements position in India: Launches Super Guru Series: The Premium Phone w...",
    img: news7,
  },
  {
    category: "BLOG",
    title:
      "itel cements position in India: Launches Super Guru Series: The Premium Phone w...",
    img: news8,
  },
  {
    category: "BLOG",
    title:
      "itel cements position in India: Launches Super Guru Series: The Premium Phone w...",
    img: news9,
  },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
};

function Media() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredItems = (category) => {
    if (category === "All") return items;
    return items.filter((item) => item.category === category);
  };

  return (
    <>
      {/* Landing */}

      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: '80vh', md: '80vh' },
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landing2 : landing1}
          alt="Banner"
          sx={{ width: "100%", height: "100%", display: "block" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: { md: "10%", xs: "40%", sm: "10%" },
            color: "white",
            marginLeft: { md: "8%", xs: "4%" },

          }}
        >

          <Typography
            sx={{
              fontSize: {
                xs: "36px",
                sm: "40px",
                md: "50px",
                lg: "80px",
                xl: "80px",
              },
              fontWeight: "500",
              mb: 1,

            }}
          >
            {isSmallScreen ? (
              <>
                Itel Media
                <br />
                Haven
              </>
            ) : (
              "Itel Media Haven"
            )}
          </Typography>
          <Typography
            sx={{
              color: "FAF9F6",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "20px",
                xl: "36px",
              },

              fontFamily: 'markOT-Light',
              letterSpacing: "-1%",
            }}
          >
            {isSmallScreen ? (
              <>
                Blog Binge and News
                <br />
                Updates!
              </>
            ) : (
              "Blog Binge and News Updates!"
            )}

          </Typography>
        </Box>
      </Box>

      {/* Main Section */}
      <Box p={{ xs: "2%", sm: "4% 4% 10% 4%" }}>
        {isSmallScreen && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <IconButton color="primary">
              <Box
                component="img"
                src={filterIcon}
                alt="Filter"
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="button" sx={{ ml: 1 }}>
                Filter
              </Typography>
            </IconButton>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "red",
              },
            }}
          >
            <Tab label="All" />
            <Tab label="News" />
            <Tab label="Blogs" />
          </Tabs>
          {!isSmallScreen && (
            <IconButton color="primary">
              <Box
                component="img"
                src={filterIcon}
                alt="Filter"
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant="button" sx={{ ml: 1 }}>
                Filter
              </Typography>
            </IconButton>
          )}
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            {filteredItems("All").map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <CardMedia
                    component="img"
                    image={item.img}
                    alt={item.title}
                    sx={{ height: { xs: "320px", sm: "400px", md: "auto", lg: 'auto' } }}
                  />
                  <CardContent sx={{ paddingLeft: 0 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {item.category}
                    </Typography>
                    <Typography variant="body2">{item.title}</Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                      {/* <Typography sx={{ fontFamily: { lg: 'markOT-Bold', xs: 'markOT-Medium', fontSize: { xs: '12px', lg: '16px' } } }}>
                        VIEW
                      </Typography> */}
                      <CustomButton/>
                      {/* <Box
                        component="img"
                        src={arrowIcon}
                        alt="Arrow"
                        sx={{ fontSize: "13px", ml: 1 }}
                      /> */}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            {filteredItems("NEWS").map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <CardMedia
                    component="img"
                    image={item.img}
                    alt={item.title}
                    sx={{ height: { xs: "320px", sm: "400px", md: "auto" } }}
                  />
                  <CardContent sx={{ paddingLeft: 0 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {item.category}
                    </Typography>
                    <Typography variant="body2">{item.title}</Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                      <Box size="small" color="primary">
                        VIEW
                      </Box>
                      <Box
                        component="img"
                        src={arrowIcon}
                        alt="Arrow"
                        sx={{ fontSize: "13px", ml: 1 }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={2}>
            {filteredItems("BLOG").map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: "100%", boxShadow: "none" }}>
                  <CardMedia
                    component="img"
                    image={item.img}
                    alt={item.title}
                    sx={{ height: { xs: "320px", sm: "400px", md: "auto" } }}
                  />
                  <CardContent sx={{ paddingLeft: 0 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {item.category}
                    </Typography>
                    <Typography variant="body2">{item.title}</Typography>
                    <Box display="flex" alignItems="center" mt={1}>
                      <Typography sx={{ color: 'red', fontFamily: 'markOT-Bold', fontSize: { md: '16px', xs: '14px' } }}>
                        VIEW
                      </Typography>
                      <Box
                        component="img"
                        src={arrowIcon}
                        alt="Arrow"
                        sx={{ fontSize: "13px", ml: 1 }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
}

export default Media;
