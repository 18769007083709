import React, { useEffect } from "react";
import { Box, useMediaQuery,  Grid,Hidden, Typography } from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/P55/RithikBig.png";
import landing2 from "../../../images/P55/RithikBig.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/P55T/avatar.png";
import backgroundImage from "../../../images/P55/featuresBg.png";
import icon1 from "../../../images/P55/icon1.png";
import icon2 from "../../../images/P55/icon2.png";
import icon3 from "../../../images/P55/icon3.png";
import MyIcon from "../../../images/P55/blueicon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/P55/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import CarouselPanorama from "./carousel";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/P55/productColor1Big.png";
import blue2 from "../../../images/P55/productColor1Big.png";
import blue3 from "../../../images/P55/productColor1Big.png";
import green1 from "../../../images/productlanding/green.png";
import green2 from "../../../images/productlanding/green.png";
import green3 from "../../../images/productlanding/green.png";
import bluesmall1 from "../../../images/P55/productcolorsmall.png";
import bluesmall2 from "../../../images/P55/productcolorsmall.png";
import bluesmall3 from "../../../images/P55/productcolorsmall.png";
import blacksmall1 from "../../../images/P55/productColorsmall2.png";
import blacksmall2 from "../../../images/P55/productColorsmall2.png";
import blacksmall3 from "../../../images/P55/productColorsmall2.png";
import goldsmall1 from "../../../images/P55/productcolorsmall3.png";
import goldsmall2 from "../../../images/P55/productcolorsmall3.png";
import goldsmall3 from "../../../images/P55/productcolorsmall3.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/P55/LandingBgBig.png";
import landingbg2 from "../../../images/P55/landingBgsmall.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import first from "../../../images/P55/tech1Big.png";
import second from "../../../images/P55/tech2Big.png";
import third from "../../../images/P55/tech3Big.png";
import fourth from "../../../images/P55/Tech4Big.png";
import charge from "../../../images/P55/vertical1Big.png";
import vision from "../../../images/P55/vertical2Big.png";
import ai from "../../../images/P55/vertical3Big.png";
import panorama from "../../../images/P55/vertical4Big.png";
import gaming from "../../../images/P55/vertical5Big.png";
import processor from "../../../images/P55/vertical6Big.png";
import charge2 from "../../../images/P55/vertical1small.png";
import third2 from "../../../images/P55/tech3small.png";
import first2 from "../../../images/P55/tech1small.png";
import second2 from "../../../images/P55/tech2small.png";
import ai2 from "../../../images/P55/vertical3small.png";
import panorama2 from "../../../images/P55/vertical4small.png";
import processor2 from "../../../images/P55/vertical6small.png";
import fourth2 from "../../../images/P55/tech4small.png";
import vision2 from "../../../images/P55/vertical2small.png";
import gaming2 from "../../../images/P55/vertical5small.png";


const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  blue: [blue1, blue2, blue3],
  black: [black1, black2, black3],
  gold: [green1, green2, green3],
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  gold: [goldsmall1, goldsmall2, goldsmall3],
}

const colorNames = {
  blue: "Aurora Blue",
  black: "Moonlit Black",
  gold: "Brilliant Gold",
};

const colorStyles = {
  black: "#606367",
  blue: "#7FCEE6",
  gold: "#FFE6A5",
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "Blazing Speed: 24GB* (8GB + 16GB*) RAM + 128GB Storage",
    description:
      "Experience lightning-fast performance with 8GB RAM, expandable upto 24GB* using Memory Fusion. ",
    icon: icon1,
  },
  {
    title: "Capture Brilliance: 50MP AI Dual Rear Camera",
    description:
      "Elevate your photography with intelligent 50 MP AI dual camera to get stunning clarity in every frame.",
    icon: icon2,
  },
  {
    title: "Gaming Powerhouse: Unisoc T606 Octa-Core Processor with iBoost Tech",
    description:
      "Level up your seamless gaming experience with the T606 Octa-Core processor and iBoost gaming technology.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Zap, Play, Win: All Possible with 24GB* (8GB + 16GB*) RAM",
    text: "Introducing the all-new itel P55 – your ultimate power play! With a whopping 8GB RAM, expandable upto 24GB* using Memory Fusion and 128GB ROM, capture every moment in stunning detail with the 50MP AI dual camera. Stay charged up with 18W- Type C fast charging and a massive 5000 mAh battery. Immerse yourself in a visual feast with the beautiful 6.6' HD+ display boasting a smooth 90Hz refresh rate. Plus, the punch hole display with dynamic bar makes accessing notifications a breeze."
  },
];


const specs = {
  generalSpecs: {
    model: "P55",
    colors: ["Moonlit Black", "Aurora Blue", "Brilliant Gold"],
  },
  display: {
    displaySize: "6.56' HD+",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    dimension:"163.54*76.04*8.4 mm",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisco T606(12nm)",
    processorCore:"Octa Core",
  },
  camera: {
    rearCamera: "50 MP AI Dual Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "41 Days",
    talkTime: "60 Hours",
    mediaPlaybackTime:"Video: 24 Hours"
  },
  memoryStorage: {
    ram:"8GB + 16GB",
    rom:"128GB",
    userMemory:"113GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "5.0",
    wiFi : "802.11 ac/a/b/g/n(2.4G/5G)",
    GPS : "Yes",
    USBConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    thickness:"8.4mm",
    sensors: "Fingerprint | Face Unlock | G Sensor | Electronic Compass ",
    fingerprint:"Side",
    accessories: "18W Charger| USB Cable | Protective Cover | Protective Film",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const P55 = () => {
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  //const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const maxWidthLandingImage = {
    xs: "55%",
    sm:"45%",
    md: "37%",
    lg: "37%",
  }
  
  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const chargeimg = isSmallScreen ? charge2 : charge;
  const aiimg = isSmallScreen ? ai2 : ai;
  const panoramaimg = isSmallScreen ? panorama2 : panorama;
  const processorimg = isSmallScreen ? processor2 : processor;
  const visionimg = isSmallScreen ? vision2 : vision;
  const gamingimg = isSmallScreen ? gaming2 : gaming;

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "20vw";
    const yValue = isSmallScreen ? "6vh" : "2.5vh";
    // const xValue = {xs:"0vw", sm:"0vw", md:"3vw", lg:"20vw"};
    // const yValue = {xs:"0vh", sm:"0vh", md:"2vh", lg:"2.5vh"};
    

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);


  return (
    <>
      <Box sx={{ bgcolor: "#0A0C0A" }}>
        {/* landing */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#91C9EC"
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          SpecificationsORTechSpecs= "Specifications"
          OverviewSpecificationcolor="#181818"
          backgroundImageFeatures={backgroundImage}
          productname="P55"
          Productnamecolor="#181818"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          buyNowButtonLabel="Buy Now"
          headmaintext="P55"
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#FFFFFF"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          featuresborder="none" 
          //headingColor="linear-gradient(to left, #D2B363,#EECF7F)" 
          rightsideTextColorFeatures="#FFFFFF"
          //featuresubheadingcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          featurediscriptiontextcolor="#FFFFFF"
        />

        {/*  vedio and colorchange part */}

        <Marquee
          appBarColor="#001C3A"
          textColor="#D5EEF9"
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/f64b/0b3b/-585c-4c30-b259-6c1938f9657d?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jw2rcvzMDWusr~lOq27TTP~Ju3185YuScEYVYQAL81s~vJHaeV0Wjh-49SuSzedOSGLIaYZZjXSuniWPtsbNlCMHs5KtMSsxw-lAsh6lZcvk2e0zdJ4E1TZQ08QJ1cG8961P99PCtOXhTb2HHNc~TV7FEljgve~7yZRVd7FpKymSjFdo8PVM4Xcz4Imb7CrvtpFGLD44uSTtYX9dJmB3B1ESsPI2sP3CiyAUSOsSbIZJ70JCeAqQNU1baHXiPdeq8XbeXuUkTv3QiqEu6y2rqL3S0z9nH7rXaz61gEBwgzKQjwGNMl8lE3M-Pwt6QYxxO-OonWYuPj~4GVpzVZHE6A__"
          playIconSrc={play}
          frameSrc={patterns}
        />

      
      {/* Charge */}
        <Box >
        <img  style= {{ width: "100%", display: "block"}}    src={chargeimg} alt='charge' />
        </Box>


        {/* Vision */}
        <Box >
        <img style= {{ width: "100%", display:"block"}} src={visionimg} alt='charge' />
        </Box>


        <Box 
          sx={{padding: "7% 4% 7% 4%", backgroundColor: "white"}}
        >
          <CarouselPanorama />
        </Box>

        {/* ai */}
        <Box >
        <img style= {{ width: "100%", display:"block"}} src={aiimg} alt='charge' />
        </Box>

        {/* panorama */}
        <Box sx={{ padding: "6% 4% 6% 4%", backgroundColor: "#FFFFFF"}}>
        <img style= {{ width: "100%"}} src={panoramaimg } alt='charge' />
        </Box>

        {/* gaming */}
        <Box sx={{padding: "6% 4% 6% 4%", backgroundImage: "linear-gradient(to left, #70A2E1,#D5EEF9)"}}>
        <img style= {{ width: "100%", display: "block"}} src={gamingimg} alt='charge' />
        </Box>

        {/* processor */}
        <Box>
        <img style= {{ width: "100%", display: "block"}} src={processorimg} alt='charge' />
        </Box>

          {/* TECHSPECS */}
      <Box sx={{pt: "6%" , pl: "4%", pr: "4%", pb: "6%", backgroundColor: "#181818"}}>
      
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={firstimg}
                alt="first"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={secondimg}
                    alt="Swift Power-Up"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={thirdimg}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <img
            src={fourthimg}
            alt="last"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "16px",
            }}
          />
        </Box>
      </Box>   

        {/* colorchange */}
        <Box sx={{ backgroundColor: "#D5EEF9" }}>
        <Box sx={{pt:"6%", pl:"4%", pr: "4%", }}>
        <Hidden smUp>
                <Typography variant="h4" sx={{ color: "#181818", pb:"5%", pl:"2%" }}>
                  Product Version
                </Typography>
              </Hidden>
          
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              initialColor="blue"
              isNextArrowBlack={true}
              isPrevArrowBlack={true}
              selectedBgColor="#ffffff"
            />
          </Box>
        </Box>

      

      {/* Specifications */}
        <Specifications specs={specs} bgColor={"#181818"} />

        {/* Review */}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#0A0C08"
        />

        {/* Level Up Experience with Accessories */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
          //bgColor="#0A0C08"
        />

        {/* Compare Models */}
        <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        {/* Similar Products */}
        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />

        {/* Support Channels */}
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />

        {/* Check out Products */}
        <CheckOutProducts 
          bgColor={"#0A0C08"} 
          heading="Check out our other Products"
        />
        
        {/* Follow us & Updated */}
        <FollowUs 
          mode="dark"
          isDarkMode={true}/>

        {/* FAQs */}
        <ProductFaqs

          mode="dark"  
          bgColor="#181818"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#0A0C08"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default P55;