import React, { useEffect } from "react";
import { Box, useMediaQuery, Typography, Grid, Hidden} from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import CarouselPanorama from "./carousel";
import gsap from "gsap";
import landing1 from "../../../images/P40+/landingimg.png";
import landing2 from "../../../images/P40+/landingimg2.png";
import menuIcon from "../../../images/P40+/picon.png";
import backgroundImage from "../../../images/P40+/landingbg3.png";
import icon1 from "../../../images/P40+/icon1.png";
import icon2 from "../../../images/P40+/icon2.png";
import icon3 from "../../../images/P40+/icon3.png";
import MyIcon from "../../../images/P40+/icon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/P40+/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/productversionsuperguru.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blacksmall1 from "../../../images/P55/productcolorsmall3.png";
import blacksmall2 from "../../../images/P55/productcolorsmall3.png";
import blacksmall3 from "../../../images/P55/productcolorsmall3.png";
import blue1 from "../../../images/P40+/productColor1Big.png";
import blue2 from "../../../images/P40+/productColor1Big.png";
import blue3 from "../../../images/P40+/productColor1Big.png";
import bluesmall1 from "../../../images/P40+/productColorsmall1.png";
import bluesmall2 from "../../../images/P40+/productColorsmall1.png";
import bluesmall3 from "../../../images/P40+/productColorsmall1.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import landingbg1 from "../../../images/P40+/landingbg1.png";
import landingbg2 from "../../../images/P40+/landingbg2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import gaming from "../../../images/P40+/storage.png";
import first from "../../../images/P40+/first.png";
import first2 from "../../../images/P40+/first2.png";
import second from "../../../images/P40+/second.png";
import second2 from "../../../images/P40+/second2.png";
import third from "../../../images/P40+/third.png";
import third2 from "../../../images/P40+/third2.png";
import fourth from "../../../images/P40+/fourth.png";
import fourth2 from "../../../images/P40+/fourth2.png";
import fifth from "../../../images/P40+/fifth.png";
import fifth2 from "../../../images/P40+/fifth2.png";
import gaming2 from "../../../images/P40+/storage2.png";
import camera from "../../../images/P40+/camera.png";
import camera2 from "../../../images/P40+/camera2.png";
import clarity from "../../../images/P40+/clarity.png";
import clarity2 from "../../../images/P40+/clarity2.png";
import performance from "../../../images/P40+/performance.png";
import performance2 from "../../../images/P40+/performance2.png";

const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  blue: [blue1, blue2, blue3],
  black: [black1, black2, black3],
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
}

const colorNames = {
  blue: "Ice Cyan",
  black: "Starry Black",
};

const colorStyles = {
  blue: "#CFE7DB",
  black: "linear-gradient(to left, #060708,#ACB1B7)",
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];

const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "7000 mAh battery with 18W Fast charging ",
    description:
      "Enjoy unmatched battery life and lasting performance without breaking the bank with the itel P40+.",
    icon: icon1,
  },
  {
    title: "Ample Storage, Seamless Performance",
    description:
      "With 4GB RAM, expandable upto 8GB with Memory Fusion, and 128GB ROM, you get ample storage and smooth multitasking.",
    icon: icon2,
  },
  {
    title: "Immersive 6.8'' HD+ Punch-Hole Display",
    description:
      "Take your viewing experience to the next level with exceptional clarity and immersive visuals on the itel P40+.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Unmatched Battery: 7000 mAh Smartphone under 9k",
    text: "Get ready for the ultimate power play with the itel P40+ 7000mAh smartphone, all for under Rs. 9000/-. With unrivalled battery power, enjoy extended usage and lasting performance. Experience super splendid design, seamless multitasking, exceptional viewing capabilities, and ample storage, all packed into one sleek device- itel P40+.",
  },
];

const specs = {
  generalSpecs: {
    model: "P40+",
    colors: ["Ice Cyan", "Force Black"],
  },
  display: {
    displaySize: "6.8'HD + Punch Hole",
    resolution:"1640*720 Pixels",
    resolutionType:"HD + IPS",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"259 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisco T606",
    processorCore:"OctaCore",
  },
  camera: {
    rearCamera: "13MP AI Dual Rear Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
  },
  battery: {
    batteryCapacity: "7000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "61 Days",
    talkTime: "48 Hours",
    mediaPlaybackTime:"62.5 Hours"
  },
  memoryStorage: {
    ram:"8GB*(4GB + 4GB*)",
    rom:"128 GB",
    userMemory:"113 GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8, G+F, Incell",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Type C",
  },
  additional: {
    earphoneJack: "3.5 mm",
    thickness:"8.9 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor ",
    fingerprint:"Side",
    accessories: "18W Adapter | USB Cable | Protective Cover",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const P40PLUS = () => {
  //const linkText = "Check out the support page for more.";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "75%",
    sm:"45%",
    md: "37%",
    lg: "42%",
  }

  const battery = isSmallScreen ? gaming2 : gaming;
  const cameraimg = isSmallScreen ? camera2 : camera;
  const clarityimg = isSmallScreen ? clarity2 : clarity;
  const performanceimg = isSmallScreen ? performance2 : performance;
  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const fifthimg = isSmallScreen ? fifth2 : fifth;

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "17vw";
    const yValue = isSmallScreen ? "5.2vh" : "7.7vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue });

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "#0A0C08" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          appBarColor="#ffffff" // Remove this line to use linear color
          appBarBorder= "1px solid #111111"
          mainTexts={mainTexts}
          maxWidthLandingImage={maxWidthLandingImage}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="P40+"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor="#181818"
          OverviewSpecificationcolor="#181818"
          SpecificationsORTechSpecs= "Tech Specs"
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#ffffff"
          featuresborder="none" // Added border here
          headingColor="var(--Colors-Blue-Blue-2, #3A3358);" // Remove this line to use linear color
          rightsideTextColorFeatures="var(--Colors-Blue-Blue-2, #3A3358);"
          featuresubheadingcolor="var(--Colors-Blue-Dark-Blue, #050036);" // Remove this line to use linear color
          featurediscriptiontextcolor="var(--Colors-Blue-Blue-2, #3A3358);"
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#181818"
          textColor="#ADDFDA" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/f64b/0b3b/-585c-4c30-b259-6c1938f9657d?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jw2rcvzMDWusr~lOq27TTP~Ju3185YuScEYVYQAL81s~vJHaeV0Wjh-49SuSzedOSGLIaYZZjXSuniWPtsbNlCMHs5KtMSsxw-lAsh6lZcvk2e0zdJ4E1TZQ08QJ1cG8961P99PCtOXhTb2HHNc~TV7FEljgve~7yZRVd7FpKymSjFdo8PVM4Xcz4Imb7CrvtpFGLD44uSTtYX9dJmB3B1ESsPI2sP3CiyAUSOsSbIZJ70JCeAqQNU1baHXiPdeq8XbeXuUkTv3QiqEu6y2rqL3S0z9nH7rXaz61gEBwgzKQjwGNMl8lE3M-Pwt6QYxxO-OonWYuPj~4GVpzVZHE6A__"
          playIconSrc={play}
          frameSrc={patterns}
        />

        {/* ------------------------------------------------------performance----------------------------------------------- */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={performanceimg}
            alt="charge"
          />
        </Box>

        {/* ------------------------------------------------------------Unparalleled clarity--------------------------------------- */}

        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={clarityimg}
            alt="charge"
          />
        </Box>

        {/* //--------------------------------------------------Storage--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={battery}
            alt="charge"
          />
        </Box>

        {/* --------------------------------------------Camera------------------------------------------- */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={cameraimg}
            alt="charge"
          />
        </Box>

        <Box 
          sx={{padding: "6% 4% 6% 4%", backgroundColor: "white"}}
          
          >
          <CarouselPanorama />
        </Box>

        {/* ------------------------------------------------ Phone Tech Specs------------------------------------------------- */}

        <Box sx={{ pl: "4%", pr: "4%", pb: "6%", pt: "6%", backgroundColor: "#181818" }}>
          
          <Box sx={{ mt: 2 ,pb:"1%" }}>
            <img
              src={firstimg}
              alt="first"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
              }}
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box>
                <img
                  src={secondimg}
                  alt="second"
                  style={{
                    width: "100%",
                    height: "calc(121vh - 20px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" spacing={2}>
                
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box>
                        <img
                          src={thirdimg}
                          alt="fourth"
                          style={{
                            width: "100%",
                            height: "calc(59vh - 10px)",
                            borderRadius: "16px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box>
                        <img
                          src={fourthimg}
                          alt="fifth"
                          style={{
                            width: "100%",
                            height: "calc(59vh - 10px)",
                            borderRadius: "16px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                  <Box>
                    <img
                      src={fifthimg}
                      alt="third"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                      }}
                    />
                  </Box>
                </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        {/* //--------------------------------------------------ProductColorChange--------------------------------------// */}

        {/* make sure to change backgroundcolor of this part here  */}

          <Box sx={{ backgroundColor: "#131C0E" }}>
            <Box sx={{ padding: isSmallScreen ? "6% 0% 6% 0%" : "6% 4% 6% 4%" }}>
              <Hidden smUp>
                <Typography variant="h4" sx={{ color: "#ffffff", pb:"5%", pl:"4%" }}>
                  Product Version
                </Typography>
              </Hidden>
              <ProductColor
                images={images}
                colorNames={colorNames}
                smallImages={smallImages}
                colorStyles={colorStyles}
                colortextcolor="#ffffff"
                initialColor="blue" //set default color as selected when load the page
                selectedBgColor="#131C0E"
                isNextArrowBlack={false}
                isPrevArrowBlack={false}
              />
            </Box>
          </Box>

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#181818"} />

        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          bgColor="#0A0C08"
        />

        {/* ----------------------------------------Level Up Your Experience with Accessories!----------------------------------- */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="dark"
          bgcolor="#0A0C08"
        />

        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        <CompareModels 
          mode="dark" 
          bgcolor="#0A0C08" 
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          isDarkMode={true}
          mode="dark"
          bgcolor="#0A0C08"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="dark" bgcolor="#0A0C08" />

        {/* Check out Products */}
        <CheckOutProducts 
          mode="dark" 
          bgColor={"#0A0C08"} 
          heading= "Check out our other Products"
        />

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="dark" bgcolor="#0A0C08" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          mode="dark"  
          bgColor="#181818"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#0A0C08"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default P40PLUS;