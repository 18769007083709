import React from "react";
import { Grid, Box, Typography, Button, useMediaQuery } from "@mui/material";
import support1 from "../../images/productlanding/support4.png";
import support1Dark from "../../images/productlisting/support1.png";
import support2 from "../../images/productlanding/support5.png";
import support2Dark from "../../images/productlisting/support2.png";
import support3 from "../../images/productlanding/support6.png";
import support3Dark from "../../images/productlisting/support3.png";
import { useTheme } from "@mui/material/styles";

const supportChannels = [
  {
    id: 1,
    image: support1,
    imageDark: support3Dark,
    title: "100 Days Promise",
  },
  {
    id: 2,
    image: support2,
    imageDark: support2Dark,
    title: "Screen Replacement",
  },
  {
    id: 3,
    image: support3,
    imageDark: support1Dark,
    title: "Service Center",
  },
];

const Support = ({ mode = "light", bgcolor }) => {
  const theme = useTheme();
  const isDarkMode = mode === "dark";
  const primaryTextColor = isDarkMode ? "white" : "black";


  // Example of using media query to dynamically switch images for light mode
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ bgcolor: bgcolor, py: 4 }}>
      <Typography
        variant="h4"
        sx={{
          color: primaryTextColor,
          pl:"4%",
          textAlign: isSmallScreen ? "left" : "center",
          mb: "4%",
          fontFamily: "MarkOT-Medium",
          
        }}
      >
        Support Channels
      </Typography>
      <Grid container spacing={3} justifyContent= {isSmallScreen ? "flex-start" : "center"}>
        {supportChannels.map((channel) => (
          <Grid item xs={6} sm={6} md={3} key={channel.id}>
            <Box
              sx={{
                color: primaryTextColor,
                borderRadius: "24px",
                textAlign: "center",
                p: 2,
              }}
            >
              <Box
                sx={{
                  height: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  //mb: 2,
                }}
              >
                <img
                  src={isDarkMode ? channel.imageDark : channel.image}
                  alt={channel.title}
                  style={{
                    width: isSmallScreen ? "180px" : "260px",
                    height: "auto",
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: isSmallScreen ? "1rem" : "1.5rem",
                  fontFamily: "MarkOT-Medium",
                  mt: isSmallScreen ? "1%" : "15%",
                  color: primaryTextColor,
                }}
              >
                {channel.title}
              </Typography>
              <Button
                disableElevation
                variant="text"
                sx={{
                  textTransform: "none",
                  fontFamily: "MarkPro-Medium",
                  color: primaryTextColor,
                  mt: 1,
                }}
              >
                Read more{" "}
                <span style={{ marginLeft: "8px", color: "#ff0000" }}>→</span>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Support;
