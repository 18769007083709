import React from "react";
import landing1 from "../../images/servicecenter/landing1.png";
import landing2 from "../../images/servicecenter/serviceresp.png";
import callcare from "../../images/servicecenter/Carlcare.svg";
import whitearrow from "../../images/servicecenter/Button1.png"
import icon1 from "../../images/servicecenter/setting.png";
import icon2 from "../../images/servicecenter/user-add.png";
import icon3 from "../../images/servicecenter/clock.png";
import bgimg1 from "../../images/servicecenter/bgimg1.png";
import bgimg2 from "../../images/servicecenter/bgimg2.png";

import RepairStatus from "../../images/servicecenter/RepairStatus.png";
import WarrentyCheck from "../../images/servicecenter/WarrentyCheck.png";
import SpareParts from "../../images/servicecenter/SparePart.png";
import rightarrow from "../../images/servicecenter/arrowright.png";
import india from "../../images/servicecenter/Indian.png";
import arrow45 from "../../images/servicecenter/Arrow45.svg"
import {
  Grid,
  Button,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconAnimation from "../../components/IconAnimation/IconAnimation";
import { CustomButton } from "../../components/Buttons/CustomButton";


function ServiceCenter() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const serviceCenters = [
    {
      address: "G-12, 10/54, B.D Chambers, Desh Bandhu Gupta Road, Karolbagh.",
      timings: [
        { days: "Mon-Fri", hours: "10:00 - 19:00" },
        { days: "Sat-Sun", hours: "10:00 - 21:00" },
      ],
      email: "godsenterprises@gmail.com",
      phone: "9266147147",
    },
    {
      address: "H-10, 22/54, M.N Chambers, Desh Bandhu Gupta Road, Karolbagh.",
      timings: [
        { days: "Mon-Fri", hours: "10:00 - 18:00" },
        { days: "Sat-Sun", hours: "10:00 - 20:00" },
      ],
      email: "servicecenter2@gmail.com",
      phone: "9266147890",
    },
    {
      address: "K-5, 30/54, X.Y Chambers, Desh Bandhu Gupta Road, Karolbagh.",
      timings: [
        { days: "Mon-Fri", hours: "09:00 - 17:00" },
        { days: "Sat-Sun", hours: "11:00 - 19:00" },
      ],
      email: "servicecenter3@gmail.com",
      phone: "9266147000",
    },
  ];

  return (
    <>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 10px;
          }
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          ::-webkit-scrollbar-thumb {
            background:#D9D9D9;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      {/* Landing */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: '100vh', md: '80vh' },
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landing2 : landing1}
          alt="Banner"
          sx={{
            width: "100%",
            height: "100%",
            display: "block",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "9%",
            transform: "translateY(-50%)",
            textAlign: "left",
            width: { xs: "80%", sm: "80%", md: "60%" },
            color: "white",

          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {
                xs: "30px",
                sm: "40px",
                md: "50px",
                lg: "70px",
                xl: "70px",
              },
              mb: { xs: 2, sm: 3 },
            }}
          >
            Weekend Bonanza at itel Service Center!
          </Typography>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4} container alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src={icon1}
                  alt="Free Service Icon"
                  sx={{
                    width: { xs: 30, sm: 22, md: 27, lg: 50 },
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "17px",
                      lg: "20px",
                    },
                  }}
                >
                  Free Service
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                    },
                  }}
                >
                  Saturday
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} container alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src={icon2}
                  alt="100% Discount Icon"
                  sx={{
                    width: { xs: 30, sm: 22, md: 27, lg: 50 },
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "17px",
                      lg: "20px",
                    },
                  }}
                >
                  100% Discount
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                    },
                  }}
                >
                  on Labour Charges
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} container alignItems="center">
              <Grid item>
                <Box
                  component="img"
                  src={icon3}
                  alt="10% Discount Icon"
                  sx={{
                    width: { xs: 30, sm: 22, md: 27, lg: 50 },
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item sx={{ ml: 1 }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "17px",
                      lg: "20px",
                    },
                  }}
                >
                  10% Discount
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                    },
                  }}
                >
                  on Accessories
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ width: { md: '364px', xs: '220.07px' }, height: { md: '60px', xs: '48px' }, backgroundColor: 'white', borderRadius: '50px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', pl: { xs: '4%', md: '2%' }, marginTop: { xs: '7%  ' }, marginBottom: { xs: '4%' } }} >
            <Grid container sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={5} md={6}>
                <Typography variant="h6" sx={{ fontSize: { md: '12px', xs: '10px' }, color: '#252525', fontWeight: 'light' }}>
                  Our Official Service Partner
                </Typography>
              </Grid>
              <Grid item xs={4} md={4} sx={{ mt: '2%' }}>
                <Box component="img" src={callcare} alt="" sx={{ width: { xs: '100%', md: '100%' }, height: '100%', padding: '0' }}>

                </Box>
              </Grid>
              <Grid item xs={3} md={2}>
                <Button sx={{ p: '0' }}>
                  <img
                    src={whitearrow}
                    alt="Submit Icon"

                  />
                </Button>
              </Grid>
            </Grid>

          </Box>
        </Box>
      </Box >

      {/* Main Part */}
      < Box sx={{ padding: "6% 7% 6% 7%" }}>
      
        <Box
          sx={{
            padding: { xs: "2%", sm: "4%" },
            textAlign: { xs: "left", sm: "center" },
            borderRadius: "16px",

          }}
        >

          <Grid container spacing={2}>
            <Grid item xs={12} md={4} >
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={SpareParts} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '20px', md: '24px' } }}>
                      Spare Parts Price
                    </Typography>
                    <Button sx={{ fontFamily: { md: 'markOT-Bold', xs: 'markOT-Medium' }, fontSize: { xs: '14px', md: '16px' }, p: 0 }}>
                      LEARN MORE
                      <img
                        src={rightarrow}
                        alt="Submit Icon"
                        style={{ marginLeft: "8px", height: "24px" }}
                      />
                    </Button>
                   
                   
                     {/* <CustomButton
                     label = "LEARN MORE"
                     x = "-135"
                     /> */}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={WarrentyCheck} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '20px', md: '24px' } }}>
                      Warrenty Check
                    </Typography>
                    <Button sx={{ fontFamily: { md: 'markOT-Bold', xs: 'markOT-Medium' }, fontSize: { xs: '14px', md: '16px' }, p: 0 }}>
                      LEARN MORE
                      <img
                        src={rightarrow}
                        alt="Submit Icon"
                        style={{ marginLeft: "8px", height: "24px" }}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={RepairStatus} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '20px', md: '24px' } }}>
                      Repair Status
                    </Typography>
                    <Button sx={{ fontFamily: { md: 'markOT-Bold', xs: 'markOT-Medium' }, fontSize: { xs: '14px', md: '16px' }, p: 0 }}>
                      LEARN MORE
                      <img
                        src={rightarrow}
                        alt="Submit Icon"
                        style={{ marginLeft: "8px", height: "24px" }}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Box>
      </Box>
        {/* Map and Service Centers */}
      < Box sx={{ padding:isSmallScreen ? "5% 0% 0% 3%" : "0% 7% 13% 7%" }}>
        <Grid container spacing={2} 
          sx={{ backgroundImage: `url(${isSmallScreen ? bgimg2 : bgimg1})`,
              backgroundSize: "cover",
              backgroundPosition: "center", 
              overflow:"hidden",
              backgroundRepeat:"no-repeat",
              borderRadius:"13px"
            }}>
          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: { xs: 'flex-start', md: 'center', sm: 'center' }, alignItems: { md: 'center', xs: 'left', sm: 'center' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: { md: 'left', xs: 'left', sm: 'left' } }}>
              <Typography sx={{ fontSize: { xs: '24px', md: '32px', sm: '38px', lg: '48px' } }}>
                Find itel Service Centre
              </Typography>
              <Box sx={{marginTop:"2%"}}>
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '20px' },
                    fontWeight: 'bold',

                  }}
                >
                  1300+{' '}
                  <Box
                    component="span"
                    sx={{
                      fontSize: { xs: '16px', md: '20px' },
                      fontWeight: { xs: 'normal ', md: 'normal' },

                    }}
                  >
                    Service Centres across India
                  </Box>
                </Typography>
              </Box>
              <Box component="img" src={callcare} alt="" sx={{ mt: { md: "5%", xs: "5%" }, width: { md: '30%', xs: '43%' } }} />
              <Typography variant="h6" sx={{ fontSize: { md: '12px', xs: '10px' }, color: '#252525', fontWeight: { xs: 'Medium', md: 'Medium' }, mt: { md: "2%", xs: "2%" } }}>
                Our Official Service Partner
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  mt: "4%",
                  borderRadius: "24px",
                  padding: { xs: '8px 16px', md: '12px 24px' },
                  fontSize: { xs: '12px', md: '16px' },
                  width: { md: '285px', xs: '200px' },
                  textWrap: "nowrap"
                }}
              >
                SEARCH ON CARLCARE
                <Box component="img"
                  src={arrow45}
                  alt="Submit Icon"
                  sx={{ marginLeft: { md: "8px", xs: "8px" }, height: "14px" }}
                />
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ mt: { xs: 4, md: 0 },  pb: { xs: 4, md: 5 },display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box component="img" src={india} alt="" sx={{ width: {md: 'auto', xs: '94%', sm: '50%' }, marginLeft: { md: '30%', xs: '0' } }} />
          </Grid>
        </Grid>
      </Box >
    </>
  );
}

export default ServiceCenter;
