import React from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import videoframe from "../../../images/Home/vedioframe.png";
import smallvideoframe from "../../../images/Home/smallvedioframe.png";
import ArrowRight from "../../../images/Home/arrowright.png";

const Video = styled("video")({
  width: "100%", // Adjusts the width to fit the container
  height: "100%", // Adjusts the height to fit the container
  objectFit: "cover",
});

const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "60px",
  left: "50%",
  transform: "translateX(-50%)",
  color: "#fff",
  textAlign: "center",
}));

const VideoInImage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Move the mask image logic outside the styled component
  const maskImage = isSmallScreen ? smallvideoframe : videoframe;

  const MaskedVideoContainer = styled(Box)({
    position: "relative",
    width: "100%",
    height: isSmallScreen ? "120vh" : "auto",
    maxHeight: isSmallScreen ? "100vh" : "93vh",
    maskImage: `url(${maskImage})`,
    maskSize: "cover",
    maskRepeat: "no-repeat",
    maskPosition: "center",
    WebkitMaskImage: `url(${maskImage})`,
    WebkitMaskSize: "cover",
    WebkitMaskRepeat: "no-repeat",
    WebkitMaskPosition: "center",
    overflow: "hidden", // Ensure the video does not spill out
    borderRadius: "15px", // Adjust as needed
    margin: "auto",
    display: "flex", // Centers the video within the container
    justifyContent: "center", // Centers the video horizontally
    alignItems: "center", // Centers the video vertically
  });

  return (
    <Box sx={{ p: "4%" }}>
      <MaskedVideoContainer>
        <Video autoPlay loop muted>
          <source
            src="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/2158/ea56/-8cc4-4b60-ac79-4c7f75e79501?Expires=1727654400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AAa4Q2e0Qhgafp4zcQbM4Zo7yW1BG6GB~4V9BsWr9oQw-6kPcSalaCgN1NRhvtHcfBeIMkABi0nEWsVi-~LsthqLRRcYYtjTOLNkWYUWjEKONp-EosdKYeZxLio8P9VSXQDfkGAUqPepAvzB5Cr6OTNPEdH96r-E3NCmUIOfJS0vQAhuYLCu4NDx99Fn1RV~HQdhmLjUZO0GA1psNu4qJjvhBJUptXM5GlbzmHE3XuwwBJwy4-vDXH2RzxqjEqKC~jCZeKxzefF1mwTqmAFKQ5eaWSLM0ycKjupW9RnbXN0Kv~RyCEI8mYQPhhEeXkEC5tvI7L~PBzGaPpNpv1nnqw__"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </Video>
        <Overlay>
          <Typography sx={{ fontSize: { xs: "20px", md: "36px" } }}>
            Count on itel: You, Your
            <br />
            Phone: Our Priority
          </Typography>
          <Typography sx={{ fontSize: { xs: "10px", md: "16px" } }}>
            At itel, we ensure top-notch quality through rigorous product
            testing.
            <br />
            We're here to listen and help, ensuring you stay connected and
            worry-free.
          </Typography>
          <Button
            sx={{
              backgroundColor: "#000",
              color: "#fff",
              marginTop: "20px",
              "&:hover": {
                backgroundColor: "#444",
              },
              borderRadius: "24px",
            }}
            variant="outlined"
          >
            <Typography sx={{ mr: 1, fontSize: "14px", p: 1 }}>
              GET SUPPORT
            </Typography>
            <img
              src={ArrowRight}
              alt="Arrow Right"
              style={{ color: "red", fontSize: "24px" }}
            />
          </Button>
        </Overlay>
      </MaskedVideoContainer>
    </Box>
  );
};

export default VideoInImage;
