import React, { useEffect, useRef } from "react";
import Box  from '@mui/material/Box';
import gsap from "gsap";
import useMediaQuery from '@mui/material/useMediaQuery';
import X from '../../images/Blog/X.png';
import In from '../../images/Blog/In.png';
import link from '../../images/Blog/link.png';
import FB from '../../images/Blog/FB.png';
import Banner1 from '../../images/Blog/Banner1.png';
import Banner2 from '../../images/Blog/Banner2.png';
import image1 from "../../images/Blog/image1.png";
import image2 from "../../images/Blog/image2.png";
import image3 from "../../images/Blog/image3.png";
import image11 from "../../images/Blog/image11.png";
import image22 from "../../images/Blog/image22.png";
import image33 from "../../images/Blog/image33.png";
import Arrow from "../../images/Blog/Arrow.png";
//import IconImage from "../../images/Blog/icon.png";
import { Typography, Grid } from '@mui/material';
import { CustomButton } from "../../components/Buttons/CustomButton";

export default function BoxSystemProps() {
  const starRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

useEffect( () => {
  gsap.fromTo(
    starRef.current,
    {
      scale: 0.3, 
      opacity: 0.7,
      rotate:-80,
      delay:3,
      transformOrigin: "50% 50%",
    },
    {
      duration: 2.5,  
      opacity: 1,
      rotate:80,
      transformOrigin: "50% 50%",
      scale: 1, 
      ease: "power2.out", 
      repeat: -1, 
      yoyo: true, 
    }
  );
  }, []);


  return (
    <Box sx={{
      position: "relative",
      width: "100%",
      height: "auto",
      overflow: "hidden",
      backgroundColor: "white",
    }}>
      {/* Container for Heading, Banner, and Content */}
      <Box sx={{
        maxWidth: { md: "1200px" }, // Set max width for all sections
        marginX: "auto", // Center align the container
        paddingX: { xs: "5%", md: "10%", lg: "10%" }, // Consistent padding
      }}>
        {/* Heading Section */}
        <Box sx={{
          textAlign: { md: "left" },  // Center text on small screens
          marginTop: "7%",
        }}>
          <Typography sx={{
            color: "var(--Colors-Grey-Grey-4, rgba(102, 102, 102, 1))",
            fontFamily: 'MarkOT',
            fontSize: { md: "20px", lg: "20px", xs: "12px" },
            fontWeight: "500",
          }}>
            NEWS
          </Typography>
          <Typography sx={{
            color: "rgba(26, 26, 26, 1)",
            fontSize: { md: "36px", lg: "36px", xs: "24px" },
            fontWeight: "500",
            lineHeight: { md: "45px", lg: "45px", xs: "30px" },
            marginTop: "2%",
          }}>
            itel India’s Heartwarming World Cup Campaign: Connecting India through the Joy of Cricket and Technology
          </Typography>

          {/* Date, Read Time, and Share Section */}
          <Grid container alignItems="center" justifyContent="space-between" sx={{ marginTop: "3%" }}>
            {/* Date and Read Time */}
            <Grid item xs={12} sm="auto">
              <Box display="flex" alignItems="center" justifyContent={{ sm: "flex-start" }}>
                <Typography variant="body2" color="textSecondary">
                  28 Mar, 2024
                </Typography>
                <Box
                  sx={{
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    backgroundColor: 'grey.500',
                    margin: '0 8px',
                  }}
                />
                <Typography variant="body2" color="textSecondary">
                  5 min read
                </Typography>
              </Box>
            </Grid>

            {/* Share Section */}
            <Grid item xs={12} sm="auto">
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ sm: "flex-end" }}
              gap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
              marginTop={{ xs: "3%" }}
            >
              <Typography>Share</Typography>
              <img src={FB} alt="Facebook" style={{ cursor: "pointer" }} />
              <img src={X} alt="Twitter" style={{ cursor: "pointer" }} />
              <img src={In} alt="LinkedIn" style={{ cursor: "pointer" }} />
              <img src={link} alt="Link" style={{ cursor: "pointer" }} />
            </Box>

            </Grid>
          </Grid>
        </Box>

        {/* Banner */}
        <Box sx={{
          marginTop: "7%",
          textAlign: "center",
        }}>
          <img src={isSmallScreen ? Banner2 : Banner1} alt='banner' style={{ width: "100%", height: "auto" }} />
        </Box>

        {/* Content */}
        <Box sx={{
          marginTop: "5%",
        }}>
          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },

            textAlign: "justify",
          }}>
            <span>New Delhi, October 05, 2023:</span> itel, India’s leading smartphone brand, is set to capture hearts and unite the nation with its emotionally charged brand campaign, timed perfectly with the upcoming World Cup season. As a brand that prides itself on democratizing technology for all, itel India aims to underscore its commitment to providing incredible value in every product it delivers while fostering a sense of togetherness and shared experiences among its customers.
          </Typography>

          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            marginTop: "5%",
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },

            textAlign: "justify",
          }}>
            The campaign’s centerpiece is a touching short film that encapsulates the values and aspirations of itel India. It tells the heartwarming story of a father and son, blending the emotional journey of their dreams and sacrifices with the transformative power of technology
          </Typography>

          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            marginTop: "5%",
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },

            textAlign: "justify",
          }}>
            As the campaign reminds us, itel India is not just a smartphone brand; it is a unifying force that brings together families, generations, and the entire nation, especially during moments of sporting and emotional significance.
          </Typography>

          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            marginTop: "5%",
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },
            textAlign: "justify",
          }}>
            Speaking about the campaign, Arijeet Talapatra, Chief Executive Officer, Transsion India, said “We believe that in today’s digital age, the smartphone has become the new TV. For many in the country, it serves as the gateway to information, entertainment, and connection, and for many, the gateway to the Cricket World Cup this season. Just like food, clothing, and shelter, the smartphone has also become a fundamental necessity in our lives. Like we see in the digital film, watching the World Cup is an activity that brings all the generations of the country together. Thus, in the spirit of #JodeBharatKaHarDilitel, we aim to democratize watching the World Cup by placing an itel phone in as many hands as possible. itel is here to serve the aspirations of Gen Z, providing them with endless opportunities and connections.”
          </Typography>

          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            marginTop: "5%",
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },
            textAlign: "justify",
          }}>
            The digital film has been conceptualized and executed by Havas Worldwide India. It offers a fresh approach to storytelling in the digital advertising realm.
          </Typography>

          <Typography sx={{
            fontSize: { md: "20px", lg: "20px", xs: "16px" },
            marginTop: "5%",
            lineHeight: "32px",
            fontFamily: { lg: "MarkOT", xs: 'MarkOT-Light' },
            textAlign: "justify",
          }}>
            Anupama Ramaswamy, Chief Creative Officer, Havas Worldwide India said, “Indians love two things – cricket and family. And they are happiest when they can bring the two together. This was the inspiration that went into creating this heart-warming story. A story that touched upon small-town India’s many realities – parent’s pride and belief in their children’s abilities, a father’s love for his son that pushes him to take on every challenge, the fact that life can be tough sometimes but when loved ones come together anything is achievable. And at every step of the way, itel is there to help these bonds grow stronger. We wanted this story to help itel become more than yet another mobile phone brand. We wanted itel to be seen as a companion that truly celebrates the coming together of people and their passions.”
          </Typography>
        </Box>
        <Grid item xs={12} sm="auto">
          <Box display="flex" alignItems="center" gap={{ xs: 2, sm: 3, md: 3, lg: 3 }} marginTop={{ xs: "3%" }}>
            <Typography>Share</Typography>
            <img src={FB} alt="Facebook" />
            <img src={X} alt="Twitter" />
            <img src={In} alt="LinkedIn" />
            <img src={link} alt="Link" />
          </Box>
        </Grid>
      </Box>

      {/* More Blogs Section */}
      <Box sx={{
        paddingX: { xs: "5%", md: "5%", lg: "5%" },
        marginBottom: { md: "8%", lg: "8%", xs: "15%" },
        marginTop: { xs: "15%", md: "7%", lg: '7%' },
      }}>
        <Box sx={{
          display: { xs: "flex" },
          marginBottom: { xs: "5%", md: "0", lg: '0' },
          alignItems: { xs: "center", md: "left" },
        }}>
          {isSmallScreen && (
            <svg
            width="75"
            height="72"
            viewBox="0 0 75 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="star"
              ref={starRef} // Attach the ref to the star path
              d="M37.3278 13.5C37.3278 13.5 42.2795 24.1792 47.0298 28.6814C53.6868 34.9907 67.1555 36 67.1555 36C67.1555 36 53.6868 37.0093 47.0298 43.3186C42.2795 47.8208 37.3278 58.5 37.3278 58.5C37.3278 58.5 32.3761 47.8208 27.6257 43.3186C20.9687 37.0093 7.5 36 7.5 36C7.5 36 20.9687 34.9907 27.6257 28.6814C32.3761 24.1792 37.3278 13.5 37.3278 13.5Z"
              fill="#FF073C"
            />
            <g filter="url(#filter0_d_560_6929)">
              <path
                id="circle"
                d="M47.1 48.7988L47.1 48.7188C47.1 47.7581 47.74 47.2778 48.62 47.2778L48.78 47.2778C49.74 47.2778 50.46 46.5573 50.46 45.5966L50.46 45.4365C50.46 44.5559 50.94 43.9155 51.9 43.9155L51.98 43.9155C52.94 43.9155 53.5 43.3551 53.5 42.3944L53.5 29.4255C53.5 28.4648 52.94 27.9044 51.98 27.9044L51.9 27.9044C50.94 27.9044 50.46 27.264 50.46 26.3834L50.46 26.2233C50.46 25.2626 49.74 24.5421 48.78 24.5421L48.62 24.5421C47.74 24.5421 47.1 24.0618 47.1 23.1011L47.1 23.021C47.1 22.0604 46.54 21.5 45.58 21.5L29.42 21.5C28.46 21.5 27.9 22.0604 27.9 23.021L27.9 23.1011C27.9 24.0618 27.26 24.5421 26.38 24.5421L26.22 24.5421C25.26 24.5421 24.54 25.2626 24.54 26.2233L24.54 26.3834C24.54 27.264 24.06 27.9044 23.1 27.9044L23.02 27.9044C22.06 27.9044 21.5 28.4648 21.5 29.4255L21.5 42.3944C21.5 43.3551 22.06 43.9155 23.02 43.9155L23.1 43.9155C24.06 43.9155 24.54 44.5559 24.54 45.4365L24.54 45.5966C24.54 46.5573 25.26 47.2778 26.22 47.2778L26.38 47.2778C27.26 47.2778 27.9 47.7581 27.9 48.7188L27.9 48.7988C27.9 49.7595 28.46 50.3199 29.42 50.3199L45.58 50.3199C46.54 50.3199 47.1 49.7595 47.1 48.7988Z"
                stroke="#FF0037"
                strokeWidth="1.72097"
                shapeRendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_560_6929"
                x="0.639648"
                y="0.639648"
                width="73.7207"
                height="70.5405"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.027451 0 0 0 0 0.235294 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_560_6929"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_560_6929"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
            // <Box
            //   component="img"
            //   src={IconImage}
            //   alt="Icon"
            //   sx={{ marginRight: "8px" }}
            // />
          )}
          <Typography
            sx={{
              fontSize: { xs: '24px', sm: '36px', md: '48px' },
              fontWeight: '500',
              // marginTop: '5%',
            }}
          >
            {isSmallScreen ? "More Itel Insights" : "More Blogs"}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {/* Blog Box 1 */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <img src={isSmallScreen ? image11 : image1} alt='image1' style={{ width: "100%", height: "auto", marginTop: "2%" }} />
              <Typography sx={{fontFamily:"MarkOT", fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                NEWS
              </Typography>
              <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: { md: "20px", lg: "19px", xs: "16px" }, fontWeight: "500", marginTop: "2%" }}>
                Exclusive reveal: itel to unveil A60s, India's First 8GB* RAM Smartphone for Less Th...
              </Typography>
              {/* <Typography sx={{ marginTop: "2%", fontFamily: { lg: 'MarkOT-Bold', xs: 'MarkOT-Medium' } }}>
                VIEW <img src={Arrow} alt='arrow' style={{ paddingLeft: "2%" }} />
              </Typography> */}
              <CustomButton/>
            </Box>
          </Grid>

          {/* Blog Box 2 */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <img src={isSmallScreen ? image22 : image2} alt='image2' style={{ width: "100%", height: "auto", marginTop: "2%" }} />
              <Typography sx={{fontFamily:"MarkOT", fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                BLOG
              </Typography>
              <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: { md: "20px", lg: "19px", xs: "16px" }, fontWeight: "500", marginTop: "2%" }}>
                Imagine a day with uninterrupted HiFi <br />Sound.
              </Typography>
              <Typography sx={{ marginTop: "2%", fontFamily: { lg: 'MarkOT-Bold', xs: 'MarkOT-Medium' } }}>
                VIEW <img src={Arrow} alt='arrow' style={{ paddingLeft: "2%" }} />
              </Typography>
            </Box>
          </Grid>

          {/* Blog Box 3 */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <img src={isSmallScreen ? image33 : image3} alt='image3' style={{ width: "100%", height: "auto", marginTop: "2%" }} />
              <Typography sx={{fontFamily:"MarkOT", fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                BLOG
              </Typography>
              <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: { md: "20px", lg: "19px", xs: "16px" }, fontWeight: "500", marginTop: "2%" }}>
                Unleash the Power of Memory: itel S23 Redefines Performance
              </Typography>
              <Typography sx={{ marginTop: "2%", fontFamily: { lg: 'MarkOT-Bold', xs: 'MarkOT-Medium' } }}>
                VIEW <img src={Arrow} alt='arrow' style={{ paddingLeft: "2%" }} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
