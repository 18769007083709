import React from "react";
import { Grid, Box, Typography,useMediaQuery } from "@mui/material";
import first from "../../../images/productlanding/A60Techspecs1.png";
import second from "../../../images/productlanding/A60Techspecs2.png";
import third from "../../../images/productlanding/A60Techspec3.png";
//import Star from "../../../images/productlanding/Star.png"
import RedIconAnimation from "../../Animation/RedIconAnimation";

const images = {
  Camera: first,
  Battery: second,
  Security: third,
 
};



 

const TechSpec = ({ 
  linearcolor, }) => {
    const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box>
      <Box sx={{ flexGrow: 1, pl: "4%", pr: "4%", pb: "5%" }}>
        
        <Box sx={{display:"flex"}}>
          
          <RedIconAnimation/>
          <Box>
          <Typography variant="h4" gutterBottom sx={{ color: "Black", margin:isXs ? "15px" : "8px", }}> Tech Specs </Typography>
          </Box>
        </Box>
        {/* <Typography variant="h4" gutterBottom sx={{ color: "Black" }}>
        <img src={Star} alt="star"/>
          Tech Specs
        </Typography> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={images.Camera}
                alt="Crystal Clear Shots"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={images.Battery}
                    alt="Swift Power-Up"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={images.Security}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Box>
      </Box>

  );
};

export default TechSpec;
