import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { gsap } from "gsap";
import Arrow from "../../images/customBtnArrow.svg";
import ArrowPink from "../../images/customButtonArrowPink.svg";

export const CustomButton = ({
  label = "VIEW",
  type = "simple-arrow",
  x = -115, // Default hidden position on the left
  onClick,
}) => {
  const arrowRef = useRef(null);
  const normalArrowRef = useRef(null);

  // Handle hover effect with GSAP
  const handleMouseEnter = () => {
    // Animate ArrowPink to translate to the right and hide normal Arrow when finished
    gsap.to(arrowRef.current, {
      x: -1, // Move the pink arrow beside the text
      duration: 0.5,
      ease: "power2.out",
      onComplete: () => {
        gsap.to(normalArrowRef.current, {
          opacity: 0, // Hide normal arrow when pink arrow finishes moving
          duration: 0.2,
        });
      },
    });
  };

  const handleMouseLeave = () => {
    // Move ArrowPink back to its hidden position and show normal Arrow again
    gsap.to(arrowRef.current, {
      x: type === "simple-arrow" ? parseInt(x) : -285, // Move the pink arrow back to the left (hidden based on passed 'x' value)
      duration: 0.5,
      ease: "power2.out",
    });
    gsap.to(normalArrowRef.current, {
      opacity: 1, // Show normal arrow again
      delay: 0.2, // Add a slight delay to sync with pink arrow's movement
      duration: 0.2,
    });
  };

  // Ensure the pink arrow is hidden initially using GSAP's set function
  useEffect(() => {
    gsap.set(arrowRef.current, { x: parseInt(x) }); // Start pink arrow hidden based on parsed 'x' value
    gsap.set(normalArrowRef.current, { opacity: 1 }); // Ensure normal arrow is visible initially
  }, [x]);

  return (
    <>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          textTransform: "none", // Keeps the label in the original case
          padding: "8px 16px",
          borderRadius: "8px",
          cursor: "pointer",
          overflow: "hidden",
          transition: "none", // Remove default MUI button hover effect
          "&:hover": {
            backgroundColor: "transparent", // Ensure no hover effect
          },
        }}
      >
        <Typography variant="body1" sx={{ marginRight: "8px" }}>
          {label}
        </Typography>
        {/* Normal arrow */}
        <img
          ref={normalArrowRef}
          src={Arrow}
          alt="normal arrow"
          style={{ position: "absolute", right: 14 }}
        />

        {type === "simple-arrow" && (
          <img
            ref={arrowRef}
            src={ArrowPink}
            alt="pink arrow"
            style={{
              position: "relative",
              transform: `translateX(${parseInt(x)}px)`, // Initially hide the pink arrow outside the left based on parsed 'x'
              transition: "none", // Prevent any unwanted initial animations
            }}
          />
        )}
      </Box>
    </>
  );
};
