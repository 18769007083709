import React from "react";
import {
  Box,
  Fab,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,

} from "@mui/material";
import Union from "../../images/Investor/Union.png";
import landing1 from "../../images/Investor/landing1.png";
import landing2 from "../../images/Investor/landing2.png";
import csr from "../../images/Investor/csr.png";
import arrow from "../../images/Investor/arrow.png";
import csr2 from "../../images/Investor/csr2.png";
import downarrow from "../../images/Investor/downarrow.png";
import download from "../../images/Investor/download.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const directors = [
  "Mr. Ravi Garg, Director of the Company, resigned from the Board of Directors of the company wef 11th October 2022.",
  "Mrs. Rekha Diwan, Director of the Company, resigned from the Board of Directors of the Company wef 12th October 2022.",
  "Ta Wei Ku, Director of the Company, resigned from the Board of Directors of the company wef 8th June 2022.",
  "Arijeet Talapatra, Director of the Company, resigned from the Board of Directors of the company wef 20th December 2021.",
  "Tushar Goel, Director of the Company, resigned from the Board of Directors of the company wef 19th December 2021.",
  "Wang Jiaqiang and Mr. Ming Ma, Directors of the company, resigned from the Board of Directors of the Company wef 28th October, 2020.",
  "Rashmi Rastogi, Director of the Company, resigned from the Board of Directors of the company wef 16th March, 2020.",
  "As the Company has been converted into Private Limited from Public Limited, therefore, the requirement of Section 149 of the Companies Act, 2013 of having Independent Directors on the board of the Company is not applicable. Accordingly, Mr. Ghanshyam Das Gupta, Independent Director of the company, and Mr. Ranjeet Kumar Verma, Independent Director of the Company, resigned from the Board of Directors of the Company wef 22nd November, 2019.",
  "Lin Qin, Director of the Company, resigned from the Board of Directors of the company wef 22nd September, 2019.",
];

const annualReturns = [
  { year: "2021-22" },
  { year: "2020-21" },
  { year: "2019-20" },
  { year: "2018-19" },
];

const egmnagmNotice = [
  { title: "EGM Notice" },
  { year: "2022", title: "AGM Notice" },
  { year: "2021", title: "EGM Notice" },
  { year: "2020", title: "AGM Notice" },
  { year: "2019", title: "AGM Notice" },
];

function InvestorRelations() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <div>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: '80vh', md: '80vh' },
          backgroundImage: isSmallScreen ? `url(${landing1})` : `url(${landing2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "20px", sm: "40px", md: "60px", lg: "80px" },
          color: "white",
          textAlign: "center",
        }}
      >
        <img src={Union} alt="Logo" style={{ paddingBottom: "4%" }} />
        <Typography
          variant="h1"
          sx={{

            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem", lg: "4rem" },
            fontWeight: "500",
            paddingBottom: "4%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Insider Itel: Get in the Know!
        </Typography>
        <Typography
          variant="bodyLarge"
          sx={{
            fontFamily: 'markOT-Light',
            fontSize: { md: "20px", lg: "20px", xs: "14px" },
            textAlign: "center",
            paddingRight: { md: "21%", xs: "8%" },
            paddingLeft: { md: "21%", xs: "8%" },
            marginBottom: { md: "8%" }
          }}
        >
          Welcome to our Investor Relations hub! Dive into our annual returns,
          catch up on our general meetings, and explore our CSR initiatives.
          It's all about transparency and keeping you in the loop!
        </Typography>

      </Box>

      <Box
        sx={{
          pr: { xs: "2%", sm: "8%" },
          pl: { xs: "2%", sm: "8%" },
          marginTop: "5%",
        }}
      >
        <Box>
          <Accordion
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "none",
              "&:before": { display: "none" },
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={downarrow}
                  alt="Expand"
                  style={{ width: "42px", height: "42px" }}
                />
              }
            >
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: "24px", sm: "36px" } }}
              >
                Annual Return
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Box>
                {annualReturns.map((item, index) => (
                  <Box
                    key={index}
                    href="#text-buttons"
                    sx={{
                      color: "red",
                      fontWeight: 400,
                      textTransform: "none",
                      fontSize: { md: "20px", xs: '14px' },
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                      maxWidth: { md: '300px', xs: '200px' }
                    }}
                  >
                    Annual Return {item.year}
                    <img
                      src={download}
                      alt="Download"
                      style={{ marginLeft: "8px" }}
                    />
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Accordion
          defaultExpanded
          sx={{
            boxShadow: "none",
            border: "none",
            "&:before": { display: "none" },
          }}
        >
          <AccordionSummary
            expandIcon={
              <img
                src={downarrow}
                alt="Expand"
                style={{ width: "42px", height: "42px" }}
              />
            }
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "24px", sm: "36px" } }}
            >
              Notice of General Meeting
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Box>
              {egmnagmNotice.map((item, index) => (
                <Box
                  key={index}
                  href="#text-buttons"
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    textTransform: "none",
                    fontSize: { md: "20px", xs: '14px' },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "8px",
                    maxWidth: { md: '300px', xs: '200px' }
                  }}
                >
                  {item.title} {item.year}
                  <img
                    src={download}
                    alt="Download"
                    style={{ marginLeft: "8px" }}
                  />
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          sx={{
            boxShadow: "none",
            border: "none",
            "&:before": { display: "none" },
          }}
        >
          <AccordionSummary
            expandIcon={
              <img
                src={downarrow}
                alt="Expand"
                style={{ width: "42px", height: "42px" }}
              />
            }
          >
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "24px", sm: "36px" } }}
            >
              General Notice
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <List>
              {directors.map((text, index) => (
                <ListItem
                  key={index}
                  alignItems="flex-start"
                  sx={{ paddingBottom: "0px", fontFamily: 'MarkOT', display: 'flex', alignItems: 'flex-start' }}
                >
                  <ListItemIcon
                    sx={{ minWidth: "unset", marginRight: "8px" }}
                  >
                    <Box
                      sx={{
                        width: "5px",
                        height: "5px",
                        bgcolor: "black",
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText sx={{ marginTop: '0' }}
                    primary={
                      <Typography
                        variant="body1"
                        sx={{ fontSize: isMobile ? "14px" : "16px", fontFamily: 'markOT' }}
                      >
                        {text}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>

          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ p: { xs: "5% 4.3% 12% 4.3%", sm: "5% 9.3% 13% 9.3%" } }}>
        <Typography sx={{ fontSize: { md: "36px", lg: "36px", xs: "27px" }, fontWeight: "500", paddingBottom: { md: "3%", lg: "3%", xs: "2%" } }}>
          Corporate Social Responsibility Policy</Typography>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "auto",
            minHeight: { xs: "400px", sm: "532px" },
            backgroundImage: `url(${isSmallScreen ? csr2 : csr})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" component="div" sx={{ mb: 2, fontSize: { md: '48px', lg: "48px", xs: '32px' } }}>
              Corporate Social <br /> Responsibility
            </Typography>
            <Typography sx={{fontFamily:"MarkOT", fontSize: { md: "20px", xs: "16px", lg: "20px" }, fontWeight: "400", paddingBottom: "3%", paddingX: { xs: "8%" }, color: "rgba(255, 255, 255, 1);" }}>
              Pathway to a Better India - CSR Endeavor
              </Typography>
            {/* <Button variant="contained" color="primary" sx={{ mt: 2 }}>
              Read More
            </Button> */}
            <Fab variant="extended" color="primary">
              READ MORE
              <img src={arrow} alt="Logo" />
            </Fab>

          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default InvestorRelations;
