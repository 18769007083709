import React, { useEffect} from "react";
import { Box, useMediaQuery, Grid, Typography } from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/v2_s23+/landingimg.png";
import landing2 from "../../../images/v2_s23+/landingimg2.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/v2_s23+/appbar.png";
import backgroundImage from "../../../images/v2_s23+/landingbg3.png";
import icon1 from "../../../images/v2_s23+/icon1.png";
import icon2 from "../../../images/v2_s23+/icon2.png";
import icon3 from "../../../images/v2_s23+/icon3.png";
import MyIcon from "../../../images/P55/blueicon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/v2_s23+/Patterns (1).png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/v2_s23+/change.png";
import blue2 from "../../../images/v2_s23+/change.png";
import blue3 from "../../../images/v2_s23+/change.png";
import bluesmall1 from "../../../images/v2_s23+/change2.png";
import bluesmall2 from "../../../images/v2_s23+/change2.png";
import bluesmall3 from "../../../images/v2_s23+/change2.png";
import blacksmall1 from "../../../images/P55/productColorsmall2.png";
import blacksmall2 from "../../../images/P55/productColorsmall2.png";
import blacksmall3 from "../../../images/P55/productColorsmall2.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/v2_s23+/landingbg.png";
import landingbg2 from "../../../images/v2_s23+/landingbg2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import first from "../../../images/v2_s23+/first.png";
import second from "../../../images/v2_s23+/second.png";
import third from "../../../images/v2_s23+/third.png";
import fifth from "../../../images/v2_s23+/fifth.png";
import fifth2 from "../../../images/v2_s23+/fifth2.png";
import sixth from "../../../images/v2_s23+/sixth.png";
import sixth2 from "../../../images/v2_s23+/sixth2.png";
import charge from "../../../images/v2_s23+/display.png";
import vision from "../../../images/v2_s23+/gorilla.png";
import ai from "../../../images/v2_s23+/video.png";
import panorama from "../../../images/v2_s23+/slim.png";
import gaming from "../../../images/v2_s23+/sharp.png";
import processor from "../../../images/v2_s23+/blue.png";
import charge2 from "../../../images/v2_s23+/display2.png";
import third2 from "../../../images/v2_s23+/third2.png";
import first2 from "../../../images/v2_s23+/first2.png";
import second2 from "../../../images/v2_s23+/second2.png";
import ai2 from "../../../images/v2_s23+/video2.png";
import panorama2 from "../../../images/v2_s23+/slim2.png";
import processor2 from "../../../images/v2_s23+/blue2new.png";
import vision2 from "../../../images/v2_s23+/gorilla2.png";
import gaming2 from "../../../images/v2_s23+/sharp2.png";
// import vertical from "../../../images/v2_s23+/vertical.png";
// import vertical2 from "../../../images/v2_s23+/vertical2.png";
import girl2 from "../../../images/v2_s23+/girl2.png";
import girl from "../../../images/v2_s23+/girl.png";
import scarf from "../../../images/v2_s23+/scarf.png";
import scarf2 from "../../../images/v2_s23+/scarf2.png";
import touch2 from "../../../images/v2_s23+/touch2.png";
import touch from "../../../images/v2_s23+/touch.png";
import timelapse from "../../../images/v2_s23+/timelapseb.png";
import timelapse2 from "../../../images/v2_s23+/timelapses.png";
import backgroundImagevideo from "../../../images/v2_s23+/Bg.png";
import nightmodeimg from "../../../images/v2_s23+/nightmodecolor.png";
import nightmodetext from "../../../images/v2_s23+/nightmodetext.png";
import proimg from "../../../images/v2_s23+/proimg.png";
import promodetextimg from "../../../images/v2_s23+/promodetext.png";




const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  blue: [blue1, blue2, blue3],
  black: [black1, black2, black3],
  
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  
}

const colorNames = {
  blue: "Lake Cyan",
  black: "Elemental Blue",
  
};

const colorStyles = {
  black: "#CA86FF",
  blue: "#1F3357",
 
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "3D Curved Brilliance: 6.78” FHD+ AMOLED Display",
    description:
      "Elevate your vision with itel S23+ smartphone with sleek 3D curved AMOLED display. ",
    icon: icon1,
  },
  {
    title: "Snap in Style with 50MP AI Dual Rear Camera & 32MP Front Camera",
    description:
      "Capture every moment in jaw-dropping detail with our 50MP AI dual rear camera and 32 MP Front Camera set-up. ",
    icon: icon2,
  },
  {
    title: "5000 mAh Battery with 18W Type-C Charger",
    description:
      "Power through your day with a 5000 mAh Battery. Recharge quickly and efficiently with the 18W Type-C Charger",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Redefining Style with 3D Curved AMOLED Display",
    text: "Experience the future of mobile tech with the itel S23+ smartphone, featuring a sleek 3D curved 6.78″ FHD+ AMOLED display, a robust 5000 mAh battery with fast charging, and a stunning 50MP AI dual rear camera setup, all wrapped in an ultra-slim 7.9mm body for an effortlessly stylish experience."
  },
];


const specs = {
  generalSpecs: {
    model: "S23+",
    colors: ["Elemental Blue & Lake Cyan"],
  },
  display: {
    displaySize: "6.78' FHD+ AMOLED Curved Screen",
    resolution:"1080*2400 Pixels",
    resolutionType:"AMOLED",
    dimension:"164.4*75.1*7.9mm",
    technology:"G+F, Incell",
    refreshRate:"60HZ",
    touchSamplingRate:"240HZ",
    pixelDensity:"388 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisoc Tiger T616",
    processorCore:"Octa Core",
  },
  camera: {
    rearCamera: "50 MP AI Dual Rear Camera",
    rearFlash: "Yes",
    frontCamera: "32 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "32 Days",
    talkTime: "7.5 Hours",
    mediaPlaybackTime:"112 hours"
  },
  memoryStorage: {
    ram:"8GB + 8GB",
    rom:"256GB",
    userMemory:"238 GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3/B5/B8",
    EDGE: "Support Download",
    WCDMA: "3G:B1/B5/B8",
    LTE: "4G:B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Type-C",
  },
  additional: {
    earphoneJack: "Type-C interface",
    thickness:"7.9mm",
    sensors: "In Display Fingerprint | Face Unlock | G Sensor ",
    fingerprint:"Side",
    accessories: "18W Adapter| USB Cable | Protective Cover | Screen Film Protection",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];




const P55 = () => {
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "55%",
    sm:"45%",
    md: "37%",
    lg: "37%",
  }
  
  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  // const verticalimg = isSmallScreen ? vertical2 : vertical;
  const fifthimg = isSmallScreen ? fifth2 : fifth;
  const sixthimg = isSmallScreen ? sixth2 : sixth;
  const chargeimg = isSmallScreen ? charge2 : charge;
  const aiimg = isSmallScreen ? ai2 : ai;
  const panoramaimg = isSmallScreen ? panorama2 : panorama;
  const processorimg = isSmallScreen ? processor2 : processor;
  const visionimg = isSmallScreen ? vision2 : vision;
  const gamingimg = isSmallScreen ? gaming2 : gaming;
  const girlimg = isSmallScreen ? girl2 : girl;
  const scarfimg = isSmallScreen ? scarf2 : scarf;
  const touchimg = isSmallScreen ? touch2 : touch;
  const timelapseimg = isSmallScreen ? timelapse2 : timelapse;


  useEffect(() => {
    // Define the x and y values based on screen size
    const xValue = isSmallScreen ? "8vw" : "25vw";
    const yValue = isSmallScreen ? "1vh" : "-2vh";
    const scaleValue = isSmallScreen ? 1.5 : 1.4; // Slightly reduce the scale to fit the image better
    const heightValue = isSmallScreen ? "40vh" : "74vh"; // Set a specific height

    // Initially set the image opacity, position, scale, and height
    gsap.set(".landing-image", { opacity: 0, x: xValue, y: yValue, scale: scaleValue, height: heightValue });

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
      scale: scaleValue, // Apply the same scaling during the animation
      height: heightValue, // Maintain the set height during the animation
    });
  }, [isSmallScreen]);

 




  return (
    <>
      <Box sx={{ bgcolor: "#1C1B1B" }}>
        {/* landing */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#1C1B1B"
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          SpecificationsORTechSpecs= "Specifications"
          OverviewSpecificationcolor="white"
          backgroundImageFeatures={backgroundImage}
          productname="S23+"
          Productnamecolor="white"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          buyNowButtonLabel="Buy Now"
          headmaintext="S23+"
          buynowbuttonbgcolor="#FFFFFF"
          buynowbuttoncolor="black"
          linearcolor="linear-gradient(to left, #85E2FD, #CA86FF)"
          backgroundImages={[landingbg1, landingbg2]}
          featuresborder="1px solid #CA86FF" 
          // headingColor="linear-gradient(to left, #85E2FD, #CA86FF)" 
          rightsideTextColorFeatures="#D9D9D9"
          featuresubheadingcolor="white"
          featurediscriptiontextcolor="#D9D9D9"
        />

        {/*  vedio and colorchange part */}

        <Marquee
          appBarColor="#001C3A"
          textColor="#D5EEF9"
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/5788/d738/-fccb-4db7-8c57-7b119e59778c?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BUaAUrnZUOXmFYdoZJbMVrxF84beGdMTY2mZnhp8Cxsac6irlo3l3Sxry6AWWP9eaNXDszP~1cGin7ffhgAYHkIwScU~i8ZIbKgOLZ2QcPtO-3WHwl9Hn0i6AFqPB0xY-kYexIBpBgyvaIEj5ZnoA4OQfqrD72ne5sLbJZ8oOv7Ij~gy5yOOnxbYwu7tj9NmwZV-giDDJA1H619IHkiEGZDHb8L3S4UKDgaAnH1FIZG2KJjKGhgpkfEXESJrKcRvfmfmtYwXLlTNQ6H8--3m1k6eazPL8qM~hRxG7t5x5reuPU5oBlnOfBCM7LaY9yYTOAWU33HuKXDwN7kb7NZVxQ__"
          playIconSrc={play}
          frameSrc={patterns}
        />

      
      {/* Charge */}
        <Box >
        <img  style= {{ width: "100%", display: "block"}}    src={chargeimg} alt='charge' />
        </Box>


        {/* Vision */}
        <Box >
        <img style= {{ width: "100%", display:"block"}} src={visionimg} alt='charge' />
        </Box>


        
        {/* ai */}
         <Box >
        <img style= {{ width: "100%", display:"block", marginBottom: "-2%"}} src={aiimg} alt='charge' />
        </Box> 
    *


      
        {/* processor */}
        <Box>
        <img style= {{ width: "100%", display: "block"}} src={processorimg} alt='charge' />
        </Box>

        <Box sx={{ backgroundColor: '#1C1B1B' }}>
          <img style={{ width: '100%', display: 'block' }} src={gamingimg} alt='charge' />
        </Box>




        <Box sx={{ backgroundColor: '#1C1B1B', display: { xs: 'block', md: 'none' }, padding: '16px' }}>
      <Typography
        variant="h5"
        color="#FFFFFF"
        sx={{
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 500,
          fontSize: '36px',
          textAlign: 'right', // Position heading to the right side
          mb: 2, // Add some margin-bottom
        }}
      >
        Crystal-Clear Shots, Even in Dim Light
      </Typography>

      <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
        <Grid item xs={6}>
          <img src={nightmodeimg} alt="Left" style={{ width: '150%', display: 'block' }} />
        </Grid>
        <Grid item xs={6} sx={{ position: 'relative' }}>
          <img
            src={nightmodetext}
            alt="Right "
            style={{
              width: '80%',
              display: 'block',
              position: 'relative',
              top: '-2px', // Adjust position to overlap with left image
            }}
          />
        </Grid>
      </Grid>
    </Box>


    <Box sx={{ backgroundColor: '#1C1B1B', display: { xs: 'block', md: 'none' }, padding: '16px' }}>
  <Typography
    variant="h5"
    color="#FFFFFF"
    sx={{
      fontFamily: 'Mark OT, sans-serif',
      fontWeight: 500,
      fontSize: '28px',
      textAlign: 'left', // Position heading to the left side
      mb: 8, // Add some margin-bottom
    }}
  >
    Master Your Shots: Unlock Advanced Photography
  </Typography>

  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
  <Grid item xs={6} sx={{ order: 2 }}>
      <img
        src={proimg} // Replace with your local right-side image
        alt="Right"
        style={{ 
          width: '109%', // Adjust width as needed
          height: "auto",
          display: 'block',
          left: "10%" 
         
          
        }} 
      />
    </Grid>
    <Grid item xs={6} sx={{ position: 'relative', order: 1 }}>
      <img
        src={promodetextimg} // Replace with your local left-side image
        alt="Left"
        style={{
          width: '80%', // Increase width to ensure overlap
          display: 'block',
          position: 'relative',
          top: '-20px', // Adjust to move the image up for more overlap
          left: '62px', // Move closer to the right image for better overlap
        }}
      />
    </Grid>
  </Grid>
</Box>





      

{/* ---------------------Time Lapse-------------------- */}
         <Box
  sx={{
    backgroundImage: `url(${backgroundImagevideo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: { xs: 'none', md: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Grid container spacing={2} alignItems="center" justifyContent="center">
    {/* Left Side - Image */}
    <Grid
      item
      xs={3}
      sm={4}
      md={3}
      sx={{ position: 'relative', zIndex: 1 }}
    >
      <img
        src={timelapseimg}
        alt="Time Lapse"
        style={{
          width: '476px',
          height: '404px',
          top: '667px',
          left: '133px',
          border: '2px',
          rotate: '90 deg',
          // maxWidth: '300px',
          // opacity: 0.7,
          // position: 'relative',
          // left: '-50px', // Adjust this value as needed
          // zIndex: 2,
        }}
      />
    </Grid>

    {/* Right Side - Video */}
    <Grid item xs={6} sm={8} md={6}>
      <video
        src="https://s3-figma-videos-production-sig.figma.com/video/925644654045209335/TEAM/1991/8cf6/-ebeb-48a5-a295-7e2eedfc0631?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=P46-jnLYZkRccsQJ-3oalM5S6QBimr4GTFZG8HBwciL-vAZYvH0UkqNuwzFFXZDPdXg1jCK5f4Xo426FCTgm55KdHdRjOMOxNoAAqXlxnUMu0GNsPpSPpQYcNa962kRLHECy3AUgdPxrmp2DkPH6VmL8b-CywD4Qeipluc3WwgpBC5v1Asn6etWWcJDeKwYpQAN3~lPEwPG5CaW5jG5lc8wl22RKFe-Kzei5HYaR9I-YYnURgycVLi2fNNug3Urm9MYpTzZOqYspUWmXaQrkd86WrsNfyIDey0-G3bcC0U-IplRqVcLk8q0LDk6B95Ij3T1K~F1YMaI0z225GSwp3Q__"
        autoPlay
        loop
        muted
        style={{
          width: '1000px',
          height: '540px',
          top: '10px',
          left: '-150px',
          // maxWidth: '950px', // Increase the size slightly
          position: 'relative',
          zIndex: 0,
        }}
      />
    </Grid>
  </Grid>
</Box>


        <Box >
        <img style= {{ width: "100%", display: "block"}} src={girlimg} alt='charge' />
        </Box>

         <Box >
        <img style= {{ width: "100%", display: "block"}} src={scarfimg} alt='charge' />
        </Box> 
         


        <Box
              sx={{
                marginBottom: { xs: '-17%', sm: '-17%', md: '0' }, // Add margin bottom only on small screens
              }}
            >
              <img
                style={{
                  width: '100%',
                  display: 'block',
                }}
                src={touchimg}
                alt='charge'
              />
            </Box>

        {/* panorama */}
        <Box >
        <img style= {{ width: "100%", display:"block"}} src={panoramaimg } alt='charge' />
        </Box>

          {/* TECHSPECS */}
          <Box sx={{pt: "6%" , pl: "4%", pr: "4%", pb: "6%", backgroundColor: "#181818"}}>
      
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box>
            <img
              src={firstimg}
              alt="first"
              style={{
                width: "100%",
                height: "calc(121vh - 20px)",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box>
                <img
                  src={secondimg}
                  alt="Swift Power-Up"
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <img
                  src={thirdimg}
                  alt="Storage"
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
     
      <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={12} md={5}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src={fifthimg}
            alt="Dual DTS Speaker"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "16px",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img
            src={sixthimg}
            alt="Sleek Style"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "16px",
            }}
          />
        </Box>
      </Grid>
    </Grid>
    </Box>   


        {/* colorchange */}
        <Box >
          
          <Box >
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              initialColor="blue"
              isNextArrowBlack={false}
              isPrevArrowBlack={false}
              selectedBgColor="#1C1B1B"
              colortextcolor="#FFFFFF"
            />
          </Box>
        </Box>

      

      {/* Specifications */}
        <Specifications specs={specs} bgColor={"#1C1B1B"} />

        {/* Review */}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

        {/* Level Up Experience with Accessories */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

        {/* Compare Models */}
        <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        {/* Similar Products */}
        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />

        {/* Support Channels */}
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />

        {/* Check out Products */}
        <CheckOutProducts 
          bgColor={"#1C1B1B"} 
          heading="Check out our other Products"
        />
        
        {/* Follow us & Updated */}
        <FollowUs 
          mode="dark"
          isDarkMode={true}/>

        {/* FAQs */}
        <ProductFaqs

        mode="dark"  
        bgColor="#1C1B1B"
        title="Frequently Asked Questions (FAQs)"
        //linkText={linkText}
        tabLabels={tabLabels}
        tabContents={tabContents}
        accordionBackgroundColor= "#1C1B1B"
        selectedTabBgColor= "#ffffff"
        selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default P55;