import React , { useEffect } from "react";
import gsap from "gsap";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import ProductReview from "../../../components/ProductReview/ProductReview";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import landing1 from "../../../images/A70/rithik.png";
import landing2 from "../../../images/A70/rithiksmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/A70/avatar.png";
import icon1 from "../../../images/A70/icon1.png";
import icon2 from "../../../images/A70/icon2.png";
import icon3 from "../../../images/A70/icon3.png";
import landingbg1 from "../../../images/A70/landingBgA70Big.png";
import landingbg2 from "../../../images/A70/landingBgA23SSmall.png";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/productlanding/blue.png";
import blue2 from "../../../images/productlanding/blue.png";
import blue3 from "../../../images/productlanding/blue.png";
import orange1 from "../../../images/A70/productColor1.png";
import orange2 from "../../../images/A70/orange.png";
import orange3 from "../../../images/A70/orange.png";
import blueSmall1 from "../../../images/A70/Wrapper.png";
import blueSmall2 from "../../../images/A70/Wrapper.png";
import blueSmall3 from "../../../images/A70/Wrapper.png";
import blackSmall1 from "../../../images/A70/Wrapper1.png";
import blackSmall2 from "../../../images/A70/Wrapper1.png";
import blackSmall3 from "../../../images/A70/Wrapper1.png";
import picSmall1 from "../../../images/A70/pic.png";
import picSmall2 from "../../../images/A70/pic.png";
import picSmall3 from "../../../images/A70/pic.png";
import level1 from "../../../images/A70/level1.png";
import level2 from "../../../images/A70/level2.png";
import level3 from "../../../images/A70/level3.png";
import level4 from "../../../images/A70/level4.png";
import MyIcon from "../../../images/A70/staricon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/A70/Patterns.png";
import tech1 from "../../../images/A70/tech1.png";
import tech2 from "../../../images/A70/tech2.png";
import tech3 from "../../../images/A70/tech3.png";
import techsmall1 from "../../../images/A70/tech1small.png";
import techsmall2 from "../../../images/A70/tech2small.png";
import techsmall3 from "../../../images/A70/tech3small.png";
import vertical1 from "../../../images/A70/vertical1.png";
import vertical2 from "../../../images/A70/vertical2.png";
import vertical3 from "../../../images/A70/vertical3.png";
import vertical4 from "../../../images/A70/vertical4.png";
import vertical5 from "../../../images/A70/vertical5.png";
import verticalsmall1 from "../../../images/A70/vertical1small.png";
import verticalsmall2 from "../../../images/A70/vertical2small.png";
import verticalsmall3 from "../../../images/A70/vertical3small.png";
import verticalsmall4 from "../../../images/A70/vertical4small.png";
import verticalsmall5 from "../../../images/A70/vertical5small.png";
import epicfullimage from "../../../images/A70/epicImage.png";
import epicsmall from "../../../images/A70/epicsmall.png";
import soha from "../../../images/Review/soha.png";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import compare11 from "../../../images/A70/compare1.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import similar1 from "../../../images/A70/similar1.png";
import similar2 from "../../../images/A70/similar2.png";
import similar33 from "../../../images/A70/similar3.png";
import similar4 from "../../../images/A70/similar4.png";
//import redStar from "../../../images/Specification/Star.png";
import { Box ,useMediaQuery, Grid, Hidden, Typography } from "@mui/material";
import RedIconAnimation from "../../../components/Animation/RedIconAnimation";

  const landingTexts = [
    {
      text1: "6.6” HD+ DISPLAY DYNAMIC BAR | 12GB* RAM | 13MP AI REAR Camera",
    },
    {
      text2: "Starting from ₹6,299/-",
    },
  ];

  const features = [
    {
      title: "Unparalleled Storage: 12GB* (4GB+8GB*) RAM + 256GB ROM",
      description:
        "Store, organize, and access your data effortlessly - 4GB RAM, expandable up to 12GB* with Memory Fusion & Massive 256GB ROM.",
      icon: icon1,
    },
    {
      title: "Brighter, Bolder, Better: 6.6inch HD+ Display",
      description:
        "Experience vibrant colors and sharp details with the 6.6” HD+ Display, featuring 500 nits of peak brightness and a dynamic display bar.",
      icon: icon3,
    },
    {
      title: "Camera Duo: 8MP AI Front & 13MP AI Dual Rear Camera",
      description:
        "With itel A70's 8MP AI Front Camera and 13MP AI Dual Rear Camera, capturing and sharing your memories has never been easier. ",
      icon: icon2,
    },
  ];

  const mainTexts = [
    {
      // headingTop: "The Ultimate Storage ",
      // headingBottom:"Solution: Massive 256GB ROM",
      heading:"The Ultimate Storage Solution: Massive 256GB ROM",
      text: "Introducing the itel A70, your gateway to the future of smartphones! With its awesome design and 6.6inch HD+ display, it's the epitome of elegance and functionality. Experience seamless multitasking with 4GB RAM, expandable up to 12GB* with Memory Fusion & Massive 256GB ROM. Powered by an octa-core processor and 5000 mAh battery, and Type-C charging it's built for seamless performance. Get yours today and step into the world of tomorrow!"
    },
  ];

  const images = {
    green: [orange1, orange2, orange3],
    peach: [blue1, blue2, blue3],
    blue: [black1, black2, black3],
    lightBlue:[orange1, orange2, orange3]
  };
  
  const smallImages = {
    green:[picSmall1,picSmall2, picSmall3],
    peach:[blueSmall1, blueSmall2, blueSmall3],
    blue: [blueSmall1, blueSmall2, blueSmall3],
    lightBlue:[blackSmall1,blackSmall2, blackSmall3]
  }

  const colorNames = {
    green: "Field Green",
    peach: "Brilliant Gold",
    blue: "Starlish Black",
    lightBlue: "Azure Blue",
  };
  
  const colorStyles = {
    green: "#97D3BF",
    peach: "#FBF0CA",
    blue: "#33405D",
    lightBlue:"#C3E1F8",
  };

  const initialReviewsData = [
    {
      name: "Radha",
      date: "15th April 2024",
      rating: 4,
      title: "Value for Money",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: radha,
    },
    {
      name: "Krishna",
      date: "15th April 2024",
      rating: 4,
      title: "Best Battery Life",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: krishna,
    },
    {
      name: "Soha Ali Khan",
      date: "8th Aug 2023",
      rating: 3,
      title: "Professional Camera",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: soha,
    },
  ];
  const additionalReviewsData = [
    {
      name: "Akshay",
      date: "1st Jan 2024",
      rating: 5,
      title: "Excellent Product",
      content: "This is a new dummy review added for testing purposes.",
      image: radha,
    },
    {
      name: "Arya",
      date: "2nd Jan 2024",
      rating: 4,
      title: "Great Value",
      content: "Another dummy review added for testing purposes.",
      image: krishna,
    },
  ];

  const products = [
    {
      name: "Earbuds T1pro",
      price: "₹849.00",
  
      imageUrl: level1,
    },
    {
      name: "Icon 2",
      price: "₹1,099.00",
      originalPrice: "₹4,999.00",
      imageUrl: level2,
    },
    {
      name: "N82",
      price: "₹999.00",
      originalPrice: "₹4,999.00",
      imageUrl: level3,
    },
    {
      name: "SmartWatch 2 Ultra",
      price: "₹2,099.00",
      originalPrice: "₹4,999.00",
      imageUrl: level4,
    },
  ];
  const products2 = [
    {
      name: "S24",
      price: "₹10,999.00",
  
      imageUrl: similar1,
    },
    {
      name: "P55+",
      price: "₹9,499.00",
      originalPrice: "₹13,999.00",
      imageUrl: similar2,
    },
    {
      name: "P55 5G",
      price: "₹9,999.00",
      originalPrice: "₹12,999.00",
      imageUrl: similar33,
    },
    {
      name: "A70",
      price: "₹6,799.00",
      originalPrice: "₹7,299.00",
      imageUrl: similar4,
    },
  ];

  const specs = {
    generalSpecs: {
      Model: "A70",
      colors: "Brilliant Gold | Azure Blue | Starlish Black | Field Green",
    },
    display: {
      displatSize: "6.6 inch",
      resolution: "1612*720 Pixels",
      resolutionType: "HD+",
      dimension: "163.9 * 75.7 * 8.6 mm ",
      technology: "G+F, incell",
      refreshRate:"60 HZ",
      touchSamplingRate:"120 Hz",
      pixelDensity:"267PPI"
    },
    osProcessor: {
      operatingSystem: "Android 13 GO",
      chipSet : "Unisoc T603 (12nm)",
      processorCore: "Octa-Core",
      
    },
    camera: {
      rearCamera: "13 MP AI DUAL CAM",
      rearFlash: "Yes",
      frontCamera: "8MP",
      FrontFlash: "Yes",
    },
    battery: {
      batteryCapacity: "5000 mAh",
      batteryType: "Li-Polymer",
      USB: "Type-C",
      standBy: "41 Days",
      talkTime: "60 Hrs",
    },
    memoryStorage: {
      ram:"(4GB + 8 GB*)RAM",
      rom:"64GB/128GB/256GB",
      expandable:"1TB",
    },
    connectivity: {
      networkType: "4G,4G VOLTE ",
      GSM: "2G:B3|5|8",
      EDGE: "Support Download",
      WCDMA: "3G:B1|5|8",
      LTE: "B1/B3/B5/B8/B40/B38/B41|120M)|",
      slimSlot: "Slot-1 & Solt-2 Nano",
      dualActiveOrStandyBy:"Dual Active",
      bluetoothSupport: "Yes",
      wiFi : "Yes",
      GPS : "Yes",
      usbConnectivity:"Yes",
    },
    additional: {
      earphoneJack: "3.5 mm",
      sensors: "Side Fingerprint Sensor",
      fingerprint: "Side",
      accessories: "Handset| Charger | USB Cable | Sim Ejector Tool |User Guide | Protective Film | Protective Cover",
      
    },
  };

  const models = [
    {
      name: "A05s",
      price: "₹6,499.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.5 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare11,
    },
    {
      name: "S24",
      price: "₹10,999.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare2,
    },
    {
      name: "A70",
      price: "₹6,799.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.9 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare3,
    },
  ];

  

  const callFaqs = [
    
    {
      question: "How to set music as a ringtone?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Cannot make a call after activating data.",
      answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
      \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      4. Try to reset factory settings, but remember to take data back up first.
      5. Visit the nearest customer service center for further check.`,
      },
    {
      question: "Cannot add new contacts to phone book or view phone book.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Copy contacts to another phone.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
  ];
  
  const cameraFaqs = [
    {
      question: "How to take high-quality photos.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      question: "How to switch between different camera modes.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
  ];
  
  const dummyFaqs = [
    {
      question: "Dummy question 1",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 2",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 3",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  
  const tabLabels = [
    "CALLS & CONTACTS",
    "CAMERA & PICTURE",
    "CHARGING",
    "INTERNET & NETWORK",
  ];
  const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

  const A70 = () => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const maxWidthLandingImage = {
      xs: "92%",
      sm:"45%",
      md: "49%",
      lg: "47%",
    }

    useEffect(() => {
      // Define the x value based on screen size
      const xValue = isSmallScreen ? "0vw" : "18vw";
      //const xValue = isSmallScreen ? "0vw" : "20vw";
      const yValue = isSmallScreen ? "5vh" : "5.5vh";
  
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
    <Box sx={{bgColor:"#fff"}} > 
    {/* <>
        <Box  sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                {mainTexts.headingTop}
            </Typography>
            <Typography variant="h4" component="div">
                {mainTexts.headingBottom}
            </Typography>
        </Box> */} 
      
      <ProductLanding
      landingImages={[landing1, landing2]}
      landingTexts={landingTexts}
      maxWidthLandingImage={maxWidthLandingImage}
      appBarColor="#FFFFFF"
      appBarBorder= "1px solid #D9D9D9"
      mainTexts={mainTexts}
      features={features}
      buyNowButtonImage={buyNowButton}
      arrowIcon={arrow}
      awesomeSeriesIcon={awesomeSeriesIcon}
      menuIcon={menuIcon}
      //backgroundImage={backgroundImage}
      buyNowButtonLabel="Buy Now"
      headbuttontext="superseries"
      productname="A70"
      ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
      Productnamecolor="#181818"
      SpecificationsORTechSpecs= "Specifications"
      linearcolor="linear-gradient(to left, #FF0037,#FF0037)"
      backgroundImages={[landingbg1, landingbg2]}
      OverviewSpecificationcolor="#181818"
      buynowbuttonbgcolor="#1A1A1A"
      buynowbuttoncolor="#FFFFFF"
      featuresborder="none" // Added border here
      rightsideTextColorFeatures="#252525"
      featuresubheadingcolor="#1C1B1B" // Remove this line to use linear color
      featurediscriptiontextcolor="#181818"
    />

      <Marquee
        appBarColor="#EBE8E2"
        textColor="#6B6682"
        iconSrc={MyIcon}
        text1="AWESOME SERIES"
        text2="IMPRESSIVELY AWESOME"
        videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/193b/2409/-d534-45fc-9deb-dc56392a90ce?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZKoSGaJNSMgXtPveo97FZI~NXRfbv6L6kvaJRncnyAdSSNX1eV3HrkMS0rUuTTePVMySiNwvbVsuUeWU7Pq8x3yulW01OCCM~EnFyhDxDouBbRm40cyqEfSuEyFI1jAMfE7oRvw3l9EDwikW3AoU~cnJvpowkcIZOBoeS-aWBg5Q16qdeyM4nFRH2sEgbWr5K4d2hKQIONDrSM5kHcymoolqIGf9E43mbfJ2i2~6VOBU220~B4-6bSEomhDkbtk3YD7O10XOdRZTvzS8YT9mD3BDP9a0bnBFWmRwuFTK3g~O9aUe-FXAMBcZgnXq7WCdCu1itk3Xxms8Ug5gAmcJYg__"
        playIconSrc={play}
        frameSrc={patterns}
      />
      
      <VerticalSection/>

      <TechSpecs/>

      <Box sx={{
        paddingLeft: "4%",
        paddingRight:"4%",
        }}>
          <Hidden smUp>
            <Box sx={{ display: "flex",
              
            }}>   
              <Box >
                  <RedIconAnimation/>
              </Box>
              <Typography variant="h4" sx={{ pb:"5%", margin: "12px" }}>
                  Product Version
              </Typography>
            </Box>
          </Hidden>
        <ProductColor
          images={images}
          smallImages={smallImages}
          colorNames={colorNames}
          colorStyles={colorStyles}
          initialColor="green"
          colornametextcolor= "#181818"
          selectedBgColor="#ffffff"
          isNextArrowBlack = {true}
          isPrevArrowBlack = {true}
        />
      </Box>
      
      <Specifications 
        specs={specs} 
        bgColor={"#fff"} 
      />
      <ProductReview
        initialReviews={initialReviewsData}
        additionalReviews={additionalReviewsData}
        isDarkMode={false}
      />
      <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="light" bgcolor="white"
      />
      <CompareModels 
        models={models}
        mode="light" bgcolor="white"
      />
      <PhoneCard 
        heading="Similar Products" 
        products={products2} 
      />
      <SupportChannels 
      />
      <CheckOutProducts 
        bgColor={"#fff"} 
        heading ="Check out our other Products"
      />
      <FollowUs />
      <ProductFaqs
          //isDarkMode="true"
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
          

          //linkText={linkText}
          //backgroundColor={accordionBackgroundColor}
          //textColor={textColor}
          //buttonTextColor={buttonTextColor}
          //selectedTabBackgroundColor={selectedTabBackgroundColor}
          //selectedTabTextColor="{selectedTabTextColor}"
          //borderColor={borderColor}
          //buttonBorder={buttonBorder}
          
          
      />

        {/* <ProductFaqs
        isDarkMode={false}
          title="Frequently Asked Questions (FAQs)"
          linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          mode="light"
          bgcolor="white"
          accordionBackgroundColor="white"
          
        /> */}
      {/* </> */}
    </Box>
    </>
  );
};
const TechSpecs = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <>
    <img
    src={isSmallScreen ? epicsmall : epicfullimage}
    alt="epic pics"
    style={{
      width: "100%",
      display: "block",
      cursor:"pointer",
      paddingBottom:"4%",
    }}
  />
  
    <div 
      style={{bgcolor: " #fff",
      textColor:"#181818",
      paddingLeft: "4%",
      paddingRight:"4%",
      paddingBottom:"4%",
      }}
    >
      {/* <Box sx={{ display: "flex",
                marginBottom:"2%"}}>
      <img src= {redStar} alt="redstar" />
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{
          fontSize: { xs: "30px", sm: "40px", lg: "48px" },
          }}>
          Tech Specs
        </Typography>
        </Box> */}
    <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box>
            <img
              src={isSmallScreen ? techsmall1 : tech1}
              alt="double vision"
              style={{
                width: "100%",
                height: "calc(121vh - 20px)",
                borderRadius: "16px",
                }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box>
                <img
                  src={isSmallScreen ? techsmall2 : tech2}
                  alt="slim glossy"
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <img
                  src={isSmallScreen ? techsmall3 : tech3}
                  alt="energy Performance "
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  </div>
 
</>
);
};

const VerticalSection = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return(
    <div 
      style={{
        paddingLeft:"4%",
        paddingRight:"4%",
        paddingBottom:"4%",
        paddingTop:"4%",
      }}>
        <Box
          sx={{
            border: {
              xs:"none",
              sm:"1px solid #08E4887A" 
            },
            borderRadius: "23px",
            padding:"10px",
            }} >
          <img src={isSmallScreen ? verticalsmall1 : vertical1}
              alt="first"
              style={{ 
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall2 : vertical2}
              alt="second"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall3 : vertical3}
              alt="third"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall4 : vertical4}
              alt="fourth"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={isSmallScreen ? verticalsmall5 : vertical5}
              alt="fifth"
              style={{
                width :"100%",
                borderRadius:"14px",
              }}
          />  
        </Box>
    </div>
  )
}

export default A70;


