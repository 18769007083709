import React from "react";
import { Route, Routes } from "react-router-dom";
import { apiConfig } from "../config/api-config";
import Template from "../components/template/template";
// import UserSignIn from "../pages/sign-in/Login";
import FourNotFour from "../pages/FourNotFour";
import Home from "../pages/Home/Home";
import Otsr from "../pages/otsr/Otsr";
import Support from "../pages/support/Support";
import EwasteManagement from "../pages/e-wastemanagement/EwasteManagement";
import ProductListing from "../pages/productlisting/ProductListing";
import DaysPromise from "../pages/100dayspromise/100DaysPromise";
import AboutUs from "../pages/aboutus/AboutUs";
import ContactUs from "../pages/contactus/ContactUs";
import FAQ from "../pages/faq/FAQ";
import InvestorRelations from "../pages/investorrelations/InvestorRelations";
import Media from "../pages/media/Media";
import ServiceCenter from "../pages/servicecenter/ServiceCenter";
import S24 from "../pages/productpages/S24/S24";
import P555G from "../pages/productpages/P55 5g/P555G";
import A70 from "../pages/productpages/A70/A70";
import P55 from "../pages/productpages/P55/P55";
import P55PLUS from "../pages/productpages/P55+/P55+.js";
import P55T from "../pages/productpages/P55T/P55T.js";
import S23PLUS from "../pages/productpages/S23+/S23+";
import A60s from "../pages/productpages/A60s/A60s";
import P40PLUS from "../pages/productpages/P40+/P40+.js";
import S23 from "../pages/productpages/S23/S23.js";
import SuperGuru from "../pages/productpages/SuperGuru/SuperGuru.js";
import It5262 from "../pages/productpages/it5262/It5262.js";
import It5330 from "../pages/productpages/it5330/It5330.js";
import Power120 from "../pages/productpages/power120/Power120.js";
import Power450 from "../pages/productpages/power450/Power450.js";
import A05 from "../pages/productpages/A05/A05.js";
import A23s from "../pages/productpages/A23s/A23s.js";
import Aura05i from "../pages/productpages/Aura05i/Aura05i.js";
import CSR from "../pages/CSR/CSR.js";
import Blog from "../pages/Blog/Blog.js";
import P40 from "../pages/productpages/P40/P40.js";
import Test from "../pages/test/Test.js";
const Routers = () => {
  return (
    <Routes>
      <Route
        path={`${apiConfig.basePath}/home`}
        element={
          <Template>
            <Home />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/`}
        element={
          <Template>
            <Home />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/otsr`}
        element={
          <Template>
            <Otsr />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/support`}
        element={
          <Template>
            <Support />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/ewastemanagement`}
        element={
          <Template>
            <EwasteManagement />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/productlisting`}
        element={
          <Template>
            <ProductListing />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/100dayspromise`}
        element={
          <Template>
            <DaysPromise />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/aboutus`}
        element={
          <Template>
            <AboutUs />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/contactus`}
        element={
          <Template>
            <ContactUs />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/faq`}
        element={
          <Template>
            <FAQ />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/investorrelations`}
        element={
          <Template>
            <InvestorRelations />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/media`}
        element={
          <Template>
            <Media />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/servicecenter`}
        element={
          <Template>
            <ServiceCenter />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/s24`}
        element={
          <Template>
            <S24 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/p555g`}
        element={
          <Template>
            <P555G />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/a70`}
        element={
          <Template>
            <A70 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/p55`}
        element={
          <Template>
            <P55 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/p55+`}
        element={
          <Template>
            <P55PLUS />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/p55t`}
        element={
          <Template>
            <P55T />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/s23+`}
        element={
          <Template>
            <S23PLUS />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/a60s`}
        element={
          <Template>
            <A60s />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/p40+`}
        element={
          <Template>
            <P40PLUS />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/s23`}
        element={
          <Template>
            <S23 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/superguru`}
        element={
          <Template>
            <SuperGuru />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/it5262`}
        element={
          <Template>
            <It5262 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/it5330`}
        element={
          <Template>
            <It5330 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/power120`}
        element={
          <Template>
            <Power120 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/power450`}
        element={
          <Template>
            <Power450 />
          </Template>
        }
      />
       <Route
        path={`${apiConfig.basePath}/A05`}
        element={
          <Template>
            <A05 />
          </Template>
        }
      />
       <Route
        path={`${apiConfig.basePath}/A23s`}
        element={
          <Template>
            <A23s />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/Aura05i`}
        element={
          <Template>
            <Aura05i />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/CSR`}
        element={
          <Template>
            <CSR />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/blog`}
        element={
          <Template>
            <Blog />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/P40`}
        element={
          <Template>
            <P40 />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/test`}
        element={
          <Template>
            <Test />
          </Template>
        }
      />

      <Route path="*" element={<FourNotFour />} />
    </Routes>
  );
};

export default Routers;
