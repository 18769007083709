import React, { useEffect } from "react";
import { Box, useMediaQuery, Typography, Grid, Hidden} from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import CarouselPanorama from "./carousel";
import gsap from "gsap";
import landing1 from "../../../images/p40/landingimg.png";
import landing2 from "../../../images/p40/landingimg2.png";
import menuIcon from "../../../images/P40+/picon.png";
import backgroundImage from "../../../images/p40/landingbg3.png";
import icon1 from "../../../images/P40+/icon1.png";
import icon2 from "../../../images/p40/icon.png";
import icon3 from "../../../images/P40+/icon2.png";
import MyIcon from "../../../images/p40/picon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/P40+/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/productversionsuperguru.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/p40/colorchange.png";
import blue2 from "../../../images/p40/colorchange.png";
import blue3 from "../../../images/p40/colorchange.png";
import bluesmall1 from "../../../images/p40/productColoursmall1.png";
import bluesmall2 from "../../../images/p40/productColoursmall1.png";
import bluesmall3 from "../../../images/p40/productColoursmall1.png";
import blacksmall1 from "../../../images/p40/productColorsmall1.png";
import blacksmall2 from "../../../images/p40/productColorsmall1.png";
import blacksmall3 from "../../../images/p40/productColorsmall1.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import caroBg from "../../../images/p40/carobg.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/p40/landingbg.png";
import landingbg2 from "../../../images/p40/landingbg2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import gaming from "../../../images/p40/display.png";
import first from "../../../images/p40/first.png";
import first2 from "../../../images/p40/first2.png";
import second from "../../../images/p40/fifth.png";
import second2 from "../../../images/p40/fifth2.png";
import third from "../../../images/p40/second.png";
import third2 from "../../../images/p40/second2.png";
import fourth from "../../../images/p40/third.png";
import fourth2 from "../../../images/p40/third2.png";
import fifth from "../../../images/p40/fourth.png";
import fifth2 from "../../../images/p40/fourth2.png";
import gaming2 from "../../../images/p40/display2.png";
import camera from "../../../images/p40/camera.png";
import camera2 from "../../../images/p40/canmera2.png";
import clarity from "../../../images/p40/processor.png";
import clarity2 from "../../../images/p40/processor2.png";
import performance from "../../../images/p40/battery.png";
import performance2 from "../../../images/p40/battery2.png";
import gold1 from "../../../images/p40/productColor.png";
import gold2 from "../../../images/p40/productColor.png";
import gold3 from "../../../images/p40/productColor.png";
import goldsmall1 from "../../../images/p40/productColorsmall2.png";
import goldsmall2 from "../../../images/p40/productColorsmall2.png";
import goldsmall3 from "../../../images/p40/productColorsmall2.png";


const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  blue: [blue1, blue2, blue3],
  black: [black1, black2, black3],
  gold:[gold1, gold2, gold3],
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  gold:[goldsmall1, goldsmall2, goldsmall3],
}

const colorNames = {
  blue: "Dreamy Blue",
  black: "Force Black",
  gold: "Luxurious Gold",
};

const colorStyles = {
  blue: "linear-gradient(to left, #CAF0FB, #4DB7D9)",
  black: "linear-gradient(to left, #060708, #ACB1B7)",
  gold: "linear-gradient(to left, #FFF0CB, #FFE092)",
};

const products = [
  {
    name: "Earbuds T1pro",
    price: "₹1,269.00",

    imageUrl: level1,
  },
  {
    name: "A60",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "Roar 75",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "Smartwear T11",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];

const products2 = [
  {
    name: "S23",
    price: "₹1,269.00",

    imageUrl: similar1,
  },
  {
    name: "A60",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar2,
  },
  {
    name: "IT 4283 Pro",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "Power That Lasts: 6000 mAh Battery with 10W Charging  ",
    description:
      "Keep pace with your busy lifestyle with the 6000 mAh battery mobile phones, paired with 10W fast charging.",
    icon: icon1,
  },
  {
    title: "Immerse Yourself in Brilliance: 6.6” HD+ Water Drop Display",
    description:
      "itel P40's 6.6-inch HD+ waterdrop display, offering stunning clarity and vibrant colours that bring your content to life.",
    icon: icon2,
  },
  {
    title: "13MP AI Dual Camera Excellence: In Your Pocket All Day",
    description:
      "Picture Perfect: Unleash Brilliance with a 13MP AI dual camera. Capture Every Detail, Every Time",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Power Up Your Persona: 6000 mAh Battery Smartphone",
    text: "Introducing the itel P40 mobile: Your power-packed sidekick for the digital era. With a vibrant 6.6-inch HD+ IPS Waterdrop display, a whopping 6000 mAh battery, and a sharp 13 MP AI dual rear camera, it's the ultimate tool for capturing life's moments. It's not just a phone – it's a statement. Stay charged, stay connected, and stay ahead with the itel P40.",
  },
];

const specs = {
  generalSpecs: {
    model: "P40",
    colors: ["Force Black", "Dreamy Blue" , "Luxurious Gold"],
  },
  display: {
    displaySize: "6.6' HD + IPS Waterdrop",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    technology:"G+F, Incell",
    refreshRate:"60HZ",
    touchSamplingRate:"120HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 12",
    chipSet:"Unisco SC9863A",
    processorCore:"OctaCore",
  },
  camera: {
    rearCamera: "13 MP + VGA",
    rearFlash: "Yes",
    frontCamera: "5 MP",
    FrontFlash: "No",
  },
  battery: {
    batteryCapacity: "",
    batteryType: "",
    fastCharging:"10W (Supports 18W)",
    USB: "Micro USB",
    standBy: "",
    talkTime: "",
    mediaPlaybackTime:"Audio: 203Hours; Video: 23 Hours"
  },
  memoryStorage: {
    ram:"64 + 7GB*, 32 + 6GB*, 64 + 4GB*",
    rom:"32GB, 64GB, 128GB",
    userMemory:"113 GB",
    expandable:"Upto 1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8; G+F, Incell",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Micro USB",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor ",
    fingerprint:"Back",
    accessories: "10W Adapter(Supports 18W) | USB Cable | Protective Cover",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const P40 = () => {


  //const linkText = "Check out the support page for more.";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "85%",
    sm:"45%",
    md: "37%",
    lg: "28%",
  }
  
  const battery = isSmallScreen ? gaming2 : gaming;
  const cameraimg = isSmallScreen ? camera2 : camera;
  const clarityimg = isSmallScreen ? clarity2 : clarity;
  const performanceimg = isSmallScreen ? performance2 : performance;
  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const fifthimg = isSmallScreen ? fifth2 : fifth;
  // const carousel = isSmallScreen ? caro2 : caro1;

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "12vw" : "28vw"; // Adjusted values for right shift
    const yValue = isSmallScreen ? "1vh" : "0vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue });
  
    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  
  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "#0A0C08" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          appBarColor="white" // Remove this line to use linear color
          mainTexts={mainTexts}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarBorder= "1px solid #181818"
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="P40"
          linearcolor="linear-gradient(to left, #70A2E1, #D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor="#181818"
          OverviewSpecificationcolor="#181818"
          SpecificationsORTechSpecs= "Full Specifications"
          buynowbuttonbgcolor= "#CADCDD"
          buynowbuttoncolor="#050036"
          featuresborder="none" // Added border here
          headingColor="var(--Colors-Blue-Blue-2, #3A3358);" // Remove this line to use linear color
          rightsideTextColorFeatures="var(--Colors-Blue-Blue-2, #3A3358);"
          featuresubheadingcolor="var(--Colors-Blue-Dark-Blue, #050036);" // Remove this line to use linear color
          featurediscriptiontextcolor="var(--Colors-Blue-Blue-2, #3A3358);"
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#181818"
          textColor="#ADDFDA" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          linearTextColor="linear-gradient(to left, #C0CDEE, #DCF7C1)"
          // Uncomment the below lines if you need the video and pattern image under that video
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/f64b/0b3b/-585c-4c30-b259-6c1938f9657d?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jw2rcvzMDWusr~lOq27TTP~Ju3185YuScEYVYQAL81s~vJHaeV0Wjh-49SuSzedOSGLIaYZZjXSuniWPtsbNlCMHs5KtMSsxw-lAsh6lZcvk2e0zdJ4E1TZQ08QJ1cG8961P99PCtOXhTb2HHNc~TV7FEljgve~7yZRVd7FpKymSjFdo8PVM4Xcz4Imb7CrvtpFGLD44uSTtYX9dJmB3B1ESsPI2sP3CiyAUSOsSbIZJ70JCeAqQNU1baHXiPdeq8XbeXuUkTv3QiqEu6y2rqL3S0z9nH7rXaz61gEBwgzKQjwGNMl8lE3M-Pwt6QYxxO-OonWYuPj~4GVpzVZHE6A__"
          playIconSrc={play}
          frameSrc={patterns}
        />

        {/* ------------------------------------------------------performance----------------------------------------------- */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={performanceimg}
            alt="charge"
          />
        </Box>

        {/* ------------------------------------------------------------Unparalleled clarity--------------------------------------- */}

        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={clarityimg}
            alt="charge"
          />
        </Box>

        {/* //--------------------------------------------------Storage--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={battery}
            alt="charge"
          />
        </Box>

        {/* --------------------------------------------Camera------------------------------------------- */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={cameraimg}
            alt="charge"
          />
        </Box>
         {/* ---------------------------------------------------Carousel--------------------------------------------- */}
         <Box
            sx={{padding: "7% 4% 7% 4%",
            backgroundColor:"#ffffff",
            backgroundImage: isSmallScreen ? " " : `url(${caroBg})`, 
            backgroundSize: "cover", 
            backgroundPosition: "center",}}
          >
            <CarouselPanorama
        // items={carouselItems}
        // smallScreenItems={smallScreenItems}
        // settings={customSettings}
      />
          </Box>

        {/* ------------------------------------------------ Phone Tech Specs------------------------------------------------- */}

        <Box sx={{ pl: "4%", pr: "4%", pb: "6%", pt:"6%", backgroundColor: "#181818" }}>
          
          <Box sx={{ mt: 3, pb:"1%" }}>
            <img
              src={firstimg}
              alt="first"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
              }}
            />
          </Box>

          <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
              
              <Grid container direction="column" spacing={2}>
                
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    

                    <Grid item xs={6} md={6}>
                      <Box>
                        <img
                          src={thirdimg}
                          alt="fourth"
                          style={{
                            width: "100%",
                            height: "calc(59vh - 10px)",
                            borderRadius: "16px",
                          }}
                        />
                      </Box>
                    </Grid>

                   

                  <Grid item xs={6} md={6}>
                      <Box>
                        <img
                          src={fourthimg}
                          alt="fifth"
                          style={{
                            width: "100%",
                            height: "calc(59vh - 10px)",
                            borderRadius: "16px",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                  <Box>
                    <img
                      src={fifthimg}
                      alt="third"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                      }}
                    />
                  </Box>
                </Grid>
              
                
                  </Grid>
                  
                </Grid>
                
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={5}>
              <Box>
                <img
                  src={secondimg}
                  alt="second"
                  style={{
                    width: "100%",
                    height: "calc(121vh - 20px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>

          </Grid>
        </Box>

                

        {/* //--------------------------------------------------ProductColorChange--------------------------------------// */}

        {/* make sure to change backgroundcolor of this part here  */}

          <Box sx={{ backgroundColor: "#131C0E" }}>
            <Box sx={{ padding: isSmallScreen ? "6% 0% 6% 0%" : "6% 4% 6% 4%" }}>
              <Hidden smUp>
                <Typography variant="h4" sx={{ color: "#ffffff", pb:"5%", pl:"4%" }}>
                  Product Version
                </Typography>
              </Hidden>
              <ProductColor
                images={images}
                colorNames={colorNames}
                smallImages={smallImages}
                colorStyles={colorStyles}
                colortextcolor="white"
                initialColor="blue" //set default color as selected when load the page
                colornametextcolor="black"
                selectedBgColor="#131C0E"
                isNextArrowBlack={false}
                isPrevArrowBlack={false}
              />
            </Box>
          </Box>

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#181818"} />

        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          bgColor="#0A0C08"
        />

        {/* ----------------------------------------Level Up Your Experience with Accessories!----------------------------------- */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="dark"
          bgcolor="#0A0C08"
        />

        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        <CompareModels 
          mode="dark" 
          bgcolor="#0A0C08" 
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
          />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          mode="dark"
          bgcolor="#0A0C08"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="dark" bgcolor="#0A0C08" />

        {/* Check out Products */}
        <CheckOutProducts mode="dark" bgColor={"#0A0C08"} heading= "Check out our other Products"/>

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="dark" bgcolor="#0A0C08" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // mode="dark"
          // bgcolor="#0A0C08"
          // accordionBackgroundColor="#0A0C08"
          mode="dark"  
          bgColor="#181818"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#0A0C08"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default P40;