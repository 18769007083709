import React, { useEffect } from "react";
import { Box, useMediaQuery , Hidden, Typography} from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/P55 5G/rithikBig.png";
import landing2 from "../../../images/P55 5G/rithiksmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/productlanding/appbaricon2.png";
import menuIcons from "../../../images/productlanding/menuicons.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import backgroundImage from "../../../images/P55 5G/fetaureBgBig.png";
import icon11 from "../../../images/P55 5G/icon1.png";
import icon12 from "../../../images/P55 5G/icon2.png";
import icon13 from "../../../images/P55 5G/icon3.png";
import MyIcon from "../../../images/productlanding/thunder.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/productlanding/Pattern.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import blue1 from "../../../images/P55 5G/productColorBig1.png";
import blue2 from "../../../images/P55 5G/productColorBig1.png";
import blue3 from "../../../images/P55 5G/productColorBig1.png";
import green1 from "../../../images/P55T/productColor.png";
import green2 from "../../../images/P55T/productColor.png";
import green3 from "../../../images/P55T/productColor.png";
import bluesmall1 from "../../../images/P55 5G/productColorsmall1.png";
import bluesmall2 from "../../../images/P55 5G/productColorsmall1.png";
import bluesmall3 from "../../../images/P55 5G/productColorsmall1.png";
import greensmall1 from "../../../images/P55T/productColorsmall1.png";
import greensmall2 from "../../../images/P55T/productColorsmall1.png";
import greensmall3 from "../../../images/P55T/productColorsmall1.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import landingbg1 from "../../../images/P55 5G/landingBgBig.png";
import landingbg2 from "../../../images/P55 5G/landingBgsmall.png";
import TechSpecs from "../../../components/productcomponents/P555Gtechspecs/Techspecs";

import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
const images = {
  blue: [blue1, blue2, blue3],
  green: [green1, green2, green3],
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  green: [greensmall1, greensmall2, greensmall3],
}

const colorNames = {
  blue: "Galaxy Blue",
  green: "Mint Green",
};

const colorStyles = {
  blue: "#3059A6",
  green: "#B3D98E",
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
 
  {
    title: "5G Enhanced with NRCA",
    description:"Experience the power of 5G enhanced with NRCA. Unlock superior connectivity with next-gen network integration.",
    icon: icon11,
  },
  {
    title: "Blazing Performance with MediaTek Dimensity 6080",
    description:"Get hyped for the itel P55 5G! Featuring MediaTek Dimensity 6080 processor, designed for ultimate multitasking.",
    icon: icon12,
  },
  {
    title: "Mega RAM & Storage: 12GB*  (6GB + 6GB*) RAM + 128GB ROM ",
    description:"Experience endless possibilities with the itel P55 5G's impressive storage capacity powered by memory fusion technology. ",
    icon: icon13,
  },
];

const mainTexts = [
  {
    heading: "Speed, Flair and Power in your pocket!",
    text: "The powerful itel P55 5G is one of the best phones under ₹12,000, featuring a superfast MediaTek Dimensity 6080 processor, a stunning 6.6' HD+ IPS display, and a robust 5000mAh battery with 18W fast charging. This chic 5G smart phone offers up to 12GB* (6GB+6GB*) RAM, 128GB storage, and a high camera mobile setup with a 50MP AI Dual Rear and 8MP Selfie Camera. With enhanced security, elegant design, and a Type-C charger, the itel P55 5G stands out among the latest itel smartphones."
  },
];

const specs = {
  generalSpecs: {
    model: "P55 5G",
    colors: ["Galaxy Blue" , "Mint Green"],
  },
  display: {
    displaySize: "6.6' HD + IPS",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Mediatek Dimensity 6080",
    processorCore:"Octa Core",
  },
  camera: {
    rearCamera: "50MP + AI Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "No",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "32 Days",
    talkTime: "7.5 Hours",
    mediaPlaybackTime:"112 Hours"
  },
  memoryStorage: {
    ram:"8GB*(4GB + 4GB*)/12GB* (6GB + 6GB*)",
    rom:"64GB/ 128GB",
    userMemory:"53GB/ 113GB",
    expandable:"Upto 1TB",
  },
  connectivity: {
    networkType: "5G(n1,n3,n5,n8,n28,n38,n40,n41,n77,n78)",
    GSM: "2G:B3,B5,B8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,B5,B8",
    LTE: "4GB: B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Type C",
  },
  additional: {
    earphoneJack: "3.5 mm",
    thickness:"8.4mm",
    sensors: "Side Fingerprint | Face Unlock | G Sensor",
    fingerprint:"Side",
    accessories: "18W Charger| USB Cable | Protective Cover | Protective Film",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const P555G = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "78%",
    sm:"45%",
    md: "37%",
    lg: "47%",
  }

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "20vw";
    const yValue = isSmallScreen ? "6vh" : "8vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  return (
    <>
      <Box sx={{ bgcolor: "#0A0C08" }}>
        {/* landing */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#013EA3"
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          SpecificationsORTechSpecs= "Specifications"
          OverviewSpecificationcolor="#FFFFFF"
          backgroundImageFeatures={backgroundImage}
          productname="P55 5G"
          Productnamecolor="#FFFFFF"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          buyNowButtonLabel="Buy Now"
          headmaintext="P55"
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#FFFFFF"
          //linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          featuresborder="none" 
          headingColor="#FFFFFF" 
          rightsideTextColorFeatures="#FFFFFF"
          featuresubheadingcolor="#FFFFFF"
          featurediscriptiontextcolor="#FFFFFF"
        />

        {/*  vedio and colorchange part */}

        <Marquee
          appBarColor="rgba(0, 0, 50, 1)"
          textColor="#4682E6"
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSELY POWERFUL"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/f64b/0b3b/-585c-4c30-b259-6c1938f9657d?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jw2rcvzMDWusr~lOq27TTP~Ju3185YuScEYVYQAL81s~vJHaeV0Wjh-49SuSzedOSGLIaYZZjXSuniWPtsbNlCMHs5KtMSsxw-lAsh6lZcvk2e0zdJ4E1TZQ08QJ1cG8961P99PCtOXhTb2HHNc~TV7FEljgve~7yZRVd7FpKymSjFdo8PVM4Xcz4Imb7CrvtpFGLD44uSTtYX9dJmB3B1ESsPI2sP3CiyAUSOsSbIZJ70JCeAqQNU1baHXiPdeq8XbeXuUkTv3QiqEu6y2rqL3S0z9nH7rXaz61gEBwgzKQjwGNMl8lE3M-Pwt6QYxxO-OonWYuPj~4GVpzVZHE6A__"
          playIconSrc={play}
          frameSrc={patterns}
        />

        
        {/* TechSpecs */}
        <TechSpecs
           appBarColor="#4682E6"
           menuIcons={menuIcons}
        />

          {/* colorchange */}
          <Box sx={{ backgroundColor: "#181818" }}>
          
          <Box sx={{padding: isSmallScreen ? "6% 0% 6% 0% " : "6% 4% 6% 4%" }}>
          <Hidden smUp>
                <Typography variant="h4" sx={{ color: "#ffffff", pb:"5%", pl:"2%" }}>
                  Product Version
                </Typography>
              </Hidden>
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              initialColor="blue"
              colortextcolor= "#ffffff"
              isNextArrowBlack={false}
              isPrevArrowBlack={false}
              selectedBgColor="#181818"
            />
          </Box>
        </Box>

        <Specifications specs={specs} bgColor={"#1C1B1B"} />
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#0A0C08"
        />
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
        />

        <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />
        
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />

        <CheckOutProducts bgColor={"#0A0C08"} heading= "Peep into our cool gadgets " />

        <FollowUs 
          mode="dark"
          isDarkMode={true}
        />

        <ProductFaqs
          
          mode="dark"  
          bgColor="#181818"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#0A0C08"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};




export default P555G