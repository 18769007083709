import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid, useMediaQuery, useTheme
} from "@mui/material";
import { styled } from "@mui/system";
import hundreddays from "../../images/otsr/hundreddays.png";
import proof from "../../images/otsr/proof.png";
import rs from "../../images/otsr/rs.png";
import dontsell from "../../images/otsr/dontsell.png";
import assurance from "../../images/otsr/assurance.png";
import landing from "../../images/otsr/landing.png";
import landing2 from "../../images/otsr/mobilelanding.png";
import termsSmall from "../../images/otsr/termsSmall.png";
import termsBig from "../../images/otsr/termsBig.png";
// import tcbg from "../../images/otsr/tcbg.png";
import assurancemobile from "../../images/otsr/assurancemobile.png"
const terms = [
  "Policy not valid after 100 Days of product purchase only - for Original purchaser of the product (customer).",
  "Valid proof of purchase required to avail policy.",
  "Labor cost INR 500 + Taxes to be borne by customer.",
  "Phones must be in identifiable condition by its IMEI no./IMEI sticker.",
  "Other parts than screen if found damaged/defective for whatsoever reason is chargeable to customer.",
  "If device is repaired by unauthorized person, water damage, abuse, misuse, fire, earthquake, war and other natural disasters, force majeure caused by the damage.",
  "The mobile phone suffered catastrophic damage such as crushed or bent whatsoever reason is not eligible for free screen replacement.",
  "Only screen can't be claimed by customer without repair at authorized service center.",
  "Kindly refer box package for offer available with product. Policy/Offers can be model specific.",
];

const disclaimer = [
  "Itel Mobiles reserves the right to change or withdraw the above mentioned policies without prior notice.",
  "Labor cost is not applicable for Vision Series products.",
];

const Item = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(2),
}));

function Otsr() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <div>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: { xs: "70vh", sm: "70vh", md: "70vh", lg: "80vh" },
            backgroundImage: `url(${isSmallScreen ? landing2 : landing})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: { xs: "20px", sm: "40px", md: "60px", lg: "80px" },
            color: "white",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "60%",
              fontSize: { xs: "30px", sm: "37px", md: "80px", lg: "82px" },
              fontWeight: 500,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
          >
            Get a Free Screen Replacement
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "20px",
                lg: "22px",
              },
              marginTop: "10px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
              paddingRight: { md: "36%", xs: "28%" }
            }}
          >
            Got a new itel phone? No worries about cracks! With our One-Time Screen Replacement Promise, if your screen gets damaged within the first 100 days, we’ll replace it for free*
          </Typography>
        </Box>
      </div>

      <Typography
        variant="h4"
        align="center"
        pt={"4%"}
        fontWeight={500}
        sx={{ fontSize: { md: "48px", xs: "30px" } }}
        gutterBottom
      >
        One-Time Screen Replacement
      </Typography>

      <Grid container spacing={4} p={"4%"}>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
            <Box display="flex" flexDirection="column" alignItems={{ xs: "center", sm: "start" }} // Center align for xs, start align for sm and up
              textAlign={{ xs: "center", sm: "start" }}>
              <img
                src={hundreddays}
                alt="Logo"
                style={{ marginBottom: "10%" }}
              />
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "start" }}
              >
                Swap Guarantee
              </Typography>
              <Typography variant="body2" >
                If your itel phone screen gets damaged within 100 days, we'll
                hook you up with a new one
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
            <Box display="flex" flexDirection="column" alignItems={{ xs: "center", sm: "start" }} // Center align for xs, start align for sm and up
              textAlign={{ xs: "center", sm: "start" }}>
              <img
                src={proof}
                alt="Logo"
                style={{ marginBottom: "10%" }}
              />
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "start" }}
              >
                Same Specs, Same Vibes
              </Typography>
              <Typography variant="body2" >
                Your replacement phone will be the same model or one with
                similar specs, so you won’t miss a beat.
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
            <Box display="flex" flexDirection="column" alignItems={{ xs: "center", sm: "start" }} // Center align for xs, start align for sm and up
              textAlign={{ xs: "center", sm: "start" }}>
              <img
                src={rs}
                alt="Logo"
                style={{ marginBottom: "10%" }}
              />
              <Typography
                variant="h6"
                color="primary"
                sx={{ textAlign: "start" }}
              >
                Fresh Look
              </Typography>
              <Typography variant="body2" >
                The swapped phone might look just like your old one or even
                better—score!
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Item>
            <Box display="flex" flexDirection="column" alignItems={{ xs: "center", sm: "start" }} // Center align for xs, start align for sm and up
              textAlign={{ xs: "center", sm: "start" }}>
              <img
                src={dontsell}
                alt="Logo"
                style={{ marginBottom: "10%" }}
              />
              <Typography
                variant="h6"
                color="primary"

              >
                Normal Warranty After 100 Days
              </Typography>
              <Typography variant="body2" >
                Once 100 days are up, the usual repair warranty kicks in.
              </Typography>
            </Box>
          </Item>
        </Grid>
      </Grid>

      <Box p={"4% 4% 6% 4%"}>
        <img src={isSmallScreen ? assurancemobile : assurance} alt="Logo" style={{ width: "100%" }}></img>
      </Box>

      <Box
        sx={{
          backgroundImage: isSmallScreen ? `url(${termsSmall})` : `url(${termsBig})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "auto",
          width: "100%",
        }}
      >
        <Box width="80%" sx={{ textAlign: { xs: "start", sm: "center" } }}>
          <Typography
            variant="h4"
            component="h1"
            fontWeight={500}
            sx={{ fontSize: { md: '48px', xs: "24px" }, paddingTop: "8%" }}
            gutterBottom
          >
            Terms & Conditions
          </Typography>
          <List dense>
            {terms.map((term, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "list-item",
                  listStyleType: "decimal",
                  ml: 2,
                  py: 0,

                }}
              >
                <ListItemText primary={term} sx={{ fontSize: '16px' }} />
              </ListItem>
            ))}
          </List>
          <Typography
            variant="h5"
            component="h2"
            fontWeight={500}
            sx={{ paddingTop: "4%", paddingRight: "100%" }}
            gutterBottom
          >
            Disclaimer
          </Typography>
          
          <List dense sx={{ paddingBottom: "15%" }}>
            {disclaimer.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex", // Ensure the asterisk and text are in the same line
                  alignItems: "center", 
                  paddingLeft:"0",
                  // ml: 2,
                  // py: 0,
                  '&::before': {
                    content: index === 0 ? '"*"' : '"**"', 
                    color: 'inherit',
                    display: 'inline-block',
                    width: '1em',
                    marginRight: '8px',
                  },
                }}
              >
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </div>
  );
}

export default Otsr;
