// src/data/products.js
import itel1 from "../../../images/productlisting/itel1.png";
import itel2 from "../../../images/productlisting/itel2.png";
import itel3 from "../../../images/productlisting/itel3.png";
import itel4 from "../../../images/productlisting/itel4.png";
import itel5 from "../../../images/productlisting/itel5.png";
import itel6 from "../../../images/productlisting/itel6.png";

export const products = [
  {
    id: 1,
    name: "itel A60S",
    image: itel1,
    price: 6000,
    tag: "New",
    camera: 8,
    screenSize: "5 in",
    storage: 64,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "164.0 x 75.0 x 9.3 mm",
    processor: "Quad-core Processor",
    battery: 4000,
    description: "Long Battery with fast performance",
  },
  {
    id: 2,
    name: "itel A60S",
    image: itel2,
    price: 7000,
    tag: "New",
    camera: 8,
    screenSize: "6 in & above",
    storage: 64,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "164.0 x 75.0 x 9.3 mm",
    processor: "Quad-core Processor",
    battery: 4000,
    description: "Long Battery with fast performance",
  },
  {
    id: 3,
    name: "itel P55",
    image: itel3,
    price: 5269,
    tag: "New",
    camera: 50,
    screenSize: "6 in & above",
    storage: 128,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "165.0 x 76.0 x 8.5 mm",
    processor: "Octa-core Processor",
    battery: 4000,
    description: "Long Battery with fast performance",
  },
  {
    id: 4,
    name: "itel Aura 05i",
    image: itel4,
    price: 10000,
    oldPrice: 12000,
    tag: "HDFC SPECIAL DISCOUNT",
    discount: true,
    camera: 16,
    screenSize: "5.7 in & above",
    storage: 64,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "158.3 x 75.3 x 7.6 mm",
    processor: "Octa-core Processor",
    battery: 4500,
    description: "Long Battery with fast performance",
  },
  {
    id: 5,
    name: "itel P40",
    image: itel5,
    price: 10999,
    oldPrice: 13000,
    tag: "New",
    camera: 8,
    screenSize: "5.7 in & above",
    storage: 128,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "159.0 x 76.0 x 8.4 mm",
    processor: "Octa-core Processor",
    battery: 5000,
    description: "Long Battery with fast performance",
  },
  {
    id: 6,
    name: "itel A70",
    image: itel6,
    price: 18000,
    oldPrice: 19000,
    tag: "New",
    camera: 64,
    screenSize: "6 in & above",
    storage: 128,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "160.5 x 75.8 x 8.5 mm",
    processor: "Octa-core Processor",
    battery: 4500,
    description: "Long Battery with fast performance",
  },
  {
    id: 7,
    name: "itel Vision 2",
    image: itel1,
    price: 5999,
    tag: "Hot Deal",
    camera: 5,
    screenSize: "5.5 in",
    storage: 32,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "152.0 x 73.0 x 8.9 mm",
    processor: "Quad-core Processor",
    battery: 3000,
    description: "Long Battery with fast performance",
  },
  {
    id: 8,
    name: "itel Vision 3",
    image: itel2,
    price: 6999,
    tag: "Hot Deal",
    camera: 8,
    screenSize: "6 in",
    storage: 64,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "155.0 x 74.0 x 8.7 mm",
    processor: "Quad-core Processor",
    battery: 4000,
    description: "Long Battery with fast performance",
  },
  {
    id: 9,
    name: "itel S12",
    image: itel3,
    price: 7999,
    tag: "New",
    camera: 13,
    screenSize: "6.2 in",
    storage: 64,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "158.0 x 75.0 x 8.3 mm",
    processor: "Quad-core Processor",
    battery: 4000,
    description: "Long Battery with fast performance",
  },
  {
    id: 10,
    name: "itel Note 1",
    image: itel4,
    price: 9999,
    tag: "Best Seller",
    camera: 48,
    screenSize: "6.5 in",
    storage: 128,
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "163.0 x 77.0 x 8.5 mm",
    processor: "Octa-core Processor",
    battery: 5000,
    description: "Long Battery with fast performance",
  },
];
