
import React, { useEffect } from "react";
import { Box, useMediaQuery,Hidden,Typography } from "@mui/material";
//import Star from "../../../images/productlanding/Star.png"
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/productlanding/A60slandingmobile1.png";
import landing2 from "../../../images/productlanding/A60slandingmobile2.png";
import menuIcon from "../../../images/productlanding/A60sicon.png";
import backgroundImage from "../../../images/productlanding/A60sfeaturesbg.png";
import icon1 from "../../../images/productlanding/A60sicon1.png";
import icon2 from "../../../images/productlanding/A60sicon2.png";
import icon3 from "../../../images/productlanding/A60sicon3.png";
import MyIcon from "../../../images/productlanding/A60smarqueicon.png";
// import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/productlanding/A60pattern.png";
//import Marquee from "../../../components/productcomponents/marquee/Marquee";
import Marquee from "./marquee";
import ProductColor from "./productColor";
// import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/productversionA60s.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/productlanding/blueproduct.png";
import blue2 from "../../../images/productlanding/blueproduct.png";
import blue3 from "../../../images/productlanding/blueproduct.png";
import green1 from "../../../images/productlanding/green.png";
import green2 from "../../../images/productlanding/green.png";
import green3 from "../../../images/productlanding/green.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/Aura05i/compare1.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import blacksmall1 from "../../../images/P55/productColorsmall2.png";
import blacksmall2 from "../../../images/P55/productColorsmall2.png";
import blacksmall3 from "../../../images/P55/productColorsmall2.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import landingbg1 from "../../../images/productlanding/A60slanding1.png";
import landingbg2 from "../../../images/productlanding/A60slanding2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
// import marqueesmall from "../../../images/productlanding/A60smallmarquee.png";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
// import marquee from "../../../images/productlanding/A60marquee.png";
import TechSpec from "../../../components/productcomponents/A60s/Techspec";
import Hd1 from "../../../images/productlanding/A60sHd.png";
import Hd2 from "../../../images/productlanding/A60sHd2.png"
import pr from "../../../images/productlanding/A60sProcessor.png";
import pr2 from "../../../images/productlanding/A60sProcessor2.png";
import camera1 from "../../../images/productlanding/A60scamera.png";
import camera2 from "../../../images/productlanding/A60scamera2.png";
import battery1 from "../../../images/productlanding/A60sbattery.png";
import battery2 from "../../../images/productlanding/A60sbattery2.png";
import storage1 from "../../../images/productlanding/A60sstorage.png";
import storage2 from "../../../images/productlanding/A60sstorage2.png";
import hr1 from "../../../images/productlanding/a60shr.png";
import hr2 from "../../../images/productlanding/A60shr2.png";
import screen from "../../../images/productlanding/A60swaterdrop.png";
import RedIconAnimation from "../../../components/Animation/RedIconAnimation";

const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
  {
    question: "Cannot make a call after activating data.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "How to set SIM card 2 as default mode when sending messages.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Phone cannot display recent calls.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "When calling, both sides cannot hear each other.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
const images = {
  starryBlack: [black1, black2, black3],
  blue: [blue1, blue2, blue3],
  green: [green1, green2, green3],
};

const smallImages={
  starryBlack: [bluesmall1, bluesmall2, bluesmall3],
  blue: [blacksmall1, blacksmall2, blacksmall3],
  green: [orangesmall1, orangesmall2, orangesmall3],
}

const colorNames = {
  starryBlack: "Moonlit Violet",
  blue: "Aurora Blue",
  green: "Royal Green",
};

const colorStyles = {
  starryBlack: "#D7D4EA",
  blue: "#353C43",
  green: "#C3DDED",
};

const storages = ["2GB + 32GB"]; // Define your storage options here
  const initialStorage = "2GB + 32GB";

const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "P55 5G",
    price: "₹9,999.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "Powerful 4000mAh Battery",
    description:"Lorem ipsum dolor sit amet consectetur sit amesectetur.",
    icon: icon1,
  },
  {
    title: "Capture your memories with 5MP Dual Rear Camera",
    description:"Lorem ipsum dolor sit amet consectetur sit amesectetur.",
    icon: icon2,
  },
  {
    title: "16.76cm (6.6) HD+ Drop Notch Display",
    description:"Lorem ipsum dolor sit amet consectetur sit amesectetur.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "India’s first 8GB RAM Smartphone under 7K ",
    text:"Introducing the itel A60s, the first smartphone in India to offer 4GB RAM +4GB Memory Fusion for under Rs 7000/-. Experience the awesomeness of dual security, stunning visuals, a massive 5000 mAh battery, and an immersive HD+ display. Get ready to elevate your smartphone experience to the next level."
  },
];

const specs = {
  generalSpecs: {
    modelNumber: "A665L",
    Model: "A70",
    colors: "Brilliant Gold | Azure Blue | Starlish Black | Field Green",
    SimType: "Dual Sim(-)",
    HybridSimSlot: "No",
  },
  display: {
    displatSize: "16.76 cm (6.6inch)",
    resolution: "1612*720 Pixels",
    resolutionType: "HD+",
    refreshRate:"180 HZ",
    pixelDensity:"267PPI"
  },
  osProcessor: {
    operatingSystem: "Android 13 ",
    processorBrand: "Snapdragon",
    processorCore: "-",
    primaryClockSpeed : "1800 GHz",
    
    
  },
  camera: {
    rearCamera: "13MP",
    rearCameraFeatures: "Digital Zoom | Auto Flash | Face Detection | Touch to Focus",
    rearFlash: "Yes",
    frontCamera: "8MP",
    FrontFlash: "Yes",
  },
  
  memoryStorage: {
    internalStorage:"128GB",
    ram:"8GB",
    memoryCardSlotType:"Dedicated Slot"
  },
  connectivity: {
    networkType: "4G",
    supportedNetworks:"4G LTE",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    wiFiHotspot: "Yes" ,
    usbConnectivity:"Yes",
    audioJack:"3.5mm"
  },
  additional: {

    batteryCapacity: "5000 mAh",
    batteryType: "Lithium Ion",
    fastCharging:"18W",
    sensors: "Fingerprint Sensor | Face Unlock | G Sensor",
    warranty:"1 Year Manufacture Warranty",
    inTheBox: "Handset| Charger | USB Cable | Sim Ejector Tool |User Guide",
    
  },
};

const models = [
  {
    name: "A05s",
    price: "₹6,499.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const A60s = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "100%",
    sm:"55%",
    md: "65%",
    lg: "23%",
  }

  const Hd = isSmallScreen ? Hd2 : Hd1;
  const Processor = isSmallScreen ? pr2 : pr;
  const camera = isSmallScreen ? camera2 : camera1;
  const battery = isSmallScreen ? battery2 : battery1;
  const storage = isSmallScreen ? storage2 : storage1;
  const hr = isSmallScreen ? hr2 : hr1;
  // const marqueeImg = isSmallScreen ? marqueesmall : marquee;

  
  

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "25vw";
    const yValue = isSmallScreen ? "2vh" : "0vh";

      // Initially set the image opacity to 0 and its position
      gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue });
  
      // Create the animation to show the image after 3 seconds
      gsap.to(".landing-image", {
        duration: 1,
        delay: 3,
        opacity: 1,
        ease: "power2.out",
      });
    }, [isSmallScreen]);

    
  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "white" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarBorder= "1px solid #D9D9D9"
          appBarColor="#FFFFFF;" // Remove this line to use linear color
          mainTexts={mainTexts}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="A60s"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor=" #181818"
          OverviewSpecificationcolor="#181818"
          SpecificationsORTechSpecs= "Tech Specs"
          buynowbuttonbgcolor="#1A1A1A"
          buynowbuttoncolor="#ffffff"
          featuresborder="none" // Added border here
          headingColor="#FF0037" // Remove this line to use linear color
          rightsideTextColorFeatures="#181818"
          featuresubheadingcolor="#181818" // Remove this line to use linear color
          featurediscriptiontextcolor="#5C5B5A"
          
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#EBE8E2"
          textColor="#6B6682" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="A SERIES"
          text2="IMPRESSIVELY AWESOME"
          linearTextColor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          // Uncomment the below lines if you need the video and pattern image under that video
          //videoSrc={marqueeImg}
          //playIconSrc={play}
          frameSrc={patterns}
        />

        {/* <Box >
          <img
            style={{ width: "100%", display: "block", height: isSmallScreen ? "350px" : "650px" }}
            src={marqueeImg}
            alt="stylish"
          />
        </Box> */}

        {/* //--------------------------------------------------ProductColorChange--------------------------------------// */}

        {/* make sure to change backgroundcolor of this part here  */}
        <Box sx={{ backgroundColor: "white" }}>
          
          <Box sx={{ pr: "5%", pl: "5%",pt:"5%" }}>
          <Hidden smUp>
              <Box sx={{display:"flex", pb: "5%"}}>
                <RedIconAnimation/>
                <Typography variant="h4" gutterBottom sx={{ color: "Black", margin:isSmallScreen ? "13px" : "8px", }}> Product Version </Typography>
              </Box>
            </Hidden>
            <ProductColor
              images={images}
              colorNames={colorNames} 
              colorStyles={colorStyles}
              smallImages={smallImages}
              isNextArrowBlack={false}
              isPrevArrowBlack={false}
              colortextcolor="#181818"
              initialColor="starryBlack" //set default color as selected when load the page
              colornametextcolor="#181818"
              selectedBgColor="#ffffff"
              storages={storages} // Pass storage options
              initialStorage={initialStorage} // 
            />
          </Box>
        </Box>
        {/* ---------------------------techspecs---------------------------------------- */}
        <Box>
          <TechSpec/>
        </Box>
         {/* -------------------------------Hd screen------------------------------------------- */}
         <Box >
          <img
            style={{ width: "100%", display: "block", height: isSmallScreen ? "350px" : "650px" }}
            src={Hd}
            alt="stylish"
          />
        </Box>
      {/* -------------------------------Hd screen------------------------------------------- */}

      <div 
      style={{
        paddingLeft:"4%",
        paddingRight:"4%",
        paddingBottom:"3%",
        paddingTop:"6%",
      }}>
        <Box
          sx={{
            border: {
              xs:"none",
              sm:"1px solid #B897F5" 
            },
            borderRadius: "23px",
            padding:"10px",
            }} >
          <img src={Processor}
              alt="first"
              style={{ 
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={camera}
              alt="second"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={battery}
              alt="third"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={storage}
              alt="fourth"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <Hidden mdUp>
            <img src={screen} alt="screen" style={{paddingBottom:"3%",width:"100%"}}/>
          </Hidden>
          <img src={hr}
              alt="fifth"
              style={{
                width :"100%",
                borderRadius:"14px",
              }}
          />  
        </Box>
      </div>
        
        

       

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#fff"} />

       
        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={false}
          bgColor="white"
        />
        {/* Level Up Experience with Accessories */}
       <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="light" bgcolor="#fff"
        />
        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        <CompareModels models={models} mode="light" bgcolor="white" isTwoMapView="true" />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          mode="light"
          bgcolor="white"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="light" bgcolor="white" />

        {/* Check out Products */}
        <CheckOutProducts bgColor={"#fff"} heading ="Check out our other Products"/>

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="light" bgcolor="white" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // mode="light"
          // bgcolor="white"
          // accordionBackgroundColor="white"
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
        />
      </Box>
    </>
  );
};

export default A60s;
