import React from "react";
import { Box, useMediaQuery, Typography, Grid, Card, CardContent} from "@mui/material";
import ProductLanding from "./landingS24";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/s24_v2/appbar.png";
import backgroundImage from "../../../images/s24_v2/landingbg.png";
import icon1 from "../../../images/s24_v2/icon1.png";
import icon2 from "../../../images/s24_v2/icon2.png";
import icon3 from "../../../images/s24_v2/icon3.png";
import MyIcon from "../../../images/s24_v2/myicon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/s24_v2/Patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/s24_v2/white.png";
import black2 from "../../../images/s24_v2/white.png";
import black3 from "../../../images/s24_v2/white.png";
import blue1 from "../../../images/s24_v2/black.png";
import blue2 from "../../../images/s24_v2/black.png";
import blue3 from "../../../images/s24_v2/black.png";
import bluesmall1 from "../../../images/s24_v2/black2.png";
import bluesmall2 from "../../../images/s24_v2/black2.png";
import bluesmall3 from "../../../images/s24_v2/black2.png";
import blacksmall1 from "../../../images/s24_v2/white2.png";
import blacksmall2 from "../../../images/s24_v2/white2.png";
import blacksmall3 from "../../../images/s24_v2/white2.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/s24_v2/landing1.png";
import landingbg2 from "../../../images/s24_v2/landing2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import first from "../../../images/s24_v2/first.png";
import second from "../../../images/s24_v2/second.png";
import third from "../../../images/s24_v2/third.png";
import fourth from "../../../images/s24_v2/fourth.png";
import charge from "../../../images/s24_v2/camera.png";
import vision from "../../../images/s24_v2/helio.png";
import ai from "../../../images/s24_v2/technology.png";
import processor from "../../../images/s24_v2/display.png";
import charge2 from "../../../images/s24_v2/camera2.png";
import third2 from "../../../images/s24_v2/third.png";
import first2 from "../../../images/s24_v2/first2.png";
import second2 from "../../../images/s24_v2/second2.png";
import ai2 from "../../../images/s24_v2/technology2.png";
import processor2 from "../../../images/s24_v2/display2.png";
import fourth2 from "../../../images/s24_v2/fourth2.png";
import vision2 from "../../../images/s24_v2/helio2.png";
import fifth from "../../../images/s24_v2/fifth.png";
import fifth2 from "../../../images/s24_v2/fifth2.png";
import sixth from "../../../images/s24_v2/sixth.png";
import sixth2 from "../../../images/s24_v2/sixth2.png";
import zoom1 from "../../../images/s24_v2/zoom1x.png";
import zoom2 from "../../../images/s24_v2/Zoom3x.png";
import zoom1x2 from "../../../images/s24_v2/ZOOM1X 2.png";
import zoom3x2 from "../../../images/s24_v2/ZOOM3X 2.png";
import snm1 from "../../../images/s24_v2/SNM.png";
import snm2 from "../../../images/s24_v2/SNM2.png";
import snmimg1 from "../../../images/s24_v2/SNMimg.png";
import snmimg2 from "../../../images/s24_v2/SNMimg2.png";
import pm1 from "../../../images/s24_v2/PM.png";
import pm2 from "../../../images/s24_v2/PM2.png";
import pmimg1 from "../../../images/s24_v2/PMimg.png";
import pmimg2 from "../../../images/s24_v2/PMimg2.png";
import timelapse1 from "../../../images/s24_v2/time lapse.png";
import timelapse2 from "../../../images/s24_v2/Time Lapse 2.png";
import shoot from "../../../images/s24_v2/shoot.png";
import shoot2 from "../../../images/s24_v2/shoot2.png";
import starrytext from "../../../images/s24_v2/Starrytext.png";
import starrytext2 from "../../../images/s24_v2/starrytext2.png";
import blacktext from "../../../images/s24_v2/Blacktext.png";
import blacktext2 from "../../../images/s24_v2/Blacktext2.png";
import starryblackimg from "../../../images/s24_v2/starryblackimg.png";
import starryblackimg2 from "../../../images/s24_v2/starryblackimg2.png";
import fullwidthimg from "../../../images/s24_v2/fullwidthimg.png";



const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  blue: [blue1, blue2, blue3],
  black: [black1, black2, black3],
  
};

const smallImages={
  blue: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  
}

const colorNames = {
  blue: "Starry Black",
  black: "Dawn White",
  
};

const colorStyles = {
  black: "linear-gradient(to left, #E0E3E8,#F4F5F9)",
  blue: "linear-gradient(to left, #060708,#ACB1B7)",
  
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "Picture-Perfect Moments: 108 MP AI Rear Camera",
    description:
      "Say goodbye to blurry memories and hello to crystal-clear shots with the Samsung HM6 ISOCELL Sensor. ",
    icon: icon1,
  },
  {
    title: "MediaTek Helio G91: Unmatched Speed",
    description:
      "Experience ultra-fast gaming & multi-tasking like never before with the Helio G91 processor.",
    icon: icon2,
  },
  {
    title: "6.6' HD+ Punch Hole Display with 90 Hz Refresh Rate",
    description:
      "Dive into a world of vibrant visuals with a 90 Hz refresh rate, every swipe and scroll feels buttery-smooth.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Your Ideal Storyteller: Stunning 108MP AI Camera",
    text: "Capture stories with the all-new itel S24. Packed with a stunning 108MP ultra clear AI camera, bring your pictures to life. Experience blazing-fast speed with a 8GB RAM, expandable upto 16GB* with memory fusion and 128GB ROM smartphone. With a 5000 mAh battery and 18W fast charging, always stay on top of your game. Get a super smooth and responsive experience with a 90Hz refresh rate on the 6.6-inch HD+ punch-hole display smartphone."
  },
];


const specs = {
  generalSpecs: {
    model: "S24",
    colors: ["Starry Black and Dawn White"],
  },
  display: {
    displaySize: "6.56' HD+",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    dimension:"163.54*76.04*8.4 mm",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisco T606(12nm)",
    processorCore:"Octa Core",
  },
  camera: {
    rearCamera: "50 MP AI Dual Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "41 Days",
    talkTime: "60 Hours",
    mediaPlaybackTime:"Video: 24 Hours"
  },
  memoryStorage: {
    ram:"8GB + 16GB",
    rom:"128GB",
    userMemory:"113GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "5.0",
    wiFi : "802.11 ac/a/b/g/n(2.4G/5G)",
    GPS : "Yes",
    USBConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    thickness:"8.4mm",
    sensors: "Fingerprint | Face Unlock | G Sensor | Electronic Compass ",
    fingerprint:"Side",
    accessories: "18W Charger| USB Cable | Protective Cover | Protective Film",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const S24 = () => {
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  

  const maxWidthLandingImage = {
    xs: "55%",
    sm:"45%",
    md: "37%",
    lg: "37%",
  }
  
  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const fifthimg = isSmallScreen ? fifth2 : fifth;
  const sixthimg = isSmallScreen ? sixth2 : sixth;
  const chargeimg = isSmallScreen ? charge2 : charge;
  const aiimg = isSmallScreen ? ai2 : ai;
  const processorimg = isSmallScreen ? processor2 : processor;
  const visionimg = isSmallScreen ? vision2 : vision;
  const zoom1ximg = isSmallScreen ? zoom1x2 : zoom1;
  const zoom3ximg = isSmallScreen ? zoom3x2 : zoom2;
  const snmtextimg = isSmallScreen ? snm2 : snm1;
  const snmimg = isSmallScreen ? snmimg2 : snmimg1;
  const pmtextimg = isSmallScreen ? pm2 : pm1;
  const pmimg = isSmallScreen ? pmimg2 : pmimg1;
  const timelapseimg = isSmallScreen ? timelapse2 : timelapse1;
  const shootimg = isSmallScreen ? shoot2 : shoot;
  const starrytextimg = isSmallScreen ? starrytext2 : starrytext;
  const blacktextimg = isSmallScreen ? blacktext2 : blacktext;
  const starryblackimgg = isSmallScreen ? starryblackimg2 : starryblackimg;

  
  // const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Box sx={{ bgcolor: "#1C1B1B" }}>
        {/* landing */}
        <ProductLanding
         
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#161616"
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          SpecificationsORTechSpecs= "Specifications"
          OverviewSpecificationcolor="#E8E8E8"
          backgroundImageFeatures={backgroundImage}
          productname="S24"
          Productnamecolor="#FFFFFF"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          buyNowButtonLabel="Buy Now"
          headmaintext="S24"
          buynowbuttonbgcolor="#FFFFFF"
          buynowbuttoncolor="#181818"
          linearcolor="linear-gradient(to left, #FFE8CB,#FFCF92)"
          backgroundImages={[landingbg1, landingbg2]}
          featuresborder="1px solid #FFE8CB" 
          // headingColor="linear-gradient(to left, #FFE8CB,#FFCF92)" 
          rightsideTextColorFeatures="#D9D9D9"
          featuresubheadingcolor="#FFFFFF"
          featurediscriptiontextcolor="#D9D9D9"
        />

        {/*  vedio and colorchange part */}

        <Marquee
          appBarColor="#120F0D"
          textColor="#FFE8CB"
          iconSrc={MyIcon}
          text1="SUPER SERIES"
          text2="INCREDIBLY SUPER"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/3e2c/a8dd/-f9dc-41b4-a0c4-e52405c16b2e?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EK37J9bPvfD4Q0VILV9n27A8oPZ-rbY44osO4NAg5-zaDpDMbCCwmldWzJMkjJvRFhICCoou-vOdMmzbQ2-Yx0HR7o5ZAIeD3Z8fBTvicQNj0ytNjWHVRFy9wvT3KyxDpFQZnE9ENggdqwQ4y6IdOhKjNNYia102p26xZWBjuIKDUqoZZqnZmIi4q4nLgSfPdfVovUPxM0Ug2tg65vk~17IXRwx5RXCd~GpiFvG-38juc10K21WlbDTVgxI4Pyb0Wc0w162eLi3CxYWPXLP4a~9j9G1PUvgPV2YfiOP5qtdPOWUaBM60~T5JMZz50gxhzAj~qXk1~JUWmOt68PpmzA__"
          playIconSrc={play}
          frameSrc={patterns}
        />

      
      {/* Charge */}
        <Box >
        <img  style= {{ width: "100%", display: "block"}}    src={chargeimg} alt='charge' />
        </Box>

     {/* ------- 3X sensor Zoom-------- */}
     <Grid container spacing={2} sx={{ padding: 4, backgroundColor: '#1C1B1B' }}>
  {/* Container for the entire section */}
  <Grid 
    container 
    spacing={2}
    sx={{ 
      backgroundColor: '#1C1B1B', 
      padding: { xs: '20px 0', md: '20px 0' }, 
      alignItems: 'center', 
      textAlign: { xs: 'center', md: 'left' }, 
    }}
  >
    {/* Text Section */}
    <Grid 
      item 
      xs={12} 
      md={12} 
      sx={{
        textAlign: { xs: 'center', md: 'left' }, 
        marginBottom: { xs: '16px', md: '-120px' }, 
        marginLeft: { md: '15%' }, // Move text slightly to the right on larger screens
      }}
    >
      <Card sx={{ backgroundColor: '#1C1B1B', boxShadow: 'none' }}>
        <CardContent>
          <Typography
            variant="h2"
            component="h2"
            color="#FFFFFF"
            gutterBottom
            style={{
              fontFamily: 'Mark OT, sans-serif',
              fontWeight: 500,
              fontSize: '48px', 
              lineHeight: '32px', 
              letterSpacing: '-4%',
              marginRight: { xs: 0, md: '33%' }, 
              marginTop: '20px', 
            }}
          >
            3X In-sensor <span style={{ color: '#FFE5C5' }}>Zoom</span>
          </Typography>

          <Typography
            variant="body1"
            color="#F2F2F2"
            style={{
              fontFamily: 'Mark OT, sans-serif',
              fontWeight: 400,
              fontSize: '14px', 
              lineHeight: '22px', 
              letterSpacing: '-1%',
              marginRight: { xs: 0, md: '45%' }, 
              marginTop: '10px', 
              marginBottom: '30px', 
            }}
          >
            With the 3X In-sensor Zoom, you can <br /> capture distant subjects with improved <br /> composition and quality.
          </Typography>
        </CardContent>
      </Card>
    </Grid>

    {/* Images Section */}
    <Grid 
      container 
      item 
      xs={12} 
      md={12} 
      spacing={2} 
      justifyContent="center"
      alignItems="center"
    >
      {/* First Image - Smaller */}
      <Grid item xs={6} md={4}>
        <img
          src={zoom1ximg}
          alt="Zoom Example 1"
          style={{ 
            width: '100%',  
            maxWidth: '80%',  
            marginTop: { xs: '80px', md: '120px' }, // Adjusted marginTop to align with the second image
            // marginBottom: "-120px"
          }}
        />
      </Grid>

      {/* Second Image - Larger */}
      <Grid item xs={6} md={4}>
        <img
          src={zoom3ximg}
          alt="Zoom Example 2"
          style={{ 
            width: '100%',
            maxWidth: '100%', 
            marginTop: { xs: '80px', md: '120px' }, // Set to the same marginTop as the first image
          }}
        />
      </Grid>
    </Grid>
  </Grid>
</Grid>







   

{/* ------Multiple Camera Modes-------- */}
<Box
  sx={{
    backgroundColor: '#1C1B1B',
    padding: { xs: '20px 10px', sm: '20px 20px', md: '80px 40px' },
    maxWidth: '1200px',
    margin: '0 auto',
  }}
>
  <Grid
    container
    spacing={2}
    sx={{
      padding: { xs: '0 50px', sm: '0 70px', md: '0 100px' },
      maxWidth: '100%',
      margin: '0 auto',
    }}
  >
    {/* Heading Section */}
    <Grid item xs={12}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        align="center"
        sx={{
          background: 'linear-gradient(to left, #FFE8CB, #FFCF92)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          display: 'inline-block',
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 500,
          fontSize: { xs: '28px', sm: '36px', md: '48px' },
          lineHeight: { xs: '35px', sm: '45px', md: '57.6px' },
          letterSpacing: '-4%',
          width: { xs: '90%', md: '986px' },
          height: { xs: 'auto', md: '58px' },
          // ml: { xs: '5%', sm: '8%' },
          textAlign: 'center',
        }}
      >
        Multiple Camera Modes: Get Your Best Shots
      </Typography>

      <Typography
        variant="h5"
        color="#F2F2F2"
        align="center"
        sx={{
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 500,
          fontSize: { xs: '16px', sm: '18px', md: '20px' },
          lineHeight: { xs: '22px', sm: '24px', md: '26px' },
          letterSpacing: '-1%',
          width: { xs: '100%', sm: '80%', md: '918px' },
          height: { xs: 'auto', md: '26px' },
          // ml: { xs: '0%', md: '6%' },
          textAlign: 'center',
        }}
      >
        AI Clear Portrait | Super Night Mode | Portrait Blurring
      </Typography>

      {"\u00A0"}

      <Typography
        variant="body1"
        color="#F2F2F2"
        align="center"
        sx={{
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 400,
          fontSize: { xs: '14px', sm: '15px', md: '16px' },
          lineHeight: { xs: '22px', sm: '23px', md: '25.6px' },
          width: { xs: '90%', md: '568px' },
          height: { xs: 'auto', md: '52px' },
          ml: { xs: '5%', sm: '10%', md: '21%' },
          textAlign: 'center',
        }}
      >
        Discover versatile camera modes, from portraits to night mode, for
        <br />
        stunning, professional-quality photos every time.
      </Typography>
    </Grid>

    {/* Image Section with Vertical Text as Image */}
    <Grid item xs={6} md={6} sx={{ position: 'relative' }}>
      {/* Super Night Mode Image */}
      <img
        src={snmimg}
        alt="Super Night Mode"
        style={{
          height: '95%',
          width: '100%',
          marginTop: '4%',
        }}
      />
      {/* Vertical Text Image - Super Night Mode */}
      <img
        src={snmtextimg}
        alt="Super Night Mode Text"
        style={{
          position: 'absolute',
          left: '-12%',
          top: '50%',
          transform: 'translateY(-50%)',
          height: '80%',
          objectFit: 'contain',
        }}
      />
    </Grid>

    <Grid item xs={6} md={6} sx={{ position: 'relative' }}>
      {/* Vertical Text Image - Portrait Mode */}
      <img
        src={pmtextimg}
        alt="Portrait Mode Text"
        style={{
          position: 'absolute',
          right: '80%',
          top: '50%',
          transform: 'translateY(-50%)',
          height: '80%',
          objectFit: 'contain',
        }}
      />
      {/* Portrait Mode Image */}
      <img
        src={pmimg}
        alt="Portrait Mode"
        style={{
          position: 'absolute',
          right: '-25%',
          width: '100%',
          height: '93%',
          objectFit: 'contain',
          marginTop: '4%',
          overflow: 'hidden',
        }}
      />
    </Grid>

    {/* New Section for Time Lapse under Super Night Mode */}
    <Grid
      item
      xs={6}
      md={6}
      sx={{ position: 'relative', marginTop: '20px' }}
    >
      {/* Time Lapse Image under Super Night Mode */}
      <img
        src={timelapseimg}
        alt="Time Lapse Text"
        style={{
          position: 'absolute',
          left: -55,
          top: '50%',
          transform: 'translateY(-50%)',
          height: '60%', // Adjust height for consistency with other text images
          objectFit: 'contain',
        }}
      />
    </Grid>

    <Grid
      item
      xs={6}
      md={6}
      sx={{
        width: '100%',
        height: { xs: '50vh', sm: '60vh', md: '80vh' }, // Adjust height for different screen sizes
        position: 'relative',
        marginTop: '20px',
      }}
    >
      {/* Video Section */}
      <video
        autoPlay
        loop
        muted
        style={{
          width: '220%', // Increase width to extend beyond container
          height: '100%', // Maintain height as 100% of the container
          objectFit: 'cover',
          position: 'absolute',
          top: '0',
          left: '0',
          transform: 'translateX(-44%)', // Adjust to center the video
        }}
      >
        <source
          src="https://s3-figma-videos-production-sig.figma.com/video/925644654045209335/TEAM/6760/328e/-93e1-4040-a4d1-5eda5b0c67a5?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OGD2qcUPi5sijWs5vPgV~kummcLxVz6EiHNroISdRqzyOPP4wrqEEF2pJK4APlg09eMeD55iu2~YxMb09JgyFj1ApU3KRbGaBYx5rx6ifNo~Nopbg3Pnp05saQvtjOTI73CgtQQ9miaIO-7Y4r2P6kY43IoSjGXBO4ON6FOP2LM6thgsvII8typM9B0FmX8iXNN3ZCGYT8XOWhGOGGagRDrG1~o~6m4g1wLzqhWS3ZHsdUFGSjbJ3Cj2CJkIeJAYEOXSRdMv9H2hnS9iPy~4FLYnom8IYGO5J4-TR6AGmi3VGp~A1QK0SNPtWZu1i4cAubwm8DbtqpeUIWCH3cUKsg__"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </Grid>
  </Grid>
</Box>




 
   {/* --------Shoot Videos with Clarity---------- */}
        
   
   <Box sx={{ backgroundColor: '#1C1B1B', padding: '20px 0' }}>
  {/* Box with full-width background color */}
  <Grid
    container
    spacing={2}
    sx={{
      padding: { xs: 2, sm: 4 },  // Responsive padding
      maxWidth: '1200px',
      margin: '0 auto',
    }}
  >
    {/* Heading Section */}
    <Grid item xs={12}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        align="center"
        sx={{
          background: 'linear-gradient(to left, #FFE8CB, #FFCF92)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          display: 'inline-block',
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 500,
          fontSize: { xs: '28px', sm: '36px', md: '48px' },  // Responsive font size
          lineHeight: { xs: '35px', sm: '45px', md: '57.6px' },  // Responsive line height
          letterSpacing: '-4%',
          width: { xs: '90%', sm: '80%', md: '986px' },  // Responsive width
          height: { xs: 'auto', md: '58px' },  // Adjust height for responsiveness
          marginLeft: { xs: '0%', sm: '2%' },
          textAlign: 'center',  // Ensure text is centered on smaller screens
        }}
      >
        Shoot Videos with Impeccable Clarity
      </Typography>

      <Typography
        variant="h5"
        color="#F2F2F2"
        align="center"
        sx={{
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 500,
          fontSize: { xs: '16px', sm: '18px', md: '20px' },  // Responsive font size
          lineHeight: { xs: '20px', sm: '24px', md: '26px' },  // Responsive line height
          letterSpacing: '-1%',
          width: { xs: '100%', sm: '90%', md: '749px' },  // Responsive width
          height: { xs: 'auto', md: '26px' },
          marginLeft: { xs: '0%', sm: '5%', md: '13%' },  // Responsive margin
          textAlign: 'center',  // Center text on small screens
        }}
      >
        EIS Video Stabilisation (video recording at 2k@30fps) | Dual Video Recording
      </Typography>

      {"\u00A0"}

      <Typography
        variant="body1"
        color="#F2F2F2"
        align="center"
        sx={{
          fontFamily: 'Mark OT, sans-serif',
          fontWeight: 400,
          fontSize: { xs: '14px', sm: '15px', md: '16px' },  // Responsive font size
          lineHeight: { xs: '22px', sm: '23px', md: '25.6px' },  // Responsive line height
          width: { xs: '100%', sm: '90%', md: '742px' },  // Responsive width
          height: { xs: 'auto', md: '52px' },
          marginLeft: { xs: '0%', sm: '5%', md: '13%' },  // Responsive margin
          textAlign: 'center',  // Center text on small screens
        }}
      >
        Capture smooth, high-quality videos with EIS stabilization at 2K@30fps. The dual recording <br />feature lets you film from front and back camera - Get travel vlogs, parties etc.
      </Typography>
    </Grid>

    {/* Image Section */}
  </Grid>

  {/* New Section for Time Lapse */}
  <img
    style={{
      width: '100%',
      display: 'block',
      marginBottom: '-5%',
     
    }}
    src={shootimg}
    alt='charge'
  />
</Box>



        {/* Vision */}
        <Box >
        <img style= {{ width: "100%", display:"block"}} src={visionimg} alt='charge' />
        </Box>

        {/* ai */}
        <Box >
        <img style= {{ width: "100%", display:"block", marginBottom: "-9%"}} src={aiimg} alt='charge' />
        </Box>

        {/* processor */}
        <Box>
        <img style= {{ width: "100%", display: "block"}} src={processorimg} alt='charge' />
        </Box>


{/* -------------------starryBlack-------------------- */}
<Box sx={{ backgroundColor: '#1C1B1B', padding: { xs: '10px', sm: '20px' }, position: 'relative' }}>
  <Typography
    variant="h2"
    color="#FFFFFF"
    gutterBottom
    sx={{
      fontFamily: 'Mark OT, sans-serif',
      fontWeight: 500,
      fontSize: { xs: '32px', sm: '40px', md: '48px' }, // Responsive font size
      lineHeight: { xs: '38.4px', sm: '48px', md: '57.6px' }, // Responsive line height
      letterSpacing: '-4%',
      width: { xs: '100%', sm: '600px', md: '798px' }, // Responsive width
      textAlign: 'left',
      marginLeft: { xs: '2px', sm: '5px', md: '60px' },
      mt: { xs: "3%", sm: "5%" },
      mb: { xs: "3%", sm: "5%" },
    }}
  >
    Modern Twist<br /> on Timeless Elegance
  </Typography>

  <Grid container justifyContent="center" alignItems="center" sx={{ position: 'relative' }}>
    {/* Left Text Image (Starry) */}
    <Grid item xs={2} sm={2} md={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img
        src={starrytextimg}
        alt="Starry"
        style={{
          maxWidth: '100%',
              height: 'auto',
              width: { xs: '80%', sm: '100%' },
              mt: "2%",
             
        }}
      />
    </Grid>

    {/* Middle Phone Image with Black Text Image Overlay */}
    <Grid item xs={8} sm={8} md={8} sx={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <img
        src={starryblackimgg}
        alt="Phone"
        style={{
          maxWidth: '100%',
          height: 'auto',
          width: '100%', // Responsive width
        }}
      />

      {/* Overlayed Black Text Image */}
      <Box
        component="img"
        src={blacktextimg}
        alt="Black"
        sx={{
          position: 'absolute',
          bottom: { xs: '5%', sm: '3%' }, // Responsive position
          left: { xs: '20%', sm: '10%' }, // Responsive position
          width: { xs: '20%', sm: '10%', md: '12%' }, // Responsive width
          transform: 'translateX(-50%)',
        }}
      />
    </Grid>
  </Grid>
</Box>
<Box
      sx={{
        display: { xs: 'block', sm: 'none' }, // Show only on small screens
        width: '100%',
        mt: 2, // Adjust margin-top as needed
      }}
    >
      <img
        src={fullwidthimg}
        alt="Full Width"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    </Box>



          {/* TECHSPECS */}
      <Box sx={{pt: "6%" , pl: "4%", pr: "4%", pb: "6%", backgroundColor: "#1C1B1B"}}>
      
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={firstimg}
                alt="first"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={secondimg}
                    alt="Swift Power-Up"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={thirdimg}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <img
            src={fourthimg}
            alt="last"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "16px",
            }}
          />
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={5}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={fifthimg}
              alt="Dual DTS Speaker"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src={sixthimg}
              alt="Sleek Style"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      </Box>   

        {/* colorchange */}
        <Box >
          
          <Box >
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              initialColor="blue"
              isNextArrowBlack={false}
              isPrevArrowBlack={false}
              selectedBgColor="#1C1B1B"
              colortextcolor="#FFFFFF"
            />
          </Box>
        </Box>

      

      {/* Specifications */}
        <Specifications specs={specs} bgColor={"#1C1B1B"} />

        {/* Review */}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

        {/* Level Up Experience with Accessories */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

        {/* Compare Models */}
        <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        {/* Similar Products */}
        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />

        {/* Support Channels */}
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />

        {/* Check out Products */}
        <CheckOutProducts 
          bgColor={"#1C1B1B"} 
          heading="Check out our other Products"
        />
        
        {/* Follow us & Updated */}
        <FollowUs 
          mode="dark"
          isDarkMode={true}/>

        {/* FAQs */}
        <ProductFaqs

          mode="dark"  
          bgColor="#1C1B1B"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#1C1B1B"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default S24;