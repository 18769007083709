import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const RedIconAnimation = () => {
    const starRef = useRef(null);

    useEffect( () => {
        gsap.fromTo(
          starRef.current,
          {
            scale: 0.3, 
            opacity: 0.7,
            rotate:-80,
            delay:3,
            transformOrigin: "50% 50%",
          },
          {
            duration: 2.5,  
            opacity: 1,
            rotate:80,
            transformOrigin: "50% 50%",
            scale: 1, 
            ease: "power2.out", 
            repeat: -1, 
            yoyo: true, 
          }
        );
        }, []);

  return (
    <svg
            width="75"
            height="72"
            viewBox="0 0 75 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="star"
              ref={starRef} // Attach the ref to the star path
              d="M37.3278 13.5C37.3278 13.5 42.2795 24.1792 47.0298 28.6814C53.6868 34.9907 67.1555 36 67.1555 36C67.1555 36 53.6868 37.0093 47.0298 43.3186C42.2795 47.8208 37.3278 58.5 37.3278 58.5C37.3278 58.5 32.3761 47.8208 27.6257 43.3186C20.9687 37.0093 7.5 36 7.5 36C7.5 36 20.9687 34.9907 27.6257 28.6814C32.3761 24.1792 37.3278 13.5 37.3278 13.5Z"
              fill="#FF073C"
            />
            <g filter="url(#filter0_d_560_6929)">
              <path
                id="circle"
                d="M47.1 48.7988L47.1 48.7188C47.1 47.7581 47.74 47.2778 48.62 47.2778L48.78 47.2778C49.74 47.2778 50.46 46.5573 50.46 45.5966L50.46 45.4365C50.46 44.5559 50.94 43.9155 51.9 43.9155L51.98 43.9155C52.94 43.9155 53.5 43.3551 53.5 42.3944L53.5 29.4255C53.5 28.4648 52.94 27.9044 51.98 27.9044L51.9 27.9044C50.94 27.9044 50.46 27.264 50.46 26.3834L50.46 26.2233C50.46 25.2626 49.74 24.5421 48.78 24.5421L48.62 24.5421C47.74 24.5421 47.1 24.0618 47.1 23.1011L47.1 23.021C47.1 22.0604 46.54 21.5 45.58 21.5L29.42 21.5C28.46 21.5 27.9 22.0604 27.9 23.021L27.9 23.1011C27.9 24.0618 27.26 24.5421 26.38 24.5421L26.22 24.5421C25.26 24.5421 24.54 25.2626 24.54 26.2233L24.54 26.3834C24.54 27.264 24.06 27.9044 23.1 27.9044L23.02 27.9044C22.06 27.9044 21.5 28.4648 21.5 29.4255L21.5 42.3944C21.5 43.3551 22.06 43.9155 23.02 43.9155L23.1 43.9155C24.06 43.9155 24.54 44.5559 24.54 45.4365L24.54 45.5966C24.54 46.5573 25.26 47.2778 26.22 47.2778L26.38 47.2778C27.26 47.2778 27.9 47.7581 27.9 48.7188L27.9 48.7988C27.9 49.7595 28.46 50.3199 29.42 50.3199L45.58 50.3199C46.54 50.3199 47.1 49.7595 47.1 48.7988Z"
                stroke="#FF0037"
                strokeWidth="1.72097"
                shapeRendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_560_6929"
                x="0.639648"
                y="0.639648"
                width="73.7207"
                height="70.5405"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="10" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 0.027451 0 0 0 0 0.235294 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_560_6929"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_560_6929"
                  result="shape"
                />
              </filter>
            </defs>
        </svg>
  )
}

export default RedIconAnimation