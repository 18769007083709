import React, { useState } from "react";
import Slider from "react-slick";
import { Box ,useMediaQuery, useTheme } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import caro1 from "../../../images/P55/caruosel1Big.png";
import caro1Small from "../../../images/P55/carousel1small.png";
import caro2 from "../../../images/P55/carousel2big.png";
import caro2Small from "../../../images/P55/carouselsmall2.png";
import caro3 from "../../../images/P55/carousel3big.png";
import caro3Small from "../../../images/P55/carousel3small.png";
import CustomDots from "../../../components/customdots/CustomDots"; // Import your CustomDots component

const carouselItems = [
  {
    type: "image",
    src: caro1,
    smallSrc: caro1Small,
    alt: "Image 1",
  },
  {
    type: "image",
    src: caro2,
    smallSrc: caro2Small,
    alt: "Image 2",
  },
  // {
  //   type: "video",
  //   src: "https://s3-figma-videos-production-sig.figma.com/video/925644654045209335/TEAM/1096/3bb4/-81d5-4e4f-a710-b261c812401d?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aKQ3HVaybZI~hJIiwwhz4KEueXb9gPMW6v-CjcXRFXL4c1M46PJvuD0qWJlcCIwIqepl0TixKd6MVNpEIVxRYJG7WesmCD~PQAIMv2pKMUuQO9GudTjC8V5T74DYs7h1~HGHDXEBC57-2AY7rUerCFnigto1rVM5Dq-LuYVVfiyr~21CvcJRdyaiJxyABcGhZ2KL9yc~vEAhYjE33rZB1XeFAIWlIC3t~0160tD1uQv6dolxiw9HU8RacRHqwDQicBwbbaMZBvUKyA9HirIp4GANUMjuBYn5b7jGoV9H58qmFgxOnidRYXRu3UitZoKzPn-kKT2H7YstWt2LbbAasQ__",
  //   alt: "Video 1",
  // },
  {
    type: "image",
    src: caro3,
    smallSrc: caro3Small,
    alt: "Image 3",
  },
];

const CarouselPanorama = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const itemHeight = isSmallScreen ? "350px" : "650px";
  //const itemHeight = "650px"; // Set a fixed height for items

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable arrow icons
    beforeChange: (current, next) => setActiveIndex(next),
    appendDots: (dots) => (
      <CustomDots
        activeIndex={activeIndex}
        length={carouselItems.length}
        onClick={(index) => sliderRef.slickGoTo(index)}
      />
    ),
    customPaging: (i) => (
      <Box
        sx={{
          width: 4,
          height: 8,
          borderRadius: "50%",
          background: i === activeIndex ? "black" : "grey",
          display: "inline-block",
          margin: "0 4px",
        }}
      />
    ),
  };

  let sliderRef;

  return (
    <Box>
      <Box sx={{padding: isSmallScreen ? "6% 6% 6% 6%" : ""}} position="relative">
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
          {carouselItems.map((item, index) => (
            <Box key={index} height={itemHeight} position="relative">
              {item.type === "image" ? (
                <img
                  src={isSmallScreen ? item.smallSrc : item.src}
                  alt={item.alt}
                  style={{ width: "100%", height: "100%", objectFit: "cover" , borderRadius:"15px"}}
                />
              ) : (
                <video
                  autoPlay
                  muted
                  loop
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                >
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
          ))}
        </Slider>
        <Box
          sx={{
            position: "absolute",
            bottom: "10%",
            left: isSmallScreen ? "80%" : "90%",

            transform: "translateX(-50%)",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <CustomDots
            activeIndex={activeIndex}
            length={carouselItems.length}
            onClick={(index) => sliderRef.slickGoTo(index)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CarouselPanorama;