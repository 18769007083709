import React, { useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { gsap } from 'gsap';
import arrowImage from "../../images/servicecenter/arrowright.png";
import svgarrow from "../../images/productlisting/svgarrow.svg";

const IconAnimation = ({ text,  fontFamily, color }) => {
  const animatedArrowRef = useRef(null);

  useEffect(() => {
    const button = animatedArrowRef.current.closest('button');

    const animateArrow = () => {
      const timeline = gsap.timeline();

      timeline
        .fromTo(
          animatedArrowRef.current,
          { x: -100, opacity: 1 }, // Start far left off-screen
          {
            x: 100, // Move slightly beyond the existing arrow's position
            opacity: 1,
            duration: 0.3, // Fast duration
            ease: 'power2.out',
          }
        )
        .to(
          animatedArrowRef.current,
          {
            x: 101, // Rewind to the existing arrow's position
            duration: 0.2, // Quick rewind
            ease: 'power2.inOut',
          }
        );
    };

    button.addEventListener('mouseenter', animateArrow);

    button.addEventListener('mouseleave', () => {
      gsap.to(animatedArrowRef.current, { x: -100, opacity: 0, duration: 0.3, ease: 'power2.inOut' });
    });

    return () => {
      button.removeEventListener('mouseenter', animateArrow);
      button.removeEventListener('mouseleave', () => {});
    };
  }, []);

  return (
    <Button
      sx={{
        fontFamily: fontFamily,  // Correct usage of the fontFamily prop
        color: color,
        fontSize: { xs: '14px', md: '16px' },
        p: 0,
        position: 'relative',
        overflow: 'hidden',
        paddingLeft: '2%', // Additional style as requested
        marginTop: '2%'    // Additional style as requested
      }}
    >
      {text}
      <img
        src={arrowImage}
        alt="Static Arrow Icon"
        style={{ marginLeft: "8px", height: "24px", position: 'relative', zIndex: 1 }}
      />
      <img
        ref={animatedArrowRef}
        src={svgarrow} // Use the svgarrow prop here
        alt="Animated Arrow"
        style={{
          marginLeft: "8px",
          height: "20px", // Smaller height for the animated arrow
          width: "20px",  // Smaller width for the animated arrow
          position: 'absolute',
          top: '50%',
          left: '8px', 
          transform: 'translateY(-50%)',
          zIndex: 0, // Ensure this is behind the static arrow
          opacity: 0, // Start hidden until animation
        }}
      />
    </Button>
  );
};

export default IconAnimation;
