import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Grid, Button, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import followus1 from "../../images/productlanding/followus1.png";
import followus2 from "../../images/productlanding/followus2.png";
import followus3 from "../../images/productlanding/followus3.png";
import followus4 from "../../images/productlanding/followus4.png";
import CustomDots from "../customdots/CustomDots";
import arrow from "../../images/productlanding/arrow.png";
import { FollowInstaBtn } from "../Buttons/FollowInstaBtn";


function FollowUs({ mode = "light", bgcolor }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeIndex, setActiveIndex] = useState(0);

  const isDarkMode = mode === "dark";
  const headingColor = isDarkMode ? "white" : "black";
  const buttonBorderColor = isDarkMode ? "gray" : "black";
  const buttonbgcolor = isDarkMode ? "#1A1A1A" : "black";

  const ImageContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(0.5),
  }));
  
  const items = [
    { src: followus1, alt: "Image 1" },
    { src: followus2, alt: "Image 2" },
    { src: followus3, alt: "Image 3" },
    { src: followus4, alt: "Image 4" },
  ];

  const Image = styled("img")({
    width: "100%",
    height: "auto",
    borderRadius: isSmallScreen ? "25px" : "",
  });

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      mt={4}
      sx={{ bgcolor: bgcolor }}
    >
      {/* Heading and Button on the same row in larger screens */}
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={isSmallScreen ? "left" : "space-between"}
        alignItems="center"
        mr={"4%"}
        ml={"4%"}
      >
        <Typography variant="h5" component="h2" sx={{ color: headingColor , fontFamily:"MarkOT-Medium"}}>
          Follow us and stay updated
        </Typography>
        {/* Instagram Button */}
        {!isSmallScreen && (
          // <Button
          //   variant="outlined"
          //   sx={{
          //     color: "white",
          //     borderRadius: "16px",
          //     fontFamily:"MarkOT-Bold",
          //     borderColor: buttonBorderColor,
          //     bgcolor: buttonbgcolor,
          //     ml: "auto",
          //     "&:hover": {
          //       backgroundColor: "#ffffff",
          //       borderColor: buttonBorderColor,
          //       color:"#181818",
          //     },
          //   }}
          // >
          //   Follow us on Instagram{" "}
          //   <img src={arrow} alt= "arrow" style={{ marginLeft: "10px" }} />
          // </Button>
          <FollowInstaBtn
          buttonBg = "#1A1A1A"
          hoverButtonBg = "#ffffff"
          hoverTextColor = "#000000"
            />
        )}
      </Grid>

      {/* Instagram Button in a separate row on smaller screens */}
      {isSmallScreen && (
        <Grid
          item
          xs={12}
          display="flex"
          mt={2}
          alignItems="center"
          mr={"4%"}
          ml={"4%"}
        >
           <FollowInstaBtn
          buttonBg = "#1A1A1A"
          hoverButtonBg = "#ffffff"
          hoverTextColor = "#000000"
            />
        </Grid>
      )}

      {/* Carousel or Grid of images */}
      <Grid item xs={12} ml={"4%"} mr={"4%"}>
        {isSmallScreen ? (
          <Carousel
            navButtonsAlwaysInvisible
            indicators={false}
            animation="slide"
            autoPlay={false}
            index={activeIndex}
            onChange={(index) => setActiveIndex(index)}
            indicatorContainerProps={{
              style: {
                marginTop: "20px", // Adjust the margin top as per your need
                textAlign: "center",
              },
            }}
            indicatorIconButtonProps={{
              style: {
                color: "white", // Adjust the color of the indicators (dots)
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: "white", // Color of the active indicator (dot)
              },
            }}
          >
            {items.map((item, index) => (
              <ImageContainer key={index}>
                <Image src={item.src} alt={item.alt} />
              </ImageContainer>
            ))}
          </Carousel>
        ) : (
          <Grid container spacing={2}>
            {items.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <ImageContainer>
                  <Image src={item.src} alt={item.alt} />
                </ImageContainer>
              </Grid>
            ))}
          </Grid>
        )}

        {/* CustomDots for Carousel on small screens */}
        {isSmallScreen && (
          <CustomDots
            activeIndex={activeIndex}
            length={items.length}
            onClick={(index) => setActiveIndex(index)}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default FollowUs;
