import React ,{useState} from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  Hidden,
  Button,
} from "@mui/material";
import arrow from "../../../images/productlisting/arrowicon.png";
import close from "../../../images/productlisting/close-square.png";

const Filters = ({ filters, setFilters }) => {
  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.checked,
    });
  };

  const checkboxStyles = {
    "& .MuiSvgIcon-root": {
      color: "gray",
    },
    "&.Mui-checked .MuiSvgIcon-root path": {
      fill: "red",
      color: "#fff",
    },
  };

  const labelStyles = {
    "& .MuiFormControlLabel-label": {
      color: "#949494",
    },
    "& .MuiCheckbox-root.Mui-checked + .MuiFormControlLabel-label": {
      color: "white",
    },
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const handleCloseFilterModal = () => {
    console.log('Close button clicked');
    setIsFilterModalOpen(false);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <Box sx={{ color: "white", pl: isSmallScreen ? "1%" : "5%" }}>
      <FormControl component="fieldset" sx={{ color: "white" }}>
      <Hidden smUp>
        <Box sx={{display:"flex",
            justifyContent:"space-between"
          }} >
            <Typography variant="h6" sx={{ color: "#ffffff", pb:"7%",  }}>
              Filter
            </Typography>
            <img src={close} alt="close button" onClick={handleCloseFilterModal} style={{height:"1rem", marginTop:"4%",}}/>
          </Box>
          </Hidden>
        <Typography sx={{ color: "white", fontSize: isSmallScreen ? "13px" : "16px", pb:isSmallScreen && "5%" }}>PRICE RANGE</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.allPrice}
                onChange={handleChange}
                name="allPrice"
                sx={checkboxStyles}
              />
            }
            label="All Price"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.price1000}
                onChange={handleChange}
                name="price1000"
                sx={checkboxStyles}
              />
            }
            label="Under ₹1000"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.price1000to3000}
                onChange={handleChange}
                name="price1000to3000"
                sx={checkboxStyles}
              />
            }
            label="₹1000 to ₹3000"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.price3000to5000}
                onChange={handleChange}
                name="price3000to5000"
                sx={checkboxStyles}
              />
            }
            label="₹3000 to ₹5000"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.price5000to7500}
                onChange={handleChange}
                name="price5000to7500"
                sx={checkboxStyles}
              />
            }
            label="₹5000 to ₹7500"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.priceUpto7500}
                onChange={handleChange}
                name="priceUpto7500"
                sx={checkboxStyles}
              />
            }
            label="Upto ₹7500"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
        </FormGroup>
      </FormControl>

      <Divider sx={{ backgroundColor: "#3F3F3F", my: 2 }} />

      <FormControl component="fieldset" sx={{ color: "white", mt: 2 }}>
        <Typography sx={{ color: "white" ,fontSize: isSmallScreen ? "12px" : "14px", }}>PRIMARY CAMERA (FRONT)</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.camera8}
                onChange={handleChange}
                name="camera8"
                sx={checkboxStyles}
              />
            }
            label="8 - 11.9 MP"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.camera12}
                onChange={handleChange}
                name="camera12"
                sx={checkboxStyles}
              />
            }
            label="12 - 15.9 MP"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.camera16}
                onChange={handleChange}
                name="camera16"
                sx={checkboxStyles}
              />
            }
            label="16 - 47.9 MP"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.camera48}
                onChange={handleChange}
                name="camera48"
                sx={checkboxStyles}
              />
            }
            label="48 - 63.9 MP"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.camera64}
                onChange={handleChange}
                name="camera64"
                sx={checkboxStyles}
              />
            }
            label="64 MP & Above"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
        </FormGroup>
      </FormControl>

      <Divider sx={{ backgroundColor: "#3F3F3F", my: 2 }} />

      <FormControl component="fieldset" sx={{ color: "white", mt: 2 }}>
        <Typography sx={{ color: "white",fontSize: isSmallScreen ? "13px" : "16px",  }}>SCREEN SIZE</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.screenSize4_5}
                onChange={handleChange}
                name="screenSize4_5"
                sx={checkboxStyles}
              />
            }
            label="4.5 in"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.screenSize5}
                onChange={handleChange}
                name="screenSize5"
                sx={checkboxStyles}
              />
            }
            label="5 in"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.screenSize5_5}
                onChange={handleChange}
                name="screenSize5_5"
                sx={checkboxStyles}
              />
            }
            label="5.5 in"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.screenSize5_7}
                onChange={handleChange}
                name="screenSize5_7"
                sx={checkboxStyles}
              />
            }
            label="5.7 in & above"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.screenSize6}
                onChange={handleChange}
                name="screenSize6"
                sx={checkboxStyles}
              />
            }
            label="6 in & above"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
        </FormGroup>
      </FormControl>

      <Divider sx={{ backgroundColor: "#3F3F3F", my: 2 }} />

      <FormControl component="fieldset" sx={{ color: "white", mt: 2 }}>
        <Typography sx={{ color: "white",fontSize: isSmallScreen ? "13px" : "16px",  }}>STORAGE</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.storage32}
                onChange={handleChange}
                name="storage32"
                sx={checkboxStyles}
              />
            }
            label="32 GB"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.storage64}
                onChange={handleChange}
                name="storage64"
                sx={checkboxStyles}
              />
            }
            label="64 GB"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.storage128}
                onChange={handleChange}
                name="storage128"
                sx={checkboxStyles}
              />
            }
            label="128 GB"
            sx={{...labelStyles,
              "& .MuiFormControlLabel-label": {
                color:"#949494",
                fontFamily:"MarkOT!important",
                fontSize:isSmallScreen ? "0.9rem!important" : "1rem",
              }
            }}
          />
        </FormGroup>
      </FormControl>
      {isSmallScreen && (
        <Button
          variant="contained"
          sx={{
            mt: 2,
            mb: 2,
            backgroundColor: "black",
            color: "white",
            borderRadius: "24px",
            border:"0.75px solid #949494",
            fontFamily:"10px",
            padding: "6px 24px !important"
          }}
        >
          Show Result <img src={arrow} alt="arrow"/>
        </Button>
      )}
    </Box>
  );
};

export default Filters;
