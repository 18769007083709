import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Modal,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import arrow from "../../../images/productlisting/arrowicon.png";

import mobile1 from "../../../images/productlisting/itel1.png";
import closeIconImage from "../../../images/productlisting/Cross.png";

const CompareModal = ({ open, handleClose, productOptions = [] }) => {
  const [selectedProducts, setSelectedProducts] = useState([
    productOptions[0] || null,
    productOptions[0] || null,
  ]);

  useEffect(() => {
    if (productOptions.length > 0) {
      setSelectedProducts([productOptions[0], productOptions[0]]);
    }
  }, [productOptions]);

  const handleSelectChange = (index, event) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts[index] = event.target.value;
    setSelectedProducts(newSelectedProducts);
  };

  const renderProductDetails = (product) => {
    if (!product) return null;
    return (
      <Box
        sx={{
          borderRadius: "10px",
          p: 2,
          textAlign: "left", // Align text to the left
          bgcolor: "#F8F6F3",
        }}
      >
        <img
          src={product.image || mobile1}
          alt="product"
          style={{ width: "100%", marginBottom: "16px" }}
        />
        <Typography variant="h6" sx={{ fontSize: "20px", textAlign: "left" }}>
          {product.name}
        </Typography>
        <Typography
          variant="h6"
          color="error"
          sx={{ mb: 2, fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}
        >
          ₹{product.price.toFixed(2)}
        </Typography>
        <Typography sx={{ fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}>
          Camera: {product.camera}
        </Typography>
        <Typography sx={{ fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}>
          Memory: {product.memory}
        </Typography>
        <Typography sx={{ fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}>
          Dimensions: {product.dimensions}
        </Typography>
        <Typography sx={{ fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}>
          Processor: {product.processor}
        </Typography>
        <Typography sx={{ fontSize: {xs: "10px",md:"15px"}, textAlign: "left" }}>
          Battery: {product.battery}mAh
        </Typography>
        <Button
          variant="outlined"
          sx={{ mt: 2, borderRadius: "24px", fontSize: {xs: "12px",md:"17px"} }}
          fullWidth
        >
          BUY NOW <img src={arrow} alt="arrow" style={{paddingLeft:"2%"}}/>
        </Button>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          height: "96vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <style>
          {`
            ::-webkit-scrollbar {
              width: 4px;
            }
            ::-webkit-scrollbar-track {
              background: #f1f1f1; 
            }
            ::-webkit-scrollbar-thumb {
              background: #D9D9D9; 
              border-radius: 6px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }
          `}
        </style>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ textAlign: "left" }}>
            Compare
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <img
              src={closeIconImage}
              alt="close"
              style={{ width: "24px", height: "24px" }}
            />
          </IconButton>
        </Box>
        <Typography variant="subtitle1" sx={{ mb: 2, textAlign: "left" }}>
          CHOOSE THE RIGHT MODEL
        </Typography>
        <Box
          sx={{
            overflowY: "auto",
            height: "calc(100% - 64px)",
            pr: 2,
          }}
        >
          <Grid container spacing={2} sx={{ mb: 4 }}>
            {selectedProducts.map((selectedProduct, index) => (
              <Grid item xs={6} key={index}>
                <Select
                  fullWidth
                  value={selectedProduct || ""}
                  onChange={(event) => handleSelectChange(index, event)}
                  displayEmpty
                  sx={{ mb: 2, textAlign: "left" }}
                >
                  {productOptions.map((option, idx) => (
                    <MenuItem value={option} key={idx}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} pb={2}>
            {selectedProducts.map((product, index) => (
              <Grid item sm={6} xs={6} key={index}>
                {renderProductDetails(product)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompareModal;
