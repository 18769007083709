import React from 'react';
import { Box, useMediaQuery, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import heart from "../../images/Csr/heart.png"
import downarrow from "../../images/Investor/downarrow.png";
import mission1 from "../../images/Csr/mission1.png";
import mission2 from "../../images/Csr/mission2.png";
import landing1 from "../../images/Investor/landing1.png";
import landing2 from "../../images/Investor/landing2.png";
import { useTheme } from '@mui/material/styles';

function CSR() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const mission = isSmallScreen ? mission2 : mission1;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      {/* Landing Image */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: '80vh', md: '80vh' },
          backgroundImage: isSmallScreen ? `url(${landing1})` : `url(${landing2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "20px", sm: "40px", md: "60px", lg: "80px" },
          color: "white",
          textAlign: "center",
        }}
      >
        <img src={heart} alt="Logo" style={{ paddingBottom: "4%" }} />
        <Typography
          variant="h1"
          sx={{
            width: { xs: "80%", sm: "70%", md: "60%" },
            fontSize: { xs: "36px", sm: "2rem", md: "3rem", lg: "80px" },
            fontFamily: 'MarkOT-Medium',
            paddingBottom: "4%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Corporate Social Responsibility
        </Typography>
        {isDesktop && (
          <Typography
            variant="bodyLarge"
            sx={{
              width: { xs: "80%", sm: "70%", md: "60%" },
              fontFamily: 'MarkOT',
              fontSize: { xs: "0.75rem", sm: "1rem", md: "1.25rem", lg: "1.3rem" },

              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
              paddingLeft: "5%",
              paddingRight: "5%"
            }}
          >
            Pathway to a Better India-CSR Endeavor
          </Typography>
        )}

      </Box>
      <Box marginX={{ md: "14%", xs: "1%" }}>
        {/* Title and Subtitle */}
        <Box marginX={{ md: "1%", xs: "5%" }} marginTop={"5%"}>
          <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { md: "48px", xs: "32px" }, fontWeight: "500", lineHeight: { xs: "38.4px", md: "57.6px" } }}>
            Corporate Social Responsibility Policy
          </Typography>
          <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { md: "24px", xs: "16px" }, fontWeight: "500" }}>
            S Mobile Devices Private Limited (Formerly S Mobile Devices Ltd)
          </Typography>
        </Box>

        {/* Accordions    start*/}
        <Box marginTop={"5%"}>
          {/* Preamble Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography variant="h4" sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500", }}>
                Preamble
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>
                The aim of the Corporate Social Responsibility Policy (the “CSR Policy”) is to ensure that the Company becomes a socially responsible entity contributing towards improving the quality of life of society at large. As a Corporate Citizen receiving various benefits out of society, it is our co-extensive responsibility towards the society to return the same by adopting best practices. It is the Company’s intent to make a positive contribution to the society in which the Company operates. The objective of this Policy is to set guiding principles for carrying out CSR activities by the Company and also to set up process of execution, implementation and monitoring of the CSR activities to be undertaken by the Company.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {/* Scope Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography variant="h4" sx={{ fontFamily: 'MarkOT-Medium',fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Scope
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ fontFamily: 'MarkOT' }}>
              <Typography sx={{ marginBottom: "2%", lineHeight: "25.6px", fontFamily: "MarkOT" }}>
                This Policy:
              </Typography>
              <ol sx={{ fontFamily: 'MarkOT' }}>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>
                    shall be called the “Corporate Social Responsibility Policy” or “CSR Policy” of the Company which is developed and implemented in accordance with the provisions of Section 135 of the Act read with the CSR Rules.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>
                    outlines the Company’s philosophy and responsibility and lays down the guidelines and mechanism for undertaking socially impactful programs towards welfare and sustainable development of the community around the area of its operations and other parts of the country
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>
                    shall be applicable to all CSR Activities taken up by the Company at various locations in India for the benefit of different segments of the society at large, specifically the deprived and underprivileged segment.
                  </Typography>
                </li>
              </ol>
            </AccordionDetails>
          </Accordion>
          {/* Mission Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography variant="h4" sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Mission & Purpose
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>
                To work on the popularity, adoption, and implementation of the concept of Corporate Social Responsibility while adding measurable values to”” the community and to our corporation along with managing related processes to the advantage of all concerned in a way that becomes model for other corporations for replication, to further expanding the scope.
              </Typography>
              <Box sx={{ marginTop: { md: "4%", xs: "5%" } }}>
                <img src={mission} alt='mission' style={{ width: "100%", height: "auto" }} />
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Objectives Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography variant="h4" sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Objectives of the CSR Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>
                The CSR Policy is laid down by the Company to ensure that the:

              </Typography>
              <ul>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>CSR agenda is integrated with the business</Typography>
                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Focussed efforts are made in the identified community development areas to achieve the expected outcomes</Typography>

                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Support in nation building and bringing inclusive growth through our CSR programs</Typography>


                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          {/* Committee Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Composition of Committee
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>
                The Board of Directors of the Company shall constitute / re-constitute the CSR Committee from time to time in compliance with statutory requirements. The CSR Committee shall comprise of a minimum of two directors.
                The composition and terms of reference of the Committee is available on Company’s website i.e. www.itel-india.com. The CSR Committee may invite members of senior management and other persons as it deems necessary to the committee meetings.
              </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: "24px", fontWeight: "500", lineHeight: "31.2px" }}>Committee Members </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', marginTop: "1%", fontSize: "20px", fontWeight: "500", lineHeight: "26px" }}>Mrs. Rekha Diwan </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Chairperson </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', marginTop: "1%", fontSize: "20px", fontWeight: "500", lineHeight: "26px" }}>Mr.Ravi Garg </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Committee Member </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', marginTop: "1%", fontSize: "20px", fontWeight: "500", lineHeight: "26px" }}>Mr.Vikas Kumar </Typography>
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Committee Member </Typography>


            </AccordionDetails>
          </Accordion>
          {/* Activities Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                CSR Activities
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The Company will primarily focus on following welfare activities: </Typography>
              <ol>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Eradicating hunger, poverty and malnutrition, ‘promoting health care including preventive health care and sanitation including contribution to the Swach Bharat Kosh set-up by the Central Government for the promotion of sanitation and making available safe drinking water. </Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Promoting education, including special education and employment enhancing vocation skills especially among children, women, elderly, and the differently abled and livelihood enhancement projects.</Typography> </li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Promoting gender equality, empowering women, setting up homes and hostels for women and orphans; setting up old age homes, day care centres and such other facilities for senior citizens and measures for reducing inequalities faced by socially and economically backward groups.</Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Contribution to the Prime Minister’s National Relief Fund or any other fund set up by the Central Government for socio-economic development and relief and welfare of the Scheduled Castes, the Scheduled Tribes, other backward classes, minorities and women.</Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Rural development projects. </Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Slum area development.</Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Any other activities may be added as prescribed under the Companies Act, 2013 and Rules made thereunder, and approved from time to time. </Typography></li>

              </ol>
            </AccordionDetails>
          </Accordion>
          {/* Functions Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Functions of CSR Committee
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Typography sx={{ fontSize: "16px", lineHeight: "25.6px", fontFamily: 'MarkOT' }}>1. Formulate and recommend a Corporate Social Responsibility Policy (“CSR Policy”) of the Company, for approval by the Board of Directors. </Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "25.6px", fontFamily: 'MarkOT' }}>2. Approve and undertake projects that are in line with the Schedule VII to the Act and CSR policy of the Company.</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "25.6px", fontFamily: 'MarkOT' }}>3. Constitution of transparent monitoring mechanisms in order to ensure the timely implementation and progress of each project/ program and activity.</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "25.6px", fontFamily: 'MarkOT' }}>4. Recommend the amount of CSR expenditure for the determined CSR activities to the Board.</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "25.6px", fontFamily: 'MarkOT' }}>5. Any other matter as deemed appropriate or determined by the Board from time to time. </Typography>



            </AccordionDetails>
          </Accordion>
          {/* Execution Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                Execution of CSR Activities
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The Company can form collaborative partnerships with the Government, the District Authorities, the village panchayats, NGOs in carrying out its CSR Programs. The Company may undertake CSR Programs through a registered trust or a registered society or a company established under Section 8 of the Act by the Company, either singly or along with its holding or subsidiary or associate company, or along with any other company or holding or subsidiary or associate company of such other company or otherwise.
                The Company may also collaborate with a trust, society or company, other than those mentioned above for undertaking its CSR projects or programs, provided that the trust, society or company has an established track record of three years in undertaking similar programs or projects.</Typography>




            </AccordionDetails>
          </Accordion>
          {/* Budget Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                CSR Budget
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The Board shall approve overall limits for CSR Activities to be undertaken by the Company. Within the overall limits approved by the Board, the CSR Committee shall approve individual projects / programs.</Typography>

              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The CSR expenditure shall be as prescribed under Section 135 of the Act and Companies (Corporate Social Responsibility) Rules, 2014. CSR expenditure will include both direct and indirect expenditures, incurred by the Company on CSR Programmes undertaken in accordance with the approved CSR Policy. Any surplus arising from any CSR projects, programmes or activities shall not form part of the business profit of a company and shall be utilized towards the CSR activities. All reasonable efforts shall be made to ensure that the annual CSR allocation is fully utilized in the respective year. However, if the Company fails to spend such amount, the Board shall, its report under Section 134 of the Companies Act, 2013, specify the reason for not spending the amount.</Typography>


            </AccordionDetails>
          </Accordion>
          {/* Monitoring Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px", fontWeight: "500" } }}>
                Monitoring and Reporting Mechanism
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The CSR Committee will evaluate the viability of the proposals/ programs for CSR initiatives and report the same to the board. Money expended towards CSR shall be released only against verification of utilization of funds as per approved work-plans and timelines. Verification may be done through review of documents, field visits and through an active feedback mechanism.</Typography>

              <Typography sx={{ marginBottom: "2%", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The CSR Committee will regularly review and submit periodic reports to the Board indicating the following:</Typography>
              <ul>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Details of the CSR projects/ programmes /activities undertaken during the period;</Typography>
                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Impact assessment with key indicators in our areas of operations</Typography>

                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Details of CSR outlay, stating the budgeted and actual amount spent during the period, and reasons of variance, if any;</Typography>


                </li>
                <li>
                  <Typography sx={{ fontFamily: 'MarkOT' }}>Achievements or impacts or improvements recorded in the society due to the CSR initiatives of the Company during the period;</Typography>

                </li>

              </ul>
              <Typography sx={{ fontFamily: 'MarkOT' }}>Based on the periodic reports, the Board shall recommend and ensure that the projects/ programmes and activities included in the CSR Policy of the Company are undertaken accordingly.</Typography>

            </AccordionDetails>
          </Accordion>
          {/* Location Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px", fontWeight: "500" } }}>
                Location
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>All CSR projects / programs under this policy shall be undertaken in India. The CSR Committee shall decide on locations for implementation of the CSR projects / programs and shall give preference to the area where the Company has its operations.</Typography>


            </AccordionDetails>
          </Accordion>
          {/* Program Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "24px", sm: "36px" }, fontWeight: "500" }}>
                Program Duration
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The time period of implementation of the programme will depend on its nature, extent of coverage and the impact of the programme. The Company will follow a project based accountability approach to stress on the long term sustainability of its CSR projects.
                Based on the tenure of the identified projects, they will be qualified as:
              </Typography>
              <ul>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Short Term – up to 1 year</Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Long term</Typography></li>

              </ul>

            </AccordionDetails>
          </Accordion>
          {/*meeting Accordion */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px" }, fontWeight: "500" }}>
                CSR Committee Meetings
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
              padding: 0,  // Remove padding
              margin: 0,   // Remove margin
              paddingLeft: "0px !important", // Ensure no padding-left
              fontWeight: "400",
              lineHeight: "25.6px"
            }}>

              <ol>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Frequency of Meetings – the Committee will meet as and when require.</Typography></li>

                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Quorum – A minimum of two Members shall form the Quorum.</Typography></li>
                <li><Typography sx={{ fontFamily: 'MarkOT' }}>Minutes – Minutes of the proceedings and resolutions of the CSR Committee meetings shall be signed and confirmed by the Chairman of the meeting. Minutes so signed and confirmed shall be conclusive evidence of such proceedings and resolutions. Minutes of all Committee meetings will be open for inspection at any reasonable time on reasonable notice by any member of the Board of Directors of the Company.</Typography></li>
              </ol>

            </AccordionDetails>
          </Accordion>
          {/* amendments accordian */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px", fontWeight: "500px" }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px", fontWeight: "500" } }}>
                Amendments to CSR Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>CSR policy may be amended or revised from time to time after approval of Board of Directors upon recommendations of the CSR Committee.
              </Typography>

            </AccordionDetails>
          </Accordion>
          {/* disclousure accordian */}
          <Accordion defaultExpanded sx={{ boxShadow: "none", border: "none", "&:before": { display: "none" } }}>
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" style={{ width: "42px", height: "42px", }} />}
            >
              <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { xs: "28px", md: "36px", fontWeight: "500" } }}>
                Disclosures
              </Typography>
            </AccordionSummary>
            <AccordionDetails>

              <Typography sx={{ marginBottom: "2%", fontSize: "16px", fontFamily: 'MarkOT', lineHeight: "25.6px" }}>The company shall place the contents of Corporate Social Responsibility Policy on the website of the company.
                Annual Report on CSR Activities shall be prepared and be attached with Company’s Board Report as prescribed under the Companies Act, 2013.
              </Typography>

            </AccordionDetails>
          </Accordion>
        </Box>
        {/* accordian end */}
        <Box marginBottom={"20%"} marginX={{ md: "1%", xs: "5%" }}>
          <Typography sx={{fontFamily: 'MarkOT-Medium', fontSize: { md: "48px", xs: "32px" }, marginBottom: "2%", lineHeight: "57.6px" }}>CSR Projects and Activities</Typography>
          <Typography sx={{ marginBottom: "2%", fontSize: { md: "16px" }, lineHeight: "25.6px", fontFamily: 'MarkOT' }}>The Company shall undertake such projects, programs and activities which falls within the purview of schedule VII of the Act and as may be amended by the ministry from time to time.</Typography>
          <Typography sx={{ marginBottom: "2%", fontSize: { md: "16px" }, lineHeight: "25.6px", fontFamily: 'MarkOT' }}>CSR activities shall exclude the activities undertaken in pursuance of its normal course of business. The CSR projects or programs or activities that benefit only the employees of the company and their families shall not be considered as CSR activities. Contribution of any amount directly or indirectly to any political party under section 182 of the Act, shall not be considered as CSR activity.</Typography>

        </Box>
      </Box>
    </Box>
  );
}

export default CSR;