import React from "react";
import { styled } from "@mui/system";

const CustomDotsContainer = styled("div")({
  marginTop: "2%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const DotBox = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  height: "1rem",
  marginTop: "0.5rem",
});

const Dot = styled("div")(({ theme, selected }) => ({
  backgroundColor: selected ? "#ffffff" : "#777676",
  height: "4px",
  width: selected ? "35px" : "10px",
  borderRadius: "2.51px",
  marginRight: "0.25rem",
  transition: "all 0.3s ease-in-out",
}));

const CustomDots = ({ activeIndex, length, onClick }) => {
  return (
    <CustomDotsContainer>
      <DotBox>
        {Array.from({ length }).map((_, index) => (
          <Dot
            key={index}
            selected={index === activeIndex}
            onClick={() => onClick(index)}
          />
        ))}
      </DotBox>
    </CustomDotsContainer>
  );
};

export default CustomDots;