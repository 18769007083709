import React from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import logo from "../../images/NavFooter/logo.png";
import linkedin from "../../images/NavFooter/linkedin.png";
import instagram from "../../images/NavFooter/Instagram.png";
import facebook from "../../images/NavFooter/facebook.png";
import sms from "../../images/NavFooter/sms.png";
import call from "../../images/NavFooter/call.png";
import ok from "../../images/NavFooter/Ok.png";
import { Link } from "react-router-dom";

function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <footer>
      <Grid
        container
        spacing={{ xs: 0, md: 10 }}
        sx={{
          justifyContent: "space-between",
          backgroundColor: "#1C1B1B",
          paddingTop: "0%",
          paddingLeft: "4%",
          paddingRight: 0,
        }}
      >
        {/* First Column */}
        <Grid item xs={12} lg={3} sx={{ mt: { xs: "20px", md: "0px" } }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "row", lg: "column" },
                alignItems: "start", // Ensures logo and icons align vertically
                justifyContent: "space-between", // Adds space between the logo and the icons
              }}
            >
              {/* Logo */}
              <img
                src={logo}
                alt="Logo1"
                style={{ height: "40px", width: "70px" }}
              />

              {/* Social Media Icons */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start", // Aligns icons vertically
                  mt: { xs: "0px", lg: "10px" },
                }}
              >
                <img
                  src={linkedin}
                  alt="linkedin"
                  style={{ height: "40px", width: "40px" }}
                />
                <img
                  src={instagram}
                  alt="instagram"
                  style={{
                    height: "40px",
                    width: "40px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <img
                  src={facebook}
                  alt="facebook"
                  style={{ height: "40px", width: "40px" }}
                />
              </Box>
            </Box>

            <Typography
              variant="body1"
              sx={{
                marginBottom: "10px",
                color: "#A3A89F",
                mt: 3,
                fontFamily: "MarkOT-Medium",
                fontSize: "14px",
              }}
            >
              Subscribe
            </Typography>
            <TextField
              label="Enter your email address"
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "#A3A89F" } }} // Label color
              InputProps={{
                endAdornment: (
                  <img
                    src={ok}
                    alt="icon"
                    style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  />
                ),
                style: { color: "white" }, // Text color inside input
              }}
              sx={{
                marginBottom: "10px",
                borderRadius: "10px",
                mt: { xs: 0, sm: 3 },
                color: "white",
                width: { xs: "80%", lg: "100%" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#4B4B4B", // Set the border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#4B4B4B", // On hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#4B4B4B", // On focus border color
                  },
                },
              }}
            />

            <Typography
              variant="caption"
              sx={{ mt: 2, color: "white", fontFamily: "MarkOT", mb: "4%" }}
            >
              Join our newsletter to stay up to date on <br /> features and
              releases
            </Typography>
          </Box>
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} lg={9} container spacing={isSmallScreen ? 0 : 12}>
          {/* Nested Grid for Product, Support, Media, Contact, and About Itel */}
          {[...Array(5).keys()].map((index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {index === 0 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#A3A89F",
                      marginBottom: "20px",
                      fontFamily: "MarkOT-Medium",
                      fontSize: "14px",
                    }}
                  >
                    Products
                  </Typography>
                )}
                {index === 0 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Smart phones
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 0 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Feature phones
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 0 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Smart TV
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 0 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Smart Gadgets
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#A3A89F",
                      marginBottom: "20px",
                      fontFamily: "MarkOT-Medium",
                      fontSize: "14px",
                    }}
                  >
                    Support
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Exchange offer
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    component={Link}
                    to="/otsr"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Screen replacement
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    variant="caption"
                    component={Link}
                    to="/servicecenter"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Service center
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    component={Link}
                    to="/100dayspromise"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    100 days promise
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 1 && (
                  <Typography
                    component={Link}
                    to="/faq"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    FAQs
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}{" "}
                {index === 1 && (
                  <Typography
                    component={Link}
                    to="/ewastemanagement"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    E-Waste Management
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#A3A89F",
                      marginBottom: "20px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                      fontSize: "14px",
                    }}
                  >
                    Media
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    component={Link}
                    to="/media"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    News
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    component={Link}
                    to="/blog"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "40px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Blogs
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#A3A89F",
                      marginBottom: "20px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                      fontSize: "14px",
                    }}
                  >
                    About itel
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    component={Link}
                    to="/aboutus"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    About us
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 2 && (
                  <Typography
                    component={Link}
                    to="/investorrelations"
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                    }}
                  >
                    Investor quick links
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 3 && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#A3A89F",
                      marginBottom: "20px",
                      fontFamily: "MarkOT-Medium",
                      fontSize: "14px",
                    }}
                  >
                    Contact
                  </Typography>
                )}
                {index === 3 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      marginBottom: "10px",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                      display: "flex", // Ensure flexbox layout
                      alignItems: "center", // Align items vertically in the center
                    }}
                  >
                    <img
                      src={call}
                      alt="call"
                      style={{ marginRight: "5px", verticalAlign: "middle" }} // Align the image in the middle
                    />
                    +91-120-6650888
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
                {index === 3 && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: "white",
                      textDecoration: "none",
                      fontFamily: "MarkOT-Medium",
                      display: "flex", // Ensure flexbox layout
                      alignItems: "center", // Align items vertically in the center
                    }}
                  >
                    <img
                      src={sms}
                      alt="sms"
                      style={{ marginRight: "5px", verticalAlign: "middle" }} // Align the image in the middle
                    />
                    sales@kleeto.in
                    <IconButton color="primary" size="small">
                      <MdOutlineKeyboardArrowRight color="white" />
                    </IconButton>
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* Footer Bottom */}
      <Grid
        container
        justifyContent="center"
        sx={{
          backgroundColor: "#1F1F1F",
          padding: "20px 20px",
          color: "#A3A89F",
          gap: { xs: "10px", sm: "40px" }, // Smaller gap for small screens
          flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontal on larger
          alignItems: { xs: "flex-start", sm: "center" }, // Align to start on small screens
        }}
      >
        {/* First on large screens, last on small screens: Copyright Info */}
        <Grid item xs={12} sm="auto" order={{ xs: 2, sm: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "MarkOT",
              fontSize: "12px",
              textAlign: { xs: "left", sm: "left" }, // Align text to the left on small and large screens
            }}
          >
            © 2024 Itel. All rights reserved.
          </Typography>
        </Grid>

        {/* Privacy Policy and Terms of Service on the same row */}
        <Grid
          item
          xs={12}
          sm="auto"
          container
          justifyContent={{ xs: "flex-start", sm: "center" }} // Align items to the start on small screens
          spacing={2}
          order={{ xs: 1, sm: 2 }}
        >
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "MarkOT",
                fontSize: "12px",
                textAlign: { xs: "left", sm: "center" }, // Left-align on small screens, center on larger
              }}
            >
              Privacy policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "MarkOT-Medium",
                fontSize: "12px",
                textAlign: { xs: "left", sm: "center" }, // Left-align on small screens, center on larger
              }}
            >
              Terms of service
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
