import React from "react";
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import arrow from "../../../images/productlanding/arrow.png";

const ProductLanding = ({
  backgroundImages,
  landingImages,
  maxWidthLandingImage,
  appBarColor,
  mainTexts,
  features,
  menuIcon,
  backgroundImageFeatures,
  productname,
  linearcolor,
  ProductnamefontSize,
  Productnamecolor,
  OverviewSpecificationcolor,
  SpecificationsORTechSpecs,
  buynowbuttonbgcolor,
  buynowbuttoncolor,
  featuresborder,
  headingColor,
  rightsideTextColorFeatures,
  featuresubheadingcolor,
  featurediscriptiontextcolor,
  appBarBorder
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      {/* Landing */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          // maxWidth:"100%",
          // maxHeight:"100%",
          backgroundImage: `url(${
            isSmallScreen ? backgroundImages[1] : backgroundImages[0]
          })`,
          backgroundSize: isSmallScreen ? "100% 100%" : "cover", 
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landingImages[1] : landingImages[0]}
          alt="Banner"
          className="landing-image"
          sx={{
            maxWidth: maxWidthLandingImage,
            height: "auto",
            marginTop: isSmallScreen ? "50%" : "0",
          }}
        />
      </Box>

      {/* AppBar */}
      <AppBar
        position="static"
        sx={{
          bgcolor: appBarColor || "transparent",
          backgroundImage: appBarColor ? "none" : linearcolor,
          borderTop: appBarBorder,
          borderBottom: appBarBorder,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: isSmallScreen ? "1%" : "3%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <Box
                component="img"
                src={menuIcon}
                alt="Menu Icon"
                sx={{ width: "36px", height: "36px" }}
              />
            </IconButton>
            <Typography
              sx={{ fontSize: ProductnamefontSize, fontFamily:"MarkOT-Medium",color: Productnamecolor }}
            >
              {productname}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: isSmallScreen ? "flex-start" : "center",
              flexGrow: 1,
            }}
          >
            {!isSmallScreen && (
              <>
                <Button
                  sx={{
                    mx: 1,
                    fontSize: "16px",
                    color: OverviewSpecificationcolor,
                    textTransform: "none",
                    //fontWeight: "bold",
                    fontFamily:"MarkOT-Bold",
                  }}
                >
                  Overview
                </Button>
                <Button
                  sx={{
                    mx: 1,
                    fontSize: "16px",
                    color: OverviewSpecificationcolor,
                    textTransform: "none",
                    fontWeight: "400",
                    fontFamily:"MarkOT",
                  }}
                >
                  {SpecificationsORTechSpecs}
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ pr: isSmallScreen ? "1%" : "3%" }}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                bgcolor: buynowbuttonbgcolor,
                color: buynowbuttoncolor,
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                '&:hover': {
                  bgcolor: buynowbuttonbgcolor,
                  color: buynowbuttoncolor
                },
                fontFamily:"MarkOT-Bold",
              }}
            >
              Buy Now
              <Box
                component="img"
                src={arrow}
                alt="Buy Now Icon"
                sx={{
                  width: "24px",
                  height: "24px",
                  ml: isSmallScreen ? 0.5 : 1,
                }}
              />
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Features Section */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          backgroundImage: `url(${backgroundImageFeatures})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          py: isSmallScreen ? 5 : 8,
          pr: "4%",
          pl: "4%",
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          {mainTexts.map((text, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      WebkitBackgroundClip: "text",
                      backgroundImage: headingColor ? "none" : linearcolor,
                      color: headingColor || "transparent",
                      textAlign: "left",
                      fontSize: isSmallScreen ? "30px" : "48px",
                      fontFamily:"MarkOT-Medium",
                    }}
                  >
                    {text.heading}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: rightsideTextColorFeatures,
                      fontSize: "16px",
                      fontFamily:"MarkOT",
                    }}
                  >
                    {text.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                sx={{
                  p: isSmallScreen ? "1% 0% 0% 0%" : "6% 6% 7% 6%",
                  textAlign: "left",
                  border: featuresborder,
                  display: "flex",
                  flexDirection: "column",
                  height: "327px",
                  borderRadius: "16px",
                }}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "flex-start",  }}
                >
                  <Box>
                    <Box
                      component="img"
                      src={feature.icon}
                      alt={`${feature.title} Icon`}
                      sx={{
                        width: "60px",
                        height: "60px",
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "24px",
                      fontFamily:"MarkOT-Medium",
                      //mt: 2,
                      WebkitBackgroundClip: "text",
                      backgroundImage: featuresubheadingcolor
                        ? "none"
                        : linearcolor,
                      color: featuresubheadingcolor || "transparent",
                      fontWeight:"590",
                    }}
                  >
                    {feature.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ mt: 2, fontSize: "16px",fontFamily:"MarkOT", color:featurediscriptiontextcolor }}>
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProductLanding;
