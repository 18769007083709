import React, { useEffect, useRef ,useState} from "react";
import { gsap } from "gsap";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Hidden,
  Tab,
  Tabs
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from "@mui/material/styles";
import ProductFaqs from "../../components/support/Faq";

// import rightarrow from "../../images/servicecenter/arrowright.png";
// import svgarrow from "../../images/productlisting/svgarrow.svg";
import mobile1 from "../support/supportimages/mobile1.png";
import mobile2 from "../support/supportimages/mobile2.png";
import contact1 from "../support/supportimages/contact1.png";
import contact2 from "../support/supportimages/contact2.png";
import itelhome from "../support/supportimages/itelhome.png";
import screenreplace from "../support/supportimages/screenreplace.png";
import days from "../support/supportimages/days.png";
import FAQ from "../support/supportimages/FAQ.png";
import Ewaste from "../support/supportimages/Ewaste.png";
import contactus from "../support/supportimages/contactus.png";
// import starImage from "../../images/contactus/Star 2.png";
// import circleImage from "../../images/contactus/circle.png";
import supportLanding from "../support/supportimages/supportLanding.png";
import support3 from "../support/supportimages/support3.png";
import call from "../support/supportimages/call.png";
import chat from "../support/supportimages/chat.png";
import email from "../support/supportimages/email.png";
// import search from "../support/supportimages/search.png";
import news1 from "../support/supportimages/news1.png";
import news2 from "../support/supportimages/news2.png";
import news3 from "../support/supportimages/news3.png";
import mobilenews1 from "../support/supportimages/mobilenews1.png";
import mobilenews2 from "../support/supportimages/mobilenews2.png";
import mobilenews3 from "../support/supportimages/mobilenews3.png";
import { FaArrowRightLong } from "react-icons/fa6";
// import star from "../support/supportimages/Star.png";
import IconAnimation from "../../components/IconAnimation/IconAnimation";
import RedIconAnimation from "../../components/Animation/RedIconAnimation";


const callFaqs = [
  {
    question: "How can I get easy EMI?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Where can I find product video tutorials?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Where can I find product video tutorials?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Where can I find product video tutorials?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];
const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "CHARGING",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
function Support() {
  const starRef = useRef(null);
  const [value, setValue] = React.useState(0);
  const linkText = "Check out the support page for more.";
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm')); //
  const [selectedTab, setSelectedTab] = useState("ALL");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    tl.fromTo(
      starRef.current,
      { scale: 0.5, rotation: 0 },
      { scale: 1, rotation: 180, duration: 2 }
    );
  }, []);


  
  

  

  return (

    <Box sx={{ backgroundColor: "#f5f5f5" }}>
      <Box
        sx={{
          height: { xs: "80vh", sm: "80vh", md: "80vh", lg: "80vh" }, // Use percentages for height
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: `url(${supportLanding}) no-repeat center center`,
          backgroundSize: "cover",
          padding: { xs: "5%", sm: "8%", md: "10%" }, // Use percentages for padding
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#fff",

            fontSize: { xs: "20px", sm: "24px", md: "36px", lg: "40px" }, // Use px for fontSize
            fontWeight: "400",
          }}
        >
          Welcome to
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#fff",

            fontSize: { xs: "36px", sm: "48px", md: "60px", lg: "80px" }, // Use px for fontSize
            fontWeight: "500",
          }}
        >
          itel support
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#fff",
            marginBottom: { xs: "2%", sm: "3%", md: "4%" }, // Use percentages for marginBottom
            fontSize: { xs: "18px", sm: "20px", md: "22px", lg: "24px" }, // Use px for fontSize
            fontFamily: 'markOT-Light '
          }}
        >
          We're here to help you tackle any tech troubles head-on.
        </Typography>
      </Box>

      {/* Section 2 */}

      < Box sx={{ p: "4% 4% 10% 4%", mt: '4%' }
      }>
        <Box
          sx={{

            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: { xs: "2%", sm: "4%" },
            textAlign: { xs: "left", sm: "center" },
            borderRadius: "16px",

          }}
        >

          <Grid container spacing={2}>
            <Grid item xs={12} md={4} >
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={itelhome} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '25px', md: '24px' } }}>
                      Service Centers
                    </Typography>
                    {isSmallScreen ? (
                     <IconAnimation
                     text="LEARN MORE"
                   
                     fontFamily="MarkOT-Medium" 
                     />
                    ) : (
                    <IconAnimation
                    text="READ MORE"
                    fontFamily="markOT-Bold" 
                    />
                  )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={screenreplace} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '25px', md: '24px' } }}>
                      Screen Replacement
                    </Typography>
                    {isSmallScreen ? (
                     <IconAnimation
                     text="LEARN MORE"
                   
                     fontFamily="MarkOT-Medium" 
                     />
                    ) : (<IconAnimation
                      text="READ MORE"
                      
                      fontFamily="markOT-Bold" 
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={days} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '24px', md: '24px' } }}>
                      100 Days Promise
                    </Typography>
                    {isSmallScreen ? (
                     <IconAnimation
                     text="LEARN MORE"
                   
                     fontFamily="MarkOT-Medium" 
                     />
                    ) : (<IconAnimation
                      text="READ MORE"
                      
                      fontFamily="markOT-Bold" 
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* secon raw */}
          <Grid container spacing={2} sx={{ marginTop: { md: "3%", lg: "4%", xs: "3%" } }}>
            <Grid item xs={12} md={4} >
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={FAQ} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    {isSmallScreen ? (
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '25px', md: '24px' } }}>
                      FAQ's
                    </Typography>
                    ) : (
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '20px', md: '24px' } }}>
                      FAQ
                    </Typography>
                    )}
                    {isSmallScreen ? (
                     <IconAnimation
                     text="LEARN MORE"
                   
                     fontFamily="MarkOT-Medium" 
                     />
                    ) : (<IconAnimation
                      text="READ MORE"
                     
                      fontFamily="markOT-Bold" 
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={Ewaste} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '24px', md: '24px' } }}>
                      Ewaste Management
                    </Typography>
                    {isSmallScreen ? (
                     <IconAnimation
                     text="LEARN MORE"
                   
                     fontFamily="MarkOT-Medium" 
                     />
                    ) : (<IconAnimation
                      text="READ MORE"
                      
                      fontFamily="markOT-Bold" 
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container sx={{ width: '100%' }}>
                <Grid item md={12} xs={6}>
                  <Box component="img" src={contactus} alt="" sx={{ width: { md: '78%', xs: '90%' } }} />
                </Grid>
                <Grid item md={12} xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                  <Box>
                    {isSmallScreen ? (
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '24px', md: '24px' } }}>
                       Contact <br></br>  US
                    </Typography>
                    ) : (
                    <Typography variant="h6" sx={{ fontWeight: { md: 'Medium', xs: 'Medium' }, fontSize: { xs: '20px', md: '24px' } }}>
                       Contact  US
                    </Typography>
                    )}
                    
                    {isSmallScreen ? (
                      <IconAnimation
                      text="LEARN MORE"
                    
                      fontFamily="MarkOT-Medium" 
                      />
                    ) : (<IconAnimation
                      text="READ MORE"
                     
                      fontFamily="markOT-Bold" 
                      />)}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        {/* Section 3 */}
        <Box>
          {/* <ProductFaqs
            title="Frequently Asked Questions (FAQs)"
            linkText={linkText}
            tabLabels={tabLabels}
            tabContents={tabContents}
            mode="light"
            bgcolor="#f5f5f5"
            accordionBackgroundColor="#f5f5f5"
          /> */}
         <ProductFaqs
          //isDarkMode="true"
          mode="light"
          bgcolor="#f5f5f5" 
          accordionBackgroundColor = "#f5f5f5"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
          

         
          
          
      />
        </Box>
        {/* Section 4 */}
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",

            }}
          >
            
              <Box
                sx={{ display: "flex",
                pt:"4%",
                }}>
                {/* <img src= {star}  alt="redstar" />  */}
                <RedIconAnimation/>
              
            <Typography
              variant="h3"
              sx={{ fontSize: { xs: "27px", md: "48px" },fontFamily:"MarkOT-Medium",}}
            >
              Discover itel Mobile Insights
            </Typography>
            </Box>
          </Box>
          <Hidden mdDown>
          <Box
              display="flex"
              sx={{
                paddingLeft: { xs: "10px", md:"0" },
                paddingTop: "30px",
                paddingBottom:"13px",
                flexDirection: "row",
                justifyContent: { xs: "center", md: "start" },
                overflowX: "auto",
              }}
            >
              {["ALL", "LATEST", "SERVICE", "USEFUL TIPS"].map((tab) => (
                <Button
                  key={tab}
                  onClick={() => handleTabChange(tab)}
                  variant="text"
                  sx={{
                    color: "black",
                    backgroundColor: selectedTab === tab ? "#EBE8E2" : "#f5f5f5",
                    minWidth: "80px",
                    borderRadius: "50px",
                    textTransform: "none",
                    marginRight: "10px",
                    fontSize: { md: "16px" },
                    fontWeight: "700",
                    "&:hover": {
                      backgroundColor: "rgba(235, 232, 226, 1)",
                    },
                  }}
                >
                  {tab}
                  </Button>
                ))}
              </Box>
            {/* <Box
              display="flex"
              sx={{
                paddingLeft: { xs: "10px", },
                paddingTop: "30px",
                flexDirection: "row",
                justifyContent: { xs: "center", md: "start" },
                overflowX: "auto",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "black",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "50px",
                  textTransform: "none",
                  marginRight: "10px",
                  fontSize: { md: "16px" },
                  fontWeight: "700",
                  "&:hover": {
                    backgroundColor: "rgba(235, 232, 226, 1)",
                  },
                }}
              >
                ALL
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "black",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "50px",
                  textTransform: "none",
                  marginRight: "10px",
                  fontSize: { md: "16px" },
                  fontWeight: "700",
                  "&:hover": {
                    backgroundColor: "rgba(235, 232, 226, 1)",
                  },
                }}
              >
                LATEST
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "black",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "50px",
                  textTransform: "none",
                  marginRight: "10px",
                  fontSize: { md: "16px" },
                  fontWeight: "700",
                  "&:hover": {
                    backgroundColor: "rgba(235, 232, 226, 1)",
                  },
                }}
              >
                SERVICE
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "black",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "50px",
                  textTransform: "none",
                  marginRight: "10px",
                  fontSize: { md: "16px" },
                  fontWeight: "700",

                  "&:hover": {
                    backgroundColor: "rgba(235, 232, 226, 1)",
                  },
                }}
              >
                USEFUL TIPS
              </Button>
            </Box> */}
          </Hidden>

          <Hidden mdUp>
            <Box
              sx={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                mb: 4,
                paddingTop: "20px",

              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "22px",
                  minWidth: "70px",
                  overflowX: "auto",
                  fontFamily:"MarkOT",
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "nowrap",
                  },
                  "& .MuiTab-root": {
                    border: 1,
                    borderColor: "black",
                    //margin: "0 7px",
                    borderRadius: "20px",
                    margin: "0 9px 2px 0",
                    color: "black",
                    padding: "8px 16px",
                    whiteSpace: "nowrap",
                    minWidth: "70px",
                    fontFamily:"MarkOT",

                    "&.Mui-selected": {
                      borderColor: "primary.main",
                      backgroundColor: "primary.main",
                      color: "white !important",
                      borderRadius: "20px",
                      minWidth: "70px",
                      fontFamily:"MarkOT",
                    },
                  },
                }}
              >
                <Tab
                  label="All"
                  sx={{ textTransform: "none", paddingLeft: "80px" }}
                />
                <Tab label="Latest" sx={{ textTransform: "none" }} />
                <Tab label="Services" sx={{ textTransform: "none" }} />
                <Tab label="Feature Phone" sx={{ textTransform: "none" }} />
              </Tabs>
            </Box>
          </Hidden>
          <Grid container spacing={2} >
            {/* Box1 */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexDirection: "column", width: { md: "100%", lg: "100%" }, marginX: { xs: "0" } }}>
                <img src={isSmallScreen ? mobilenews1 : news1} alt='image1' style={{ width: "100%", height: "auto", marginTop: "2%" }} />
                <Typography sx={{ fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                  NEWS
                </Typography>
                <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: { md: "20px", lg: "20px" }, fontWeight: "500", marginTop: "2%" }}>
                  itel India’s Heartwarming World Cup Campaign: Connecting India through th...
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2% ' }}>
                <IconAnimation
                    text="VIEW"
                    
                    fontFamily="MarkOT-Bold" 
                    />
                </Box>
              </Box>
            </Grid>

            {/* Box2 */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginX: { xs: "0%" } }}>
                <img src={isSmallScreen ? mobilenews2 : news2} alt='image1' style={{ width: "100%", height: "auto", marginTop: "2%" }} />
                <Typography sx={{ fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                  BLOG
                </Typography>
                {isSmallScreen ? (
                  <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: "16px", fontWeight: "400", marginTop: "2%" }}>
                    Battery out soon! Lets fix it

                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: {fontFamily:"MarkOT-Medium", md: "20px", lg: "20px" }, fontWeight: "500", marginTop: "2%" }}>
                    Imagine a day with uninterrupted HiFi <br /> Sound.
                  </Typography>
                )}
                 <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2% ' }}>
                <IconAnimation
                    text="VIEW"
                    
                    fontFamily="MarkOT-Bold" 
                    />
                </Box>
              </Box>
            </Grid>

            {/* Box3 */}
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%", marginX: { xs: "0" }, marginBottom: { xs: "15%", md: "0", lg: '0' } }}>
                <img src={isSmallScreen ? mobilenews3 : news3} alt='image1' style={{ width: "100%", height: "auto", marginTop: "2%", }} />
                <Typography sx={{ fontSize: { md: "14px", lg: "14px", xs: "12px" }, color: "grey", marginTop: "2%" }}>
                  BLOG
                </Typography>
                {isSmallScreen ? (
                  <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: "16px", fontWeight: "400", marginTop: "2%" }}>
                    ITEL S24 NEW RELEASE

                  </Typography>
                ) : (
                  <Typography sx={{fontFamily:"MarkOT-Medium", fontSize: { md: "20px", lg: "20px" }, fontWeight: "500", marginTop: "2%" }}>
                    Unleash the Power of Memory: itel S23 Redefines Performance
                  </Typography>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2% ' }}>
                <IconAnimation
                    text="VIEW"
                   
                    fontFamily="MarkOT-Bold" 
                    />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Box>
      {/* Section 5 */}
      <Box
        sx={{
          flexGrow: 1,
          paddingBottom: "100px",
          marginLeft: { xs: "15px", sm: "20px", md: "4%" },
          marginRight: { xs: "15px", sm: "20px", md: "4%" },

        }}
      >
        <Grid container spacing={2}>
          {/* First Section */}
          <Grid item xs={12} sm={6} md={6} sx={{ maxWidth: "1200px", margin: "0 auto" }}>
            <Item
              sx={{
                height: { md: "300px", xs: "180px" },
                width: "100%", // Use percentage width
                maxWidth: "100%",
                backgroundImage: {
                  md: `url(${contact1})`, // Use the image for large devices
                  xs: `url(${mobile1})`, // Use the image for small devices
                },
                borderRadius:"18px",
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgba(235, 232, 226, 1)",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* Typography for this section */}
              <Typography
                variant="h3"
                sx={{
                  color: "black",
                  fontSize: { md: "36px", xs: "20px" },
                  fontWeight: "500",
                  paddingTop: { md: "20px", xs: "5px" },
                  paddingLeft: { md: "20px", xs: "10px" },
                }}
              >
                Find Your Nearest itel
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "black",
                  fontSize: { md: "36px", xs: "20px" },
                  fontWeight: "500",
                  paddingLeft: { md: "20px", xs: "10px" },
                }}
              >
                Store!
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#1C1B1BCC",
                  marginTop: "8px",
                  fontSize: { md: "16px", xs: "14px" },
                  paddingLeft: { md: "20px", xs: "10px" },
                  fontFamily: 'markOT-Light',
                  maxWidth: { xs: '80%', lg: '100%' }
                }}
              >
                We are here to help you find the right phone.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  marginTop: "16px",
                  fontWeight: "bold",
                  fontSize: { md: "16px", xs: "12px" },
                  paddingLeft: { md: "20px", xs: "10px" },
                }}
              >
                SEE MORE <FaArrowRightLong color="red" />
              </Typography>
            </Item>
          </Grid>

          {/* Second Section */}
          <Grid item xs={12} sm={6} md={6}>
            <Item
              sx={{
                height: { md: "300px", xs: "180px" },
                width: "100%", // Use percentage width
                maxWidth: "100%",
                backgroundImage: {
                  md: `url(${contact2})`, // Use the image for large devices
                  xs: `url(${mobile2})`, // Use the image for small devices
                },
                borderRadius:"18px",
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgba(235, 232, 226, 1)",
                backgroundSize: "contain",
                backgroundPosition: "right center",

              }}
            >
              {/* Typography for this section */}
              <Typography
                variant="h3"
                sx={{
                  color: "black",
                  fontSize: { md: "36px", xs: "20px" },
                  fontWeight: "500",
                  paddingTop: { md: "20px", xs: "10px" },
                  paddingLeft: { md: "20px", xs: "10px" },
                }}
              >
                Connect for
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "black",
                  fontSize: { md: "36px", xs: "20px" },
                  fontWeight: "500",
                  paddingLeft: { md: "20px", xs: "10px" },
                }}
              >
                Business Queries
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#1C1B1BCC",
                  fontSize: { md: "16px", xs: "14px" },
                  paddingTop: "20px",
                  paddingLeft: { md: "20px", xs: "10px" },
                  fontFamily:"MarkOT-Medium",
                  // textTransform:"none"
                }}
              >
                MAIL US AT
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#1C1B1BCC",
                  fontSize: { md: "16px", xs: "14px" },

                  paddingLeft: { md: "20px", xs: "10px" },
                  fontFamily: 'MarkOT'
                }}
              >
                itelMarketing.in@transsion.com
              </Typography>
            </Item>
          </Grid>
        </Grid>

        {/* Third Section */}
        <Grid container spacing={2} sx={{ marginTop: isSmallScreen ? "1px" : "20px", marginBottom:  isSmallScreen ? "2px" : "7%" }}>
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              maxWidth: "100%",
              padding: { md: "0", xs: "0" },
            }}
          >
            <Item
              sx={{
                height: { md:"auto", xs: "319px" },
                width: "100%", // Use percentage width
                background: {
                  md: `url(${support3})`, // Use the image for large devices
                  xs: `url(${support3})`, // Use the image for small devices
                },
                borderRadius:"18px",
                backgroundImageRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center", // Ensure the image is centered
                marginTop: { xs: "5px", md: "0" }, // Add margin to avoid merging with previous sections
                padding: "20px", // Adjust padding as needed to avoid overlapping content
                boxSizing: "border-box",
              }}
            >
              {/* Typography for this section */}
              <Typography
                variant="body1"
                sx={{
                  fontSize: { md: "16px", xs: "14px" },
                  marginTop: { md: "100px", xs: "30px" },
                  color: "#1C1B1B",
                  paddingLeft: { md: "54px", xs: "10px" },
                }}
              >
                Need our help?
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { md: "36px", xs: "28px" },
                  color: "black",
                  paddingLeft: { md: "50px", xs: "10px" },
                }}
              >
                Contact us
              </Typography>

              {/* Contact Options Section */}
              <Hidden mdDown>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    paddingLeft: { md: "47%", xs: "10px" },
                    marginTop: { md: "-80px" },
                  }}
                >
                  {/* Call Section */}
                  <Box sx={{ flex: 1 }}>
                    <img style={{paddingBottom: "10px"}} src={call} alt="call" />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                        fontFamily:"MarkOT-Medium",
                        paddingBottom:"3px",
                      }}
                    >
                      CALL US
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: { md: "14px", xs: "16px" },
                        color: "rgba(28, 27, 27, 1)",
                        fontFamily:"MarkOT-Bold",
                        marginBottom:"42px"
                      }}
                    >
                      1800-4190-525
                    </Typography>
                  </Box>

                  {/* Chat Section */}
                  <Box sx={{ flex: 1, paddingLeft: { md: "20px", xs: "2px" } }}>
                    <img style={{paddingBottom: "10px"}} src={chat} alt="chat" />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                        fontFamily:"MarkOT-Medium",
                        paddingBottom:"3px",
                      }}
                    >
                      CHAT WITH US
                    </Typography>

                    <Typography
                      component="a"
                      href="https://www.example.com"  // External URL
                      target="_blank"
                      rel="noopener noreferrer" 
                      sx={{
                        fontSize: { md: "14px", xs: "16px" },
                        fontWeight: "500",
                        color: "rgba(28, 27, 27, 1)",
                        fontFamily:"MarkOT-Bold",
                        cursor: "pointer",
                        textDecoration:"under-lined",
                        marginBottom:"42px"
                      }}
                    >
                      CLICK HERE
                    </Typography>
                  </Box>

                  {/* Email Section */}
                  <Box sx={{ flex: 1, paddingLeft: { md: "20px", xs: "2px" } }}>
                    <img style={{paddingBottom: "10px"}} src={email} alt="email" />
                    <Typography
                      sx={{
                        fontSize: { md: "12px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                        fontFamily:"MarkOT-Medium",
                        paddingBottom:"3px",
                      }}
                    >
                      E-MAIL US
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { md: "14px", xs: "16px" },
                        fontWeight: "500",
                        color: "rgba(28, 27, 27, 1)",
                        fontFamily:"MarkOT-Bold",
                        marginBottom:"42px"
                      }}
                    >
                      INFO.IN@TRANSSION.COM 
                    </Typography>
                  </Box>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    paddingLeft: { md: "700px", xs: "10px" },
                    marginTop: { md: "-80px" },
                  }}
                >
                  {/* Call Section */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1rem', alignItems: 'center' }}>
                    <Box component="img" src={call} alt="call" sx={{ width: '35px', height: '35px', mr: '1rem' }} />
                    <Typography
                      sx={{
                        fontSize: { md: "16px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                      }}
                    >
                      Call us <br />1800-4190-525
                    </Typography>

                  </Box>

                  {/* Chat Section */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: { md: "20px", xs: "2px" }, mt: '1rem', alignItems: 'center' }}>
                    <Box component="img" src={chat} alt="chat" sx={{ width: '35px', height: '35px', mr: '1rem' }} />
                    <Typography
                      sx={{
                        fontSize: { md: "16px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                      }}
                    >
                      Chat With us<br />1800-4190-525
                    </Typography>


                  </Box>

                  {/* Email Section */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: { md: "20px", xs: "2px" }, mt: '1rem', alignItems: 'center' }}>
                    <Box component="img" src={email} alt="email" sx={{ width: '35px', height: '35px', mr: '1rem' }} />
                    <Typography
                      sx={{
                        fontSize: { md: "16px", xs: "14px" },
                        color: "rgba(102, 102, 102, 1)",
                      }}
                    >
                      E-Mail <br />info.in@transsion.com
                    </Typography>

                  </Box>
                </Box>
              </Hidden>
            </Item>
          </Grid>
        </Grid>
      </Box>


    </Box>

  );
}

export default Support;
