import React, { useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import mobile6 from "../../../images/Home/mobile6.png";
import mobile7 from "../../../images/Home/mobile7.png";
import tv3 from "../../../images/Home/tv3.png";
import tvsmall from "../../../images/Home/newtv.png";
import RedIconAnimation from "../../Animation/RedIconAnimation";

const tabData = [
  {
    label: "All",
    products: [
      {
        id: 1,
        image: mobile7,
        smallImage: mobile7,
        name: "itel A60S",
        description: "Your Ideal Storyteller: Stunning 108MP AI Camera",
        oldPrice: "₹12,000",
        newPrice: "₹6,000",
        tag: "New",
      },
      {
        id: 2,
        image: tv3,
        smallImage: tvsmall,
        name: "itel G4365",
        description: "Big Screen thrills! Loud beats, compact controls!",
        oldPrice: "₹30,000",
        newPrice: "₹27,000",
        tag: "New",
      },
      {
        id: 3,
        image: mobile6,
        smallImage: mobile6,
        name: "Itel T23",
        description: "Next Gen vibes- Bright Amoled speed surge, Game on!",
        oldPrice: "₹5,000",
        newPrice: "₹3,000",
        tag: "Hot",
      },
    ],
  },
  {
    label: "Best Deals",
    products: [
      {
        id: 4,
        image: mobile6,
        smallImage: mobile6,
        name: "itel A27",
        description: "Compact and Powerful: Your Best Budget Choice",
        oldPrice: "₹8,000",
        newPrice: "₹4,500",
        tag: "New",
      },
      {
        id: 5,
        image: tv3,
        smallImage: tvsmall,
        name: "itel U6385",
        description: "Unbeatable Display at an Unbeatable Price!",
        oldPrice: "₹32,000",
        newPrice: "₹25,000",
        tag: "New",
      },
    ],
  },
  {
    label: "Smart Phone",
    products: [
      {
        id: 6,
        image: mobile6,
        smallImage: mobile6,
        name: "itel A27",
        description: "Compact and Powerful: Your Best Budget Choice",
        oldPrice: "₹8,000",
        newPrice: "₹4,500",
        tag: "New",
      },
    ],
  },
  {
    label: "Feature Phone",
    products: [
      {
        id: 7,
        image: mobile6,
        smallImage: mobile6,
        name: "itel A27",
        description: "Compact and Powerful: Your Best Budget Choice",
        oldPrice: "₹8,000",
        newPrice: "₹4,500",
        tag: "New",
      },
    ],
  },
  {
    label: "Smart Tv",
    products: [
      {
        id: 8,
        image: tv3,
        smallImage: tvsmall,
        name: "itel A27",
        description: "Compact and Powerful: Your Best Budget Choice",
        oldPrice: "₹8,000",
        newPrice: "₹4,500",
        tag: "New",
      },
    ],
  },
  // Add other categories like "Smart Phone", "Feature Phone", "Smart TV" with their products
];

const TrendingSession = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pl: "4%",
          pb: "2%",
          mt: 10,
        }}
      >
        <RedIconAnimation />
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "30px" },
            color: "white",
            fontFamily: "MarkOT",
          }}
        >
          Hot picks: Snag ‘Em Now!
        </Typography>
      </Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        variant="scrollable"
        textColor="inherit"
        TabIndicatorProps={{ style: { display: "none" } }}
        sx={{ color: "white", pl: "4%" }}
      >
        {tabData.map((tab, index) => (
          <Tab
            key={tab.label}
            sx={{
              border: "2px solid #1F1F1F",
              borderRadius: "50px",
              mr: "10px",
              textTransform: "none",
              fontFamily: "MarkOT",
              "&.Mui-selected": {
                backgroundColor: "#666666",
                color: "white",
              },
            }}
            label={tab.label}
          />
        ))}
      </Tabs>

      {tabData.map((tab, index) => (
        <TabPanel key={tab.label} value={tabValue} index={index}>
          <Grid
            container
            spacing={2}
            justifyContent="start"
            sx={{ pl: "4%", pt: 3, pb: 8, pr: "4%" }}
          >
            {tab.products.map((product) => (
              <Grid item xs={6} md={4} sm={6} key={product.id}>
                <Paper
                  sx={{
                    backgroundColor: "#232323",
                    color: "#fff",
                    padding: "1rem",
                    borderRadius: "15px",
                    height: { xs: "426px", sm: "456px", md: "486px" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    position: "relative",
                  }}
                >
                  {product.tag && (
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "#3A3358",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "43px",
                        fontFamily: "MarkOT",
                      }}
                    >
                      {product.tag}
                    </Typography>
                  )}
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    flexGrow={1}
                  >
                    <Grid item mt={6}>
                      <img
                        src={
                          isExtraSmallScreen
                            ? product.smallImage
                            : product.image
                        } // Use different image for small screen
                        alt={product.name}
                        style={{
                          height: "170px",
                          width: "100%",
                          objectFit: "contain",
                        }} // Ensure the image is responsive
                      />
                    </Grid>
                    <Grid item textAlign={"center"}>
                      <Typography
                        sx={{
                          fontSize: { xs: "16px", md: "24px" },
                          fontFamily: "MarkOT",
                        }}
                        gutterBottom
                      >
                        {product.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "10px", md: "14px" },
                          color: "#C0C0C0",
                          mr: { xs: "4%", md: "10%" },
                          ml: { xs: "4%", md: "10%" },
                          fontFamily: "MarkOT",
                        }}
                      >
                        {product.description}
                      </Typography>
                      {product.oldPrice && (
                        <Typography
                          sx={{
                            fontSize: { xs: "10px", md: "14px" },
                            color: "#C0C0C0",
                            mt: 2,
                            fontFamily: "MarkOT",
                          }}
                        >
                          Old price{" "}
                          <span
                            style={{
                              textDecoration: "line-through",
                              fontFamily: "MarkOT",
                            }}
                          >
                            {product.oldPrice}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "24px",
                      bgcolor: isExtraSmallScreen ? "transparent" : "#2C2C2C",
                      border: isExtraSmallScreen
                        ? "none"
                        : "0.5px solid #7E7E7E",
                      width: { xs: "100%", sm: "60%" },
                      alignSelf: "center",
                      mb: 2,
                      fontFamily: "MarkOT",
                    }}
                    endIcon={<BsArrowRight style={{ color: "red" }} />}
                  >
                    {product.newPrice}/-
                  </Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      ))}
    </div>
  );
};

export default TrendingSession;

function TabPanel({ children, value, index }) {
  return (
    <div hidden={value !== index}>
      {value === index && <div>{children}</div>}
    </div>
  );
}
