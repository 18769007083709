import React from "react";
import {
  Typography,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import banner from "../../images/ewaste/Banner Section.png";
import bannersmall from "../../images/ewaste/small.png";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { styled } from "@mui/system";
import forbidden from "../../images/ewaste/forbidden.png";
import delet from "../../images/ewaste/delete.png";
import sell from "../../images/ewaste/dontsell.png";
import apple from "../../images/ewaste/apple.png";
import landing1 from "../../images/ewaste/landing1.png";
import landing2 from "../../images/ewaste/landing2.png";
import curves from "../../images/ewaste/bg.png";
import vector from "../../images/ewaste/background.png";
import rightarrow from "../../images/ewaste/arrowright.png";
import downarrow from "../../images/ewaste/downarrow.svg";
import shell from "../../images/ewaste/shell.svg"
// import ResponsiveText from "../../components/responsivetext/ResponsiveText";

const accordionData = [
  {
    title: "S Mobile Devices Private Limited - Recycling Program",
    content: [
      "At S Mobile Devices Private Limited, we understand that our responsibility doesn’t end at selling you our products. S Mobile Devices Private Limited has been working in the area of safe disposal of electronic waste. Since mobiles and other electronic items are made of hazardous constituents, our e-waste policy aligns with the legislation passed by the Ministry of Environment and Forests (MoEF & CC) called E-Waste (Management) Rules, 2016, which came into effect on 1st Oct 2016.",
      "S Mobile Devices Private Limited will seek shared responsibility and cooperation from customers in reducing the environmental impact of their products.",
      "S Mobile Devices Private Limited will comply with all applicable laws related to e-waste management. As a part of our e-waste recycling initiative, S Mobile Devices Private Limited has partnered with 3R Recycler and Exigo Recycling to comply with E-Waste (Management) Rules, 2016, in providing environmentally sound management of end-of-life electronics.",
    ],
  },
  {
    title: "3R Recycler",
    content: [
      "As part of this partnership with 3R Recycler, the company will ensure environmentally sound management of electronics that have reached their end-of-life phase. 3R Recycler has obtained all the necessary authorizations from the appropriate governmental agencies for their processing facilities. 3R Recycler ensures proper recycling and disposal of e-waste. This helps us protect the environment from any hazardous consequences, which would otherwise be caused by the inappropriate waste management of e-waste.",
    ],
  },
  {
    title: "Exigo Recycling Pvt Ltd",
    content: [
      "Exigo Recycling Pvt. Ltd. is an initiative started to remove the complexity out of the process of electronic assets management and recycling. Our aim is to provide a simple, straightforward, transparent, and sustainable way to recycle and manage your e-waste and electronic assets. We collect all types of IT, electronics, electrical, media, and communications equipment. We are authorized by the State Pollution Boards and the Government of India to safely collect, transport, dismantle, segregate, and dispose of e-waste.",
    ],
  },
];
const dosData = [
  {
    step: "Step 1",
    description:
      "Follow the instructions for proper handling of end-of-life equipment in our catalogues.",
  },
  {
    step: "Step 2",
    description:
      "Make sure that your electronic products are recycled only by authorised recyclers/dismantlers.",
  },
  {
    step: "Step 3",
    description:
      "Consult your nearest itel Collection Point for disposing products that have reached end-of-life.",
  },
  {
    step: "Step 4",
    description:
      "Drop-off used Mobile Phones, batteries or any accessories at the nearest itel Collection Point.",
  },
  {
    step: "Step 5",
    description:
      "Separate the packaging materials from the electronic products as per safe waste disposal options.",
  },
  {
    step: "Step 6",
    description:
      "Protect the glass surface of any electronic product to avoid breakage.",
  },
];

const dontsData = [
  {
    image: forbidden,
    description:
      "Never <b>dismantle</b> your electronic <b>products yourself</b> at home.",
  },
  {
    image: delet,
    description:
      "Never dispose electronic products in bins having <b>‘Do Not Dispose’ sign</b> .",
  },
  {
    image: sell,
    description:
      "<b>Never sell</b> or give e-waste to informal and unorganized sectors like <b>local scrap dealer</b>/rag pickers.",
  },
  {
    image: apple,
    description:
      "Never throw your electronic waste<b> along with municipal waste</b> as they end up reaching landfills.",
  },
];

function EWaste() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <div>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          // height: { xs: "80vh", sm: "70vh", md: "70vh", lg: "80vh" },
          overflow: "hidden",
          marginBottom: 2
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landing2 : landing1}
          alt="Banner"
          sx={{
            width: "100%",
            height: "80vh",
            display: "block",

          }}
        />
        <Box
          sx={{
            width: { md: '60%', xs: '90%' },
            position: 'absolute',
            top: '30%',
            left: { md: '10%', xs: '5%' },

          }}
        >
          <Typography
            variant="h2"
            sx={{
              transform: { xs: "translateY(-60%)", md: "translateY(-30%)" },
              color: "white",
              maxWidth: { xs: "55%", sm: "50%", md: "90%" },
              fontSize: {
                xs: "32px",
                sm: "34px",
                md: "50px",
                lg: '80px',
              },
              fontFamily: 'MarkOT-Medium',
              marginBottom: { xs: 1.5, sm: 2, md: 1 },
              // Reduce margin bottom
            }}
          >
            Ditch Your Old Gadgets with Us!
          </Typography>
          <Typography
            variant="h5"
            sx={{
              transform: "translateY(-50%)",
              maxWidth: { xs: "70%", sm: "70%", md: '99%' },
              color: "white",
              lineHeight: { xs: '25.6px' },
              textAlign: { xs: "left", lg: "left" },
              fontSize: {
                xs: "14px",
                sm: "18px",
                md: "20px",
              },
              fontFamily: 'MarkOT'
              // Reduce or remove margin top
            }}
          >
            Got old gadgets collecting dust? Don't just toss them! Drop off your outdated tech with us, and we'll recycle it responsibly. Save the planet, one gadget at a time!
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          sx={{ mr: { xs: "4%", sm: "12%" }, ml: { xs: "4%", sm: "12%" } }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              pt={"8%"}
              color="#252525"
              fontFamily={"MarkOT"}

              sx={{ fontSize: '16px', mb: { md: '2rem', xs: '2rem' }, lineHeight: '25.6px' }}

            >
              E-waste is a popular, informal name for electronic waste products nearing the end of their “useful life.” Telephones, pay phones, cordless phones, air conditioners, televisions, refrigerators, washing machines, mobile phones, computers, VCDs, stereos, copiers, and fax machines are common electronic products. Many of these products can be reused, refurbished, or recycled.


            </Typography>
            <Typography
              variant="body1"
              component="p"

              color="#252525"
              fontFamily={"MarkOT"}
              sx={{ fontSize: '16px', lineHeight: '25.6px', mb: { md: '2rem', xs: '2rem' } }}

            >
              E-waste has been one of the fastest-growing waste streams in the world. While e-waste contains valuable materials such as aluminium, copper, gold, palladium, and silver, it also contains harmful substances like cadmium, lead, and mercury. In the absence of proper awareness, disposing of e-waste in landfills can result in toxic emissions to the air, water, and soil and pose serious health and environmental hazards.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: 400,
                objectFit: "cover",
                borderRadius: '10px'
              }}
              alt="E-waste"
              src={isSmallScreen ? bannersmall : banner}
            />
          </Grid>
        </Grid>
        {/*Drop Zone*/}
        <Grid item xs={12} >
          <Box sx={{ pr: { xs: "4%", sm: "12%" }, pl: { xs: "4%", sm: "12%" }, pb:"4%"}}>
            <Box
              sx={{
                backgroundImage: `url(${curves})`,

                p: { sm: "5% 5%", md: "5% 5%", lg: "4% 4% 4% 4%" },

                mt:"5%",
                height: "auto",
                width: "100%",
                borderRadius: 2,
                textAlign: "center",

              }}
            >
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  fontSize: { md: '36px', xs: '24px' }, fontFamily: "MarkOT-Medium", 
                  pt:{xs: "5%", md:"0"}
                }}
              >
                E-Waste Drop Points
              </Typography>

              <Typography
                variant="body1"
                component="p"
                fontFamily={"MarkOT"}
                gutterBottom
                sx={{
                  pl: { md: "3%", lg: "12%", xl: "18%", xs: "5%" },
                  pr: { md: "3%", lg: "12%", xl: "18%", xs: "5%" },
                  fontSize: '14px',
                  lineHeight: '20.4px',
                }}
              >
                Looking to get rid of your old electronics responsibly? Our Eco
                Drop Zones make it easy. Find our collection and drop points
                near you to safely dispose of e-waste. Together, we can recycle
                and repurpose old gadgets, reducing harmful environmental
                impact. Join us in making a greener future!
              </Typography>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  mt: '10px',
                  mb:{xs:"10px", md: "20px"},
                  fontSize: '14px'
                }}
              >
                See the table
              </Typography>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                
              >
                <Grid item xs={12} sm={12} md={5} >
                  <Typography textAlign="left" sx={{ fontFamily: 'MarkOT-Medium', fontSize: '16px', pb: '.5rem',pr :isSmallScreen && "4%", pl :isSmallScreen && "4%" }}>State</Typography>
                  <FormControl
                    sx={{ position: 'relative', width: '100%' ,pr :isSmallScreen && "4%", pl :isSmallScreen && "4%"}}

                  >
                    <InputLabel
                      id="select-state-label"
                      sx={{
                        pl:{xs:"3%"},
                        fontSize: '14px',
                        left:{xs: '5%',md:"1%"},
                        top: '50%',          // Centers the label horizontally
                        transform: 'translateY(-50%)', // Adjusts for the label's width
                        position: 'absolute' // Position the label relative to FormControl
                      }}
                    >
                      Select State
                    </InputLabel>
                    <Select
                      labelId="select-state-label"
                      id="select-state"
                      label="State"

                    >
                      <MenuItem value="">
                        <em>Select State</em>
                      </MenuItem>
                      <MenuItem value={10}>Kerala</MenuItem>
                      <MenuItem value={20}>Mumbai</MenuItem>
                      <MenuItem value={30}>Karnataka</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={5} >
                  <Typography textAlign="left" sx={{ fontFamily: 'MarkOT-Medium', fontSize: '16px', pb: '.5rem' ,pr :isSmallScreen && "4%", pl :isSmallScreen && "4%"}}>City</Typography>
                  <FormControl
                    sx={{ position: 'relative', width: '100%' ,pr :isSmallScreen && "4%", pl :isSmallScreen && "4%"}}

                  >
                    <InputLabel
                      id="select-state-label"
                      
                      sx={{
                        pl:{xs:"3%"},
                        fontSize: '14px',
                        left:{xs: '5%', md:"1%"},
                        top: '50%',          // Centers the label horizontally
                        transform: 'translateY(-50%)', // Adjusts for the label's width
                        position: 'absolute', // Position the label relative to FormControl
                       
                      }}
                    >
                      Select City
                    </InputLabel>
                    <Select
                      labelId="select-city-label"
                      id="select-city"
                      label="City"
                      
                    >
                      <MenuItem value="">
                        <em>Select City</em>
                      </MenuItem>
                      <MenuItem value={10}>City 1</MenuItem>
                      <MenuItem value={20}>City 2</MenuItem>
                      <MenuItem value={30}>City 3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                sx={{
                  mt: "1rem",
                  borderRadius: "24px",
                  bgcolor: "black",
                  color: "white",
                  width: { xs: "90%", sm: "25%", md: "20%", xl: "15%" },
                  mb: isSmallScreen ? "7%" : "3%",
                }}
              >
                SEARCH
                <img
                  src={rightarrow}
                  alt="Search Icon"
                  style={{ marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* 3 dropdowns*/}
      <Box
        sx={{ pb:{xs:"8%", md: "5%"}, pr: { xs: "4%", sm: "12%" }, pl: { xs: "4%", sm: "12%" }, mt: { xs: '7%', sm: '8%', md: '4%' } }}
      >
        {accordionData.map((accordion, index) => (
          <Accordion
            key={index}
            defaultExpanded
            sx={{
              boxShadow: "none",
              border: "none",
              "&:before": { display: "none" },
              mb:{xs:"5%", md:"2%"}
            }}
          >
            <AccordionSummary
              expandIcon={<img src={downarrow} alt="Expand" />}
              sx={{
                padding: 0,
                backgroundColor: "transparent",
                "& .MuiAccordionSummary-content": { margin: 0 },
              }}
            >
              <Typography variant="h5" sx={{ fontFamily: 'MarkOT-Medium', fontSize: { xs: '32px', sm: '28px' }, lineHeight: '33.6px' }}>
                {accordion.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, mb: "40px" }}>
              {accordion.content.map((paragraph, paragraphIndex) => (
                <Typography sx={{ fontFamily: 'MarkOT', fontSize: '14px', mb: '1rem' }} key={paragraphIndex}>{paragraph}</Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* dont's */}

      <Box
        sx={{
          backgroundImage: `url(${vector})`,
          backgroundSize: "100% 100%",
          height: "auto",
          width: "100%",
          pr: { xs: "4%", sm: "12%" }, pl: { xs: "4%", sm: "12%" }, pt: "0%"
          
        }}
      >
        <Grid item xs={12} sx={{ mb: { md: '15%' }, }}>
          <Typography variant="h4" gutterBottom sx={{ mb: { xs: '10%', md: '4%' }, pt: "10%" }}>
            Do's
          </Typography>
          <Grid
            container
            spacing={{ xs: 1, md: 10, sm: 5, lg: 15 }}
            justifyContent={"center"}
            alignItems={"center"}
            // Increased margin for more space between sections
          >
            {dosData.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                key={index}
                sx={{
                  mb: {
                    xs: 2, // smaller margin-bottom for extra small to small screens
                    lg: -8, // larger margin-bottom for large screens
                  },
                }}
              >
                {/* <Typography variant="h6">{item.step}</Typography> */}
                <Box component="img" src={shell} alt="" />
                <Typography sx={{ fontSize: { md: "16.8px", xs: '14px', lineHeight: '22.4px' }, fontFamily: 'MarkOT' }}>
                  {item.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mb: { md: '8%', xs: '10%' } }}>
          <Typography variant="h4" gutterBottom sx={{ mb: { xs: '10%', md: '4%' }, mt:{xs:"7%"} }}>
            Dont's
          </Typography>
          <Grid container spacing={2}>
            {dontsData.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Grid
                  container
                  direction={{ xs: "row", sm: "column" }}
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  justifyContent={{ xs: 'center' }}
                  spacing={1}
                  marginBottom={isSmallScreen && "7%"}
                >
                  <Grid item xs={2} sm={12}>
                    <Box
                      component="img"
                      src={item.image}
                      alt={`img ${index + 1}`}
                      sx={{
                        width: {
                          xs: "100%", // 50% width on extra small screens
                          sm: "100%", // 100% width on small and larger screens
                        },
                        maxWidth: "100%",
                        
                        // Ensure the image doesn't exceed its container width
                      }}
                    />
                  </Grid>
                  <Grid item xs={10} sm={12} sx={{ pt:{xs:"5%", md:" 10%!important"} }} >
                    <Typography
                      sx={{ fontSize: "14px", fontFamily: 'MarkOT',width:{ xs: "18rem ", md:"14rem"}, mb:isSmallScreen ? "0%" : "30%"}}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      {/*last Accordion*/}
      <Box
        sx={{
          pr: { xs: "4%", sm: "12%" }, pl: { xs: "4%", sm: "12%" }, mt: { xs: '12%', sm: '8%', md: '4%' }, pb: "10%"
        }}
      >
        <Accordion
          defaultExpanded
          sx={{
            boxShadow: "none",
            border: "none",
            "&:before": { display: "none" },
          }}
        >
          <AccordionSummary
            expandIcon={<img src={downarrow} alt="Expand" />}
            sx={{
              padding: 0,
              backgroundColor: "transparent",
              "& .MuiAccordionSummary-content": { margin: 0 },
            }}
          >
            <Typography variant="h5" sx={{ fontFamily: "MarkOT-Medium", fontSize: { md: '36px', xs: '31px' } }}>
              Lifecycle of our Products
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Typography paragraph sx={{ fontFamily: "MarkOT", fontSize: { md: '16px', xs: '14px' } }}>
              As defined under New E-waste (Management) Rules, 2016 life cycle
              of our Feature Phone is 7 years and Smartphone is 5 Years.
            </Typography>

            <Typography paragraph sx={{ fontFamily: "MarkOT", fontSize: { md: '16px', xs: '14px' }, marginBottom: '20px' }}>
              In case of any queries regarding the proper disposal and/or recycling of electronics, consumers can contact the helpdesk.
              <br /> <span style={{ fontFamily: 'MarkOT', fontSize: { md: '16px', xs: '14px' }, display: isSmallScreen ? 'none' : 'block' }}>(Toll free) at:</span>
            </Typography>
            <Typography sx={{ fontFamily: "MarkOT", fontSize: { md: '16px', xs: '14px' }, display: isSmallScreen ? 'block' : 'none', marginBottom: '20px' }} >(Toll free) at:
            </Typography>

            <Typography
              sx={{
                fontFamily:isSmallScreen ? "MarkOT-Bold" : "MarkOT",
                fontSize: { md: '16px', xs: '14px' },
                color: '#050036',
                display: isSmallScreen ? 'flex' : 'none',
                alignItems: 'center', // Aligns items vertically in the center
              }}
            >
              1800-3000-8198
              <span
                style={{
                  color: '#050036',
                  fontFamily:isSmallScreen ? "MarkOT-Bold" : 'MarkOT',
                  display: isSmallScreen ? 'block' : 'none',
                  marginLeft: '8px', // Adds space to the left of the span
                  marginRight: '8px', // Adds space to the right of the span
                }}
              >
                or
              </span>
              1800-102-5018
            </Typography>
            <Typography
              sx={{
                fontFamily: "MarkOT",
                fontSize: { md: '16px', xs: '14px' },
                color: '#050036',
                display: isSmallScreen ? 'flex' : 'none',
                alignItems: 'center', // Aligns items vertically in the center
              }}
            >
              Email -
              <span
                style={{
                  color: '#050036',
                  fontFamily:isSmallScreen ? "MarkOT-Bold" : "MarkOT",
                  display: isSmallScreen ? 'block' : 'none',
                  marginLeft: '8px', // Adds space to the left of the span
                  marginRight: '8px', // Adds space to the right of the span
                }}
              >
                info@3rrecycler.com
              </span>

            </Typography>

            <Typography sx={{ fontFamily: { md: "MarkOT" }, fontSize: { md: '16px', xs: '14px' }, color: '#050036', display: isSmallScreen ? 'none' : 'block' }} >

              1800-3000-8198 or 1800-102-5018 <span style={{ color: "#949494", fontFamily: 'MarkOT' }}>|</span>  Email - info@3rrecycler.com

            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>


      <div style={{ height: "50px" }}></div>
    </div>
  );
}

export default EWaste;
