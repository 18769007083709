import React from "react";
import {
  Box, Typography, Grid, Button, useMediaQuery, List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from '@mui/system';
import hundreddays from '../../images/100Days/hundreddays.png';
import proof from '../../images/100Days/vuesax/twotone/receipt-text.png';
import headset from '../../images/100Days/twotone/repair.png';
import repair from '../../images/100Days/two/receipt-text.png';
import landing from "../../images/100Days/100days.png";
import assurance from "../../images/100Days/assurance.png";
import subtract from "../../images/100Days/Subtract.png";
import landingSmall from "../../images/100Days/100daysresp.png";
import arrow from "../../images/100Days/arrow-right.png";
import tcbg from "../../images/otsr/tcbg.png";
import assuranceSmall from "../../images/100Days/assuranceSmall.png";
import { CustomButton } from "../../components/Buttons/CustomButton";

// const Item = styled(Box)(({ theme }) => ({   
//   textAlign: 'center',
//   padding: theme.spacing(2),
// }));
const terms = [
  "Certified defective handsets complying to warranty conditions will be swapped with similar units within 100 days of purchase.",
  "Swap units will be same or similar spec model.",
  "Cosmetic of swap units might be similar if not better than earlier unit",
  "Beyond 100days, the normal repair warranty terms would be applicable"
];


function Days() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <div >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landingSmall : landing}
          alt="Banner"
          sx={{
            width: "100%",
            height:isSmallScreen ? "100vh" : "80vh",
            display: "block",

          }}
        />
        <Box
          sx={{
            width: { md: '60%', xs: '90%' },
            position: 'absolute',
            top: '35%',
            left: { md: '10%', xs: '5%' },



          }}
        >
          <Typography
            variant="h2"
            sx={{
              transform: { xs: "translateY(-60%)", md: "translateY(-30%)" },
              color: "white",
              maxWidth: { xs: "65%", sm: "50%", md: "80%" },
              fontFamily:"MarkOT-Medium",
              fontSize: {
                xs: "32px",
                sm: "34px",
                md: "50px",
                lg: '80px',
              },
              marginBottom: { xs: 1, sm: 2, md: 1 }, // Reduce margin bottom
            }}
          >
            100 Days Service Promise
          </Typography>
          <Typography
            variant="h5"
            sx={{
              transform: "translateY(-50%)",
              maxWidth: { xs: "65%", sm: "70%", md: '92%' },
              color: "white",
              textAlign: { xs: "left", lg: "left" },
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
              },
              fontFamily: 'MarkOT',
              paddingRight: { md: "15%" },
              marginTop: { xs: "10%", md: '2%', lg: "2%", sm: "4%" }
            }}
          >
            No hassle, no stress—just seamless service with our 100-day replacement promise. Should your mobile show any defects within the first 100 days, we'll swap it out for a brand-new one, absolutely free.
          </Typography>
        </Box>
      </Box>
      {/* <div>
      {/* <Box
      sx={{
        component:'img',
        backgroundImage: `url(${isSmallScreen? landingSmall:landing})`,
        position: 'relative',
        width: '100%',
        height: { xs: '50vh', sm: '60vh', md: '70vh', lg: '80vh' },
        //backgroundImage: `url(${landing})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: { xs: '20px', sm: '40px', md: '60px', lg: '80px' },
        color: 'white'
      }}
    >
      <Box
              component="img"
              //src={isSmallScreen ? landingSmall : landing}
              //alt="landing"
              sx={{
                width: '100%',
                height:'auto',
                display: 'block',
                backgroundImage: `url(${isSmallScreen? landingSmall:landing})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            /> */}


      {/* <Typography
        variant="h1"
        sx={{
          position: 'absolute',
          width: {xs:'85%!important', sm:'75%!important',md:'80%!important', lg:'67%!important'},
          fontSize: { xs: "1.3rem!important", sm: '2rem!important', md: '3rem!important', lg: '4rem!important' },
          fontWeight: 500,
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
        }}
      >
        Don't Sweat Defects:Get Free Replacement Within 100 Days!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          position: 'absolute',
          fontSize: { xs: '0.55rem !important', sm: '1rem!important', md: '1.25rem', lg: '1.5rem' },
          marginTop: '10px',
          width: {xs:'75%!important', sm:'75%!important',md:'80%!important', lg:'45%!important'},
          textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
          fontFamily:"Mark OT ",
        }}
      >
        Experience peace of mind with our 100-day replacement promise. Should your mobile show any defects within the first 100 days, we'll swap it out for a brand-new one, absolutely free. No hassle, no stress—just seamless service. Enjoy your device with confidence!
      </Typography>
      
    </Box> 
  </div>  */}



      <Grid container spacing={4} p={"4%"}>
        <Grid item xs={12} sm={6} md={3}>

          <Box display="flex" flexDirection="column" >
            <Box
              component="img"
              src={hundreddays}
              alt="Logo"
              sx={{
                alignSelf: {
                  xs: 'center',
                  sm: 'start',
                },
                marginBottom: '10%',
                pt:"5%"
              }}
            />
            {/* <img src={hundreddays} alt="Logo" style={{ alignSelf: 'start', marginBottom: '10%' }} /> */}
            <Typography sx={{ textAlign: { xs: 'center', sm: 'start' }, fontFamily: 'MarkOT-Medium', fontSize: { xs: '20px', lg: '26px' } }}>
              100-Day Service Guarantee
            </Typography>
            <Typography sx={{ textAlign: { xs: 'center', sm: 'start' }, width: { xs: '69vw', sm: '30vw', md: '20vw', lg: '16vw' }, alignSelf: { xs: "center", sm: "start", lg: "start" }, fontSize: '16px', fontFamily: 'MarkOT' }}>
              Enjoy peace of mind with our 100-day warranty, ensuring your satisfaction within the initial period of use.
            </Typography>
          </Box>

        </Grid>
        <Grid item xs={12} sm={6} md={3}>

          <Box display="flex" flexDirection="column" alignSelf="start" >
            <Box
              component="img"
              src={proof}
              alt="Logo"
              sx={{
                alignSelf: {
                  xs: 'center',
                  sm: 'start',
                },
                marginBottom: '10%',
              }}
            />
            {/* <img src={proof} alt="Logo" style={{ alignSelf: 'start', marginBottom: '10%' }} /> */}
            <Typography sx={{ textAlign: { xs: 'center', sm: 'start' }, fontFamily: 'MarkOT-Medium', fontSize: { xs: '20px', lg: '26px' } }}>
              Model-to-Model Swaps
            </Typography>
            <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'start' }, width: { xs: '77vw', sm: '30vw', md: '21vw', lg: '16vw' }, alignSelf: { xs: "center", sm: "start", lg: "start" }, fontSize: '16px', fontFamily: 'MarkOT' }}>
              Exchange your device within our extensive range of similar models, ensuring you always find the perfect fit for your needs.
            </Typography>
          </Box>

        </Grid>
        <Grid item xs={12} sm={6} md={3}>

          <Box display="flex" flexDirection="column" >
            <Box
              component="img"
              src={headset}
              alt="Logo"
              sx={{
                alignSelf: {
                  xs: 'center',
                  sm: 'start',
                },
                marginBottom: '10%',
              }}
            />
            {/* <img src={headset} alt="Logo" style={{ alignSelf: 'start', marginBottom: '10%' }} /> */}
            <Typography sx={{ textAlign: { xs: 'center', sm: 'start' }, fontFamily: 'MarkOT-Medium', fontSize: { xs: '20px', lg: '26px' } }}>
              Headset Hassie-Free Replacement
            </Typography>
            <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'start' }, width: { xs: '78vw', sm: '33vw', md: '21vw', lg: '17vw' }, alignSelf: { xs: "center", sm: "start", lg: "start" }, fontSize: '16px', fontFamily: 'MarkOT' }}>
              Experience seamless service as defective headsets are swiftly swapped out, ensuring your listening experience remains uninterrupted.
            </Typography>
          </Box>

        </Grid>
        <Grid item xs={12} sm={6} md={3}>

          <Box display="flex" flexDirection="column" >
            <Box
              component="img"
              src={repair}
              alt="Logo"
              sx={{
                alignSelf: {
                  xs: 'center',
                  sm: 'start',
                },
                marginBottom: '10%',
              }}
            />
            {/* <img src={repair} alt="Logo" style={{ alignSelf: 'start', marginBottom: '10%' }} /> */}
            <Typography sx={{ textAlign: { xs: 'center', sm: 'start' }, fontFamily: 'MarkOT-Medium', fontSize: { xs: '20px', lg: '26px' } }}>
              Extended Repair Coverage
            </Typography>
            <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'start' }, width: { xs: '81vw', sm: '33vw', md: '21vw', lg: '17vw' }, alignSelf: { xs: "center", sm: "start", lg: "start" }, fontSize: '16px', fontFamily: 'MarkOT' }}>
              Beyond the initial 100 days, rest assured with our comprehensive repair warranty, providing continued support for your device.
            </Typography>
          </Box>

        </Grid>
      </Grid>
      <Box sx={{pt:isSmallScreen ? "10%" : "4%", pb:isSmallScreen ? "10%" : "4%"}}>
      <Box
        sx={{
          backgroundImage: `url(${tcbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "auto",
          width: "100%",
          
        }}
      >
        <Box width="80%" sx={{ textAlign: { xs: "start", sm: "center" } }}>
          <Typography
            variant="h4"
            component="h1"
            fontFamily="MarkOT-Medium"
            sx={{ fontSize: { md: '48px', xs: "26px" }, paddingTop:"8%" }}
            gutterBottom
          >
            Terms & Conditions
          </Typography>
          <List sx={{ mb: "10%", fontFamily: 'MarkOT' }}> {/* Set font family here */}
            {terms.map((term, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "list-item",
                  listStyleType: "decimal",
                  ml: { lg: 10, xs: 1 },
                  py: 0,
                }}
              >
                <ListItemText
                  primary={term}
                  sx={{ fontSize: '16px', fontFamily: 'MarkOT' }} // Inherit font family from parent
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      </Box>

      <Box sx={{
        mt: "5%",
        position: 'relative',
        marginLeft: '5%',
        marginRight: '5%',
        width: { xs: '90vw', sm: '90vw', md: '90vw', lg: '90vw' },
        height: 'auto',
        backgroundImage:isSmallScreen ? `url(${assuranceSmall})` : `url(${assurance})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '.7rem',
        color: 'white',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' },
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          zIndex: 1,
        },
        '& > *': {
          position: 'relative',
          zIndex: 2,
        },
      }}
      >
        <Box
          component="img"
          src={subtract}
          alt="Logo"
          sx={{
            width: {
              xs: '15vw',
              sm: '18vw',
              md: '12vw',
              lg: '13vw',
            },
            height: {
              xs: '13vh',
              sm: '20vh',
              md: '16vh',
              lg: '25vh',
            },
            margin: { xs: '4%', md: '4%' }
          }}
        />
        {/* < img src={subtract} alt="Logo" style={{height: {xs:'20vh!important', sm:'60vh', md:'66vh', lg: '25%!important'}}} ></img>  */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: '4%',
            width: '100%',
          }}>
          <Typography
            variant="h3"
            sx={{
              width: { xs: '73%!important', sm: '86%!important', md: '80%!important', lg: '87%!important' },
              fontSize: { xs: "24px!important", sm: '1.1rem!important', md: '3rem', lg: '36px!important' },
              fontWeight: "500",
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              marginTop: { lg: '4%' }
            }}
          >
            Unparalleled Service Guaranteed
          </Typography>
          <Typography
            variant="body1"
            sx={{
              //fontSize: { xs: '0.7rem !important', sm: '0.9rem', md: '0.9rem', lg: '0.9rem!important' },
              fontSize: { xs: "13px", sm: "12px", md: "16px", lg: "20px" },
              marginTop: { xs: '2%', md: '1%' },
              width: { xs: '76%!important', sm: '75%', md: '80%!important', lg: '60%!important' },
              //textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
              fontFamily: "MarkOT",
              color: "#C0C0C0",
            }}
          >
            With our commitment to excellence, expect unparalleled support and service throughout your journey with us.
          </Typography>
          &nbsp;
          <Button variant="contained"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              width: { xs: '32vw!important', sm: '17vw!important', lg: '12vw!important' },
              fontSize: { xs: '0.5rem', lg: '0.8rem' },
              borderRadius: '1.5rem',
              '&:hover': {
                backgroundColor: '#f0f0f0', // Slightly darker shade for hover effect
              },
            }} >
            Contact Us
            &nbsp;
            <img src={arrow} alt="arrow"></img>
          </Button>
          {/* <CustomButton
          label = "CONTACT US"
          x = "-135"
          /> */}
        </Box>
      </Box>
      <Box p={"6%"}>

      </Box>
    </div>
  );
}

export default Days;
