import { Grid, Typography ,Box, useMediaQuery,} from '@mui/material'
import React from 'react'
import image1 from "../../images/s24_checkoutProducts/s24am1.png"
import arrow from "../../images/ewaste/arrowright.png"
import image2 from "../../images/s24_checkoutProducts/s24am2.png"
import image3 from "../../images/s24_checkoutProducts/s24am3.png"
import resp1 from "../../images/s24_checkoutProducts/s24am1resp.png"
import resp2 from "../../images/s24_checkoutProducts/s24am2resp.png"
import resp3 from "../../images/s24_checkoutProducts/s24am3resp.png"
import whitefull1 from "../../images/s24_checkoutProducts/whitefull1.png"
import whitefull2 from "../../images/s24_checkoutProducts/whitefull2.png"
import whitefull3 from "../../images/s24_checkoutProducts/whitefull3.png"
import whitesmall1 from "../../images/s24_checkoutProducts/whitesmall1.png"
import whitesmall2 from "../../images/s24_checkoutProducts/whitesmall2.png"
import whitesmall3 from "../../images/s24_checkoutProducts/whitesmall3.png"
import "./CheckOutProducts.css"; 


function CheckOutProducts({bgColor, heading}) {
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const textColor = bgColor === '#fff' ? '#181818' : '#fff';
  const firstWhiteImg = isSmallScreen ? whitesmall1 : whitefull1;
  const firstBlackImg = isSmallScreen ? resp1 : image1;
  const secondWhiteImg = isSmallScreen ? whitesmall2 : whitefull2;
  const secondBlackImg = isSmallScreen ? resp2 : image2;
  const thirdWhiteImg = isSmallScreen ? whitesmall3 : whitefull3;
  const thirdBlackImg = isSmallScreen ? resp3 : image3;

  const firstImg = bgColor === '#fff' ? firstWhiteImg : firstBlackImg;
  const secondImg = bgColor === '#fff' ? secondWhiteImg : secondBlackImg;
  const thirdImg = bgColor === '#fff' ? thirdWhiteImg : thirdBlackImg;

  return (
    <div 
        style={{
            padding:"5%",
            background:bgColor,
            color: textColor,
            //border:"1px solid red",
            marginBottom: isMediumScreen ? "0" : "5%"
        }}
    >
      <Box>
        <Typography 
          variant='h4'
          marginBottom={"4%"}
          fontSize={{xs: "30px", sm: "30px",md:"33px", lg:"36px"}}
          sx={{fontFamily: "MarkOT-Medium"}}
          
          >{heading}</Typography>
        
        <Grid container sx={{
          justifyContent : 'space-between'
          
        }} >
          <Grid item xs={12} sm={5.6} md={5.5} lg={5.9} 
          //  <Box
          //   component="img"
          //   src={isSmallScreen ? resp1 : image1}
          //   alt="frstimage"
          //   sx={{
          //     width: "100%",
          //     height: "auto",
              
          //   }} />
             sx={{
                  backgroundImage: `url(${firstImg})`,
                   backgroundSize: isSmallScreen ? "cover" : "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  //height: isSmallScreen ? "200px" : "135px",
                  height: {xs: "200px", sm: "150px",md:"210px", lg:"270px"},
                  borderRadius : '1rem',
                  overflow : 'hidden',
                  //border : '1px solid red'
                  marginBottom: isSmallScreen ? "5%" : "0"
                }}
            >
              <Typography 
                
                sx={{
                  fontSize: {xs: "20px", sm: "16px",md:"22px", lg:"24px"},
                  pl:isSmallScreen ? "8%" : "6%",
                  pt:isSmallScreen ? "5%" : "3%",
                  color: textColor,
                  fontFamily: bgColor === "#fff" 
                    ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                    : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                    ? "MarkOT-Medium" : undefined,
                  
                }}
                gutterBottom
                > 
                {bgColor === "#fff" ? (
                <>
                 Smart Gadgets,Exquisite<br></br> Design 
                </>
                ) :(
                <>
                  {isSmallScreen ? (
                    <>
                      Smart Gadgets,Exquisite<br></br> Design
                    </>
                    )  : (
                      <>
                      Smart Gadgets and <br></br>Wearables, Exquisite <br></br>Design and Power
                      </>
                      )}
                </>
                )}
                </Typography>
              <Box sx={{
                  display:"flex",
                  paddingLeft:isSmallScreen ? "8%" : "6%",
                  }}>
                  {bgColor === "#fff" ? (
                    <Typography 
                    
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      //fontFamily: isSmallScreen ? "MarkOT-Medium" : "MarkPro-Medium" 
                      fontFamily: bgColor === "#fff" 
                        ? "MarkOT-Medium"
                        : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                        ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                        : undefined
                    }}
                  > Read More
                  </Typography>
                  ) :(
                  <Typography
                    fontFamily={"Mark-OT Medium"} 
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      fontFamily: isSmallScreen ? "MarkOT" : "MarkOT-Bold"
                    }}
                  > {isSmallScreen ? "BUY NOW" : "READ MORE"}
                  </Typography>
                  )}
                  <img src={arrow} alt='arrow' />
                </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3.1} lg={2.9}
            sx={{
              backgroundImage: `url(${secondImg})`,
              backgroundSize: isSmallScreen ? "cover" : "cover",
                backgroundPosition: "center center",
                backgroundRepeat:"no-repeat",
                height: {xs: "200px", sm: "150px",md:"210px", lg:"270px"},
                //height: isSmallScreen ? "200px" : "auto",
                borderRadius : '1rem',
                overflow : 'hidden',
                //border:"1px solid red"
                marginBottom: isSmallScreen ? "5%" : "0"
                }}
            >
              <Typography 
                
                gutterBottom 
                sx={{
                  fontSize: {xs: "20px", sm: "16px",md:"21px", lg:"24px"},
                  paddingLeft: "8%",
                  pt:"7%",
                  color: textColor,
                  fontFamily: bgColor === "#fff" 
                    ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                    : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                    ? "MarkOT-Medium" : undefined,
                }}
              >
                {bgColor === "#fff" ? (
                  <>
                    Smart TV, Non-Stop<br></br> Entertainment 
                  </> 
                ) : (
                  <>
                  Smart TV, Non-Stop<br></br> Entertainment
                  </>
                )}
              </Typography>
              <Box sx={{
                display:"flex",
                paddingLeft:"8%",
                }}>
                  {bgColor === "#fff" ? (
                    <Typography 
                    
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      fontFamily: bgColor === "#fff" 
                        ? "MarkOT-Medium"
                        : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                        ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                        : undefined
                    }}
                  > Read More
                  </Typography>
                  ) :(
                  <Typography 
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      fontFamily: isSmallScreen ? "MarkOT" : "MarkOT-Bold"
                    }}
                  >{isSmallScreen ? "BUY NOW" : "READ MORE"}
                  </Typography>
                  )}
                  <img src={arrow} alt='arrow' />
                </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3.1} lg={2.9}
            sx={{
              backgroundImage: `url(${thirdImg})`,
                backgroundSize: isSmallScreen ? "cover" : "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                height: {xs: "200px", sm: "150px",md:"210px", lg:"270px"},
                //height: isSmallScreen ? "200px" : "auto",
                borderRadius : '1rem',
                overflow : 'hidden',
                //border:"1px solid red"
                marginBottom: isSmallScreen ? "5%" : "0"
              }}
            >
              
              <Typography 
                gutterBottom
                sx={{
                  fontSize: {xs: "20px", sm: "15px",md:"20px", lg:"22px"},
                  //padding: "5%",
                  //pl: {xs:"10%", lg:"8%"},
                  pl: "8%",
                  pt:"7%",
                  color: textColor,
                  fontFamily: bgColor === "#fff" 
                    ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                    : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                    ? "MarkOT-Medium" : undefined,
                }}
              >  
              {bgColor === "#fff" ? (
                <>
                 Smart Phones: Sleek & <br></br> Stylish 
                </>
                ) :(
                <>
                  {isSmallScreen ? (
                    <>
                      Smart Phones: Sleek & <br></br> Stylish
                    </>
                    ) : (
                    
                    <>
                      Feature Phones-
                      <br />
                      <span
                      className='spanContent'
                        
                      >
                        Stay Connected All Day Long
                      </span>
                    </>
                  )}
                </>
                )}
              </Typography>
              
              <Box sx={{
                  display:"flex",
                  paddingLeft:"8%"
                  }}>
                  {bgColor === "#fff" ? (
                    <Typography 
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      fontFamily: bgColor === "#fff" 
                        ? "MarkOT-Medium"
                        : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113") 
                        ? (isSmallScreen ? "MarkOT-Medium" : "MarkOT-Bold") 
                        : undefined
                    }}
                  > Read More
                  </Typography>
                  ) :(
                  <Typography 
                    sx={{
                      fontSize: {xs:"12px",sm:"10px", md: "14px", lg: "16px"},
                      color: textColor,
                      fontFamily: isSmallScreen ? "MarkOT" : "MarkOT-Bold"
                    }}
                  >{isSmallScreen ? "BUY NOW" : "READ MORE"}
                  </Typography>
                  )}
                  <img src={arrow} alt='arrow' />
                </Box>
          </Grid> 

        </Grid> 
      </Box>
    </div>
  )
}

export default CheckOutProducts

