import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Chip,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import arrow from "../../../images/productlisting/arrowicon.png";
import gsap from "gsap";


const ProductCard = ({ product }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [isHovered, setIsHovered] = useState(false);
  const priceRef = useRef(null);

  useEffect(() => {
    if (isHovered) {
      // Animate the price text going down and "Buy Now" coming up
      gsap.fromTo(
        priceRef.current,
        { y: "100%", opacity: 0 },
        { y: "0%", opacity: 1, duration: 0.5 }
      );
    } else {
      // Animate the "Buy Now" going down and price coming up
      gsap.fromTo(
        priceRef.current,
        { y: "-100%", opacity: 0 },
        { y: "0%", opacity: 1, duration: 0.5 }
      );
    }
  }, [isHovered]);


  return (
    <Box >
      <Card
        sx={{
          bgcolor: "#232323",
          color: "white",
          padding: 2,
          //paddingLeft:isSmallScreen && "25px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: isSmallScreen && "323px",
          //height:isSmallScreen ? "auto" : "100%",
          textAlign: "center",
          //width:isSmallScreen && "100%",
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {product.tag && (
            <Chip
              label={product.tag}
              size="small"
              sx={{
                fontFamily: "MarkOT !important",
                mt: 1,
                fontSize: isSmallScreen ? product.tag === "HDFC SPECIAL DISCOUNT" ? "7px" : "10px" : "inherit",
                backgroundColor:
                  product.tag === "HDFC SPECIAL DISCOUNT"
                    ? "#427857"
                    // : product.tag === "New"
                    // ? "#3A3358" 
                    : "#3A3358",
                color: "#ffffff", marginBottom: 2,
              }}
            />
          )}
          <CardMedia
            component="img"
            alt={product.name}
            image={product.image}
            // sx={{ objectFit: "contain", mt: isSmallScreen ? 0 : 2, height: isSmallScreen ? "100px" : "180px", width: "auto" }} // Ensure the image is centered
            sx={{ objectFit: "contain", height: "180px", width: "auto" }}
          />
        </Box>
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontFamily: "MarkOT-Medium", fontSize: isSmallScreen && "16px" }}
          >
            {product.name}
          </Typography>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: isSmallScreen ? "8px" : "14px",
              color: "#C0C0C0",
              fontFamily: "MarkOT"
            }}
          >
            {product.description}
          </Typography>

          {product.oldPrice && (
            <Typography
              variant="body2"
              sx={{ fontFamily: "MarkOT-Medium", fontSize: isSmallScreen && "8px", mt: isSmallScreen && 1, color: "#C0C0C0", fontFamily: "MarkOT" }}
            >
              Old Price:{" "}
              <span style={{ textDecoration: "line-through" }}>
                ₹{product.oldPrice.toLocaleString()}
              </span>
            </Typography>
          )}
        </CardContent>
        <Box sx={{ textAlign: "center", marginTop: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 0 : 2, width: "100%" }}>
          {!isSmallScreen ? (
            // <Button
            //   disableElevation
            //   variant="contained"
            //   color="primary"
            //   sx={{
            //     borderRadius: "16px",
            //     textTransform: "none",
            //     fontFamily: "MarkOT-Medium",
            //     bgcolor: "#2C2C2C",
            //     width: "70%",
            //     border: "0.25px solid #4F4F4F",
            //     ":hover": {
            //       bgcolor: "#fff",
            //       color: "black",
            //     }
            //   }}
            // >
            <Button
              disableElevation
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "16px",
                textTransform: "none",
                fontFamily: "MarkOT-Medium",
                bgcolor: "#2C2C2C",
                fontSize: { md: "16px", xs: "12px", sm: "12px" },
                fontWeight: "700",
                width: "70%",
                "&:hover": {
                  bgcolor: "white",   // Background color on hover
                  color: "black",     // Text color on hover
                },
                "& span": {
                  transition: "color 0.3s ease", // Smooth color transition
                },
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {/* ₹{product.price.toLocaleString()}
              <img src={arrow} style={{ marginLeft: "10px" }}></img> */}
              <span ref={priceRef}>
                {isHovered ? "Buy Now" : `₹${product.price.toLocaleString()}`}
              </span>
              <img
                alt=""
                src={arrow}
                style={{
                  marginLeft: "10px",

                }}
              />
            </Button>
          ) : (
            <Typography>
              ₹{product.price.toLocaleString()}
              <img src={arrow} style={{ marginLeft: "10px", marginBottom: "-5px" }}></img>
            </Typography>
          )}
        </Box>
      </Card>
    </Box>
  );
};

export default ProductCard;
