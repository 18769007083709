import { CssBaseline } from "@mui/material";
import React, { Suspense, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader/Loader";
import Themes from "./components/themes/index";
import Routers from "./routes/index";
import { store } from "./state/store/index";
import { loaderContext } from "./context/index";

const App = () => {
  const [trigger, setTrigger] = useState(false);
  return (
    <div className="App full-height">
      <loaderContext.Provider value={{ trigger, setTrigger }}>
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Themes>
                <CssBaseline />
                <Routers />
              </Themes>
            </Suspense>
          </BrowserRouter>
        </Provider>
      </loaderContext.Provider>
    </div>
  );
};

export default App;


// import React from 'react'
// import './App.css'
// import Home from './components/Home';
// import { Route, Routes} from 'react-router-dom';
// import Solutions from './components/Solutions';
// import ClinicalPrograms from './components/ClinicalPrograms';
// import ClinicalProgramsDetails from './components/ClinicalProgramsDetails';
// import CareerGuidance from './components/CareerGuidance';
// import LoginInvalid from './components/Login';
// import Register from './components/Register'
// import Profile from './components/Profilesetup'
// import Dashboard from './components/Dashboard'
// import Personaldetails from './components/Personaldetails'
// import Booked from './components/Booked'
// import Subscribed from './components/Subscribed'
// import Mrcogclinical from './components/Mrcogclinical';
// import Library1 from './components/Library1';
// import Library2 from './components/Library2';
// import Library3 from './components/Library3';
// import Library4 from './components/Library4';


// function App() {
//   return (
   
//     <div>
   


//    <Routes>
//   <Route  element={<Home/>} path='/' ></Route>
//   <Route  element={<Solutions></Solutions>} path='/solutions' ></Route>
//   <Route  element={<ClinicalPrograms/>} path='/clinical-programs' ></Route>
//   <Route  element={<ClinicalProgramsDetails/>} path='/clinicalprogramsdetails' ></Route>
//   <Route  element={<CareerGuidance/>} path='/careerguidance' ></Route>
//   <Route  element={<LoginInvalid/>} path='/login' ></Route>
//   <Route  element={<Register/>} path='/register' ></Route>
//   <Route  element={<Profile/>} path='/profile'  ></Route>
//   <Route  element={<Dashboard/>} path='/dashboard' ></Route>
//   <Route  element={<Personaldetails/>} path='/personal'  ></Route>
//   <Route  element={<Booked/>} path='/booked'  ></Route>
//   <Route  element={<Subscribed/>} path='/subscribed'  ></Route>
//   <Route  element={<Mrcogclinical/>} path='/mrcog'  ></Route>
//   <Route  element={<Library1/>} path='/library1'  ></Route>
//   <Route  element={<Library2/>} path='/library2'  ></Route>
//   <Route  element={<Library3/>} path='/library3'  ></Route>
//   <Route  element={<Library4/>} path='/library4'  ></Route>
  
  
// </Routes>

//     </div>
  
 
  
//   )
// }

// export default App;