import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Grid,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
  CardMedia,
  Button,
} from "@mui/material";
import Banner from "../../components/home/banner/Banner";
import ArrowRight from "../../images/Home/arrowright.png";
import deal1 from "../../images/Home/deal1.png";
import deal2 from "../../images/Home/deal2.png";
import deal3 from "../../images/Home/deal3.png";
import deal4 from "../../images/Home/deal4.png";
import deal5 from "../../images/Home/deal5.png";
import smart1 from "../../images/Home/smart1.png";
import smart2 from "../../images/Home/smart2.png";
import design from "../../images/Home/design.png";
import last from "../../images/Home/homelastbanner.png";
import redicon from "../../images/Home/redicon.png";
import TrendingSession from "../../components/home/trending/Trending";
import FeaturePhones from "../../components/home/featurephones/FeaturePhones";
import Video from "../../components/home/videoframe/Video ";
import HomeFirst from "../../components/home/homefirstdiv/HomeFirst";
import MarqueePage from "../../components/home/MarqueePage/MarqueePage";
import Explore1 from "../../images/Home/Explore1.png";
import Explore2 from "../../images/Home/Explore2.png";
import transform from "../../images/Home/Transform.png";
import Discover from "../../images/Home/newdiscover.png";
import smallDiscover from "../../images/Home/smallDiscover.png";
import peep from "../../images/Home/peep.png";
import smallpeep from "../../images/Home/smallpeep.png";
import buttons from "../../images/Home/Buttons.png";
import nextgen from "../../images/Home/nextgen.png";
import smallnextgen from "../../images/Home/smallnextgen.png";
import HomeVideoCarousel from "../../components/home/HomeVideoCarousel/HomeVideoCarousel";
import RedIconAnimation from "../../components/Animation/RedIconAnimation";
import smalllast from "../../images/Home/smalllast.png";

const deals = [
  { image: deal1, label: "Under 5000" },
  { image: deal5, label: "Under 10000" },
  { image: deal3, label: "Deals" },
  { image: deal4, label: "Deals" },
  { image: deal2, label: "Offer" },
];
const data = [
  {
    title: "100 Days Promise",
    icon: redicon,
  },
  {
    title: "100% Free Screen Replacement",
    icon: redicon,
  },
  {
    title: "Service Centres",
    icon: redicon,
  },
];

function Home() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{ backgroundColor: "#111111" }}>
      {/* -------------------------------------------------BANNER SESSION---------------------------------------------- */}
      <Banner></Banner>
      {/* First div */}
      <Grid item xs={12} md={12} pr={"4%"} pl={"4%"} pt={"4%"}>
        <Paper
          sx={{
            backgroundColor: "#191919",
            color: "#fff",
            padding: "1.5rem",
            borderRadius: "32px",
            mt: 6,
            mb: "10%",
          }}
        >
          {/* Wrapper for horizontal scrolling */}
          <Box
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar for WebKit browsers
              "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
              "scrollbar-width": "none", // Hide scrollbar for Firefox
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
              sx={{ flexWrap: "nowrap" }} // Disable wrapping
            >
              {deals.map((deal, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={2.2}
                  key={index}
                  sx={{ display: "inline-block", textAlign: "center" }}
                >
                  <img
                    src={deal.image}
                    alt="deal"
                    style={{
                      height: "100px",
                      marginBottom: "0.5rem",
                    }}
                  />
                  <Typography sx={{ fontFamily: "MarkOT-Medium" }}>
                    {deal.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Grid>
      {/*--------------------------------------------------------SECOND DIV ---------------------------------------------------*/}
      <HomeFirst></HomeFirst>
      {/*---------------------------------------------------Trending Session---------------------------------------------------*/}
      <TrendingSession></TrendingSession>
      {/* ------------------------------------------------Vedio session -------------------------------------------*/}
      <HomeVideoCarousel />
      {/*------------------------------------------------ maquee--------------------------------------------- */}
      <Box pt={"4%"} pb={"4%"}>
        <MarqueePage />
      </Box>
      {/* --------------------------------------------------------------------Third div---------------------------- */}
      <Box sx={{ p: "7% 4% 4% 4%" }}>
        <CardMedia
          component="img"
          src={isSmallScreen ? Explore2 : Explore1}
          alt="Conditional"
          sx={{ width: "100%", height: "auto" }}
        />
      </Box>
      {/*----------------------------------------------------------------------------- fourth div----------------------- */}
      <Box
        sx={{
          color: "#fff",
          padding: "1% 0% 0% 4%",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* Left Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "start", md: "center" },
              textAlign: { xs: "start", md: "left" },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "32px",
                  lg: "36px",
                },
                fontFamily: "MarkOT-Medium",
                pr: "15%",
              }}
              gutterBottom
            >
              Transform Every Moment with itel's Family-Friendly Smart TVs!
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "16px",
                  lg: "16px",
                },
                fontFamily: "MarkOT-Medium",
                color: "#D9D9D9",
                pr: "35%",
              }}
              gutterBottom
            >
              Experience high-quality entertainment for every generation — all
              at a budget-friendly price.
            </Typography>

            {/* Button with Background Image */}
            <Button
              variant="contained"
              sx={{
                position: "relative",
                backgroundImage: `url(${buttons})`,
                backgroundSize: "100% 100%",
                width: {
                  xs: "200px",
                  sm: "210px",
                },
                height: "48px",
                color: "#fff",

                mt: "4%",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {/* Text Over Button */}
              <Typography
                variant="button"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textTransform: "none",
                  position: "relative",
                  zIndex: 2,
                  color: "black",
                }}
              >
                EXPLORE TV
              </Typography>

              {/* Arrow Icon */}
              <img
                src={ArrowRight}
                style={{
                  marginLeft: "10px",
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                }}
                alt="Arrow Right"
              />
            </Button>
          </Grid>

          {/* Right Section (Image) */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
              mt: { xs: "20px", md: 0 },
            }}
          >
            <img
              src={transform}
              alt="Family watching TV"
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* -----------------------------------------------------------------------------design image---------- */}
      <img src={design} alt="decoration" style={{ width: "100%" }}></img>
      {/*------------------------------------------------------------- sixth div----------------------------- */}
      <Box p={"4%"}>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundImage: `url(${isSmallScreen ? smallDiscover : Discover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            height: "650px",
            borderRadius: "24px",
          }}
        >
          {/* Left side with text */}
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "start", md: "center" },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "24px",
                  sm: "28px",
                  md: "32px",
                  lg: "36px",
                },
                fontFamily: "MarkOT-Medium",
                pr: { xs: "10%", md: "43%" },
                pl: { xs: "4%", md: "10%" },
              }}
              gutterBottom
            >
              Discover the Next-Gen Feature Phones with itel
            </Typography>
            <Typography
              sx={{
                fontFamily: "MarkOT",
                color: "#D9D9D9",
                fontSize: "20px",
                pr: { xs: "0%", md: "40%" },
                pl: { xs: "4%", md: "10%" },
              }}
            >
              itel's feature phones blend classic simplicity with modern style.
            </Typography>
          </Grid>

          {/* Right side with nextgen image */}

          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component="img"
              src={isSmallScreen ? smallnextgen : nextgen}
              alt="Next Gen Phone"
              sx={{
                width: { xs: "100%", sm: "6%" }, // Adjust width as needed
                height: "auto",
                overflowX: "hidden",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <FeaturePhones></FeaturePhones>
      {/* seventh div */}
      <Video></Video>
      {/* 8th div */}
      <Box
        sx={{
          color: "#fff",
          padding: "4% 0%",
          pl: { xs: "6%", md: "8%" },
        }}
      >
        <Grid container spacing={3}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: isExtraSmallScreen ? "flex-start" : "flex-start",
                  flexDirection: isExtraSmallScreen ? "column" : "column",
                  justifyContent: "flex-start",
                  textAlign: "start",
                }}
              >
                {/* Icon and Title arrangement */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isExtraSmallScreen ? "row" : "column",
                    alignItems: isExtraSmallScreen
                      ? "flex-start"
                      : "flex-start",
                    justifyContent: isExtraSmallScreen ? "center" : "center",
                  }}
                >
                  {/* Icon */}
                  <Box
                    sx={{
                      marginRight: isExtraSmallScreen ? "8px" : 0, // Space between icon and title for small screens
                    }}
                  >
                    <RedIconAnimation />
                  </Box>

                  {/* Title and Read More box */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: isExtraSmallScreen
                        ? "flex-start"
                        : "flex-start",
                    }}
                  >
                    {/* Title */}
                    <Typography
                      sx={{
                        fontFamily: "MarkOT",
                        marginBottom: isExtraSmallScreen ? 0 : 1,

                        fontSize: { xs: "16px", md: "24px" },
                      }}
                    >
                      {item.title}
                    </Typography>

                    {/* "Read More" and Arrow */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isExtraSmallScreen
                          ? "flex-start"
                          : "center",
                        mt: isExtraSmallScreen ? 1 : 2, // Add space between title and "Read More"
                        flexDirection: "row", // Keep "Read More" and arrow in a row
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#C0C0C0",
                          fontSize: "14px",
                          marginRight: "8px", // Space between text and arrow
                          fontFamily: "MarkOT",
                        }}
                      >
                        Read More
                      </Typography>
                      <img
                        src={ArrowRight}
                        alt="Arrow Right"
                        style={{
                          fontSize: "24px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* 9thdiv */}
      <Box sx={{ color: "#fff", padding: "4%" }}>
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "42px" },
            fontFamily: "MarkOT-Medium",
          }}
        >
          Peep into Our Cool Gadgets
        </Typography>
        {!isSmallScreen && (
          <CardMedia
            component="img"
            src={peep}
            alt="peep"
            sx={{ width: "100%", height: "auto" }}
          />
        )}

        <Box>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "16px",
              fontSize: "16px",
              mt: "4%",
              fontFamily: "MarkOT",
            }}
          >
            Check out our lineup of dope gadgets, from itel smartwatches to itel
            earbuds, blending
            <br />
            connectivity with style. Stay ahead of the curve with itel's smart
            gear— perfect for your vibe!
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body1"
              sx={{
                color: "#C0C0C0",
                fontSize: "16px",
                marginRight: "8px",
                fontWeight: "bold",
                fontFamily: "MarkOT",
              }}
            >
              EXPLORE
            </Typography>
            <img
              src={ArrowRight}
              alt="Arrow Right"
              style={{ width: "24px", height: "24px" }}
            />
          </Box>
          {isSmallScreen && (
            <CardMedia
              component="img"
              src={smallpeep}
              alt="peep"
              sx={{ width: "100%", height: "auto", marginTop: "16px" }}
            />
          )}
        </Box>
      </Box>
      {/*------------------------------------------------------------ smart------------------ */}
      <Box sx={{ color: "#fff", padding: "4%" }}>
        <Typography
          sx={{ fontSize: { xs: "24px", md: "42px" }, fontFamily: "MarkOT" }}
        >
          Our Smart Appliances
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                image={smart1}
                alt="Smart Appliance 1"
                sx={{ height: "auto" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                image={smart2}
                alt="Smart Appliance 2"
                sx={{ height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* last div,banner */}
      <Box pb={"4%"}>
        <img
          src={isSmallScreen ? smalllast : last}
          alt="banner"
          style={{ width: "100%" }}
        />
      </Box>
    </div>
  );
}

export default Home;
