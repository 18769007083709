import React, { useEffect, useRef } from "react";
import { Typography, AppBar, Toolbar ,useMediaQuery,} from "@mui/material";
import { Box } from "@mui/system";
//import VideoPlayer from "../vedioplayer/VedioPlayer";
import gsap from "gsap";
import marqueesmall from "../../../images/productlanding/A60smallmarquee.png";
import marquee from "../../../images/productlanding/A60marquee.png";


function Marquee({
  appBarColor,
  textColor,
  iconSrc,
  frameSrc,
  text1,
  text2,
  linearTextColor,
}) {
  const marqueeRef = useRef(null);
  const marqueeContentRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const marqueeImg = isSmallScreen ? marqueesmall : marquee;

  useEffect(() => {
    const marqueeContentWidth = marqueeContentRef.current.scrollWidth;
    const marqueeWidth = marqueeRef.current.clientWidth;

    const tl = gsap.timeline({ repeat: -1 });
    tl.fromTo(
      marqueeContentRef.current,
      { x: marqueeWidth },
      { x: -marqueeContentWidth, duration: 6000, ease: "linear" } // Adjust duration here for slower speed
    );

    return () => {
      tl.kill();
    };
  }, []);

  const repeatedTextWithIcon = (
    <>
      <img
        src={iconSrc}
        alt="Icon"
        style={{ width: "38px", marginLeft: "5px", marginRight: "5px" }}
      />
      <Typography
        variant="body1"
        component="span"
        sx={{
          marginRight: "5px",
          WebkitBackgroundClip: textColor ? "none" : "text",
          backgroundImage: textColor ? "none" : linearTextColor,
          color: textColor || "transparent",
        }}
      >
        {text1}{" "}
      </Typography>
      <img
        src={iconSrc}
        alt="Icon"
        style={{
          width: "38px",
          height: "auto",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      />
      <Typography
        variant="body1"
        component="span"
        sx={{
          marginLeft: "5px",
          marginRight: "5px",
          WebkitBackgroundClip: textColor ? "none" : "text",
          backgroundImage: textColor ? "none" : linearTextColor,
          color: textColor || "transparent",
        }}
      >
        {text2}{" "}
      </Typography>
    </>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: appBarColor || "black",
          color: textColor || "#FFCF92",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Toolbar
          ref={marqueeRef}
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <Box
            ref={marqueeContentRef}
            sx={{
              display: "inline-flex",
              whiteSpace: "nowrap",
            }}
          >
            {Array.from({ length: 1000 }).map((_, index) => (
              <React.Fragment key={index}>
                {repeatedTextWithIcon}
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      {/* {videoSrc && playIconSrc && (
        <VideoPlayer videoSrc={videoSrc} playIconSrc={playIconSrc} />
      )} */}
      <Box >
          <img
            style={{ width: "100%", display: "block", height: isSmallScreen ? "350px" : "650px" }}
            src={marqueeImg}
            alt="stylish"
          />
        </Box>

      {frameSrc && (
        <Box
          sx={{
            width: "100%",
            margin: 0,
            padding: 0,
          }}
        >
          <img
            src={frameSrc}
            alt="frame"
            style={{ display: "block", width: "100%", height:isSmallScreen ? "2.5rem" : "auto" }}
          />
        </Box>
      )}
    </>
  );
}

export default Marquee;
