import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowRight from "../../../images/productlanding/arrow.png";
import { BuyNowButton } from "../../Buttons/BuyNowButton";

const PhoneCard = ({ heading, products, mode = "light", bgcolor }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isDarkMode = mode === "dark";
  const primaryTextColor = isDarkMode ? "white" : "black";
  const secondaryTextColor = isDarkMode ? "gray" : "#666";
  const backgroundColor = isDarkMode ? "#242423" : "#F8F6F3";
  const priceTextColor = isDarkMode ? "white" : "black";

  return (
    <Box p={"4%"} sx={{ bgcolor: bgcolor }}>
      <Typography
        sx={{ color: primaryTextColor, mb: 5, mt: 4, fontSize: isSmallScreen? "30px" : "36px", fontFamily:"MarkOT-Medium" }}
      >
        {heading}
      </Typography>
      <Grid container spacing={4}>
        {products.map((product, index) => (
          <Grid item xs={6} sm={6} md={3} key={index}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: backgroundColor,
                color: primaryTextColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "16px",
                p: "4%",
                height: "100%",
              }}
            >
              <CardMedia
                component="img"
                image={product.imageUrl}
                alt={product.name}
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "contain",
                }}
              />
              <CardContent sx={{ textAlign: "center", mt: 2, flexGrow: 1, fontFamily:"MarkOT-Medium" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: primaryTextColor,
                    fontSize: { xs: "16px", md: "26px" },
                  }}
                >
                  {product.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: priceTextColor, 
                    fontFamily:"MarkOT-Bold", }}
                >
                  {product.price}{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: secondaryTextColor,
                      fontSize: "12px",
                      fontFamily:"MarkOT"
                    }}
                  >
                    {product.originalPrice}
                  </span>
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                {/* <Typography
                  variant="body1"
                  sx={{
                    fontSize: "14px",
                    marginRight: "8px",
                    color: primaryTextColor,
                    fontFamily:"MarkOT-Bold", 
                  }}
                >
                  BUY NOW
                </Typography>
                <img
                  src={ArrowRight}
                  alt="Arrow Right"
                  style={{ fontSize: "24px" }}
                /> */}
                <BuyNowButton
                hoverButtonBg = "transparent"
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PhoneCard;
