import React, { useEffect } from "react";
import { Box, useMediaQuery, Grid, Hidden, Typography } from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landingimg from "../../../images/P55T/rithikBig.png";
import landingimg2 from "../../../images/P55T/rithiksmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/P55T/avatar.png";
import landingbg3 from "../../../images/P55T/featureBgBig.png";
import icon1 from "../../../images/P55T/icon1.png";
import icon2 from "../../../images/P55T/icon2.png";
import icon3 from "../../../images/P55T/icon3.png";
import MyIcon from "../../../images/P55T/staricon.png";
import patterns from "../../../images/P55T/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import gold1 from "../../../images/P55T/productColor.png";
import gold2 from "../../../images/P55T/productColor.png";
import gold3 from "../../../images/P55T/productColor.png";
import goldSmall1 from "../../../images/P55T/productColorsmall1.png";
import goldSmall2 from "../../../images/P55T/productColorsmall1.png";
import goldSmall3 from "../../../images/P55T/productColorsmall1.png";
import greySmall1 from "../../../images/P55/productColorsmall2.png";
import greySmall2 from "../../../images/P55/productColorsmall2.png";
import greySmall3 from "../../../images/P55/productColorsmall2.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/P55T/compare1.png";
import compare2 from "../../../images/P55T/compare2.png";
import compare3 from "../../../images/P55T/compare3.png";
import landingbg from "../../../images/P55T/landingBgBigv2.png";
import landingbg2 from "../../../images/P55T/landingBgSmall.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import first from "../../../images/P55T/tech1Big.png";
import first2 from "../../../images/P55T/tech1small.png";
import second from "../../../images/P55T/tech2Big.png";
import second2 from "../../../images/P55T/tech2small.png";
import third from "../../../images/P55T/tech3Big.png";
import third2 from "../../../images/P55T/tech3small.png";
import fourth from "../../../images/P55T/tech4Big.png";
import fourth2 from "../../../images/P55T/tech4Small.png";
import fifth from "../../../images/P55T/tech5Big.png";
import fifth2 from "../../../images/P55T/tech5small.png";
import charge from "../../../images/P55T/vertical1Big.png";
import charge2 from "../../../images/P55T/vertical1small.png";
import vision from "../../../images/P55T/vertical2Big.png";
import vision2 from "../../../images/P55T/vertical2Small.png";
import ai from "../../../images/P55T/vertical3Big.png";
import ai2 from "../../../images/P55T/vertical3small.png";
import panorama from "../../../images/P55T/vertical4Big.png";
import panorama2 from "../../../images/P55T/vertical4small.png";
import processor from "../../../images/P55T/vertical5Big.png";
import processor2 from "../../../images/P55T/vertical5Small.png";
import gaming from "../../../images/P55T/vertical6Big.png";
import gaming2 from "../../../images/P55T/vertical6small.png";
import vertical7 from "../../../images/P55T/vertical7Big.png";
import vertical7Small from "../../../images/P55T/vertical7small.png";


const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.
      2. Make sure the SIM account has enough balance and the place has a good signal.
      3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      4. Try to reset factory settings, but remember to take data back up first.
      5. Visit the nearest customer service Centre for further check.`
   },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
const images = {
  gold: [gold1, gold2, gold3],
  grey: [black1, black2, black3],
  
};

const smallImages = {
  gold:[goldSmall1, goldSmall2, goldSmall3],
  grey:[greySmall1,greySmall2, greySmall3],
}

const colorNames = {
  gold: "Astral Gold",
  grey: "Astral Black",
  
};

const colorStyles = {
  gold: "linear-gradient(to left, #FFF0CB,#FFE092)",
  grey: "linear-gradient(to right, #ACB1B7,#060708)",
  
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "50MP AI Dual Rear Camera",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "50MP AI Dual Rear Camera",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "50MP AI Dual Rear Camera",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const features = [
  {
    title: "Powered by Android 14 Go Edition! ",
    description:
      "Game and stream like a pro with the latest Android 14 Go Edition. Smooth, efficient performance in a sleek package!",
    icon: icon1,
  },
  {
    title: "6000 mAh Powerhouse: Fast Charging Beast",
    description:
      "Meet the ultra-fast charging smartphone with a powerful 6000mAh battery and 18W Type-C charging. ",
    icon: icon2,
  },
  {
    title: "Dive Into the 6.6″ HD+ Punch-Hole Display ",
    description:
      "Your binge-watching sessions just got an upgrade with the sleek 6.6″ HD+ punch-hole display and a buttery smooth 90Hz refresh rate.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Your New Tech Flex Powered by Android 14 Go",

    text: "Meet the itel P55T – a game-changer! With a 6000mAh battery, rapid 18W Type-C charging, and Android 14 Go Edition, stay powered up and ahead. Enjoy a stunning 6.6-inch display, up to 8GB RAM, and 128GB storage. Capture moments with the 50MP dual rear camera and pick from three cool colours!",
  },
];


const specs = {
  generalSpecs: {
    model: "P55T",
    colors: ["Astral Black", "Astral Gold"],
  },
  display: {
    displaySize: "6.56' HD + IPS Display",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    dimension:"164*76*9.2 mm",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 14 Go",
    chipSet:"Unisco T606(12nm)",
    processorCore:"OctaCore",
  },
  camera: {
    rearCamera: "50 MP AI Dual Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "6000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"18W",
    USB: "Type-C",
    standBy: "45 Days",
    talkTime: "30 Hours",
    mediaPlaybackTime:"155 Hours"
  },
  memoryStorage: {
    ram:"8GB*(4GB + 4GB*)",
    rom:"128GB",
    userMemory:"113GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "5.0",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Type-C",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor | Electronic Compass | Soft Gyroscope | OTG",
    fingerprint:"Side",
    accessories: "18W | USB Cable | Protective Cover | Protective Film",
  },
};

const P55 = () => {
  //const linkText = "Check out the support page for more.";
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "85%",
    sm:"45%",
    md: "49%",
    lg: "35%",
  }

  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const fifthimg = isSmallScreen ? fifth2 : fifth;
  
  const chargeimg = isSmallScreen ? charge2 : charge;
  const aiimg = isSmallScreen ? ai2 : ai;
  const panoramaimg = isSmallScreen ? panorama2 : panorama;
  const processorimg = isSmallScreen ? processor2 : processor;
  const visionimg = isSmallScreen ? vision2 : vision;
  const gamingimg = isSmallScreen ? gaming2 : gaming;
  const verical7img = isSmallScreen ? vertical7Small : vertical7;

  // useEffect(() => {
  //   // Define the x value based on screen size
  //   const xValue = isSmallScreen ? "0vw" : "25vw";

  //   // Define the animations you want for different pages here
  //   gsap.fromTo(
  //     ".landing-image",
  //     { x: "100vw" },
  //     { duration: 3, x: xValue, ease: "power2.out" }
  //   );
  // }, [isSmallScreen]);

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "20vw";
    const yValue = isSmallScreen ? "5vh" : "7.2vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", {  opacity: 0, x: xValue , y: yValue });

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
      <Box sx={{ bgcolor: "#0A0C08" }}>
        {/* landing */}
        <ProductLanding
          landingImages={[landingimg, landingimg2]}
          appBarColor="#EECF7F"
          maxWidthLandingImage={maxWidthLandingImage}
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          backgroundImageFeatures={landingbg3}
          productname="P55T"
          Productnamecolor="#181818"
          buyNowButtonLabel="Buy Now"
          headmaintext="P55T"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          OverviewSpecificationcolor="#181818"
          SpecificationsORTechSpecs= "Tech Specs"
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#FFFFFF"
          linearcolor="linear-gradient(to left, #EECF7F, #D2B363)"
          backgroundImages={[landingbg, landingbg2]}
          featuresborder="none" 
          //headingColor="linear-gradient(to left, #D2B363,#EECF7F)" 
          rightsideTextColorFeatures="#FFFFFF"
          featuresubheadingcolor="#FFFFFF" 
          featurediscriptiontextcolor="#FFFFFF"
        />

        {/*  vedio and colorchange part */}

        <Marquee
          appBarColor="#001C3A"
          textColor="#ECC07D"
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/5788/d738/-fccb-4db7-8c57-7b119e59778c?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QXb1Vb6GpLhFQmG82IkV5QcJOZi4aZLFAtIqk40t6fa5cpGVzxhfbpprJ7wzI4pgX8geLBtPDBiCCBpekUJD-72lScn9Ladzs6E3ZVhw8ZlSk313vOmQTJ0TnpVvGt4FYOyOAxMOsSc39~Cn-8bm65lEgfwMAMhs17O~b-zVA5FqHNXX7SboFqdX1Dxkcm0v7CgxrrZeOnWOEcTuw~Xy-8ov4iTN9p1az8q-HRok3a~zsJlgA8fNuyVgjfgesjj~2LjcwUKvV9nmfGgwqal0tEZup~Xc9yOT-Kglyyxh3hSJ89H9JbIausdOwA4rDm35Vit4xdPGL6rm9fx49XmgdQ__"
          // playIconSrc={play}
          frameSrc={patterns}
        />

        {/* Charge */}
        <Box >
        <img  style= {{ width: "100%", display: "block"}}    src={chargeimg} alt='charge' />
        </Box>


        {/* Vision */}
        <Box sx={{padding: "6% 4% 6% 4%", backgroundColor: "#EBE8E2"}}>
        <img style= {{ width: "100%", display: "block"}} src={visionimg} alt='charge' />
        </Box>


        {/* ai */}
        <Box sx={{ backgroundColor: "#FFFFFF"}}>
        <img style= {{ width: "100%", display: "block"}} src={aiimg} alt='charge' />
        </Box>

        {/* panorama */}
        <Box sx={{ padding: "6% 4% 6% 4%", backgroundColor: "#EBE8E2"}}>
        <img style= {{ width: "100%", display: "block"}} src={panoramaimg } alt='charge' />
        </Box>

       

        {/* processor */}
        <Box>
        <img style= {{ width: "100%", display: "block"}} src={processorimg} alt='charge' />
        </Box>

        {/* {vertical6} */}
        <Box sx={{ padding: "6% 4% 6% 4%",backgroundColor: "#EBE8E2"}}>
        <img style= {{ width: "100%", display: "block"}} src={gamingimg} alt='charge' />
        </Box>

        {/* {vertical7} */}
        <Box>
        <img style= {{ width: "100%", display: "block"}} src={verical7img} alt='charge' />
        </Box>

        

        {/* TECHSPECS */}

      <Box sx={{ pl: "4%", pr: "4%", pb: "6%", pt:"6%", backgroundColor: "#181818" }}>
      {/* <Box sx={{ pt: "4%" }}>
        <Typography variant="h2" gutterBottom sx={{ color: "#181818" }}>
          Tech Specs
        </Typography>
      </Box> */}

      <Box sx={{ mt: 3, pb:"1%" }}>
        <img
          src={firstimg}
          alt="first"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "16px",
          }}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Box>
            <img
              src={secondimg}
              alt="second"
              style={{
                width: "100%",
                height: "calc(121vh - 20px)",
                borderRadius: "16px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box>
                <img
                  src={thirdimg}
                  alt="third"
                  style={{
                    width: "100%",
                    height: "calc(59vh - 10px)",
                    borderRadius: "16px",
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Box>
                    <img
                      src={fourthimg}
                      alt="fourth"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box>
                    <img
                      src={fifthimg}
                      alt="fifth"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>




      {/* <Box sx={{ pl: "4%", pr: "4%", pb: "6%", backgroundColor: "#EBE8E2"}}>
      <Box sx={{ pt: "4%" }}>
        <Typography variant="h2" gutterBottom sx={{ color: "black" }}>
          Tech Specs
        </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <img
            src={firstimg}
            alt="last"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "16px",
            }}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={secondimg}
                alt="first"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={thirdimg}
                    alt="Swift Power-Up"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={fourthimg}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
       
      </Box>    */}
      
      {/* colorchange */}
      <Box sx={{  pt: "6%", pb: "4%", backgroundColor: "#EBE8E2" }}>
      <Box sx={{pt:"5%", pl:isSmallScreen ? "0" : "5%", pr:isSmallScreen ? "0" : "5%", }}>
        <Hidden smUp>
                <Typography variant="h4" sx={{ color: "#181818", pb:"5%", pl:"2%" }}>
                  Product Version
                </Typography>
              </Hidden>
          
          
            <ProductColor
              images={images}
              smallImages={smallImages}
              colorNames={colorNames}
              colorStyles={colorStyles}
              initialColor="gold"
              colornametextcolor= "#181818"
              selectedBgColor="#ffffff"
              isNextArrowBlack = {true}
              isPrevArrowBlack = {true}
            />
          </Box>
        </Box>

      {/* Specifications */}
        <Specifications specs={specs} bgColor={"#181818"} />

        {/* Review */}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          bgColor="#0A0C08"
        />

        {/* Level Up Experience with Accessories */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          mode="dark"
          isDarkMode={true}
          bgcolor="#0A0C08"
        />

        {/* Compare Models */}
        <CompareModels 
          models={models}
          mode="dark"
          isDarkMode={true}
          bgcolor="#0A0C08"/>

        {/* Similar Products */}
        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
          bgcolor="#0A0C08"/>

        {/* Support Channels */}
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
          bgcolor="#0A0C08"/>

        {/* Check out Products */}
        <CheckOutProducts bgColor={"#0A0C08"} heading= {"Check out our other Products"} />
        
        {/* Follow us & Updated */}
        <FollowUs 
          mode="dark"
          isDarkMode={true}
          bgcolor="#0A0C08"/>

        {/* FAQs */}
        <ProductFaqs
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          mode="dark"
          bgcolor="#181818"
          accordionBackgroundColor="#181818"
        />
      </Box>
    </>
  );
};

export default P55;