
import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/productlanding/A05landingmobile1.png";
import landing2 from "../../../images/productlanding/A05landingmobile2.png";
import menuIcon from "../../../images/productlanding/A60sicon.png";
import backgroundImage from "../../../images/productlanding/A60sfeaturesbg.png";
import icon1 from "../../../images/productlanding/A05icon1.png";
import icon2 from "../../../images/productlanding/A05icon2.png";
import icon3 from "../../../images/productlanding/A05icon3.png";
import MyIcon from "../../../images/productlanding/A60smarqueicon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/productlanding/A60pattern.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/productversionA05.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/productlanding/blueproduct.png";
import blue2 from "../../../images/productlanding/blueproduct.png";
import blue3 from "../../../images/productlanding/blueproduct.png";
import green1 from "../../../images/productlanding/green.png";
import green2 from "../../../images/productlanding/green.png";
import green3 from "../../../images/productlanding/green.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import blacksmall1 from "../../../images/P55/productColorsmall2.png";
import blacksmall2 from "../../../images/P55/productColorsmall2.png";
import blacksmall3 from "../../../images/P55/productColorsmall2.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/Aura05i/compare1.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/productlanding/A05landing1.png";
import landingbg2 from "../../../images/productlanding/A05landing2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";

import TechSpecs from "../../../components/productcomponents/A05/Techspecs";
import Hd1 from "../../../images/productlanding/Experience1.png";
import Hd2 from "../../../images/productlanding/Experience2.png"
import pr from "../../../images/productlanding/Display1.png";
import pr2 from "../../../images/productlanding/Display2.png";
import camera1 from "../../../images/productlanding/speed-edited.png";
import camera2 from "../../../images/productlanding/Speed2.png";
import battery1 from "../../../images/productlanding/Lock1.png";
import battery2 from "../../../images/productlanding/Lock2.png";
import storage1 from "../../../images/productlanding/Camera1.png";
import storage2 from "../../../images/productlanding/Camera2.png";



const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
  {
    question: "How to set music as a ringtone?",
    answer:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",

  },
  {
    question: "Cannot make a call after activating data.",
    answer:"1.IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue 2.Make sure the SIM account has enough balance and the place has a good signal.3.Check whether the phone is on 4G only mode, if yes, change to other modes.4.Try to reset factory settings, but remember to take data back up first.5.Visit the nearest customer service Centre for further check."
  },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];
const images = {
  starryBlack: [black1, black2, black3],
  blue: [blue1, blue2, blue3],
  green: [green1, green2, green3],
  violet:[green1,green2,green3]
};

const smallImages={
  starryBlack: [blacksmall1, blacksmall2, blacksmall3],
  blue: [bluesmall1, bluesmall2, bluesmall3],
  green: [orangesmall1, orangesmall2, orangesmall3],
  violet: [bluesmall1, bluesmall2, bluesmall3],
}

const colorNames = {
  starryBlack: "GLORIOUS ORANGE",
  blue: "MEADOW GREEN",
  green: "NEBULA BLACK",
  violet:"CRYSTAL BLUE"
};

const colorStyles = {
  blue: "#CDE4DA",
  starryBlack: "#FDAB47",
  green: "#475266",
  violet:"#96CAE5",
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹1,269.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹1,269.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "P55 5G",
    price: "₹9,999.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "Blazing Speed with 8GB*(4GB+4GB*) RAM & 64GB ROM",
    description:"Ensure smooth multitasking and seamless app navigation with 8GB* (4GB+4GB*) RAM using memory fusion technology, combined with ample 64GB ROM.",
    icon: icon1,
  },
  {
    title: "Big Screen Brilliance: 6.6” HD+ Drop Notch Display",
    description:"Immerse yourself in every detail with vibrant colours and sharp clarity in our 6.6” HD+ drop notch display.",
    icon: icon2,
  },
  {
    title: "Turbocharge Your Day: 4000 mAh Battery & Type-C Charging",
    description:"No more battery anxiety! With lightning-fast Type-C charging & powerful 4000 mAh battery keeps you going non-stop.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Store More, Worry Less with Massive 64GB ROM",
    text:"Experience breathtaking visuals on the itel A05s smartphone with its expansive 6.6-inch HD+ Drop Notch Display, featuring a crisp 1612 x 720 pixel resolution. Stay powered all day with the robust 4000 mAh battery and Type-C charging support. Effortlessly multitask with 8GB* (4GB+4GB*) RAM and 64GB ROM. Capture stunning photos with the 5MP dual rear camera and enjoy enhanced security with dual protection for your device."
  },
];


const specs = {
  generalSpecs: {
    Model: "A05s",
    colors: "Nebula Black, Meadow Green, Crystal Blue, Glorious Orange",
  },
  display: {
    displatSize: "6.56 inch",
    resolution: "720*1612 Pixels",
    resolutionType: "HD + IPS",
    technology: "G+F, Incell",
    refreshRate : "60 Hz",
    houchSamplingRate:"120 Hz",
    pixelDensity:"270 PPI"
  },
  osProcessor: {
    operatingSystem: "Android 13 GO Edition",
    chipSet : "UNISOC SC9863A",
    processorCore: "Octa-core",
    
  },
  camera: {
    Rearcamera: "8MP",
    Rearflash: "Yes",
    frontCamera: "5MP",
    frontflash: "No",
  },
  battery: {
    batteryCapacity: "4000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"No",
    USB: "Type-C",
    standBy: "32 Days",
    talkTime: "7.5 Hrs",
    mediaPlaybackTime:"112 Hours"
  },
  memoryStorage: {
    ram:"8GB*(4GB + 4GB*)",
    rom:"64GB",
    userMemory:"53GB",
    expandable:"Upto 1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    usbConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor",
    fingerprint:"Side",
    accessories: "5W Adapter(Support 10W) | USB Cable | Protective Cover",
    thickness: "9.18 mm"
    
  },
};

const models = [
  {
    name: "A05s",
    price: "₹6,499.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const A05 = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "100%",
    sm:"55%",
    md: "65%",
    lg: "55%",
  }

  const Hd = isSmallScreen ? Hd2 : Hd1;
  const Display = isSmallScreen ? pr2 : pr;
  const Speed = isSmallScreen ? camera2 : camera1;
  const Lock = isSmallScreen ? battery2 : battery1;
  const Camera = isSmallScreen ? storage2 : storage1;
  
  
  

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "7vw" : "23vw";
    const yValue = isSmallScreen ? "-15vh" : "4vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue });

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "white" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          appBarColor="#FFFFFF;" // Remove this line to use linear color
          appBarBorder= "1px solid #D9D9D9"
          mainTexts={mainTexts}
          maxWidthLandingImage={maxWidthLandingImage}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="A05s"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor=" #181818"
          OverviewSpecificationcolor="#181818"
          SpecificationsORTechSpecs= "Specifications"
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#ffffff"
          featuresborder="none" // Added border here
          headingColor="#FF0037" // Remove this line to use linear color
          rightsideTextColorFeatures="#181818"
          featuresubheadingcolor="#181818" // Remove this line to use linear color
          featurediscriptiontextcolor="#5C5B5A"
          
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#EBE8E2"
          textColor="#6B6682" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="AWESOME SERIES"
          text2="IMPERSSIVELY AWESOME"
          //linearTextColor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          // Uncomment the below lines if you need the video and pattern image under that video
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/925644654045209335/TEAM/22af/d961/-be77-4afd-98d4-ff32030b4812?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ag2ort4w7Xe3LQ3zWC0UCpktuIMoEjftpVkqI92v4b0dQpCu5EeWqPKWjZMoZv-3o7KG0UWdToIcy6NLN6dMfkmFS47PynkTT2goQ2VCKyU6cy-OJ1bofmFhSNA5wP8x8QrRvaxy4ADgvTsp994Bv-AC~k4H-rONLsvqaY0tlYnmLm3XapBA-OaRF1PTMJP2xoZ00PJQapXcRPPtJZlFBlzeOUNC0V8mgyfdDBTAbfbrLxOmReDxMMs87INmwFz0vJpoaI9KwvytB5PFZLGKf7M~JpSGOM4ngNtV8s5BrFDzmX-Y4S24Dw-26q7VPa~YD62G4lfr6tyrJupoXg~QYQ__"
          playIconSrc={play}
          frameSrc={patterns}
        />
        {/* <Box sx={{padding:"4% 4% 4% 4%"}}>  
         
         <Box sx={{border:"1px solid #FCCE8D",margin:"3%", borderRadius:"64px"}}>
        
        <Box sx={{ padding: "1%" }}>
          <img
            style={{ width: "100%", height: "650px" ,paddingBottom:"7px",}}
            src={Display}
            alt="stylish"
          />
        </Box>
    
    <Box sx={{ padding: "1%" }}>
          <img style={{ width: "100%", height: "650px" }} src={Speed} alt="upi" />
        </Box>
        
        <Box sx={{ padding: "1%" }}>
          <img style={{ width: "100%", height: "650px" }} src={Lock} alt="upi" />
        </Box>
        
        <Box sx={{ padding: "1%" }}>
          <img style={{ width: "100%", height: "650px" }} src={Camera} alt="upi" />
        </Box>
       
        </Box>
        </Box> */}

    <div 
      style={{
        paddingLeft:"4%",
        paddingRight:"4%",
        paddingBottom:"6%",
        paddingTop:"6%",
      }}>
        <Box
          sx={{
            border: {
              xs:"none",
              sm:"1px solid #FCCE8D" 
            },
            borderRadius: "23px",
            padding:"10px",
            }} >
          <img src={Display}
              alt="first"
              style={{ 
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={Speed}
              alt="second"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
                overflow:"hidden",
              }}
          />  
          <img src={Lock}
              alt="third"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          <img src={Camera}
              alt="fourth"
              style={{
                width :"100%",
                borderRadius:"14px",
                paddingBottom:"7px",
              }}
          />  
          {/* <img src={isSmallScreen ? verticalsmall5 : vertical5}
              alt="fifth"
              style={{
                width :"100%",
                borderRadius:"14px",
              }}
          />   */}
        </Box>
    </div>

         {/* -------------------------------Hd screen------------------------------------------- */}
         <Box>
          <img
            style={{ width: "100%", display: "block", height: "auto" }}
            src={Hd}
            alt="stylish"
          />
        </Box>
        

        
        {/* ---------------------------techspecs---------------------------------------- */}
        <Box>
          <TechSpecs/>
        </Box>
         {/* //--------------------------------------------------ProductColorChange--------------------------------------// */}

        {/* make sure to change backgroundcolor of this part here  */}
        <Box sx={{ backgroundColor: "white" }}>
          
          <Box sx={{ pr: "5%", pl: "5%",pt:"5%" }}>
            <ProductColor
              images={images}
              colorNames={colorNames}
              smallImages={smallImages}
              colorStyles={colorStyles}
              colortextcolor="#181818"
              initialColor="starryBlack" //set default color as selected when load the page
              colornametextcolor="#181818"
              selectedBgColor="#ffffff"
              isNextArrowBlack={true}
              isPrevArrowBlack={true}
            />
          </Box>
        </Box>

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#fff"} />

       
        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={false}
          bgColor="white"
        />
        {/* Level Up Experience with Accessories */}
       <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={false}
          bgColor="#fff"
        />
        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        <CompareModels models={models} mode="light" bgcolor="white" />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          mode="light"
          bgcolor="white"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="light" bgcolor="white" />

        {/* Check out Products */}
        <CheckOutProducts bgColor={"#fff"} heading = "Take a quick Peep on our other cool gears!" />

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="light" bgcolor="white" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // mode="light"
          // bgcolor="white"
          // accordionBackgroundColor="white"
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
        />
      </Box>
    </>
  );
};

export default A05;

