import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/productlanding/supergurulandingmobile1.png";
import landing2 from "../../../images/productlanding/supergurulandingmobile2.png";
import menuIcon from "../../../images/productlanding/superguruicon.png";
import backgroundImage from "../../../images/productlanding/supergurufeaturesbg.png";
import icon1 from "../../../images/productlanding/supericon1.png";
import icon2 from "../../../images/productlanding/supericon2.png";
import icon3 from "../../../images/productlanding/supericon3.png";
import MyIcon from "../../../images/productlanding/supergurumarqueeicon.png";
// import play from "../../../images/productlanding/play.png";
// import patterns from "../../../images/P55/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "./productColor";
import black1 from "../../../images/productlanding/featureproductcolorbig.png";
import black2 from "../../../images/productlanding/featureproductcolorbig.png";
import black3 from "../../../images/productlanding/featureproductcolorbig.png";
import blue1 from "../../../images/productlanding/blueproduct.png";
import blue2 from "../../../images/productlanding/blueproduct.png";
import blue3 from "../../../images/productlanding/blueproduct.png";
import green1 from "../../../images/productlanding/green.png";
import green2 from "../../../images/productlanding/green.png";
import green3 from "../../../images/productlanding/green.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import blacksmall1 from "../../../images/productlanding/featuresmallblue.png";
import blacksmall2 from "../../../images/productlanding/featuresmallblue.png";
import blacksmall3 from "../../../images/productlanding/featuresmallblue.png";
import compare11 from "../../../images/Power120/compare1.png";
import compare2 from "../../../images/Power120/compare2.png";
import compare3 from "../../../images/Power120/compar3.png";
import similar1 from "../../../images/Power120/similar1.png";
import similar2 from "../../../images/Power120/similar2.png";
import similar33 from "../../../images/Power120/similar3.png";
import similar4 from "../../../images/Power120/similar4.png";
import landingbg1 from "../../../images/productlanding/supergurulanding1.png";
import landingbg2 from "../../../images/productlanding/supergurulanding2.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/productcomponents/FeaturedPhone/Specifications";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import charge from "../../../images/productlanding/stylishsuper.png";
import vision from "../../../images/productlanding/upi.png";
import ai from "../../../images/productlanding/multimedia2.png";
import panorama from "../../../images/productlanding/dive.png";
import gaming from "../../../images/productlanding/BatterySuper.png";
import charge2 from "../../../images/P55/charge2.png";
import ai2 from "../../../images/P55/ai2.png";
import panorama2 from "../../../images/P55/panorama2.png";
import vision2 from "../../../images/P55/vision2.png";
import gaming2 from "../../../images/P55/gaming2.png";
import small1 from "../../../images/productlanding/small1.png";
import small2 from "../../../images/productlanding/small2.png";
import small3 from "../../../images/productlanding/small3.png";
import small4 from "../../../images/productlanding/small4.png";
import small5 from "../../../images/productlanding/small5.png";


const productVersions = [
  {
    label: "SUPER GURU 4G",
    images: {
      blue: [black1, black2, black3],
      royalgreen: [blue1, blue2, blue3],
      black: [green1, green2, green3],
    },
    smallImages: {
      blue: [blacksmall1, blacksmall2, blacksmall3],
      royalgreen: [orangesmall1, orangesmall2, orangesmall3],
      black: [bluesmall1, bluesmall2, bluesmall3],
    },
    colors: ["blue", "royalgreen", "black"],
  },
  {
    label: "SG200",
    images: {
      red: [green1, green2, green3],
      yellow: [blue1, blue2, blue3],
      black: [black1, black2, black3],
    },
    smallImages: {
      red: [orangesmall1, orangesmall2, orangesmall3],
      yellow: [blacksmall1, blacksmall2, blacksmall3],
      black: [bluesmall1, bluesmall2, bluesmall3],
    },
    colors: ["red", "yellow", "black"],
  },
  {
    label: "SG600",
    images: {
      red: [black1, black2, black3],
      yellow: [green1, green2, green3],
      black: [blue1, blue2, blue3],
    },
    smallImages: {
      red: [bluesmall1, bluesmall2, bluesmall3],
      yellow: [orangesmall1, orangesmall2, orangesmall3],
      black: [blacksmall1, blacksmall2, blacksmall3],
    },
    colors: ["red", "yellow", "black"],
  },
  {
    label: "SG400",
    images: {
      red: [blue1, blue2, blue3],
      yellow: [black1, black2, black3],
      black: [green1, green2, green3],
    },
    smallImages: {
      red: [blacksmall1, blacksmall2, blacksmall3],
      yellow: [orangesmall1, orangesmall2, orangesmall3],
      black: [bluesmall1, bluesmall2, bluesmall3],
    },
    colors: ["red", "yellow", "black"],
  },
  // Add more versions as needed
];

const smallImages={
  blue: [blacksmall1, blacksmall2, blacksmall3],
  royalgreen: [orangesmall1, orangesmall2, orangesmall3],
  black: [bluesmall1, bluesmall2, bluesmall3],
}

const colorNames = {
  blue: "Blue",
  royalgreen: " Royal Green",
  black: "Black",
  red: "Red",
  yellow: "Yellow",
};

const colorStyles = {
  blue: "#D2C8ED",
  royalgreen: "#AFE1C1",
  black: "#3B4455",
  red: "#FF0000",
  yellow: "#FFFF00",
};

const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];
const products2 = [
  {
    name: "it5262",
    price: "₹1,269.00",

    imageUrl: similar1,
  },
  {
    name: "it5330",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar2,
  },
  {
    name: "Circle 1",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar33,
  },
  {
    name: "Super Guru",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: similar4,
  },
];

const specs = {
  generalSpecs: {
    Model: "SuperGuru",
    colors: "Black, Light Green, Blue",
  },
  display: {
    displatSize: "(5.08 cm)2",
    resolution: "128*160 pixels",
    resolutionType: "QQVGA",
    size: "(5.08 cm)2",
    pixelDensity:""
  },
  osProcessor: {
    operatingSystem: "Android 13 GO Edition",
    chipSet : "UNISOC SC9863A",
    processorCore: "Octa-core",
    
  },
  camera: {
    rearCamera: "5 MP",
    rearFlash: "Yes",
    frontCamera: "5 MP",
    FrontFlash: "No",
  },
  battery: {
    batteryCapacity: "4000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"5W",
    USB: "Type-C",
    standBy: "32 Days",
    talkTime: "7.5 Hrs",
    mediaPlaybackTime:"112 Hours"
  },
  memoryStorage: {
    ram:"4GB*(2GB + 2 GB*)",
    rom:"32GB",
    userMemory:"23GB",
    expandable:"Upto 1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3|5|8",
    EDGE: "Support Download",
    WCDMA: "3G:B1|5|8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    usbConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor",
    fingerprint:"Side",
    accessories: "5W Adapter(Support 10W) | USB Cable | Protective Cover",
    thickness: "9.18 mm"
    
  },
};

const models = [
  {
    name: "SuperGuru 4G",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "it5262",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "it5330",
    price: "₹1,269.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
  {
    question: "Cannot make a call after activating data.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "How to set SIM card 2 as default mode when sending messages.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Phone cannot display recent calls.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "When calling, both sides cannot hear each other.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];


const features = [
  {
    title: "Top-Tier 4G VoLTE Support",
    description:
      "Multiple bands guarantee rock-solid connectivity everywhere.Choose your speed: itel mobile 4g for lightning-fast browsing or 2G to save data. Stay connected wherever you are.",
    icon: icon1,
  },
  {
    title: "UPI 123 Pay",
    description:
      "Easy cash transfers: 4g feature phone with whatsapp for sending and receiving money. Make payments without internet, just scan the QR code and pay.",
    icon: icon2,
  },
  {
    title: "Sleek and Chic vibes",
    description:
      "Compact yet stylish, this design fits your vibe perfectly. Easy to carry, easier to flaunt.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Feature-Rich Phone with 4G VoLTE Connectivity",
    text: "Introducing India ka Super Guru—a collection of stylish keypad mobile phones that are the perfect blend of premium design and durability. These phones are your go-to for a busy lifestyle, featuring long-lasting battery life, a sleek metal finish, an ultra-slim design, and a trendy camera.",
  },
];


const SuperGuru = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "100%",
    sm:"55%",
    md: "65%",
    lg: "37%",
  }

  const stylish = isSmallScreen ? charge2 : charge;
  const multimedia = isSmallScreen ? ai2 : ai;
  const dive = isSmallScreen ? panorama2 : panorama;

  const upi = isSmallScreen ? vision2 : vision;
  const battery = isSmallScreen ? gaming2 : gaming;

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "30vw";
    const yValue = isSmallScreen ? "-10vh" : "0vh";

    gsap.set(".landing-image", { opacity: 0, x: xValue ,y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  return (
    <>
      {/* //make sure to change page background color here */}
      <Box sx={{ bgcolor: "white" }}>
        {/* //-------------------------------------------------Landing------------------------------------------------// */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#181818" // Remove this line to use linear color
          mainTexts={mainTexts}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          productname="SUPERGURU 4G"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "14px" : "18px"}
          Productnamecolor="white"
          OverviewSpecificationcolor="white"
          buynowbuttonbgcolor="white"
          buynowbuttoncolor="black"
          featuresborder="none" // Added border here
          headingColor="#FF0037" // Remove this line to use linear color
          rightsideTextColorFeatures="black"
          featuresubheadingcolor="black" // Remove this line to use linear color
          featurediscriptiontextcolor="#5C5B5A"
          SpecificationsORTechSpecs="Specifications"
        />
        {/* //--------------------------------------------------marquee-------------------------------------------// */}
        <Marquee
          appBarColor="#EBE8E2"
          textColor="#666666" //remove this line for adding lenar color for text
          iconSrc={MyIcon}
          text1="CALL BACK TO THE FUTURE"
        text2="SNAP BACK TO REALITY"
          linearTextColor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          // Uncomment the below lines if you need the video and pattern image under that video
          // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/5788/d738/-fccb-4db7-8c57-7b119e59778c?Expires=1722211200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QXb1Vb6GpLhFQmG82IkV5QcJOZi4aZLFAtIqk40t6fa5cpGVzxhfbpprJ7wzI4pgX8geLBtPDBiCCBpekUJD-72lScn9Ladzs6E3ZVhw8ZlSk313vOmQTJ0TnpVvGt4FYOyOAxMOsSc39~Cn-8bm65lEgfwMAMhs17O~b-zVA5FqHNXX7SboFqdX1Dxkcm0v7CgxrrZeOnWOEcTuw~Xy-8ov4iTN9p1az8q-HRok3a~zsJlgA8fNuyVgjfgesjj~2LjcwUKvV9nmfGgwqal0tEZup~Xc9yOT-Kglyyxh3hSJ89H9JbIausdOwA4rDm35Vit4xdPGL6rm9fx49XmgdQ__"
          // playIconSrc={play}
          // frameSrc={patterns}
        />

        {/* //--------------------------------------------------ProductColorChange--------------------------------------// */}

        {/* make sure to change backgroundcolor of this part here  */}
        <Box sx={{ backgroundColor: "white" }}>
          <Box sx={{ pr: "5%", pl: "5%" ,pt:"5%"}}>
            <ProductColor
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              colortextcolor="black"
              initialColor="blue" // set the initial color
              colornametextcolor="black"
              selectedBgColor="white"
              productVersions={productVersions}
            />
          </Box>
        </Box>

        {/* //--------------------------------------------------stylish image--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block", height: "650px" }}
            src={isSmallScreen ? small1 : stylish}
            alt="stylish"
          />
        </Box>

        {/* //--------------------------------------------------UPI image--------------------------------------// */}
        <Box sx={{ padding: "6% 4% 6% 4%" }}>
          <img
            style={{ width: "100%", height: "650px" }}
            src={isSmallScreen ? small2 : upi}
            alt="upi"
          />
        </Box>

        {/* //--------------------------------------------------Multimedia image--------------------------------------// */}
        <Box sx={{  backgroundColor: "#FFFFFF" }}>
          <img
            style={{ width: "100%" }}
            src={isSmallScreen ? small3 : multimedia}
            alt="multimedia"
          />
        </Box>

        {/* //--------------------------------------------------Entertainment image--------------------------------------// */}
        <Box sx={{ padding: "6% 4% 6% 4%"  }}>
          <img
            style={{ width: "100%" }}
            src={isSmallScreen ? small4 : dive}
            alt="dive"
          />
        </Box>

        {/* //--------------------------------------------------Battery image--------------------------------------// */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={isSmallScreen ? small5 : battery}
            alt="charge"
          />
        </Box>

        {/* //--------------------------------------------------Specifications--------------------------------------// */}

        {/* change this bgcolor #fff to change all colors */}
        <Specifications specs={specs} bgColor={"#fff"} />

        {/* //--------------------------------------------------Reviews--------------------------------------// */}

        {/* change this darkmode to true for darkmode also change bgcolor*/}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={false}
          bgColor="white"
        />
        {/* //---------------------------------------------Compare Models and store,sales images ------------------------// */}

        {/* change this mode="light" to mode="dark" for darkmode that changes all colors to dark ,also change bgoclor*/}
        {/* Compare Models */}
        <CompareModels
          mode="light"
          isDarkMode={true}
          models={models}
          isTwoMapView={true}
        />

        {/* //---------------------------------------------Similar Products------------------------// */}
        <PhoneCard
          heading="Similar Products"
          products={products2}
          mode="light"
          bgcolor="white"
        />

        {/* //---------------------------------------------Support Channels----------------------------------------// */}
        <SupportChannels mode="light" bgcolor="white" />

        {/* Check out Products */}
        <CheckOutProducts bgColor={"#fff"} heading ="Take a quick Peep on our other cool gears!"/>

        {/* //---------------------------------------------FollowUS-----------------------------------------------// */}
        <FollowUs mode="light" bgcolor="white" />

        {/* //---------------------------------------------FAQs-----------------------------------------------// */}
        <ProductFaqs
          mode="light"
          bgcolor="#ffffff"
          accordionBackgroundColor="#ffffff"
          selectedTabBgColor="#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
        />
      </Box>
    </>
  );
};

export default SuperGuru;
