import React from "react";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import heartIcon from "../../../images/Home/marquee1.png"; // Update path
import starIcon from "../../../images/Home/marquee2.png"; // Update path

const words = [
  "BOLD",
  "STYLE",
  "POTENTIAL",
  "ACTIVE",
  "FUNKY",
  "ENERGETIC",
  "PLAYFUL",
];

const MarqueeLine = ({ content, speed }) => (
  <Marquee speed={speed} gradient={false} direction="right">
    <Box display="flex" alignItems="center" sx={{ margin: 0, padding: 0 }}>
      {content.map((item, index) => (
        <React.Fragment key={index}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "35px",
              fontWeight: "bold",
              display: "inline-block",
              margin: "0 10px",
              whiteSpace: "nowrap",
            }}
          >
            {item}
          </Typography>
          {index % 2 === 0 ? (
            <Box
              component="img"
              src={heartIcon}
              alt="Heart Icon"
              sx={{
                width: "64px",
                height: "64px",
                objectFit: "contain",
                margin: "0 10px",
                display: "inline-flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            />
          ) : (
            <Box
              component="img"
              src={starIcon}
              alt="Star Icon"
              sx={{
                width: "64px",
                height: "64px",
                objectFit: "contain",
                margin: "0 10px",
                display: "inline-flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  </Marquee>
);

const DottedLine = () => (
  <Marquee speed={30} gradient={false} direction="right">
    <Typography
      variant="h6"
      sx={{
        color: "#FF99AF",
        margin: 0,
        padding: 0,
        display: "inline",
        lineHeight: 1, // Adjust line height if necessary
      }}
    >
      {"--------------------------------------------------------------".repeat(
        100
      )}
    </Typography>
  </Marquee>
);

const MarqueePage = () => {
  return (
    <Box
      sx={{
        transform: "rotate(4deg)",
        transformOrigin: "top left",
        width: "100%",

        overflow: "hidden",
        position: "relative",
        left: "0%",
        top: "0%",
        backgroundColor: "#111111",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
      }}
    >
      <Box sx={{ width: "100%", margin: 0, padding: 0 }}>
        <MarqueeLine content={words} speed={50} />
      </Box>
      <Box sx={{ width: "100%", margin: 0, padding: 0 }}>
        <DottedLine />
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: 0,
          padding: 0,
          display: "flex",
          justifyContent: "flex-start",
          overflow: "hidden",
          lineHeight: 1, // Adjust line height if necessary
        }}
      >
        <Marquee speed={50} gradient={false} direction="left">
          <Typography
            sx={{
              color: "#FF003A",
              fontSize: "180px",
              fontWeight: "bold",
              display: "inline-block",
              margin: 0,
              whiteSpace: "nowrap",
              padding: 0,
              lineHeight: 1, // Adjust line height if necessary
              overflow: "hidden", // Hide overflow to remove scrollbar
            }}
          >
            DARE TO DO IT
          </Typography>
        </Marquee>
      </Box>
      <Box sx={{ width: "100%", margin: 0, padding: 0 }}>
        <DottedLine />
      </Box>
      <Box sx={{ width: "100%", margin: 0, padding: 0 }}>
        <MarqueeLine content={words} speed={50} />
      </Box>
    </Box>
  );
};

export default MarqueePage;
