import React, { useEffect , useRef} from "react";
import gsap from "gsap";
import {Grid, Box, Typography , useMediaQuery } from "@mui/material";
// import TextImageCarousel from "../../../components/productcomponents/textimagecarousel/TextImageCarousel";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import landing1 from "../../../images/S23/landingimg.png";
import landing2 from "../../../images/S23/landingimg2.png";
import landingbg1 from "../../../images/S23/landing1.png";
import landingbg2 from "../../../images/S23/landing2.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/productlanding/appbaricon.png";
import backgroundImage from "../../../images/productlanding/frame.png";
import MyIcon from "../../../images/S23/staricon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/S23/Patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import icon1 from "../../../images/S23/icon1.png";
import icon2 from "../../../images/S23/icon2.png";
import icon3 from "../../../images/S23/icon3.png";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/S23/carouselPhone.png";
import blue2 from "../../../images/S23/carouselPhone.png";
import blue3 from "../../../images/S23/carouselPhone.png";
import bluesmall1 from "../../../images/S23/phone2.png";
import bluesmall2 from "../../../images/S23/phone2.png";
import bluesmall3 from "../../../images/S23/phone2.png";
import blacksmall1 from "../../../images/s24_v2/black2.png";
import blacksmall2 from "../../../images/s24_v2/black2.png";
import blacksmall3 from "../../../images/s24_v2/black2.png";
import techFirst from "../../../images/S23/techspec1.png";
import techSecond from "../../../images/S23/techspec2.png";
import techThird from "../../../images/S23/techspec3.png";
import techFourth from "../../../images/S23/techspec4.png";
import techFifth from "../../../images/S23/techspec5.png";
import smallFirst from "../../../images/S23/techsmall1.png";
import smallSecond from "../../../images/S23/techsmall2.png";
import smallThird from "../../../images/S23/techsmall3.png";
import smallFourth from "../../../images/S23/techsmall4.png";
import smallFifth from "../../../images/S23/techsmall5.png";
import camlenz from "../../../images/S23/camLenz.png";
import smallCamlenz from "../../../images/S23/camlenzsmall.png";
import elegeant from "../../../images/S23/elegeant.png";
import smallElegeant from "../../../images/S23/elegeantsmall.png";
import HDview from "../../../images/S23/HDview.png";
import smallHDview from "../../../images/S23/HDviewsmall.png";
import memory from "../../../images/S23/memory.png";
import smallMemory from "../../../images/S23/memorysmall.png";
import PhoneColorChange from "../../../images/S23/phoneColorChange.png";
import smallPhoneColorChange from "../../../images/S23/phonecolorchangesmall.png";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import ProductReview from "../../../components/ProductReview/ProductReview";
import Specifications from "../../../components/Specification/Specification";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import nightmode from "../../../images/S23/nightmode.png";
import tajmahal from "../../../images/S23/tajmahals.png";
import flower from "../../../images/S23/flower.png";
// import mobileImage1 from "../../../images/S23/rithik.png";
// import mobileImage2 from "../../../images/S23/phonecolorchangesmall.png";
// import backgroundImage1 from "../../../images/S23/phoneColorChange.png"

const landingTexts = [
  {
    text1: "108MP AI CAMERA | 16GB* RAM | 128GB ROM | HELIO G91 PROCESSOR",
  },
  {
    text2: "Starting from ₹10,999/-",
  },
];

const features = [
  {
    title: "Snap Magic: itel S23's 50MP Wonder!",
    description:
      "Say cheese and let the memories unfold in vibrant colours and sharpness with the itel S23's 50MP AI dual rear camera.",
    icon: icon1,
  },
  {
    title: "Boost Performance: 16GB* (8GB + 8GB*) RAM with Memory Fusion",
    description:
      "Enjoy faster app performance and smoother operations for all your needs powered by Memory Fusion Technology. ",
    icon: icon2,
  },
  {
    title: "Magic Hue: Colour Change Tech",
    description:
      "Witness the magic of colour changing seamlessly in sunlight with photochromic technology for a unique visual delight.",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Flaunt your Style: Photochromic Colour Change Technology",
    text: "Meet the itel S23 - Capture every moment with its 50MP AI Dual Rear Camera and enjoy seamless multitasking with 8GB RAM, expandable up to 16GB* with Memory Fusion Technology. Flaunt the colour-changing back panel and stay entertained with the 6.6' HD+ Display for an immersive experience. With a 5000 mAh battery and Type-C charger, this slim, sleek phone has it all. Discover the ultimate blend of style and performance with itel S23."
  },
];

const images = {
  black: [black1, black2, black3],
  white: [blue1, blue2, blue3],
};

const smallImages={
  white: [bluesmall1, bluesmall2, bluesmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  
}

const colorNames = {  
  black: "lake cyan",
  white: "Mystery white",
};

const colorStyles = {
  black: "#000000",
  white: "#E5E5E5",
};

const specs = {
  generalSpecs: {
    model: "S23",
    colors: ["Starry Black", "Mystery White"],
    touchType: "In Display"
  },
  display: {
    displaySize: "6.6 HD+ IPS WD",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    dimension:"164*76*8 mm",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisoc T606(12nm)",
    processorCore:"Octa Core",
  },
  camera: {
    rearCamera: "50M+QVGA",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"10W",
    USB: "Type-C",
    standBy: "46 Days",
    talkTime: "31 Hours",
    mediaPlaybackTime:"Audio: 187H, Video: 24H"
  },
  memoryStorage: {
    ram:"16GB*(8GB + 8GB)/8GB*()4GB+4GB)",
    rom:"128GB",
    userMemory:"118GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "900/1800 MHZ",
    EDGE: "Support Download",
    WCDMA: "900/1800/2100 MHZ",
    LTE: "TDD-2300 MHZ(B40)/2500 MHZ(B41), 2600 MHZ(B38), FDD-2100 MHZ(B1)/1800 MHZ(B3)/850 MHZ(B5)/900 MHZ(B8)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Type C",
  },
  additional: {
    earphoneJack: "3.5 mm",
    thickness:"8.4mm",
    sensors: "Fingerprint | Face Unlock | G Sensor  ",
    fingerprint:"Side",
    accessories: "10W Adapter| USB Cable | Protective Cover ",
  },
};

const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹8,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const callFaqs = [
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.
      2. Make sure the SIM account has enough balance and the place has a good signal.
      3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      4. Try to reset factory settings, but remember to take data back up first.
      5. Visit the nearest customer service Centre for further check.`
   },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];

const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];


const S23 = () => {
 
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    // Define the x value based on screen size
    const xValue = isSmallScreen ? "0vw" : "22vw";
    
    // Define the y value to move the image downwards
    const yValue = isSmallScreen ? "10vh" : "4vh"; // Adjust these values as needed
    
    // Set the scale value to make the image smaller
    const scaleValue = isSmallScreen ? 0.8 : 0.9; // Adjust these values as needed
  
    // Initially set the image opacity to 0, its position, scale it down, and move it downwards
    gsap.set(".landing-image", { opacity: 0, x: xValue, y: yValue, scale: scaleValue });
  
    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);
  
  

  return (
  <>
  <Box sx={{ bgcolor: "#1C1B1B" }}>
    <ProductLanding
      landingImages={[landing1, landing2]}
      landingTexts={landingTexts}
      appBarColor="#1C1B1B"
      mainTexts={mainTexts}
      features={features}
      buyNowButtonImage={buyNowButton}
      arrowIcon={arrow}
      awesomeSeriesIcon={awesomeSeriesIcon}
      menuIcon={menuIcon}
      SpecificationsORTechSpecs= "Specifications"
      backgroundImage={backgroundImage}
      buyNowButtonLabel="Buy Now"
      headbuttontext="superseries"
      productname="S23"
      ProductnamefontSize={isSmallScreen ? "8px" : "16px"}
      Productnamecolor="white"
      linearcolor="linear-gradient(to left, #5C5C7E, #DACFF9)"
      backgroundImages={[landingbg1, landingbg2]}
      OverviewSpecificationcolor="#E8E8E8"
      buynowbuttonbgcolor="white"
      buynowbuttoncolor="black"
      featuresborder="1px solid #D9D9D9" // Added border here
      rightsideTextColorFeatures="#D9D9D9"
      featuresubheadingcolor="#FFFFFF" // Remove this line to use linear color
      featurediscriptiontextcolor="#D9D9D9"
    />

    <Marquee
      appBarColor="#120F0D"
      linearTextColor="linear-gradient(to left, #5C5C7E, #DACFF9)"
      iconSrc={MyIcon}
      text1="SUPER SERIES"
      text2="INCREDIBLY SUPER"
      videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/1579/48bd/-e4e6-485f-a709-acbe3c038e5b?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qj6vPEsR5BX0aQfzFpsxUPMbWHZ42D1yN0LIO4fvuuoFdvRQVxCPR32CJjNfTqM6Z8Tv3QObNHINIZdD4S2yV1sthRtSQ0bAwsKjob5IzXcSDhMGMJYfB87tFhtt-N5lX67ptOnLL3-SYFrokCO8HHRegQxUPdx7nvwjC086WFUHn2aqk8g~anmOBvl~5ttH5m9IuW2OPUvHm5cjc6YdI85B1~tQJmFBylVZG6pHVcHc8wYgrKNcZ25p8YHLzpBOFp-PyeMcNWkmyKHlxE91--b0M4ovzAha-cYvxQMOeFdD8834sLFtjwci6wvs2HaWxwK3Vw8ZDEW0exgBTLgTCw__"
      playIconSrc={play}
      frameSrc={patterns}
    />


    <TechSpecs
      videoSrc= "https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/5788/d738/-fccb-4db7-8c57-7b119e59778c?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Bkw4QceX4PRiT3KY6USti2Dg328LthnmRBECNqZblwhOBXLjMWcS2JEnNqmPyK4fdRhnGEEq3Y2wOVvJ5o8b9CmKlL3DBiGEg~EhMyeg7omNTtU3sWVRrdRr-byiCR3mCYTr46kXtK1WaWykIeXxpWtkoyMeib73nb7H0ndjh9UsgNRWO~fxNexH-b~9t86dU9H~H8S6oHDBVyvRGHhPSuHtZA512BYZha1m3x0k4mxOWvh3qRWukUqhSShGIKuwZc2oZlp-Jhk~RtotKfpVVWv7~JFUTBSb~te-mkMYtQ9eomGekI~e1CXQnQhTapOcSXlk-U~8zudwqd4qXlRCzw__"
      playIconSrc={play}
      headingcolor="#1C1B1B"
    />  

    {/* colorchange */}
    <ProductColor
      images={images}
      colorStyles={colorStyles}
      colorNames={colorNames}
      colortextcolor="white"
      initialColor="white"
      smallImages={smallImages}
      isNextArrowBlack={false}
      isPrevArrowBlack={false}
      selectedBgColor="#1C1B1B"
    />

    {/* Specifications */}
    <Specifications specs={specs} bgColor={"#1C1B1B"} />
        
    {/* Review */}
    <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

   {/* Level Up Experience with Accessories */}
   <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
          bgColor="#1C1B1B"
        />

   {/* Compare Models */}
   <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

     {/* Similar Products */}
     <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />
    
   {/* Support Channels */}
   <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />
    
    {/* Check out Products */}
    <CheckOutProducts 
          bgColor={"#1C1B1B"} 
          heading="Check out our other Products"
        />

      <FollowUs 
                mode="dark"
                isDarkMode={true}/>


    <ProductFaqs

    mode="dark"  
    bgColor="#1C1B1B"
    title="Frequently Asked Questions (FAQs)"
    //linkText={linkText}
    tabLabels={tabLabels}
    tabContents={tabContents}
    accordionBackgroundColor= "#1C1B1B"
    selectedTabBgColor= "#ffffff"
    selectedTabTextColor= "#000000"
    />

    </Box>  
  </>
  )
}
const TechSpecs = ({ headingcolor }) => {
  
  //const containerRef = useRef(null);
  const phoneImageRef = useRef(null);
  //const mobileRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const enterAnimation = () => {
    const phoneImage = phoneImageRef.current;
    gsap.to(phoneImage, {duration: 1, filter: 'hue-rotate(100deg)' })
  }
  const leaveAnimation = () => {
    const phoneImage = phoneImageRef.current;
    gsap.to(phoneImage, {duration: 1, filter: 'hue-rotate(0deg)'})
  }

  // const handleMouseEnter = () => {   
  //   gsap.to(mobileRef.current, {backgroundImage: `url(${mobileImage2})`, duration: 1, ease: 'power3.inOut',});
  // };

  // const handleMouseLeave = () => {
  //   gsap.to(mobileRef.current, {backgroundImage: `url(${mobileImage1})`, duration: 1, ease: 'power3.inOut',});
  // };

  useEffect(() => {
    
    // const xValue = isSmallScreen ? "0vw" : "25vw";

    // const tl = gsap.timeline();

    // // Second animation: Move .second-element from left to right
    // tl.fromTo(
    //   ".second-element",
    //   { x: "-100vw" }, // Start position
    //   { duration: 3, x: "0vw", ease: "power2.out" } // End position
    // );

    // const handleScroll = () => {
    //   const scrollPosition = window.scrollY;
    //   gsap.to(mobileRef.current, {y: scrollPosition * 0.1, ease: 'power3.out',});
    // };

    // window.addEventListener('scroll', handleScroll);
    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };

    const phoneImage = phoneImageRef.current;

    phoneImage.addEventListener("mouseenter", enterAnimation);
    phoneImage.addEventListener("mouseleave", leaveAnimation);

    return () => {
      phoneImage.removeEventListener("mouseenter", enterAnimation);
      phoneImage.removeEventListener("mouseleave", leaveAnimation);
    };
  }, [isSmallScreen]);

  
  // const carouselItems = [
  //   {
  //     image: slide1,
  //     alt: "Game",
  //     title: "Chic & Timeless",
  //     subtitle: "Dawn White and Starry Black",
  //   },
  //   {
  //     image: slide2,
  //     alt: "Game",
  //     title: "Gaze Capture",
  //     subtitle: "Elevate your portraits\neye-tracking model",
  //   },
  // ];

  return (
    <Box>

      <img
        src={isSmallScreen ?  smallCamlenz : camlenz}
        alt="camlenz"
        style={{
          width: "100%",
          display: "block",
        }}
      />

      <img
        src={isSmallScreen ?  smallHDview : HDview}
        alt="HDview"
        style={{
          width: "100%",
          display: "block",
        }}
      />
{/* 
      <TextImageCarousel 
        items={carouselItems} 
        titleTextColor= "#CA86FF"
        subTitleTextColor = "#fff" 
        subTitleFontSize= "40px"
      /> */}

<Box sx={{ backgroundColor: '#1C1B1B' }}>
  <Typography
    variant="h5"
    color="#FFFFFF"
    sx={{
      fontFamily: 'Mark OT, sans-serif',
      fontWeight: 500,
      fontSize: { xs: '32px' }, // Font size for small screens
      lineHeight: '1.2',
      marginBottom: '10px',
      display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }, // Only show on small screens
      textAlign: 'center',
      mt: "6%",
      mb: "-10%",
      mr: "7%"
    }}
  >
    Nightlife Alive: Super Night Mode Engaged!
  </Typography>

  <img
    style={{ width: '100%', display: 'block', backgroundColor: '#1C1B1B' }}
    src={nightmode}
    alt='charge'
  />
</Box>


<Box sx={{ position: 'relative', backgroundColor: '#1C1B1B', display: { xs: 'block', md: 'none' }, mt: 4 }}>
 

  <img
    style={{ width: '100%', display: 'block', backgroundColor: '#1C1B1B' }}
    src={tajmahal} // Replace with your local image import
    alt='Ultra HD Mode'
  />
</Box>

<Box sx={{ position: 'relative', backgroundColor: '#1C1B1B', display: { xs: 'block', md: 'none' }, mt: 4 }}>
 

  <img
    style={{ width: '100%', display: 'block', backgroundColor: '#1C1B1B' }}
    src={flower} // Replace with your local image import
    alt='Ultra HD Mode'
  />
</Box>




      <img
        ref={phoneImageRef}
        src={isSmallScreen ?  smallPhoneColorChange : PhoneColorChange}
        alt="PhoneColorChange"
        style={{
          width: "100%",
          display: "block",
        }}
        onMouseEnter={enterAnimation}
        onMouseLeave={leaveAnimation}

      />

    {/* <Box
      ref={containerRef}
      sx={{
        //backgroundImage: `url("backgroundImage1")`,
        backgroundImage: `url(${backgroundImage1})`,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        ref={mobileRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: '300px',
          height: '600px',
          backgroundImage: "url('mobileImage1')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          transition: 'background-image 1s ease-in-out',
        }}
      />
    </Box> */}

      <img
        src={isSmallScreen ?  smallMemory : memory}
        alt="memory"
        style={{
          width: "100%",
          display: "block",
        }}
      />

      <img
        src={isSmallScreen ?  smallElegeant : elegeant}
        alt="elegeant"
        style={{
          width: "100%",
          display: "block",
        }}
      />

      <Box  sx={{ flexGrow: 1, pl: "4%", pr: "4%", pb: "4%" }}>
        <Typography variant="h2" gutterBottom sx={{ color: headingcolor }}>
          Tech Specs
        </Typography>

        <Grid container spacing={2} >
          <Grid item xs={12} md={5} spacing={2} >
            <Box>
              <img
                //ref={firstImgRef}
                src= {isSmallScreen ?  smallFirst : techFirst}
                alt="first"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                  // transform: "translateX(-100%)",
                  // opacity: 0,
                }}
              />
            </Box>
            <Box sx={{ paddingTop:"12px"}}>
              <img
                //ref={secondImgRef}
                src={isSmallScreen ?  smallFourth : techFourth}
                alt="fourth"
                style={{
                  width: "100%",
                  height: "calc(59vh - 10px)",
                  borderRadius: "16px",
                  // transform: "translateY(-100%)",
                  // opacity: 0,
                }}
              />
           </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    //ref={secondImgRef}
                    src={isSmallScreen ?  smallSecond : techSecond}
                    alt="Second"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                      // transform: "translateY(-100%)",
                      // opacity: 0,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    //ref={thirdImgRef}
                    src={isSmallScreen ?  smallThird : techThird}
                    alt="Third"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                      // transform: "translateX(-100%)",
                      // opacity: 0,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    //ref={thirdImgRef}
                    src={isSmallScreen ?  smallFifth : techFifth}
                    alt="Fifth"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                      // transform: "translateX(-100%)",
                      // opacity: 0,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
  
    </Box>
  );
};

export default S23;
