import React from "react";
import {
  Box,
  IconButton,
  Paper,
  Grid,
  MenuItem,
  AppBar,
  Toolbar,
} from "@mui/material";
import { RiSearchLine } from "react-icons/ri";
import { CiMenuBurger } from "react-icons/ci";
import logo from "../../images/NavFooter/logo.png";
import modal1 from "../../images/NavFooter/modal1.png";
import search from "../../images/NavFooter/searchicon.png";
import menu from "../../images/NavFooter/menu.png";
import { useNavigate } from "react-router-dom";

const SmallScreenNavbar = ({
  isSmallScreen,
  anchorEl,
  handleMenuClick,
  handleMenuClose,
  handleToggleSection,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#1C1B1B", height: "64px" }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt="Logo"
              style={{ height: "46px", width: "71px", cursor: "pointer" }}
            />
          </Box>
          <img src={search} style={{ fontSize: "24px", marginRight: "16px" }} />
          <IconButton color="secondary" onClick={handleMenuClick}>
            <img src={menu} style={{ fontSize: "24px" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {anchorEl && (
        <Paper
          sx={{
            bgcolor: "#1F1F1F",
            color: "white",
            zIndex: 999,
            width: "100%",
            position: "absolute",
            top: "64px", // Adjust based on the height of the AppBar
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <MenuItem
                onClick={() => handleToggleSection("smartphone")}
                sx={{ mt: 2, pl: 5, fontSize: "15px" }}
              >
                Smartphone
              </MenuItem>
              <MenuItem
                onClick={() => handleToggleSection("feature Phones")}
                sx={{ fontSize: "15px", pl: 5 }}
              >
                Feature Phones
              </MenuItem>
              <MenuItem
                onClick={() => handleToggleSection("serviceCenters")}
                sx={{ pl: 5, fontSize: "15px" }}
              >
                Smart Gadgets
              </MenuItem>
              <MenuItem sx={{ pl: 5, fontSize: "15px" }}>Support</MenuItem>
            </Grid>
            <Grid item>
              <img
                src={modal1}
                alt="Smartphone"
                style={{ width: 100, height: "auto", marginRight: "100px" }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default SmallScreenNavbar;
