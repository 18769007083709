import React, { useEffect, useRef } from "react";
import {
  Box,
  useMediaQuery,
  Grid,
  Card,
  CardMedia,
} from "@mui/material";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import gsap from "gsap";
import landing1 from "../../../images/P55+/rithikBig.png";
import landing2 from "../../../images/P55+/rithiksmall.png";
import arrow from "../../../images/productlanding/arrow.png";
import buyNowButton from "../../../images/productlanding/Buttons.png";
import awesomeSeriesIcon from "../../../images/productlanding/Vector.png";
import menuIcon from "../../../images/productlanding/appbaricon2.png";
import backgroundImage from "../../../images/P55+/featureBg.png";
import icon1 from "../../../images/P55+/icon1.png";
import icon2 from "../../../images/P55+/icon2.png";
import icon3 from "../../../images/P55+/icon3.png";
import MyIcon from "../../../images/P55+/greenicon.png";
import play from "../../../images/productlanding/play.png";
import patterns from "../../../images/P55+/patterns.png";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import ProductColor from "../../../components/productcomponents/productcolor/ProductColor";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import purple1 from "../../../images/P55/productColor1Big.png";
import purple2 from "../../../images/P55/productColor1Big.png";
import purple3 from "../../../images/P55/productColor1Big.png";
import green1 from "../../../images/P55+/productColorgreenbig.png";
import green2 from "../../../images/P55+/productColorgreenbig.png";
import green3 from "../../../images/P55+/productColorgreenbig.png";
import greensmall1 from "../../../images/P55+/productColorgreensmall.png";
import greensmall2 from "../../../images/P55+/productColorgreensmall.png";
import greensmall3 from "../../../images/P55+/productColorgreensmall.png";
import purplesmall1 from "../../../images/P55/productcolorsmall.png";
import purplesmall2 from "../../../images/P55/productcolorsmall.png";
import purplesmall3 from "../../../images/P55/productcolorsmall.png";
import blacksmall1 from "../../../images/P55/productcolorsmall3.png";
import blacksmall2 from "../../../images/P55/productcolorsmall3.png";
import blacksmall3 from "../../../images/P55/productcolorsmall3.png";
import similar1 from "../../../images/productlanding/similar1.png";
import similar2 from "../../../images/productlanding/similar2.png";
import similar3 from "../../../images/productlanding/similar3.png";
import similar4 from "../../../images/productlanding/similar4.png";
import level1 from "../../../images/productlanding/level1.png";
import level2 from "../../../images/productlanding/level2.png";
import level3 from "../../../images/productlanding/level3.png";
import level4 from "../../../images/productlanding/level4.png";
import compare11 from "../../../images/P55/S23+.png";
import compare2 from "../../../images/productlanding/compare2.png";
import compare3 from "../../../images/productlanding/compare3.png";
import landingbg1 from "../../../images/P55+/landingBgBig.png";
import landingbg2 from "../../../images/P55+/landingBgsmall.png";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import soha from "../../../images/Review/soha.png";
import Specifications from "../../../components/Specification/Specification";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import first from "../../../images/P55+/tech1Big.png";
import second from "../../../images/P55+/tech2Big.png";
import third from "../../../images/P55+/tech3Big.png";
import fourth from "../../../images/P55+/tech4Big.png";
import charge from "../../../images/P55+/vertical1big.png";
import boost from "../../../images/P55+/vertical2Big.png";
import gaming from "../../../images/P55+/vertical6big.png";
import processor from "../../../images/P55+/vertical3Big.png";
import charge2 from "../../../images/P55+/vertical1small.png";
import third2 from "../../../images/P55+/tech3small.png";
import first2 from "../../../images/P55+/tech1small.png";
import second2 from "../../../images/P55+/tech2small.png";
import processor2 from "../../../images/P55+/vertical3small.png";
import fourth2 from "../../../images/P55+/tech4small.png";
import animationSmall from "../../../images/P55+/vertical2small.png";
import gaming2 from "../../../images/P55+/vertical6small.png";
import chargeboost from "../../../images/P55+/chargeboost.png";
import slice1 from "../../../images/P55+/vertical4Big.png";
import slice2 from "../../../images/P55+/vertical5Big.png";
import slice3 from "../../../images/P55+/vertical4,5small.png";

const initialReviewsData = [
  {
    name: "Radha",
    date: "15th April 2024",
    rating: 4,
    title: "Value for Money",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: radha,
  },
  {
    name: "Krishna",
    date: "15th April 2024",
    rating: 4,
    title: "Best Battery Life",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: krishna,
  },
  {
    name: "Soha Ali Khan",
    date: "8th Aug 2023",
    rating: 3,
    title: "Professional Camera",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
    image: soha,
  },
];

const additionalReviewsData = [
  {
    name: "Akshay",
    date: "1st Jan 2024",
    rating: 5,
    title: "Excellent Product",
    content: "This is a new dummy review added for testing purposes.",
    image: radha,
  },
  {
    name: "Arya",
    date: "2nd Jan 2024",
    rating: 4,
    title: "Great Value",
    content: "Another dummy review added for testing purposes.",
    image: krishna,
  },
];

const callFaqs = [
    
  {
    question: "How to set music as a ringtone?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Cannot make a call after activating data.",
    answer:
    `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
    \n3. Check whether the phone is on 4G only mode, if yes, change to other modes.
    4. Try to reset factory settings, but remember to take data back up first.
    5. Visit the nearest customer service center for further check.`,
    },
  {
    question: "Cannot add new contacts to phone book or view phone book.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
  {
    question: "Copy contacts to another phone.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
  },
];

const cameraFaqs = [
  {
    question: "How to take high-quality photos.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
  {
    question: "How to switch between different camera modes.",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  },
];

const dummyFaqs = [
  {
    question: "Dummy question 1",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 2",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    question: "Dummy question 3",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const tabLabels = [
  "CALLS & CONTACTS",
  "CAMERA & PICTURE",
  "COMPILE",
  "INTERNET & NETWORK",
];
const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

const images = {
  green: [green1, green2, green3],
  black: [black1, black2, black3],
  purple: [purple1, purple2, purple3],
};

const smallImages={
  green: [greensmall1, greensmall2, greensmall3],
  black: [blacksmall1, blacksmall2, blacksmall3],
  purple: [purplesmall1, purplesmall2, purplesmall3],
}

const colorNames = {
  green: "Royal Green",
  black: "Meteor Black",
  purple: "Meteor Purple",
};

const colorStyles = {
  green: "#B3D98E",
  black: "#444444",
  purple: "#A187E6",
};
const products = [
  {
    name: "Earbuds T1pro",
    price: "₹849.00",

    imageUrl: level1,
  },
  {
    name: "Icon 2",
    price: "₹1,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level2,
  },
  {
    name: "N82",
    price: "₹999.00",
    originalPrice: "₹4,999.00",
    imageUrl: level3,
  },
  {
    name: "SmartWatch 2 Ultra",
    price: "₹2,099.00",
    originalPrice: "₹4,999.00",
    imageUrl: level4,
  },
];
const products2 = [
  {
    name: "S24",
    price: "₹10,999.00",

    imageUrl: similar1,
  },
  {
    name: "P55+",
    price: "₹9,499.00",
    originalPrice: "₹13,999.00",
    imageUrl: similar2,
  },
  {
    name: "S23",
    price: "₹8,199.00",
    originalPrice: "₹12,999.00",
    imageUrl: similar3,
  },
  {
    name: "A70",
    price: "₹6,299.00",
    originalPrice: "₹7,299.00",
    imageUrl: similar4,
  },
];

const features = [
  {
    title: "45 W Ultra Super Charging Phone",
    description:
      "Stay juiced up all day with 45W ultra-fast charging smartphone under 10K. Say goodbye to low battery anxiety!",
    icon: icon2,
  },
  {
    title: "Memory Marvel: 8GB RAM, expandable up to 16GB* with Memory Fusion",
    description:
      "Unleash multitasking prowess and store your world – from apps to shows to memories – all in one powerhouse device.",
    icon: icon1,
  },
  {
    title: "Vegan Leather Finish",
    description:
      "Flaunt your grace with the luxurious vegan leather finish, adding an extra touch of sophistication to your device!",
    icon: icon3,
  },
];

const mainTexts = [
  {
    heading: "Get 45W Super Charge Smartphone for Under 10k",

    text: "Get Ready for Power: Meet itel P55+, a smartphone under 10K with 45W Charging. It is your performance companion with 8GB RAM, expandable up to 16GB* with Memory Fusion, and 256GB ROM. Experience every detail on the 6.6' HD+ punch-hole display, complete with a smooth 90Hz refresh rate for seamless viewing. The sleek vegan leather finish exudes unparalleled elegance.",
  },
];


const specs = {
  generalSpecs: {
    model: "P55+",
    colors: ["Royal Green", "Meteor Purple" , "Meteor Black"],
  },
  display: {
    displaySize: "6.56' HD + IPS Display",
    resolution:"1612*720 Pixels",
    resolutionType:"HD + IPS",
    dimension:"164*76*8.3 mm",
    technology:"G+F, Incell",
    refreshRate:"90HZ",
    touchSamplingRate:"180HZ",
    pixelDensity:"267 PPI"
  },
  osProcessor: {
    operatingSystem:"Android 13",
    chipSet:"Unisco T606(12nm)",
    processorCore:"OctaCore",
  },
  camera: {
    rearCamera: "50 MP AI Dual Rear Camera",
    rearFlash: "Yes",
    frontCamera: "8 MP",
    FrontFlash: "Yes",
  },
  battery: {
    batteryCapacity: "5000 mAh",
    batteryType: "Li-Polymer",
    fastCharging:"45W",
    USB: "Type-C",
    standBy: "40 Days",
    talkTime: "60 Hours",
    mediaPlaybackTime:"video : 24 Hours"
  },
  memoryStorage: {
    ram:"16GB*(8GB + 8GB*)",
    rom:"256 GB",
    userMemory:"240 GB",
    expandable:"1TB",
  },
  connectivity: {
    networkType: "4G",
    GSM: "2G:B3,5,8",
    EDGE: "Support Download",
    WCDMA: "3G:B1,5,8",
    LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
    slimSlot: "Slot-1 & Solt-2 Nano",
    dualActiveOrStandyBy:"Dual Active",
    bluetoothSupport: "Yes",
    wiFi : "Yes",
    GPS : "Yes",
    USBConnectivity:"Yes",
  },
  additional: {
    earphoneJack: "3.5 mm",
    sensors: "Fingerprint | Face Unlock | G Sensor | Electronic Compass | Soft Gyroscope | OTG",
    fingerprint:"Side",
    accessories: "45W | USB Cable | Protective Cover | Protective Film",
  },
};

const models = [
  {
    name: "S23+",
    price: "₹12,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.5 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare11,
  },
  {
    name: "S24",
    price: "₹10,999.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.8 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare2,
  },
  {
    name: "A70",
    price: "₹6,799.00",
    camera: "50MP AI Dual Rear Camera",
    memory: "Up to 32+4(2+2)GB*",
    dimensions: "16.4 * 7.6 * 0.9 cm",
    processor: "Octa-core Processor",
    battery: "4000mAh",
    image: compare3,
  },
];

const sliceimg = [
  slice1,
  slice2,
  slice3,
  
];

const P55PLUS = () => {

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const maxWidthLandingImage = {
    xs: "61%",
    sm:"45%",
    md: "37%",
    lg: "37%",
  }

  const firstimg = isSmallScreen ? first2 : first;
  const secondimg = isSmallScreen ? second2 : second;
  const thirdimg = isSmallScreen ? third2 : third;
  const fourthimg = isSmallScreen ? fourth2 : fourth;
  const chargeimg = isSmallScreen ? charge2 : charge;
  const processorimg = isSmallScreen ? processor2 : processor;
  const gamingimg = isSmallScreen ? gaming2 : gaming;
  const turboimg = boost;
  const sectionRef = useRef(null);
  const firstImgRef = useRef(null);
  const secondImgRef = useRef(null);
  const thirdImgRef = useRef(null);
  const fourthImgRef = useRef(null);

  useEffect(() => {
    
    const xValue = isSmallScreen ? "0vw" : "20vw";
    const yValue = isSmallScreen ? "3vh" : "6.5vh";

    // Initially set the image opacity to 0 and its position
    gsap.set(".landing-image", { opacity: 0, x: xValue , y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 2,
      opacity: 1,
      ease: "power2.out",
    });
  
    const tl = gsap.timeline();

    tl.to(".text1", { opacity: 1, duration: 3, y: 0 })
      .to(".text2", { opacity: 1, duration: 3, y: 0 }, "-=0.5")
      .to(".text3", { opacity: 1, duration: 3, y: 0 }, "-=0.5")
      .fromTo(
        ".charging-image",
        { opacity: 0, y: "100vh", x: 0 }, 
        { opacity: 1, duration: 3, y: "50vh", x: 0, ease: "power2.out" } 
      );

    // Second animation: Move .second-element from left to right
    tl.fromTo(
      ".second-element",
      { x: "-100vw" }, // Start position
      { duration: 2, x: "0vw", ease: "power2.out" } // End position
    );

    const section = sectionRef.current;
    const firstImg = firstImgRef.current;
    const secondImg = secondImgRef.current;
    const thirdImg = thirdImgRef.current;
    const fourthImg = fourthImgRef.current;

    const enterAnimation = () => {
      gsap.to(firstImg, { x: 0, opacity: 1, duration: 1 });
      gsap.to(secondImg, { y: 0, opacity: 1, duration: 1 });
      gsap.to(thirdImg, { x: 0, opacity: 1, duration: 1 });
      gsap.to(fourthImg, { y: 0, opacity: 1, duration: 1 });
    };

    const leaveAnimation = () => {
      gsap.to(firstImg, { x: "-100%", opacity: 0, duration: 1 });
      gsap.to(secondImg, { y: "-100%", opacity: 0, duration: 1 });
      gsap.to(thirdImg, { x: "-100%", opacity: 0, duration: 1 });
      gsap.to(fourthImg, { y: "100%", opacity: 0, duration: 1 });
    };

    section.addEventListener("mouseenter", enterAnimation);
    section.addEventListener("mouseleave", leaveAnimation);

    return () => {
      section.removeEventListener("mouseenter", enterAnimation);
      section.removeEventListener("mouseleave", leaveAnimation);
    };
  }, [isSmallScreen]);

  const displayedImages = isSmallScreen ? [sliceimg[2]] : sliceimg.slice(0, 2);

  return (
    <>
      <Box sx={{ bgcolor: "#0A0C08" }}>

        {/* landing */}
        <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#AEC68F"
          mainTexts={mainTexts}
          features={features}
          buyNowButtonImage={buyNowButton}
          arrowIcon={arrow}
          awesomeSeriesIcon={awesomeSeriesIcon}
          menuIcon={menuIcon}
          backgroundImageFeatures={backgroundImage}
          buyNowButtonLabel="Buy Now"
          headmaintext="P55+"
          linearcolor="linear-gradient(to left, #3E9C6F,#ACC887)"
          backgroundImages={[landingbg1, landingbg2]}
          SpecificationsORTechSpecs= "Specifications"
          OverviewSpecificationcolor="#181818"          
          productname="P55+"
          Productnamecolor="#181818"
          ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          buynowbuttonbgcolor="#181818"
          buynowbuttoncolor="#FFFFFF"
          featuresborder="none" 
          //headingColor="#FFFFFF" 
          rightsideTextColorFeatures="#FFFFFF"
          //featuresubheadingcolor="#FFFFFF"
          featurediscriptiontextcolor="#FFFFFF"
        />

        {/*  vedio and colorchange part */}
        <Marquee
          appBarColor="#181818"
          textColor="#AEC68F"
          iconSrc={MyIcon}
          text1="POWER SERIES"
          text2="INTENSIVELY POWERFUL"
          videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/f64b/0b3b/-585c-4c30-b259-6c1938f9657d?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jw2rcvzMDWusr~lOq27TTP~Ju3185YuScEYVYQAL81s~vJHaeV0Wjh-49SuSzedOSGLIaYZZjXSuniWPtsbNlCMHs5KtMSsxw-lAsh6lZcvk2e0zdJ4E1TZQ08QJ1cG8961P99PCtOXhTb2HHNc~TV7FEljgve~7yZRVd7FpKymSjFdo8PVM4Xcz4Imb7CrvtpFGLD44uSTtYX9dJmB3B1ESsPI2sP3CiyAUSOsSbIZJ70JCeAqQNU1baHXiPdeq8XbeXuUkTv3QiqEu6y2rqL3S0z9nH7rXaz61gEBwgzKQjwGNMl8lE3M-Pwt6QYxxO-OonWYuPj~4GVpzVZHE6A__"
          playIconSrc={play}
          frameSrc={patterns}
        />

        {/* Charge */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={chargeimg}
            alt="charge"
          />
        </Box>

        <Box
          sx={{
            p: "7% 5% 7% 5%",
            backgroundColor: "#FFFFFF",
          }}
        >
        {isSmallScreen ? (
          <Box>
            <img
              style={{ width: "100%", display: "block" }}
              src={animationSmall}
              alt="charge"
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100vh",
              backgroundImage: `url(${turboimg})`,
              borderRadius:"20px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              overflow: "hidden",
              padding: isSmallScreen ? "0" : "50px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "25%",
                textAlign: "center",
                color: "white",
              }}
            >
              <Grid
                item
                xs={12}
                md={4}
                className="text1"
                sx={{
                  opacity: 0,
                  transform: "translateY(20px)",
                }}
              >
                <Box sx={{color:"#B3D98E"}}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#B3D98E",
                    color: "#181818",
                    borderRadius: "50%",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  1
                </Box>
                POWER BOOST
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="text2"
                sx={{
                  opacity: 0,
                  transform: "translateY(20px)",
                }}
              >
                <Box sx={{color:"#B3D98E"}}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#B3D98E",
                    color: "#181818",
                    borderRadius: "50%",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  2
                </Box>
                UITRA-POWER SAVING
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className="text3"
                sx={{
                  opacity: 0,
                  transform: "translateY(20px)",
                }}test
              >
                <Box sx={{color:"#B3D98E"}}>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#B3D98E",
                    color: "#181818",
                    borderRadius: "50%",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  3
                </Box>
                BATTERY SAVING SETTING
                </Box>
              </Grid>
            </Grid>
            <Box
              component="img"
              src={chargeboost}
              alt="Banner"
              className="charging-image"
              sx={{
                maxWidth: "100%",
                height: "auto",
                opacity: 0,
                position: "relative",
                overflow: "hidden",
              }}
            />
          </Box>
        )}
        </Box>


        {/* processor */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={processorimg}
            alt="charge"
          />
        </Box>

        {/* slice */}
        <Box>
          <Grid container spacing={0}>
            {displayedImages.map((image, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <Card>
                  <CardMedia
                    sx={{ display: "block" }}
                    component="img"
                    image={image}
                    alt="slice"
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

          {/* Atom storage technology */}
        <Box>
          <img
            style={{ width: "100%", display: "block" }}
            src={gamingimg}
            alt="charge"
          />
        </Box>

          {/* techspecs */}
          <Box
          ref={sectionRef}
          sx={{ pl: "4%", pr: "4%", pb: "6%",pt: "6%",  backgroundColor: "#181818" }}
        >
          {/* <Box sx={{ pt: "4%" }}>
            <Typography variant="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
              Tech Specs
            </Typography>
          </Box> */}

          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Box>
                <img
                  ref={firstImgRef}
                  src={firstimg}
                  alt="first"
                  style={{
                    width: "100%",
                    height: "calc(121vh - 20px)",
                    borderRadius: "16px",
                    transform: "translateX(-100%)",
                    opacity: 0,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <img
                      ref={secondImgRef}
                      src={secondimg}
                      alt="Swift Power-Up"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                        transform: "translateY(-100%)",
                        opacity: 0,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <img
                      ref={thirdImgRef}
                      src={thirdimg}
                      alt="Storage"
                      style={{
                        width: "100%",
                        height: "calc(59vh - 10px)",
                        borderRadius: "16px",
                        transform: "translateX(-100%)",
                        opacity: 0,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <img
              ref={fourthImgRef}
              src={fourthimg}
              alt="last"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px",
                transform: "translateY(100%)",
                opacity: 0,
              }}
            />
          </Box>
        </Box>

        {/* colorchange */}
        <Box sx={{ backgroundColor: "#131C0E" }}>
          <Box sx={{ pr: "5%", pl: "5%", pt: "7%" }}>
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              smallImages={smallImages}
              initialColor="green"
              colortextcolor= "#ffffff"
              isNextArrowBlack={true}
              isPrevArrowBlack={true}
              selectedBgColor="#181818"
            />
          </Box>
        </Box>

        {/* Specifications */}
        <Specifications specs={specs} bgColor={"#101113"} />

        {/* Review */}
        <ProductReview
          initialReviews={initialReviewsData}
          additionalReviews={additionalReviewsData}
          isDarkMode={true}
          mode="dark"
          bgColor="#0A0C08"
        />

        {/* Level Up Experience with Accessories */}
        <PhoneCard
          heading="Level Up Your Experience with Accessories!"
          products={products}
          isDarkMode={true}
          mode="dark"
        />

        {/* Compare Models */}
        <CompareModels 
          mode="dark"
          isDarkMode={true}
          models={models}
          isTwoMapView={false}
        />

        {/* Similar Products */}
        <PhoneCard 
          heading="Similar Products" 
          products={products2} 
          mode="dark"
          isDarkMode={true}
        />

        {/* Support Channels */}
        <SupportChannels 
          mode="dark"
          isDarkMode={true}
        />

        {/* Check out Products */}
        <CheckOutProducts 
          bgColor={"#0A0C08"} 
          heading= "Take a quick Peep on our other cool gears!"
        />

        {/* Follow us & Updated */}
        <FollowUs 
          mode="dark"
          isDarkMode={true}
        />

        {/* FAQs */}
        <ProductFaqs
          // backgroundColor={accordionBackgroundColor}
          // textColor={textColor}
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // accordionBackgroundColor={accordionBackgroundColor}
          // buttonTextColor={buttonTextColor}
          // selectedTabBackgroundColor={selectedTabBackgroundColor}
          // selectedTabTextColor={selectedTabTextColor}
          // borderColor={borderColor}
          mode="dark"  
          bgColor="#181818"
          title="Frequently Asked Questions (FAQs)"
          //linkText={linkText}
          tabLabels={tabLabels}
          tabContents={tabContents}
          accordionBackgroundColor= "#0A0C08"
          selectedTabBgColor= "#ffffff"
          selectedTabTextColor= "#000000"
        />
      </Box>
    </>
  );
};

export default P55PLUS;
