import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: "relative",
    width: "100%",
    height: "90vh",
    overflow: "hidden",
    margin: 0,
    padding: 0,
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  iconOverlay: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    cursor: "pointer",
  },
  playIcon: {
    width: "150px",
  },
}));

function VideoPlayer({ videoSrc, playIconSrc }) {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    const handleEnded = () => {
      video.currentTime = 0;
      video.play();
    };

    video.addEventListener("ended", handleEnded);

    return () => {
      video.removeEventListener("ended", handleEnded);
    };
  }, []);

  const togglePlay = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box className={classes.videoContainer} onClick={togglePlay}>
      <video
        ref={videoRef}
        className={classes.video}
        muted
        src={videoSrc}
        // autoPlay={false} // Do not autoplay
      />
      {!isPlaying && (
        <div className={classes.iconOverlay}>
          <img src={playIconSrc} alt="Play Icon" className={classes.playIcon} />
        </div>
      )}
    </Box>
  );
}

export default VideoPlayer;
