import React, { useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { gsap } from "gsap";
import Arrow from "../../images/customBtnArrow.svg";
import ArrowPink from "../../images/customButtonArrowPink.svg";

export const BuyNowButton = ({
  buttonBg = "transparent",
  hoverButtonBg = "#000000",
  hoverTextColor = "#ffffff",
}) => {
  const arrowRef = useRef(null);
  const normalArrowRef = useRef(null);

  // Handle hover effect with GSAP
  const handleMouseEnter = () => {
    // Animate ArrowPink to translate to the right and hide normal Arrow when finished
    gsap.to(arrowRef.current, {
      x: 3, // Move the pink arrow beside the text
      duration: 0.5,
      ease: "power2.out",
      onComplete: () => {
        gsap.to(normalArrowRef.current, {
          opacity: 0, // Hide normal arrow when pink arrow finishes moving
          duration: 0.2,
        });
      },
    });
  };

  const handleMouseLeave = () => {
    // Move ArrowPink back to its hidden position and show normal Arrow again
    gsap.to(arrowRef.current, {
      x: -285, // Move the pink arrow back to the left (hidden based on passed 'x' value)
      duration: 0.5,
      ease: "power2.out",
    });
    gsap.to(normalArrowRef.current, {
      opacity: 1, // Show normal arrow again
      delay: 0.2, // Add a slight delay to sync with pink arrow's movement
      duration: 0.2,
    });
  };

  // Ensure the pink arrow is hidden initially using GSAP's set function
  useEffect(() => {
    gsap.set(arrowRef.current, { x: -285 }); // Start pink arrow hidden based on passed 'x' value
    gsap.set(normalArrowRef.current, { opacity: 1 }); // Ensure normal arrow is visible initially
  }, []);

  const onClick = () => {};

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      sx={{
        display: "flex",
        width: "100%",
        position: "relative",
        border: "1px solid #1A1A1A",
        borderRadius: 24,
        alignItems: "center",
        padding: "10px 20px",
        cursor: "pointer",
        overflow: "hidden",
        transition: "background-color 0.3s ease", // Add a transition for smooth background color change
        backgroundColor: buttonBg, // Default background color
        "&:hover": {
          backgroundColor: hoverButtonBg, // Background color on hover
          color: hoverTextColor, // Ensure the text color is readable on hover
        },
      }}
    >
      <Typography variant="body1" sx={{ marginRight: "8px" }}>
        BUY NOW
      </Typography>
      {/* Normal arrow */}
      <img
        ref={normalArrowRef}
        src={Arrow}
        alt="normal arrow"
        style={{ position: "absolute", right: 14 }}
      />
      <img
        ref={arrowRef}
        src={ArrowPink}
        alt="pink arrow"
        style={{
          position: "relative",
          transform: `translateX(${-285}px)`, // Initially hide the pink arrow outside the left based on 'x'
          transition: "none", // Prevent any unwanted initial animations
        }}
      />
    </Box>
  );
};
