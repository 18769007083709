import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  IconButton,
  Box,
  Paper,
  useMediaQuery,
  Grid,
  Card,
} from "@mui/material";
import { IoCallOutline } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../images/NavFooter/logo.png";
import mobile1 from "../../images/NavFooter/mobile1.png";
import mobile2 from "../../images/NavFooter/mobile2.png";
import tv from "../../images/NavFooter/Tv.png";
import locationv2 from "../../images/NavFooter/locationv2.png";
import arrowImageUrl from "../../images/NavFooter/arrowImageUrl.png";
import { useTheme } from "@emotion/react";
import SmallScreenNavbar from "./SmallScreenNavbar";
import { RiSearchLine } from "react-icons/ri";

const exchangeOffers = [
  { title: "Exchange Offer", path: "/exchange-offer" },
  { title: "Screen Replacement", path: "/100dayspromise" },
  { title: "Service Centers", path: "/servicecenter" },
  { title: "E-waste Management", path: "/ewastemanagement" },
  { title: "FAQ'S", path: "/faq" },
  { title: "Contact us", path: "/contactus" },
];

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const handleMenuClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggleSection = (section) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section));
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };


  return (
    <>
      {isSmallScreen ? (
        <SmallScreenNavbar
          isSmallScreen={isSmallScreen}
          anchorEl={anchorEl}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handleToggleSection={handleToggleSection}
        />
      ) : (
        <>
          <AppBar
            elevation={0}
            position="static"
            sx={{
              backgroundColor: "#1C1B1B",
              height: "88px",
              padding: "0 2%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <img
                  onClick={() => navigate("/")}
                  src={logo}
                  alt="Logo"
                  style={{
                    height: "46px",
                    width: "71px",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "#2E2D2D",
                  "&:hover": { backgroundColor: "#2E2D2D" },
                  width: "50%",
                  maxWidth: "600px",
                  height: "50px",
                }}
              >
                <Box
                  sx={{
                    padding: "0 16px",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiSearchLine />
                </Box>
                <InputBase
                  placeholder="What are you looking for..."
                  sx={{
                    width: "100%",
                    padding: "8px 8px 8px 0",
                    paddingLeft: "calc(1em + 32px)",
                    fontSize: "16px",
                    color: "#979696",
                    fontFamily: "MarkOT",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="secondary">
                  <IoCallOutline style={{ color: "red" }} />
                </IconButton>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    onClick={() => navigate("/servicecenter")}
                    sx={{
                      fontSize: "10px",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontFamily: "MarkOT",
                    }}
                  >
                    Service Center
                  </Typography>

                  <Typography
                    onClick={handleModalOpen}
                    sx={{
                      fontSize: "10px",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontFamily: "MarkOT",
                    }}
                  >
                    1800-4190-525
                  </Typography>
                </Box>
                <IconButton color="secondary" sx={{ ml: 2 }}>
                  <img src={locationv2} alt="location" />
                </IconButton>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontFamily: "MarkOT",
                    }}
                  >
                    Nearby Stores
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      textDecoration: "none",
                      cursor: "pointer",
                      fontFamily: "MarkOT",
                    }}
                  >
                    Get Direction
                  </Typography>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          {isLargeScreen && (
            <AppBar elevation={0} position="sticky" sx={{ bgcolor: "#1F1F1F" }}>
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {[
                  "smartphone",
                  "feature Phones",
                  "smart Tv",
                  "smart Gadgets",
                  "Home Care",
                  "Personal Care",
                  "About Us",
                  "support",
                ].map((section) => (
                  <Typography
                    key={section}
                    onMouseEnter={() => setOpenSection(section)}
                    onMouseLeave={() => setOpenSection(null)}
                    onClick={() => {
                      if (section === "About Us") {
                        navigate("/aboutus"); // Navigate to the About Us page
                      }
                    }}
                    sx={{
                      fontSize: "14px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      mr: 5,
                      height: "65px",
                      fontFamily: "MarkOT",
                    }}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                    {section !== "feature Phones" &&
                      section !== "smart Gadgets" &&
                      section !== "Home Care" &&
                      section !== "Personal Care" &&
                      section !== "About Us" && (
                        <img
                          src={arrowImageUrl}
                          alt="Arrow"
                          style={{
                            fontSize: "20px",
                            transition: "transform 0.3s ease",
                            transform:
                              openSection === section
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            marginLeft: "8px", // Adjust the margin as needed
                          }}
                        />
                      )}
                  </Typography>
                ))}
              </Toolbar>
              {openSection === "smartphone" && (
                <Paper
                  onMouseEnter={() => setOpenSection("smartphone")}
                  onMouseLeave={() => setOpenSection(null)}
                  sx={{
                    backgroundColor: "#1C1B1B",
                    color: "white",
                    padding: "9rem",
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    position: "absolute",
                    top: "100%",
                    zIndex: 1,
                    height: "200px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "20px" }}>
                      A SERIES{" "}
                      <BsArrowRight style={{ verticalAlign: "middle" }} />{" "}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      P SERIES{" "}
                      <BsArrowRight
                        style={{ verticalAlign: "middle", color: "red" }}
                      />{" "}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      S SERIES{" "}
                      <BsArrowRight style={{ verticalAlign: "middle" }} />{" "}
                    </Typography>
                  </Box>

                  <Paper
                    sx={{
                      backgroundColor: "#1F1F1F",
                      color: "white",
                      padding: "25px",
                      width: "30%",
                      height: "200px",
                      borderRadius: "10px",
                      mr: 2,
                      mb: 1,
                      ml: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={mobile2}
                        alt="mobile"
                        style={{
                          marginRight: "10px",
                          width: "9rem",
                          height: "9rem",
                        }}
                      />{" "}
                      <div>
                        <Typography
                          sx={{ fontSize: "25px", fontWeight: "400" }}
                        >
                          Itel P55 +
                        </Typography>
                        <Typography sx={{ fontSize: "20px", mt: 2 }}>
                          ₹14999/-
                        </Typography>
                      </div>
                    </Box>
                  </Paper>

                  <Paper
                    sx={{
                      backgroundColor: "#1F1F1F",
                      color: "white",
                      padding: "25px",
                      width: "30%",
                      height: "200px",
                      borderRadius: "10px",
                      mr: 1,
                      mb: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={mobile1}
                        alt="mobile"
                        style={{
                          marginRight: "10px",
                          width: "9rem",
                          height: "9rem",
                        }}
                      />{" "}
                      <div>
                        <Typography sx={{ fontSize: "25px" }}>
                          Itel P55
                        </Typography>
                        <Typography sx={{ fontSize: "20px", mt: 2 }}>
                          ₹9989/-
                        </Typography>
                      </div>
                    </Box>
                  </Paper>
                </Paper>
              )}

              {openSection === "smart Tv" && (
                <Paper
                  onMouseEnter={() => setOpenSection("smart Tv")}
                  onMouseLeave={() => setOpenSection(null)}
                  sx={{
                    backgroundColor: "#1C1B1B",
                    color: "white",
                    padding: "9rem",
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    position: "absolute",
                    top: "100%",
                    zIndex: 1,
                    height: "200px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: "20px" }}>
                      G SERIES{" "}
                      <BsArrowRight style={{ verticalAlign: "middle" }} />{" "}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      L SERIES{" "}
                      <BsArrowRight
                        style={{ verticalAlign: "middle", color: "red" }}
                      />{" "}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      N SERIES{" "}
                      <BsArrowRight style={{ verticalAlign: "middle" }} />{" "}
                    </Typography>
                  </Box>

                  <Paper
                    sx={{
                      backgroundColor: "#1F1F1F",
                      color: "white",
                      padding: "25px",
                      width: "30%",
                      height: "200px",
                      borderRadius: "10px",
                      ml: 3,
                      mb: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={tv}
                        alt="TV"
                        style={{
                          marginRight: "10px",
                          width: "9rem",
                          height: "9rem",
                        }}
                      />{" "}
                      <div>
                        <Typography
                          sx={{ fontSize: "25px", fontWeight: "400" }}
                        >
                          L4365/L3625
                        </Typography>
                        <Typography sx={{ fontSize: "20px", mt: 2 }}>
                          ₹18269/-
                        </Typography>
                      </div>
                    </Box>
                  </Paper>
                </Paper>
              )}
              {openSection === "support" && (
                <Paper
                  onMouseEnter={() => setOpenSection("support")}
                  onMouseLeave={() => setOpenSection(null)}
                  sx={{
                    backgroundColor: "#1C1B1B",
                    color: "white",
                    padding: "9rem",
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    position: "absolute",
                    top: "100%",
                    zIndex: 1,
                    height: "200px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container justifyContent="center">
                    {exchangeOffers.map((offer, index) => (
                      <Grid item xs={4} key={index}>
                        <Card
                          onClick={() => navigate(offer.path)}
                          sx={{
                            background:
                              "linear-gradient(to left, #242526,#383838)",
                            color: "white",
                            textAlign: "start",
                            height: "100px",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: "10px",
                            width: "20vw",
                            mr: 1,
                            mb: 1,
                            cursor: "pointer", // Add cursor to show it's clickable
                          }}
                        >
                          <Typography variant="h6">{offer.title}</Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ mr: 3 }}>Read more</Typography>
                            <BsArrowRight />
                          </Box>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              )}
            </AppBar>
          )}
        </>
      )}
    </>
  );
};

export default Navbar;
