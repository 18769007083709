import React, { useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import { gsap } from "gsap";
import stack3 from "../../../images/Home/Stack3.png";
import mobile3 from "../../../images/Home/Stack1.png";
import Tv from "../../../images/Home/tvnew1.png";
import stacklast from "../../../images/Home/Satcklast.png";
import bgImage from "../../../images/Home/Better.png";
import mobileBgImage from "../../../images/Home/Better.png";

// New smaller screen images
import mobileStack1 from "../../../images/Home/bettersmall1.png";
import mobileStack2 from "../../../images/Home/bettersmall2.png";
import mobileTv from "../../../images/Home/bettersmall3.png";
import mobileStackLast from "../../../images/Home/bettersmall4.png";
function HomeFirst() {
  const theme = useTheme();
  const paperRefs = useRef([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    paperRefs.current.forEach((paperRef) => {
      if (paperRef) {
        gsap.fromTo(
          paperRef,
          { backgroundSize: "100%" },
          {
            backgroundSize: "110%",
            duration: 0.3,
            paused: true,
            ease: "power1.inOut",
            onReverseComplete: () =>
              gsap.set(paperRef, { backgroundSize: "100%" }),
          }
        );

        paperRef.addEventListener("mouseenter", () => {
          gsap.to(paperRef, {
            backgroundSize: "110%",
            duration: 0.3,
            ease: "power1.inOut",
          });
        });

        paperRef.addEventListener("mouseleave", () => {
          gsap.to(paperRef, {
            backgroundSize: "100%",
            duration: 0.3,
            ease: "power1.inOut",
          });
        });
      }
    });

    return () => {
      paperRefs.current.forEach((paperRef) => {
        if (paperRef) {
          paperRef.removeEventListener("mouseenter", null);
          paperRef.removeEventListener("mouseleave", null);
        }
      });
    };
  }, []);

  const setPaperRef = (element, index) => {
    paperRefs.current[index] = element;
  };

  return isMobile ? (
    <div
      style={{
        padding: "8% 4%",
        backgroundImage: `url(${isMobile ? mobileBgImage : bgImage})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid container spacing={2}>
        {/* First Paper (large) */}
        <Grid item xs={12}>
          <Paper
            ref={(el) => setPaperRef(el, 0)}
            sx={{
              backgroundImage: `url(${mobileStack1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              bgcolor: "#232322",
              color: "#fff",
              padding: "1.5rem 2.5rem",
              height: "300px",
              borderRadius: "16px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontFamily: "MarkOT" }}>
              Amp Your Style with itel Latest Smartphones
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BsArrowRight
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "22px",
                  marginTop: "10px",
                }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Second Paper */}
        <Grid item xs={6}>
          <Paper
            ref={(el) => setPaperRef(el, 1)}
            sx={{
              backgroundImage: `url(${mobileStack2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              bgcolor: "#232322",
              color: "#fff",
              padding: "1.5rem 1rem",
              height: "300px",
              borderRadius: "16px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontFamily: "MarkOT" }}>
              Stay Effortlessly Connected
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BsArrowRight
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "22px",
                  marginTop: "10px",
                }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Third Paper */}
        <Grid item xs={6}>
          <Paper
            ref={(el) => setPaperRef(el, 2)}
            sx={{
              backgroundImage: `url(${mobileTv})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              bgcolor: "#232322",
              color: "#fff",
              padding: "1.5rem 1rem",
              height: "300px",
              borderRadius: "16px",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontFamily: "MarkOT" }}>
              Endless Entertainment: itel Smart TV
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BsArrowRight
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "22px",
                  marginTop: "10px",
                }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Fourth Paper */}
        <Grid item xs={12}>
          <Paper
            ref={(el) => setPaperRef(el, 3)}
            sx={{
              backgroundImage: `url(${mobileStackLast})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              bgcolor: "#232322",
              color: "#fff",
              padding: "1.5rem 2.5rem",
              height: "300px",
              borderRadius: "16px",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontFamily: "MarkOT" }}>
              Level up Your Game: itel Smart Gadgets!
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BsArrowRight
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "22px",
                  marginTop: "10px",
                }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div
      style={{
        backgroundImage: `url(${isMobile ? mobileBgImage : bgImage})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        padding: "8% 4% 8% 4%",
      }}
    >
      <Grid container spacing={2}>
        {/* Paper components, dynamically changing images based on screen size */}
        <Grid item xs={12} md={5}>
          <Paper
            ref={(el) => setPaperRef(el, 0)}
            sx={{
              backgroundImage: `url(${isMobile ? mobileStack1 : mobile3})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              bgcolor: "#232322",
              color: "#fff",
              padding: "1.5rem 2.5rem 2.5rem 2.5rem",
              height: { xs: "277px", md: "82vh" },
              borderRadius: "16px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "16px", sm: "20px", md: "24px" },
                fontFamily: "MarkOT",
              }}
            >
              Amp Your Style with itel Latest Smartphones
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BsArrowRight
                style={{
                  color: "red",
                  marginLeft: "10px",
                  fontSize: "26px",
                  marginTop: "10px",
                }}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} md={7}>
          <Paper
            ref={(el) => setPaperRef(el, 1)}
            sx={{
              backgroundImage: `url(${isMobile ? mobileStack2 : stack3})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              color: "#fff",
              padding: "1.5rem 2.5rem 2.5rem 2.5rem",
              borderRadius: "16px",
              height: { xs: "250px", md: "40vh" },
              bgcolor: "#232322",
            }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "20px", md: "24px", pr: "10%" },
                    fontFamily: "MarkOT",
                    pr: "30%",
                  }}
                >
                  Stay Effortlessly Connected All Day, Every Day
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BsArrowRight
                    style={{
                      color: "red",
                      marginLeft: "10px",
                      fontSize: "26px",
                      marginTop: "10px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Grid container spacing={2} sx={{ mt: 0.1 }}>
            <Grid item xs={6} md={7}>
              <Paper
                ref={(el) => setPaperRef(el, 2)}
                sx={{
                  backgroundImage: `url(${isMobile ? mobileTv : Tv})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  color: "#fff",
                  padding: "1.5rem 2.5rem 2.5rem 2.5rem",
                  borderRadius: "16px",
                  height: { xs: "250px", md: "39.5vh" },
                  bgcolor: "#232322",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", sm: "19px", md: "23px" },
                        fontFamily: "MarkOT",
                      }}
                    >
                      Dive into Endless Entertainment: itel Smart TV
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BsArrowRight
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "26px",
                          marginTop: "10px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper
                ref={(el) => setPaperRef(el, 3)}
                sx={{
                  backgroundImage: `url(${
                    isMobile ? mobileStackLast : stacklast
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  color: "#fff",
                  padding: "1.5rem 2.5rem 2.5rem 2.5rem",
                  borderRadius: "16px",
                  height: { xs: "250px", md: "39.5vh" },
                  bgcolor: "#232322",
                }}
              >
                <Grid container spacing={0}>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", sm: "19px", md: "23px" },
                        fontFamily: "MarkOT",
                      }}
                    >
                      Level up Your Game: itel Smart Gadgets!
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BsArrowRight
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "26px",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ); // Return null for larger screens to avoid changes.
}

export default HomeFirst;
