import React from "react";
import { Grid, Box,useMediaQuery } from "@mui/material";
import first from "../../../images/productlanding/A05Techspecs1.png";
import second from "../../../images/productlanding/A05Techspecs2.png";
import third from "../../../images/productlanding/A05Techspecs3.png";

const images = {
  Camera: first,
  Battery: second,
  Security: third,
 
};



 

const TechSpecs = ({ 
  linearcolor, }) => {
  return (
    <Box sx={{paddingTop:"6%"}}>
      <Box sx={{ flexGrow: 1, pl: "4%", pr: "4%", pb: "4%" }}>
        
        

        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box>
              <img
                src={images.Camera}
                alt="Crystal Clear Shots"
                style={{
                  width: "100%",
                  height: "calc(121vh - 20px)",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={images.Battery}
                    alt="Swift Power-Up"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={images.Security}
                    alt="Storage"
                    style={{
                      width: "100%",
                      height: "calc(59vh - 10px)",
                      borderRadius: "16px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Box>
      </Box>

  );
};

export default TechSpecs;
