import { Box } from '@mui/material'
import React from 'react'
import { CustomButton } from '../../components/Buttons/CustomButton'
import { FollowInstaBtn } from '../../components/Buttons/FollowInstaBtn'
import { BuyNowButton } from '../../components/Buttons/BuyNowButton'

const Test = () => {
    return (
        <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
            <CustomButton />
            <FollowInstaBtn />
            <Box> <BuyNowButton /></Box>
        </Box>
    )
}

export default Test