import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import downArrow from "../../images/ProductFaqs/downArrow.png";
import upArrow from "../../images/ProductFaqs/upArrow.png";

const ProductFaqs = ({
  mode = "light",
  title,
  linkText,
  tabLabels,
  tabContents,
  bgcolor,
  accordionBackgroundColor,
  selectedTabBgColor= "#ffffff",
  selectedTabTextColor= "#ffffff"
}) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isDarkMode = mode === "dark";
  const textColor = isDarkMode ? "#ffffff" : "#000000";
  const buttonTextColor = isDarkMode ? "#ffffff" : "#000000";
  //const selectedTabbgColor = isDarkMode ? "#ffffff" : "#000000";
  //const selectedTabTextColor = isDarkMode ? "#000000" : "#ffffff";
  //const selectedTabTextColor = selectedTabBgColor === "#000000" ? "#ffffff" : "#000000";
  const borderColor = isDarkMode ? "#252525" : "#D9D9D9";
  const borderColortab = isDarkMode ? "#333333" : "#666666";
  const tabtextcolor = isDarkMode ? "#ffffff" : "#000000";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: bgcolor,
        py: { md: 4, xs: 0 },
        overflow: "hidden",
      }}
    >
      <Container>
        <Typography
          align= {isMobile ? "left" : "center" }
          sx={{
            mt: 4,
            mb: 4,
            fontSize: { xs: "25px", md: "43px" },
            fontWeight: "500",
            fontFamily:"MarkOT-Medium",
            color: textColor,
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            mb: 4,
            overflowY: "hidden",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            variant={isMobile ? "scrollable" : "standard"}
            indicatorColor="none"
            sx={{
              fontSize: "12px",
              color: tabtextcolor,
              fontFamily:"MarkOT-Bold",
              "& .MuiTab-root": {
                color: textColor,
                border: `2px solid ${borderColortab}`,
                borderRadius: "20px",
                fontSize: isMobile ? "12px" : "15px",
                margin: "0 4px",
              },
              "& .Mui-selected": {
                backgroundColor: selectedTabBgColor,
                color: selectedTabTextColor,
                fontFamily:"MarkOT-Bold",
                fontSize: isMobile ? "12px" : "15px",
                border: "none",
              },
              "& .MuiTabs-scrollButtons": {
                display: "none",
                fontSize: isMobile ? "12px" : "15px",
              },
            }}
          >
            {tabLabels.map((label, index) => (
              <Tab key={index} label={label} />
            ))}
          </Tabs>
        </Box>
        {tabContents.map((faqs, index) => (
          <TabPanel key={index} value={value} index={index}>
            <FAQSection
              faqs={faqs}
              linkText={linkText}
              accordionBackgroundColor={accordionBackgroundColor}
              buttonTextColor={buttonTextColor}
              textColor={textColor}
              borderColor={borderColor}
            />
          </TabPanel>
        ))}
      </Container>
    </Box>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <Box hidden={value !== index} sx={{ pt: 2 }}>
      {children}
    </Box>
  );
};

const FAQSection = ({
  faqs,
  linkText,
  accordionBackgroundColor,
  buttonTextColor,
  textColor,
  borderColor,
  selectedTabbgColor
}) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      sx={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: isMobile ? "0 0" : "0 16px",
        paddingBottom: "10%",
      }}
    >
      {(expanded ? faqs : faqs.slice(0, 3)).map((faq, index) => (
        <Accordion
          key={index}
          defaultExpanded={index === 0}
          sx={{
            mb: 2,
            backgroundColor: accordionBackgroundColor,
            boxShadow: "none",
            borderTop: `1px solid ${borderColor}`,
            // borderBottom: `1px solid ${borderColor}`,
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-content-${index}`}
            id={`faq-header-${index}`}
            sx={{ borderLeft: "none", borderRight: "none" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{    fontSize: "20px", fontWeight: "500", fontFamily:"MarkOT-Medium", color: textColor }}
              >
                {faq.question}
              </Typography>
              
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ borderLeft: "none", borderRight: "none" }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "400",fontFamily:"MarkOT", color: textColor }}
            >
              {faq.answer}
            </Typography>
            {index === 0 && (
              <Link
                href="#"
                underline="always"
                sx={{
                  display: "block",
                  mt: 2,
                  color: "#949494",
                  textDecorationColor: "#949494",
                }}
              >
                {linkText}
              </Link>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ borderTop: `1px solid ${borderColor}`,display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          onClick={handleToggle}
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontFamily:"MarkOT-Medium",
            color: buttonTextColor,
            textTransform: "none",
          }}
        >
          {expanded ? "Read Less" : "Read More"}
          <Box
            component="img"
            src={expanded ? upArrow : downArrow}
            alt="Arrow"
            sx={{ ml: 1, width: "16px", height: "16px" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ProductFaqs;
