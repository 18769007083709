import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const ResponsiveText = ({
  xsText,
  lgText,
  xsFontSize,
  lgFontSize,
  variant = "h6",
  align = "center",
  gutterBottom = false,
  ...props
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Use `md` breakpoint (900px and above)

  const text = isLargeScreen ? lgText : xsText;
  const fontSize = isLargeScreen ? lgFontSize : xsFontSize;

  return (
    <Typography
      variant={variant}
      align={align}
      gutterBottom={gutterBottom}
      sx={{ fontSize: fontSize }}
      {...props}
    >
      {text}
    </Typography>
  );
};

export default ResponsiveText;
