import React, { useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Box,
  Rating,
  Button,
  styled,
  useMediaQuery,
} from "@mui/material";
import downarrow from "../../images/Review/downarrow.png";
import arrowup from "../../images/Review/arrowup.png";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty": {
    color: "#6F7384",
  },
});


const Review = ({ review, isDarkMode, bgColor }) => (
  
  <Card
    elevation={0}
    sx={{
      mb: 2,
      backgroundColor: bgColor ? bgColor : isDarkMode ? "#1C1B1B" : "#FFFFFF",
      color: isDarkMode ? "#ffffff" : "#000000",
      pr: { xs: "5%", sm: "10%" },
      pl: { sm: "10%" },
    }}
  >
    <CardContent>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Avatar
          sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          src={review.image}
          alt={review.name}
        />
        <Box>
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: "1rem", sm: "1.25rem" }, fontFamily:"MarkOT-Medium" }}
          >
            {review.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: isDarkMode ? "#949494" : "#7B7B7B", fontFamily:"MarkOT" }}
          >
            {review.date}
          </Typography>
        </Box>
      </Box>
      <Box mt={3} display="flex" alignItems="center">
        <StyledRating value={review.rating} readOnly />
        <Typography
          variant="h6"
          sx={{ ml: 2, fontSize: { xs: "0.875rem", sm: "1rem" }, fontFamily:"MarkOT" }}
        >
          {review.title}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography
          variant="body2"
          sx={{ color: isDarkMode ? "#C0C0C0" : "#4F4F4F", fontWeight: 300,fontFamily:"MarkOT" }}
        >
          {review.content}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const ReadMoreButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "white" : "black",
  backgroundColor: "transparent",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

const ProductReview = ({
  initialReviews,
  additionalReviews,
  isDarkMode,
  bgColor,
}) => {
  const [reviews, setReviews] = useState(initialReviews);
  const [showMore, setShowMore] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleReadMore = () => {
    setShowMore(true);
    setReviews([...initialReviews, ...additionalReviews]);
  };

  const handleReadLess = () => {
    setShowMore(false);
    setReviews(initialReviews);
  };

  return (
    <Box
      sx={{
        bgcolor: bgColor ? bgColor : isDarkMode ? "#1C1B1B" : "#FFFFFF",
        minHeight: "100vh",
        py: 4,
      }}
    >
      <Container>
        <Typography
          variant="h4"
          gutterBottom
          color={isDarkMode ? "white" : "black"}
          textAlign= {isSmallScreen ? "left" : "center"}
          sx={{ fontSize: { xs: "1.9rem", sm: "2rem", md: "2.25rem" }, 
              mb: 4 , 
              fontFamily:"MarkOT-Medium",
              pl: { xs: "4%"},}}
        >
          Top Reviews
        </Typography>
        <Grid container direction="column">
          {reviews.map((review, index) => (
            <Grid item key={index}>
              <Review
                review={review}
                isDarkMode={isDarkMode}
                bgColor={bgColor}
              />
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center" mt={2} display="flex" justifyContent="center">
          {!showMore ? (
            <ReadMoreButton
              onClick={handleReadMore}
              sx={{ color: isDarkMode ? "white" : "black", fontFamily:"MarkOT-Medium" }}
            >
              Read More{" "}
              <Box
                component="img"
                src={downarrow}
                alt="arrow down"
                sx={{ width: 16, height: 16 }}
              />
            </ReadMoreButton>
          ) : (
            <ReadMoreButton
              onClick={handleReadLess}
              sx={{ color: isDarkMode ? "white" : "black" ,fontFamily:"MarkPro-Medium"}}
            >
              Read Less{" "}
              <Box
                component="img"
                src={arrowup}
                alt="arrow up"
                sx={{ width: 16, height: 16 }}
              />
            </ReadMoreButton>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ProductReview;
