import React, { useEffect, useState,useRef } from "react";
import gsap from "gsap";
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
// import circleImage from "../../images/Specification/circle.png"
// import star from "../../images/Specification/Star 2.png"

const Specifications = ({ specs , bgColor}) => {
  const starRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    tl.fromTo(
      starRef.current,
      { scale: 0.5, rotation: 0 },
      { scale: 1, rotation: 180, duration: 2 }
    );
  }, []);

  const sections = [
    { label: "General", content: specs.generalSpecs },
    { label: "Display Features", content: specs.display },
    { label: "OS & Processor", content: specs.osProcessor },
    { label: "Camera", content: specs.camera },
    { label: "Battery", content: specs.battery },
    { label: "Memory & Storage", content: specs.memoryStorage },
    { label: "Connectivity", content: specs.connectivity },
    { label: "Additional", content: specs.additional },
  ];

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };


  const renderContent = (content) => {
    if (!content || Object.keys(content).length === 0) {
      return (
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">No Data Available</Typography>
        </Box>
      );
    }

    

    return (
      <Box>
        {Object.entries(content).map(([key, value]) => (
          <React.Fragment key={key}>
            <Grid container spacing={isXs ? 1 : 2} sx={{ padding: isXs ? "4%" : "2%" }}>
              <Grid item xs={12} sm={4} >
                <Typography
                  variant="body1"
                  sx={{ color: bgColor === "#fff" 
                  ? (isXs ? "#0E0E0E" : "#666666") 
                  : (bgColor === "#181818" || bgColor === "#1C1B1B" || bgColor === "#101113")
                  ? (isXs ? "#D9D9D9" : "#949494") 
                  : "#666666", marginRight: isXs ? 0 : "1rem", fontSize: { xs: "12px", lg: "14px" } , fontFamily: isXs ? "MarkOT" : "MarkOT-Medium",}}
                >
                  {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} >
                <Typography variant="body1" sx={{ textAlign: "start", color: "#666666", fontSize: { xs: "10px", sm: "0.9rem", lg: "14px" }, fontFamily:"MarkOT", }}>
                  {Array.isArray(value) ? value.join(", ") : value}
                </Typography>
              </Grid>
            </Grid>
            {isXs && <Divider sx={{ backgroundColor: "#CECCD5" }} />}
          </React.Fragment>
        ))}
      </Box>
    );
  };


  return (
    <div
      style={{
        overflow: "hidden",
        background: bgColor,
        color: bgColor==="#fff" ? "#181818" : "#fff",
        paddingLeft: isXs ? "5%" : "4%",
        paddingRight: isXs ? "7%" : "4%",
        paddingBottom: "10%",
        paddingTop: "4%",
      }}
    >
       <Box sx={{ display: "flex",
                marginBottom:"1%",
                bgColor: bgColor 
                }}> 
               {/* <Box >
          {bgColor === "#fff" && (
              <img src= {redStar}  alt="redstar" /> 
          )}
          </Box> */}
          {/* <Typography 
            variant="h4" 
            gutterBottom 
            sx={{
              fontSize: { xs: "30px", sm: "40px", lg: "48px" },
              }}>
              Full Specifications
            </Typography>
        </Box> */}
        {/* <Box sx={{ display: "flex",
                marginBottom:"2%",
                bgColor: bgColor 
                }}> */}
          {/* <Box
              sx={{
                position: "relative",
                marginBottom:"2%",
                left:{ xs: "4%", sm: "3%", lg: "1.5%" },
              }}
            >
            {bgColor === "#fff" && (
            <>
              <Box
                component="img"
                src={circleImage}
                alt="circle"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: 80,
                  height: 80,
                  transform: "translate(-50%, -50%)",
                }}
              />
              <Box
                component="img"
                src={star}
                alt="star"
                ref={starRef}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </>
            )}
            </Box> */}
            <Typography 
            variant="h4" 
            gutterBottom 
            sx={{
              position:"relative",
              pl: "1%",
              pt: isXs ? "10%" : "4%",
              fontSize: { xs: "28px", sm: "40px", lg: "48px" },
              fontFamily:"MarkOT-Medium",
              //left:{ xs: "9.5%", sm: "7%", lg: "1%" }
              //top:{xs:"2%", sm:"5%", lg:"2%"}
              }}>
              Full Specifications 
            </Typography>
            </Box>
      <Box
        sx={{
          display: "flex",
          width: '100%',
          // width: { xs: "22rem",sm:"40.5rem", md: "62rem", lg: "82rem" },
          // height: { xs: "27rem", sm: "26rem", lg: "26rem" },
          // justifyItems: "center",
          // justifySelf: "center",
          // alignItems: "center",
          // paddingTop: '3%',
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: { xs: "37%", sm: "30%", md: "25%", lg: "20%" },
            //height: "100%",
            minHeight:"100%",
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              zIndex:0,
              position: "relative",
              background: bgColor==="#fff" ? "#FBFBFB" : "#202020",
              color: "#949494",
              boxSizing: "border-box",
              borderRight: "0px",
            },
          }}
        >
          <List sx={{
            paddingTop: 0,
            fontFamily: isXs ? "MarkOT-Medium" : "MarkOT",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            {sections.map((section, index) => (
              <ListItem
                button
                key={section.label}
                selected={selectedIndex === index}
                onClick={() => handleListItemClick(index)}
                sx={{
                  //marginBottom: "3.5px",
                  paddingLeft: "14%",
                  paddingTop: isLg ? "3.7%" : "4.5%",
                  paddingBottom:isLg ? "4%" : "4.4%",
                  // paddingTop: {
                  //   xs: "6.3%",
                  //   sm: "5.7%",
                  //   md: "4.3%",
                  //   lg: "3.7%",
                  // },
                  // paddingBottom: {
                  //   xs: "6.5%",
                  //   sm: "6.2%",
                  //   md: "4.4%",
                  //   lg: "4%",
                  // },
                  "&.Mui-selected": {
                    //p: "8%",
                    backgroundColor: bgColor==="#fff" ? "#F2F2F2" : "#252525",
                    fontSize: "16px",
                    fontFamily: isXs ? "MarkOT-Medium" : "MarkOT",
                    color: bgColor==="#fff" ? "#181818" : "#fff",
                    "&::before": {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: '4px',
                      backgroundColor: '#FF0037',
                      boxShadow: '0px 0px 15px 2px #FF0037',
                    }
                  },
                }}
              >
                <ListItemText primary={section.label} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          sx={{
            //p: 2,
            // minHeight: "100%",
            // width: {sm:"75%",lg:"80% "},
            width: '100%',
            background: bgColor==="#fff" ? "#F2F2F2" : "#252525",
            color: "#949494",
            overflow: "hidden",
            // border : '1px solid red'
          }}
        >
          {renderContent(sections[selectedIndex].content)}
        </Box>
      </Box>
      &nbsp;
    </div>
  );
};

export default Specifications;
