import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import banner1 from "../../images/aboutus/LandingVector2.png";
import banner2 from "../../images/aboutus/banner2.png"
import bannervideo from "../../images/aboutus/bannervideo.mp4";
import backgroundLines from "../../images/aboutus/lines.png";
import image1 from "../../images/aboutus/Frame4.png";
import image2 from "../../images/aboutus/frame5.png";
import image3 from "../../images/aboutus/frame6.png";
import image4 from "../../images/aboutus/frame7.png";
import lastSection from "../../images/aboutus/lastsectionbig.png";
import lastSectionSmall from "../../images/aboutus/lastsectionsmall.png";
import redArrowFrame2 from "../../images/aboutus/RedArrowFrame3.svg";
import redArrowFrame1 from "../../images/aboutus/RedArrowFrame1.svg";
import sampleVedio from "../../images/aboutus/samplevideo.mp4";
import trophy1 from "../../images/aboutus/CTrophy1.png";
import trophy2 from "../../images/aboutus/CTrophy2.png";
import trophy3 from "../../images/aboutus/CTrophy3.png";
import TrophyCarousels from "./TrophyCarouselsV2";
import ResponsiveText from "../../components/responsivetext/ResponsiveText"; // Adjust the import path as necessary
import backgroundImage from "../../images/aboutus/Layer1.png";

const stats = [
  { number: "70+", description: "Countries" },
  { number: "10 Cr+", description: "Customers across India" },
  { number: "1300+", description: "Service Centers" },
];
const images = [
  {
    image: trophy1,
    text: "“Champions of the Year",
    second_text: "the year”",
    bottom_text: "2018 | Economic Times"
  },
  {
    image: trophy2,
    text: "“Most Prestigious Brands Asia Award”",
    second_text: "the year”",
    bottom_text: "2022 | BARC Herala Global"
  },
  {
    image: trophy3,
    text: "“Best Progressive Brand of the Year Award”",
    second_text: "the year”",
    bottom_text: "2018"
  },
];


function AboutUs() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const banner = isSmallScreen ? banner2 : banner1;
  return (
    <>
      {/* Banner */}
   <Box   sx={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Background Video */}
      <Box
        component="video"
        src={bannervideo}
        autoPlay
        loop
        muted
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1, // Ensures the video is behind the content
        }}
      />

      {/* Centered Image */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          component="img"
          src={banner}
          alt="Banner"
          sx={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%', // Ensure it doesn't overflow horizontally
            maxHeight: '100%', // Ensure it doesn't overflow vertically
            display: 'block',
            paddingBottom:{md:"2%"}
          }}
        />
      </Box>
    </Box>
      

      {/* ourmission */}
      <Box sx={{ mt: '10%', marginBottom: '7%', pl: "4%", pr: "4%", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
        <Grid container spacing={3} alignItems="center" sx={{ margin: '0!important', width: { lg: '70%', md: '85%', xs: '95%' } }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0!important ', marginBottom: { md: '3rem', xs: '1.5rem' } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '24px', sm: '38px', md: '40px', lg: '48px' },
                fontFamily: 'MarkOT',
                fontWeight: 'bold',
                lineHeight: '32px',
                color: 'rgba(0, 0, 0, 1);',
                mt: { xs: '1rem', sm: '2rem' }
              }}>
              Our Mission
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0!important ' }} >

            <Typography variant="h6" sx={{
              fontSize: { xs: '16px', sm: '18px', md: '20px', lg: '20px' },
              fontFamily: 'MarkOT',
              lineHeight: '32px',
              color: '#00000',
              textAlign: 'center'
            }}>
              Established over 10 years, itel is a reliable smart life brand for everyone. Adopting “Enjoy Better Life” as its brand philosophy, itel’s mission is to provide budget-friendly consumer electronic products and lifestyle products to everyone. It democratises technology by giving entry to technology and connectedness to consumers without prior access to it. After more than 10 years’ development, itel has expanded its presence in more than 70 emerging markets globally. itel has a product portfolio of smartphones, TV, accessories, electrics, home appliances, laptop and lifestyle products.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* 3rddiv */}
      <Box
        sx={{
          // display: isLargeScreen ? 'block' : 'none',

          backgroundImage: `url(${backgroundLines})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: "relative",
          width: "100vw",
          height: { md: '150vh', xs: '270vh ', sm: '280vh', xl: '180vh' },
          overflow: "hidden",
          p: "1% 4% 1% 4%",
          mb: "10%",
        }}
      >

        <Box sx={{ width: '100%', height: '100%' }}>
          <Grid container spacing={2} sx={{ width: 'auto', position: isLargeScreen ? 'relative' : 'none', height: '100%' }}>
            <Grid item md={8} xs={12} sx={{ position: isLargeScreen ? 'relative' : 'none', order: { xs: 2, md: 0 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <Box sx={{ position: isLargeScreen ? 'absolute' : 'none', right: '5%', top: '20%', display: isLargeScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography sx={{
                  fontSize: { xs: '40px', sm: '38px', md: '40px', lg: '80px', xl: '100px' },
                  fontFamily: 'MarkOT',
                  fontWeight: 'bold',
                  lineHeight: { lg: '96px', sm: '57.6px' },
                  color: '#333',
                  maxWidth: { md: "220px", xs: '100%', lg: '450px' }

                }}>Enjoy Better <span style={{ color: "#FF0037" }} sx={{ textAlign: { sm: 'left' }, whiteSpace: 'normal', wordBreak: { xs: 'break-word' } }}>Life</span></Typography>

              </Box> */}
             <Box
      sx={{
        position: isLargeScreen ? 'absolute' : 'relative',
        right: '13%',
        top: '20%',
        display: isLargeScreen ? 'block' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',

        
      }}
    >
      
      <Typography
        sx={{
          fontSize: { xs: '40px', sm: '38px', md: '40px', lg: '80px', xl: '100px' },
          fontFamily: 'MarkOT',
          fontWeight:"Bold",
          
          lineHeight: { lg: '96px', sm: '57.6px' },
          color: 'var(--Colors-Blue-Dark-Blue, rgba(5, 0, 54, 1))',
         
          whiteSpace: 'nowrap', // Ensures the text stays on one line until the break
          
        }}
      >
        {isSmallScreen ? (
          <>
            Enjoy Better
            <br />
            <span style={{ color: '#FF0037' }}>Life</span>
          </>
        ) :(
          <>
            Enjoy
            <br />
           <Box sx={{paddingTop:{md:"3%"}}}> Better <span style={{ color: '#FF0037' }}>Life</span> </Box>
          </>
        
        )}
      </Typography>
    </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ display: isLargeScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 1, md: 0 }, positoin: isLargeScreen ? 'none' : 'relative',paddingBottom:{md:"9%"} }} >

              <Box component="img" src={image1} alt="Logo" sx={{ width:{xs: '95%', sm: '50%',md:"auto",lg:"auto"}, height: { xs: 'auto', md: 'auto' }, top: '0', right: '4%', zIndex: 99, position: isLargeScreen ? 'absolute' : 'static' }} />
              <Box component="img" src={image4} alt="Logo" sx={{ width:{xs: '95%', sm: '50%',md:"auto",lg:"auto"},height: { xs: 'auto', md: 'auto' }, top: '0', right: '4%', zIndex: 99, position: isLargeScreen ? 'absolute' : 'absolute', display: isLargeScreen ? 'block' : 'block' }} />

            </Grid>
            <Grid item md={4} xs={12} sx={{ display: isLargeScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 3, md: 0 } }} >
              <Box component="img" src={image2} alt="Logo" sx={{ width:{xs: '95%', sm: '50%',md:"auto",lg:"auto"}, height: { md: 'auto', xs: 'auto' }, top: '35%', left: '5%', zIndex: 99, position: isLargeScreen ? 'absolute' : 'static' }} />
            </Grid>
            <Grid item md={8} xs={12} sx={{ position: isLargeScreen ? 'relative' : 'none', order: { xs: 4, md: 0 }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ position: isLargeScreen ? 'absolute' : 'none', top: '73%', right: '8%', bottom: '0', display: isLargeScreen ? 'block' : 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'right' }}>
                <Typography sx={{
                  fontSize: { xs: '22px', sm: '34px', md: '35px', lg: '44.11px', xl: '54px' },
                  fontFamily: 'MarkOT-Medium',
                  fontWeight:"Bold",
                  
                  lineHeight: { md: '52.93px', xs: '38.4px', xl: '62px' },
                  color: 'rgba(0, 0, 0, 1)',
                  textAlign: { md: 'right', sm: 'center', xs: 'right' },
                  mb: { xs: '0', xl: '2%' },
                }}>
                  Dream Big with itel
                </Typography>
                <Typography sx={{
                  fontSize: { xs: '16px', sm: '16px', md: '20px', lg: '20px', xl: '24px' },
                  fontFamily: 'MarkOT',
                  fontWeight:"400",
                  lineHeight: { md: '32px', xs: '25.6px' },
                  color: 'rgba(55, 63, 65, 1)',
                  textAlign: { md: "right", sm: 'center', xs: 'right' },
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                  maxWidth: { sm: '350px', md: '500px', xl: '700px' },
                  margin: '0 auto', // Centers the text container
                 
                }}>
                  
                    We're here to turn your dreams into reality. Let us be your companion in progress, unlocking potential  and transforming your hopes into achievements.
               
                  

                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} xs={12} sx={{ position: isLargeScreen ? 'relative' : 'none', order: { xs: 6, md: 0 }, mb: { xs: '5%' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ position: isLargeScreen ? 'absolute' : 'none', top: '90%', left: '14%', display: isLargeScreen ? 'block' : 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '500px' }} >
                <Typography sx={{
                  fontSize: { xs: '16px', sm: '20px', md: '22px', lg: '24px' },
                  fontFamily: 'MarkOT-Medium',
                  fontWeight: 'bold',
                  lineHeight: { md: '31.2px', xs: '26px' },
                  color: 'rgba(0, 0, 0, 1)',
                  textAlign: { md: 'left', xs: 'center' },
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',

                }}>
                  With itel's affordable and high-performing smartphones, feature phones, gadgets, home care and personal care products, you can
                </Typography>

                <Typography sx={{
                  fontSize: { xs: '30px', sm: '36px', md: '35px', lg: '80px' },
                  fontFamily: 'MarkOT-Medium',
                  // fontWeight: 'bold',
                  lineHeight: { md: '96px', xs: '43.2px' },
                  color: '#FF0037',
                  textAlign: 'left',
                  whiteSpace: 'normal',
                  pb:"8%",
                  // wordBreak: 'break-word',

                }}>
                  {isSmallScreen ? (
                    <>
                     reach for the stars!
                    </> ):(

                    <>
                    reach for <br/>
                    the stars!
                    </>
                  )}
                  
                </Typography>


              </Box>
            </Grid>
            <Grid item md={7} xs={12} sx={{ display: isLargeScreen ? 'block' : 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 5, md: 0 } ,}} >
              <Box component="img" src={image3} alt="Logo" sx={{
                width: { md: '52%', sm: "60%", xs: '95%' }, height: { md: 'auto', xs: 'auto' }, top: '82%', right: '3%', zIndex: 99, position: isLargeScreen ? 'absolute' : 'static', 
              }} />
            </Grid>
          </Grid>
        </Box>
      </Box>






      {/* video */}
      < Box
        sx={{
          pt:"15%",
          position: "relative",
          width: "100vw",
          height: { xs: "30vh",sm:"50vh", md: "100vh" },
          overflow: "hidden",
          mb: "8%",
        }
        }
      >
        <Box
          component="video"
          src={sampleVedio}
          autoPlay
          loop
          muted
          sx={{
            width: "100%",
            height: { xs: "30vh",sm:'50vh', md: "100vh" },

            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: {md:"90%",xs:"85%"},
            left: "50%",
            transform: { md: "translate(-50%, -50%)",  xs: "translate(-50%, 0%)" },
            color: "white",
            textAlign: "center",
            width:"100%"
          }}
        >
          <ResponsiveText
            xsText="Connecting Hearts, Everyday With Itel!"
            lgText="Connecting Hearts, Everyday With Itel!"
            sx={{
              fontSize: {
                xs: "18px",
                sm: "22px",
                md: "38px",
              },
            }}
          />
        </Box>
      </Box >
      {/* RedArrow */}

      < Box sx={{ width: '100%', height: { md: 'auto' }, marginBottom: '8%', display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop: { xs: '20%', sm: '2%', md: '3%' }, }}>


        <Grid container spacing={3} sx={{ width: '100%', height: '100% ' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: { xs: '24px', sm: '38px', md: '40px', lg: '48px' },
                fontFamily: 'MarkOT',
                fontWeight: 'bold',
                lineHeight: '32px',
                color: ' rgba(0, 0, 0, 1)',

              }}>
              Itel's Global Milestones
            </Typography>

          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <Box component="img" src={redArrowFrame1} alt='' sx={{ display: isLargeScreen ? 'block' : 'none', width: '90%' }}>

            </Box>
            <Box component="img" src={redArrowFrame2} alt='' sx={{ display: isLargeScreen ? 'none' : 'block', width: '100%' }}>

            </Box>
          </Grid>


        </Grid>

      </Box >

      {/* trophy */}
      < Box >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: { lg: 5, md: 5, sm: 12, xs: 12 }
        }}>
          <Grid container spacing={{ lg: 10, md: 4, xs: 2 }} >
            <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <Typography
                sx={{
                  fontSize: { xs: '24px', sm: '38px', md: '40px', lg: '48px' },
                  fontFamily: 'MarkOT',
                  fontWeight: 'bold',
                  lineHeight: '32px',
                  color: ' rgba(0, 0, 0, 1)',

                }}>
                Awards And Recognitions
              </Typography>


            </Grid>
            <Grid item xs={12} md={12}>
              <TrophyCarousels images={images} />
            </Grid>
          </Grid>
        </Box>
      </Box >

      {/* status */}
      
       <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        mr: '18%',
        ml: '18%',
        mb:"9%",
        mt:"10%",
        height: { xs: '300px', sm: '350px', md: '400px', lg: '430px' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',

        
      }}
    >
      <Grid container spacing={8}>
        {stats.map((stat, index) => {
          // Define order based on the screen size
          const order = {
            xs: [2, 1, 0], // Reverse order for xs screens
            sm: [0, 1, 2], // Default order for larger screens
          };

          return (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              sx={{
                order: order.xs[index],
                [theme.breakpoints.up('sm')]: {
                  order: order.sm[index],
                },
              }}
            >
              <ResponsiveText
                xsText={stat.number}
                lgText={stat.number}
                sx={{
                  color: '#FF0037',
                  fontSize: {
                    xs: '30px',
                    sm: '40px',
                    md: '50px',
                    lg: '60px',
                    xl: '80px',
                  },
                }}
              />
              <ResponsiveText
                xsText={stat.description}
                lgText={stat.description}
                sx={{
                  fontSize: {
                    xs: '14px',
                    sm: '16px',
                    md: '20px',
                    lg: '22px',
                    xl: '24px',
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
      {/* Last Section */}

      <Box sx={{ pl: "5%", pr: "5%", pb: "15%"}}>
          <img
            style={{ width: "100%" }}
            src= {isSmallScreen ? lastSectionSmall : lastSection}
            alt="lastSection"
          />
      </Box>


      {/* < Box sx={{

        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "100% 100%",
        mr: { md: "9%" },
        ml: { md: "9%" },
        mb: { md: "15%" },
        height: { sm: "470px", md: "550px", lg: "510px" },
        position: 'relative',
        display: isLargeScreen ? 'block' : 'none'



      }}>

        <img src={structure} alt='' style={{
          width: '100%', zIndex: 2, position: 'relative', height: '100%'
        }} />
        <Box sx={{ position: 'absolute', top: '0', right: '0', width: '100%', height: '100%' }}>
          <Grid container sx={{

            height: '100%',
            width: '100%',


          }} >
            <Grid item lg={12} xl={9} sx={{
              height: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'

            }}>

              <Grid container gap={6} sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '30%', mt: '10px'
              }}>
                <Grid item sm={2} >
                  <img src={circle1} alt='' />
                </Grid>
                <Grid item sm={2}>
                  <img src={circle2} alt='' />
                </Grid>
                <Grid item sm={2}>
                  <img src={circle3} alt='' />
                </Grid>
                <Grid item sm={2} >
                  <img src={circle4} alt='' />
                </Grid>
              </Grid>
              <Box sx={{
                width: '87%', justifyContent: 'center', mt: '1rem'
              }}>
                <Typography sx={{
                  fontSize: { md: '18px', lg: '20px' },
                  // fontWeight: '400',
                  fontFamily: 'MarkOT',
                  lineHeight: '32px',
                  color: '#181818'
                }}>Our logo"Stylised Speech Bubble" shape is not just a design element; it embodies our commitment to empathetic communication.</Typography>
                <Typography sx={{
                  fontSize: { md: '18px', lg: '20px' },
                  fontFamily: 'MarkOT',
                  lineHeight: '32px',
                  color: '#666666',
                  mt: { xs: '1rem', sm: '2rem' }
                }}>
                  By integrating this shape into an abstract human face, we elevate its significance. This design enables deeper and more empathetic connections with users.
                </Typography>
                <Typography sx={{
                  fontSize: { md: '18px', lg: '20px' },
                  fontFamily: 'MarkOT',
                  lineHeight: '32px',
                  color: '#666666',
                  mt: { xs: '1rem', sm: '2rem' }
                }}>
                  Fluid waves (design element) symbolises seamless connectivity and dynamic interactions, reflecting the intuitive flow of data and communication. Highlighting itel's mission to smoothly integrate affordable and smart solutions for everyone.
                </Typography>
              </Box>
            </Grid>
            <Hidden xlDown>
              <Grid item xl={3} sx={{ positon: 'relative' }} >
                <img src={red_logo} alt='' style={{ position: 'absolute', height: '100%', zIndex: 1, right: '2.2%' }} />
                <img src={white_background} alt='' style={{ position: 'absolute', top: '25%', right: '6%', zIndex: 3 }} />
                <img src={logo_bottom} alt='' style={{ position: 'absolute', top: '28%', right: '7.2%', zIndex: 3 }} />
              </Grid>
            </Hidden>
          </Grid>


        </Box>

      </Box >




      <Box sx={{
        display: isLargeScreen ? 'none' : 'flex', width: '100vw', justifyContent: 'center', alignItems: 'center', mb: '10%', height: 'auto',paddingTop: { xs: '10%', sm: '8%' }, // adjusted padding
        paddingBottom: { xs: '10%', sm: '8%' },
      }}>
        <Box sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: "center",
          justifyContent: 'center',
          pt: { xs: '17%', sm: '10%' },
          pb: { xs: '17%', sm: '10%' },
          // border: '1px solid red',
          background: `url(${bgBorder})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '95% 100%',
        }}>

          <Box sx={{ height: '100%', width: '80%' }}>
            <Typography sx={{
              fontSize: { xs: '16px', sm: '20px' },
              // fontWeight: '400',
              fontFamily: 'MarkOT',
              lineHeight: '25.6px',
              color: '#181818'
            }}>Our logo"Stylised Speech Bubble" shape is not just a design element; it embodies our commitment to empathetic communication.</Typography>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mt: { xs: '1rem', sm: '2rem' },
              mb: { xs: '1rem', sm: '2rem' }
            }}>
              <Box component="img" src={bgPng}></Box>
            </Box>
            <Typography sx={{
              fontSize: { xs: '16px', sm: '20px' },
              fontFamily: 'MarkOT',
              lineHeight: '25.6px',
              color: '#666666',
              mt: { xs: '1rem', sm: '2rem' }
            }}>
              By integrating this shape into an abstract human face, we elevate its significance. This design enables deeper and more empathetic connections with users.
            </Typography>
            <Typography sx={{
              fontSize: { xs: '16px', sm: '20px' },
              fontFamily: 'MarkOT',
              lineHeight: '25.6px',
              color: '#666666',
              mt: { xs: '1rem', sm: '2rem' }
            }}>
              Fluid waves (design element) symbolises seamless connectivity and dynamic interactions, reflecting the intuitive flow of data and communication. Highlighting itel's mission to smoothly integrate affordable and smart solutions for everyone.
            </Typography>
          </Box>

        </Box>
        

      </Box > */}

    </>
  );
}

export default AboutUs;
