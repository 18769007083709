import React , { useEffect} from "react";
import gsap from "gsap";
import ProductLanding from "../../../components/productcomponents/productlanding/ProductLanding";
import Marquee from "../../../components/productcomponents/marquee/Marquee";
import Specifications from "../../../components/productcomponents/FeaturedPhone/Specifications";
import CheckOutProducts from "../../../components/CheckOutProducts/CheckOutProducts";
import PhoneCard from "../../../components/productcomponents/phonecard/PhoneCard";
import ProductReview from "../../../components/ProductReview/ProductReview";
import ProductColor from "../../../components/productcomponents/FeaturedPhone/ProductColor";
import CompareModels from "../../../components/productcomponents/comparemodels/CompareModels";
import SupportChannels from "../../../components/support/Support";
import FollowUs from "../../../components/followus/FollowUs";
import ProductFaqs from "../../../components/ProductFaqs/ProductFaqs";
import landing1 from "../../../images/Power120/landingBig120.png";
import landing2 from "../../../images/Power120/landingSmall120.png";
import menuIcon from "../../../images/Power120/avatar.png";
import icon1 from "../../../images/Power120/icon1.png";
import icon2 from "../../../images/Power120/icon2.png";
import icon3 from "../../../images/Power120/icon3.png";
import background from "../../../images/productlanding/supergurufeaturesbg.png";
import landingbg1 from "../../../images/Power120/landingBgBig120.png";
import landingbg2 from "../../../images/Power120/landingBgsmall120.png";
import black1 from "../../../images/productlanding/black.png";
import black2 from "../../../images/productlanding/black.png";
import black3 from "../../../images/productlanding/black.png";
import blue1 from "../../../images/Power120/productColor120.png";
import blue2 from "../../../images/Power120/productColor120.png";
import blue3 from "../../../images/Power120/productColor120.png";
import green1 from "../../../images/Power120/productColor.png";
import green2 from "../../../images/Power120/productColor5330.png";
import green3 from "../../../images/Power120/productColor120.png"
import MyIcon from "../../../images/Power120/staricon.png";
import vertical1 from "../../../images/Power120/vertical1.png";
import vertical2 from "../../../images/Power120/vertical2.png";
import vertical3 from "../../../images/Power120/vertical3.png";
import vertical4 from "../../../images/Power120/vertical4.png";
import verticalsmall1 from "../../../images/Power120/vertical1small.png";
import verticalsmall2 from "../../../images/Power120/vertical2small.png";
import verticalsmall3 from "../../../images/Power120/vertical3small.png";
import verticalsmall4 from "../../../images/Power120/vertical4small.png";
import soha from "../../../images/Review/soha.png";
import krishna from "../../../images/Review/krishna.png";
import radha from "../../../images/Review/radha.png";
import bluesmall1 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall2 from "../../../images/Aura05i/productcolorsmall.png";
import bluesmall3 from "../../../images/Aura05i/productcolorsmall.png";
import orangesmall1 from "../../../images/P55/productcolorsmall3.png";
import orangesmall2 from "../../../images/P55/productcolorsmall3.png";
import orangesmall3 from "../../../images/P55/productcolorsmall3.png";
import blacksmall1 from "../../../images/Power120/productColorsmall120.png";
import blacksmall2 from "../../../images/Power120/productColorsmall120.png";
import blacksmall3 from "../../../images/Power120/productColorsmall120.png";
import compare11 from "../../../images/Power120/compare1.png";
import compare2 from "../../../images/Power120/compare2.png";
import compare3 from "../../../images/Power120/compar3.png";
import similar1 from "../../../images/Power120/similar1.png";
import similar2 from "../../../images/Power120/similar2.png";
import similar33 from "../../../images/Power120/similar3.png";
import similar4 from "../../../images/Power120/similar4.png";
import { Box ,useMediaQuery} from "@mui/material";

  
  const features = [
    {
      title: "Wireless FM  with Recording" ,
      description:
        "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
      icon: icon1,
    },
    {
      title: "Auto Call  Recording",
      description:
        "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
      icon: icon2,
    },
    {
      title: "5.08cm (2) QVGA Large Display",
      description:
        "Lorem ipsum dolor sit amet conseit amet conse it amet consectetur sit amesectetur.",
      icon: icon3,
    },
  ];

  const mainTexts = [
    {
      
      heading:"YOUR PREFECT COMPANION ",
      text: "Fuel your conversations with the itel Power 120. This powerhouse phone delivers upto 12 days of battery backup and an incredible 55 days standby to keep you connected. It comes with special LED torch with independent activation. Experience a vibrant world on the 5.08cm (2) clear display. Immerse yourself in your favorite audio with the built-in wireless FM radio and even record your cherished tunes for later. The itel Power 120 boasts an elevated design and comes in a trio of eye soothing colours, making it as stylish as it is powerful.",
    },
  ];

  const productVersions = [
    {
      label: "SUPER GURU 4G",
      images: {
        blue: [blue1, blue2, blue3],
        royalgreen: [black1, black2, black3],
        black: [green1, green2, green3],
      },
      colors: ["blue", "royalgreen", "black"],
    },
    {
      label: "SG200",
      images: {
        red: [green1, green2, green3],
        yellow: [blue1, blue2, blue3],
        black: [black1, black2, black3],
      },
      colors: ["red", "yellow", "black"],
    },
    {
      label: "SG600",
      images: {
        red: [black1, black2, black3],
        yellow: [green1, green2, green3],
        black:[blue1, blue2, blue3] ,
      },
      colors: ["red", "yellow", "black"],
    },
    {
      label: "SG400",
      images: {
        red: [blue1, blue2, blue3],
        yellow:[black1, black2, black3] ,
        black: [green1, green2, green3],
      },
      colors: ["red", "yellow", "black"],
    },
    // Add more versions as needed
  ];
  
  const smallImages={
    blue: [blacksmall1, blacksmall2, blacksmall3],
    royalgreen: [orangesmall1, orangesmall2, orangesmall3],
    black: [bluesmall1, bluesmall2, bluesmall3],
  }
  
  const colorNames = {
    blue: "Blue",
    royalgreen: " Royal Green",
    black: "Black",
    red: "Red",
    yellow: "Yellow",
    
  };
  
  const colorStyles = {
    blue: "#D2C8ED",
    royalgreen: "#AFE1C1",
    black: "#3B4455",
    red: "#FF0000",
    yellow: "#FFFF00",
    
  };

  const initialReviewsData = [
    {
      name: "Radha",
      date: "15th April 2024",
      rating: 4,
      title: "Value for Money",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: radha,
    },
    {
      name: "Krishna",
      date: "15th April 2024",
      rating: 4,
      title: "Best Battery Life",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: krishna,
    },
    {
      name: "Soha Ali Khan",
      date: "8th Aug 2023",
      rating: 3,
      title: "Professional Camera",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Lorem Ipsum has been the Lorem Ipsum is simply dummy dummy.",
      image: soha,
    },
  ];
  const additionalReviewsData = [
    {
      name: "Akshay",
      date: "1st Jan 2024",
      rating: 5,
      title: "Excellent Product",
      content: "This is a new dummy review added for testing purposes.",
      image: radha,
    },
    {
      name: "Arya",
      date: "2nd Jan 2024",
      rating: 4,
      title: "Great Value",
      content: "Another dummy review added for testing purposes.",
      image: krishna,
    },
  ];

  
  const products2 = [
    {
      name: "it5262",
      price: "₹1,269.00",
  
      imageUrl: similar1,
    },
    {
      name: "it5330",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar2,
    },
    {
      name: "Circle 1",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar33,
    },
    {
      name: "Super Guru",
      price: "₹1,269.00",
      originalPrice: "₹4,999.00",
      imageUrl: similar4,
    },
  ];

  const specs = {
    generalSpecs: {
      Model: "Power 120",
      colors: "Black, Light Green, Blue",
    },
    display: {
      displatSize: "6.78' FHD+ AMOLED Curved Screen",
      resolution: "1080*2400 pixels",
      resolutionType: "AMOLED",
      refreshRate:"240 Hz",
      pixelDensity:"388PPI"
    },
    osProcessor: {
      operatingSystem: "Android 13 GO Edition",
      chipSet : "UNISOC SC9863A",
      processorCore: "Octa-core",
      
    },
    camera: {
      rearCamera: "5 MP",
      rearFlash: "Yes",
      frontCamera: "5 MP",
      FrontFlash: "No",
    },
    battery: {
      batteryCapacity: "4000 mAh",
      batteryType: "Li-Polymer",
      fastCharging:"5W",
      USB: "Type-C",
      standBy: "32 Days",
      talkTime: "7.5 Hrs",
      mediaPlaybackTime:"112 Hours"
    },
    memoryStorage: {
      ram:"4GB*(2GB + 2 GB*)",
      rom:"32GB",
      userMemory:"23GB",
      expandable:"Upto 1TB",
    },
    connectivity: {
      networkType: "4G",
      GSM: "2G:B3|5|8",
      EDGE: "Support Download",
      WCDMA: "3G:B1|5|8",
      LTE: "B1/B3/B5/B8/B40/B38/B41(120M)",
      slimSlot: "Slot-1 & Solt-2 Nano",
      dualActiveOrStandyBy:"Dual Active",
      bluetoothSupport: "Yes",
      wiFi : "Yes",
      GPS : "Yes",
      usbConnectivity:"Yes",
    },
    additional: {
      earphoneJack: "3.5 mm",
      sensors: "Fingerprint | Face Unlock | G Sensor",
      fingerprint:"Side",
      accessories: "5W Adapter(Support 10W) | USB Cable | Protective Cover",
      thickness: "9.18 mm"
      
    },
  };

  const models = [
    {
      name: "SuperGuru 4G",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.5 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare11,
    },
    {
      name: "it5262",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.8 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare2,
    },
    {
      name: "it5330",
      price: "₹1,269.00",
      camera: "50MP AI Dual Rear Camera",
      memory: "Up to 32+4(2+2)GB*",
      dimensions: "16.4 * 7.6 * 0.9 cm",
      processor: "Octa-core Processor",
      battery: "4000mAh",
      image: compare3,
    },
  ];

  

  const callFaqs = [
    
    {
      question: "Cannot make a call after activating data.",
      answer:
      `1. IF or not all the contacts cannot be called. If yes, go to step 2. If not, maybe it's not a phone issue.\n 2. Make sure the SIM account has enough balance and the place has a good signal.
      \n 3. Check whether the phone is on 4G only mode, if yes, change to other modes.
      \n 4. Try to reset factory settings, but remember to take data back up first.
      \n 5. Visit the nearest customer service center for further check.`,
      },
    {
      question: "How to set SIM card 2 as default mode when sending messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "Phone cannot display recent calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
    {
      question: "When calling, both sides cannot hear each other.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
    },
  ];
  
  const cameraFaqs = [
    {
      question: "How to take high-quality photos.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      question: "How to switch between different camera modes.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
  ];
  
  const dummyFaqs = [
    {
      question: "Dummy question 1",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 2",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Dummy question 3",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];
  
  const tabLabels = [
    "CALLS & CONTACTS",
    "CAMERA & PICTURE",
    "CHARGING",
    "INTERNET & NETWORK",
  ];
  const tabContents = [callFaqs, cameraFaqs, dummyFaqs, dummyFaqs];

  const Power120 = (mode = "light") => {
    
    const isDarkMode = mode === "dark";
    const selectedTabTextColor = isDarkMode ? "#000000" : "#ffffff";
    const selectedTabProductTextColor = "#ffffff";
    // const handleChange = (event, newValue) => {
    //   setValue(newValue);
    // };
    
    console.log("mode:", mode)
    console.log("isDarkMode:", isDarkMode)
    console.log("selectedTabProductTextColor:", selectedTabProductTextColor)
    console.log("selectedTabTextColor:", selectedTabTextColor)


    
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    //const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

    const maxWidthLandingImage = {
      xs: "58%",
      sm:"55%",
      md: "65%",
      lg: "30%",
    }

    useEffect(() => {
      
    const xValue = isSmallScreen ? "0vw" : "25vw";
    const yValue = isSmallScreen ? "-5vh" : "0vh";

    gsap.set(".landing-image", { opacity: 0, x: xValue ,y: yValue});

    // Create the animation to show the image after 3 seconds
    gsap.to(".landing-image", {
      duration: 1,
      delay: 3,
      opacity: 1,
      ease: "power2.out",
    });
  }, [isSmallScreen]);

  return (
    <>
    <Box sx={{bgColor:"#fff"}} > 
      
    <ProductLanding
          landingImages={[landing1, landing2]}
          maxWidthLandingImage={maxWidthLandingImage}
          appBarColor="#181818" // Remove this line to use linear color
          mainTexts={mainTexts}
          features={features}
          menuIcon={menuIcon}
          backgroundImageFeatures={background}
          productname="POWER 120"
          linearcolor="linear-gradient(to left, #70A2E1,#D5EEF9)"
          backgroundImages={[landingbg1, landingbg2]}
          ProductnamefontSize={isSmallScreen ? "12px" : "18px"}
          //ProductnamefontSize={isSmallScreen ? "22px" : "30px"}
          Productnamecolor="#ffffff"
          OverviewSpecificationcolor="#EBE8E2"
          SpecificationsORTechSpecs= "Tech Specs"
          buynowbuttonbgcolor="#FFFFFF"
          buynowbuttoncolor="#181818"
          featuresborder="none" // Added border here
          headingColor="#FF0037" // Remove this line to use linear color
          rightsideTextColorFeatures="#181818"
          featuresubheadingcolor="#181818" // Remove this line to use linear color
          featurediscriptiontextcolor="#5C5B5A"
      />

      <Marquee
        appBarColor="#EBE8E2"
        textColor="#666666"
        iconSrc={MyIcon}
        text1="CALL BACK TO THE FUTURE"
        text2="SNAP BACK TO REALITY"
        // videoSrc="https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/193b/2409/-d534-45fc-9deb-dc56392a90ce?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HdhpZIZ4r~NDbZlTF6q2K9A0I6zk1P6SpsjtZ3bXbRuTudRMOO7ozOPKY2WPuhhGHI8BAYvJCbNlhy50HMmWCCkhvz5Ur5iYbCgL825vBgcRiG9Dvd9Ts5wIS7n4PJwZ5t1dWYW2c3vwgtw2fQjW2YOvmQAD1U~NsFzoyB-gKgKSPM-VEgRXxEmbxK-~uaKwugD2rCU~VguG7a9u61KLOIQ4pvSpA2Zlz6bXgmK8SjqZidGiNxFSjqDePwvtpgOboISnI7KBg4PxwNhTZ9nvzhUVo0kNLzCzSJUln0IVocvStzRJoBsr3IxpF8rWkblneXHTbYiqa0S-A4NeTF6How__"
        // playIconSrc={play}
        // frameSrc={patterns}
      />
      
      <Box sx={{ backgroundColor: "white" }}>
        
        <Box sx={{ pr: "5%", pl: "5%",pt:"4%" }}>
        <ProductColor
      colorNames={colorNames}
      colorStyles={colorStyles}
      smallImages={smallImages}
      colortextcolor="black"
      initialColor="blue" // set the initial color
      colornametextcolor="#181818"
      selectedBgColor="#fff"
      productVersions={productVersions}
      isNextArrowBlack={false}
      isPrevArrowBlack={false}
    />
        </Box>
      </Box>

      {/* <Box sx={{
        paddingLeft: "4%",
        paddingRight:"4%",
        paddingTop:"4%",
        }}>
        <Box sx={{display:"flex", alignItems:"center", justifyContent: "space-between"}}>
        <Box>
          <Typography 
            variant='h4'
            marginBottom={"4%"}
            marginTop={"4%"}
            fontSize={{xs: "24px", sm: "33px",md:"36px", lg:"40px"
            }}> 
            Product Versions
          </Typography> 
        </Box>

        {isLargeScreen ? (
        <Box
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            //mb: "4%",
            overflowY: "hidden",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant= "standard"
            indicatorColor="none"
            sx={{
              color: tabtextcolor,
              "& .MuiTab-root": {
                color: textColor,
                border: `2px solid ${borderColortab}`,
                borderRadius: "20px",
                margin: "0 4px",
              },
              "& .Mui-selected": {
                backgroundColor: selectedTabbgColor,
                color: selectedTabProductTextColor,
                //mode:"light",
                isDarkMode:"true", 
                border: "none",
              },
              "& .MuiTabs-scrollButtons": {
                display: "none",
              },
            }}
          >
            {productTabs.map((label, index) => (
              <Tab key={index} label={label} />
            ))}
          </Tabs>
        </Box>
        ) : ( 
          null
        )}
        </Box>
        
        {tabContents.map((contents, index) => (
          <TabPanel key={index} value={value} index={index}>
            <ProductColor
              images={images}
              colorNames={colorNames}
              colorStyles={colorStyles}
              initialColor="blue"
              colornametextcolor= "#181818"
              // mode="light"
              // isDarkMode={true}
            />
          </TabPanel>
        ))}

      </Box> */}

      <VerticalSection/>

    
      <Specifications 
        specs={specs} 
        bgColor={"#fff"} 
      />
      <ProductReview
        initialReviews={initialReviewsData}
        additionalReviews={additionalReviewsData}
        isDarkMode={false}
      />
      
      <CompareModels 
        models={models}
        mode="light" bgcolor="white"
        isTwoMapView= "true"
      />
      <PhoneCard 
        heading="Similar Products" 
        products={products2} 
        mode="light"
          bgcolor="white"
      />
      <SupportChannels 
        mode="light" bgcolor="white"
      />
      <CheckOutProducts 
        bgColor={"#fff"} 
        heading ="Check out our other Products"
      />
      <FollowUs mode="light" bgcolor="white" />
      <ProductFaqs
          // backgroundColor={accordionBackgroundColor}
          // textColor={textColor}
          // title="Frequently Asked Questions (FAQs)"
          // linkText={linkText}
          // tabLabels={tabLabels}
          // tabContents={tabContents}
          // accordionBackgroundColor={accordionBackgroundColor}
          // buttonTextColor={buttonTextColor}
          // selectedTabBackgroundColor={selectedTabBackgroundColor}
          // selectedTabTextColor={selectedTabTextColor}
          // borderColor={borderColor}
          // buttonBorder={buttonBorder}
          // mode="light"
          // bgcolor="white" 
          // isDarkMode={true}
          mode="light"
          bgcolor="#ffffff" 
          accordionBackgroundColor = "#ffffff"
          selectedTabBgColor= "#000000"
          selectedTabTextColor="#FFFFFF"
          title="Frequently Asked Questions (FAQs)"
          tabLabels={tabLabels}
          tabContents={tabContents}
      />

    </Box>
    </>
  );
};

// const TabPanel = ({ children, value, index }) => {
//   return (
//     <Box hidden={value !== index} sx={{ pt: 2 }}>
//       {children}
//     </Box>
//   );
// };

const VerticalSection = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return(
    <div 
      style={{
        paddingBottom:"4%",
        paddingTop:"4%",
      }}>
        <Box>
          <img src={isSmallScreen ? verticalsmall1 : vertical1}
              alt="first"
              style={{ 
                width :"100%",
                //height:"100%",
                display:"block"
              }}
          />  
          <Box style= {{
            paddingLeft:"4%", 
            paddingRight:"4%",
            paddingTop:"5%",
            paddingBottom:"5%"
            }} >
            <img src={isSmallScreen ? verticalsmall2 : vertical2}
              alt="second"
              style={{
                width :"100%",
              }}
            /> 
          </Box>
            <img src={isSmallScreen ? verticalsmall3 : vertical3}
                alt="third"
                style={{
                  width :"100%",
                  display:"block"
                }}
            />  
          
          <img src={isSmallScreen ? verticalsmall4 : vertical4}
              alt="fourth"
              style={{
                width :"100%",
                display:"block"
              }}
          />  
          
        </Box>
    </div>
  )
}

export default Power120;


