import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import compareicon from "../../../images/productlanding/compareicon.png";
import arrow from "../../../images/productlanding/arrow.png";
import additionalImage1 from "../../../images/productlanding/store2.png";
import additionalImage2 from "../../../images/productlanding/sales2.png";
import additionalImage1Dark from "../../../images/productlanding/store.png";
import additionalImage2Dark from "../../../images/productlanding/sales.png";
import smallScreenLongMapDark from "../../../images/P55/smalldarkLongimg.png";
import smallScreenLongMapWhite from "../../../images/P55/smallwhitelongimg.png";
import longMapDark from "../../../images/A70/longmapdark.png";
import longMapWhite from "../../../images/A70/longmapwhite.png";
import { BuyNowButton } from "../../Buttons/BuyNowButton";


const CompareModels = ({ mode = "light", bgcolor , models = [], isTwoMapView}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  //const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // const getImageSrc = () => {
  //   if (isDarkMode) {
  //     return isSmallScreen ? smallscreenlongmapdark : longmapdark;
  //   } else {
  //     return isSmallScreen ? smallscreenlongmapwhite : longmapwhite;
  //   }
  // };


  const isDarkMode = mode === "dark";

  const  darkMapImage = isSmallScreen ? smallScreenLongMapDark : longMapDark ;
  const whiteMapImage= isSmallScreen ? smallScreenLongMapWhite : longMapWhite ;
  const primaryTextColor = isDarkMode ? "white" : "black";
  const secondaryTextColor = isDarkMode ? "gray" : "#666";
  const backgroundColor = isDarkMode ? "#242423" : "#F8F6F3";
  const priceColor = isDarkMode ? "#FF99AF" : "#FF0037";
  const buttonTextColor = isDarkMode ? "#1A1A1A" : "#F8F6F3";
  const buttonBorderColor = isDarkMode ? "#636363" : "#5E5E5E";

  return (
    <Box sx={{ p: "4%", bgcolor: bgcolor }}>
      <Box display="flex" flexDirection= {isSmallScreen ? "column" : "row"} justifyContent="space-between" alignItems= {isSmallScreen ? "start" : "center"}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          color={primaryTextColor}
          sx={{ 
            fontFamily: "MarkOT-Medium",
            fontSize:{xs:"25px", lg:"36px"},
            pb:"1%"
          }}
        >
          Compare Models
        </Typography>
        <Button
          variant="text"
          sx={{
            color: primaryTextColor,
            textDecoration: "underline",
            textTransform: "none",
            fontFamily: "MarkOT-Medium",
            fontSize:{xs:"10px", lg:"20px"},
            pb: isSmallScreen ? "4%" : " ",
          }}
          onClick={() => {}}
        >
          <img
            style={{ marginRight: "10px" }}
            src={compareicon}
            alt="compare icon"
          />{" "}
          Compare other models
        </Button>
      </Box>
      <Grid container spacing={3}>
        {models.slice(0, isSmallScreen ? 2 : models.length).map((model) => (
        
          <Grid item xs={6} sm={6} md={4} key={model.name}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: backgroundColor,
                borderRadius: "24px",
                p: "4%",
              }}
            >
              <CardMedia
                component="img"
                image={model.image}
                alt={model.name}
                sx={{ width: "100%", height: "200px", objectFit: "contain" }}
              />
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems={isSmallScreen ? "center" : "flex-start"}
                >
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: primaryTextColor,
                      textAlign: isSmallScreen ? "center" : "left",
                      fontFamily: "MarkOT-Medium",
                      fontSize: isSmallScreen ? "18px" :  "28px"
                    }}
                  >
                    {model.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: priceColor,
                      textAlign: isSmallScreen ? "center" : "left",
                      fontFamily: "MarkOT-Bold",
                      fontSize: isSmallScreen ? "17px" : "28px"
                    }}
                  >
                    {model.price}
                  </Typography>
                </Box>
                {isSmallScreen && (
                  <Divider sx={{ my: 2, bgcolor: primaryTextColor }} />
                )}
                <Box mt={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems={isSmallScreen ? "center" : "flex-start"}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: primaryTextColor, fontFamily: "MarkOT-Medium" }}
                    >
                      Camera:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        textAlign: isSmallScreen ? "center" : "left",
                        fontFamily: "MarkOT",
                      }}
                    >
                      {model.camera}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems={isSmallScreen ? "center" : "flex-start"}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: primaryTextColor, mt: 1,fontFamily: "MarkOT-Medium" }}
                    >
                      Memory:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        mt: 1,
                        textAlign: isSmallScreen ? "center" : "left",
                        fontFamily: "MarkOT"
                      }}
                    >
                      {model.memory}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems={isSmallScreen ? "center" : "flex-start"}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: primaryTextColor, mt: 1 ,fontFamily: "MarkOT-Medium"}}
                    >
                      Dimensions:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        mt: 1,
                        textAlign: isSmallScreen ? "center" : "left",
                        fontFamily: "MarkOT"
                      }}
                    >
                      {model.dimensions}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems={isSmallScreen ? "center" : "flex-start"}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: primaryTextColor, mt: 1 ,fontFamily: "MarkOT-Medium"}}
                    >
                      Processor:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        mt: 1,
                        textAlign: isSmallScreen ? "center" : "left",
                        fontFamily: "MarkOT"
                      }}
                    >
                      {model.processor}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={isSmallScreen ? "column" : "row"}
                    alignItems={isSmallScreen ? "center" : "flex-start"}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: primaryTextColor, mt: 1 ,fontFamily: "MarkOT-Medium"}}
                    >
                      Battery:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        mt: 1,
                        textAlign: isSmallScreen ? "center" : "left",
                        fontFamily: "MarkOT"
                      }}
                    >
                      {model.battery}
                    </Typography>
                  </Box>
                </Box>
                {/* <Button
                  disableElevation
                  variant="contained"
                  fullWidth
                  sx={{
                    border: `1px solid ${buttonBorderColor}`,
                    borderRadius: "24px",
                    mt: 4,
                    backgroundColor: buttonTextColor,
                    color: isDarkMode ? "white" : "black",
                    fontSize: isSmallScreen ? "13px" : "18px",
                    fontFamily: "MarkOT-Bold",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color:"#181818",
                    },
                  }}
                >
                  Buy Now
                  <img src={arrow} style={{ marginLeft: "10px" }} alt="arrow" />
                </Button> */}
                <BuyNowButton
                buttonBg = "#1A1A1A"
                hoverButtonBg = "#ffffff"
                hoverTextColor = "#1A1A1A"
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

        {/* {2 map images} */}

    {isTwoMapView ? (
      <Grid container spacing={3} mt={"8%"}>
        <Grid item xs={12} sm={6}>
          <CardMedia
            component="img"
            image={isDarkMode ? additionalImage1Dark : additionalImage1}
            alt="Additional Image 1"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardMedia
            component="img"
            image={isDarkMode ? additionalImage2Dark : additionalImage2}
            alt="Additional Image 2"
            sx={{ borderRadius: "16px" }}
          />
        </Grid>
      </Grid>

    ) : (
        
      <Box 
        sx={{
          pt: isSmallScreen ? "15%" : "8%",
          // pl: isSmallScreen ? "3%" : isMediumScreen ? "4%" : "0%",
          // pr: isSmallScreen ? "3%" : isMediumScreen ? "4%" : "0%",
          //mt:"8%",
          //pl:"4%",  
          //pr:"4%",
          display:"block",
          overflow:"hidden",
          
          // minWidth:"100%",
          //borderRadius:"20px", 
          //paddingTop:"6%"
        }} 
        >
          {/* <img src={getImageSrc()} alt="longmap" /> */}
        <img src={isDarkMode ? darkMapImage : whiteMapImage} alt="longmap" style={{width:"100%", pt: isSmallScreen ? "4%" : "2%"}} />
      </Box>
    )}

    </Box>
  );
};

export default CompareModels;
