import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import mobile11 from "../../../images/Home/mobile9.png";
import mobile9 from "../../../images/Home/mobile10.png";
import mobile10 from "../../../images/Home/mobile11.png";
import ArrowRight from "../../../images/Home/arrowright.png";

const products = [
  {
    name: "Circle 1",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: mobile11,
  },
  {
    name: "Power 440",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: mobile9,
  },
  {
    name: "it 4283 Pro",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: mobile11,
  },
  {
    name: "it 4283",
    price: "₹1,269.00",
    originalPrice: "₹4,999.00",
    imageUrl: mobile10,
  },
];

const FeaturePhones = () => {
  return (
    <>
      <Box p={"4%"}>
        <Grid container spacing={4}>
          {products.map((product, index) => (
            <Grid item xs={6} md={3} key={index}>
              <Card
                sx={{
                  backgroundColor: "#242423",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "16px",
                }}
              >
                <CardMedia
                  component="img"
                  image={product.imageUrl}
                  alt={product.name}
                  sx={{
                    p: "4% 20% 0% 20%",

                    fontFamily: "MarkOT-Medium",
                  }}
                />
                <CardContent sx={{ textAlign: "center" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      color: "white",
                      fontFamily: "MarkOT-Medium",
                      fontSize: { xs: "16px", md: "24px" },
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "white", fontFamily: "MarkOT-Medium" }}
                  >
                    {product.price}
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontFamily: "MarkOT-Medium",
                        fontSize: "12px",
                        color: "#949494",
                        marginLeft: "10px",
                      }}
                    >
                      {product.originalPrice}
                    </span>{" "}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: "4%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#C0C0C0",
                      fontSize: "14px",
                      marginRight: "8px",
                      fontFamily: "MarkOT",
                    }}
                  >
                    Buy Now
                  </Typography>
                  <img
                    src={ArrowRight}
                    alt="Arrow Right"
                    style={{ fontSize: "24px" }}
                  />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default FeaturePhones;
