import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomDots from "../../customdots/CustomDots"; // Import your CustomDots component

const carouselItems = [
  {
    type: "video",
    src: "https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/2158/ea56/-8cc4-4b60-ac79-4c7f75e79501?Expires=1727654400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AAa4Q2e0Qhgafp4zcQbM4Zo7yW1BG6GB~4V9BsWr9oQw-6kPcSalaCgN1NRhvtHcfBeIMkABi0nEWsVi-~LsthqLRRcYYtjTOLNkWYUWjEKONp-EosdKYeZxLio8P9VSXQDfkGAUqPepAvzB5Cr6OTNPEdH96r-E3NCmUIOfJS0vQAhuYLCu4NDx99Fn1RV~HQdhmLjUZO0GA1psNu4qJjvhBJUptXM5GlbzmHE3XuwwBJwy4-vDXH2RzxqjEqKC~jCZeKxzefF1mwTqmAFKQ5eaWSLM0ycKjupW9RnbXN0Kv~RyCEI8mYQPhhEeXkEC5tvI7L~PBzGaPpNpv1nnqw__",
    alt: "Video 1",
    text: "Dream Big with itel: Join Us in Sparking India's Digital Revolution!",
  },
  {
    type: "video",
    src: "https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/2158/ea56/-8cc4-4b60-ac79-4c7f75e79501?Expires=1727654400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AAa4Q2e0Qhgafp4zcQbM4Zo7yW1BG6GB~4V9BsWr9oQw-6kPcSalaCgN1NRhvtHcfBeIMkABi0nEWsVi-~LsthqLRRcYYtjTOLNkWYUWjEKONp-EosdKYeZxLio8P9VSXQDfkGAUqPepAvzB5Cr6OTNPEdH96r-E3NCmUIOfJS0vQAhuYLCu4NDx99Fn1RV~HQdhmLjUZO0GA1psNu4qJjvhBJUptXM5GlbzmHE3XuwwBJwy4-vDXH2RzxqjEqKC~jCZeKxzefF1mwTqmAFKQ5eaWSLM0ycKjupW9RnbXN0Kv~RyCEI8mYQPhhEeXkEC5tvI7L~PBzGaPpNpv1nnqw__",
    alt: "Video 2",
    text: "This is the text for Video 2",
  },
  {
    type: "video",
    src: "https://s3-figma-videos-production-sig.figma.com/video/1141774195399563017/TEAM/2158/ea56/-8cc4-4b60-ac79-4c7f75e79501?Expires=1727654400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AAa4Q2e0Qhgafp4zcQbM4Zo7yW1BG6GB~4V9BsWr9oQw-6kPcSalaCgN1NRhvtHcfBeIMkABi0nEWsVi-~LsthqLRRcYYtjTOLNkWYUWjEKONp-EosdKYeZxLio8P9VSXQDfkGAUqPepAvzB5Cr6OTNPEdH96r-E3NCmUIOfJS0vQAhuYLCu4NDx99Fn1RV~HQdhmLjUZO0GA1psNu4qJjvhBJUptXM5GlbzmHE3XuwwBJwy4-vDXH2RzxqjEqKC~jCZeKxzefF1mwTqmAFKQ5eaWSLM0ycKjupW9RnbXN0Kv~RyCEI8mYQPhhEeXkEC5tvI7L~PBzGaPpNpv1nnqw__",
    alt: "Video 3",
    text: "Dream Big with itel: Join Us in Sparking India's Digital Revolution!",
  },
];

const HomeVideoCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const itemHeight = "650px"; // Set a fixed height for items

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable arrow icons
    beforeChange: (current, next) => setActiveIndex(next),
    appendDots: (dots) => (
      <CustomDots
        activeIndex={activeIndex}
        length={carouselItems.length}
        onClick={(index) => sliderRef.slickGoTo(index)}
      />
    ),
    customPaging: (i) => (
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          background: i === activeIndex ? "black" : "grey",
          display: "inline-block",
          margin: "0 4px",
        }}
      />
    ),
  };

  let sliderRef;

  return (
    <Box>
      <Box mt={5} position="relative">
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
          {carouselItems.map((item, index) => (
            <Box key={index} height={itemHeight} position="relative">
              {item.type === "image" ? (
                <img
                  src={item.src}
                  alt={item.alt}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Box position="relative" height="100%">
                  <video
                    autoPlay
                    muted
                    loop
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <Typography
                    sx={{
                      position: "absolute",
                      bottom: "5%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: { xs: "10px", md: "36px" },
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    {item.text}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Slider>
        <Box
          sx={{
            position: "absolute",
            bottom: "5%",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <CustomDots
            activeIndex={activeIndex}
            length={carouselItems.length}
            onClick={(index) => sliderRef.slickGoTo(index)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeVideoCarousel;
