import { Slide } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";

const theme = (themeOptions) => createTheme(themeOptions);

const Themes = (props) => {
  const themeOptions = {
    palette: {
      primary: { main: "#000000" },
      secondary: { main: "#FFFFFF" },
    },
    colors: {
      primary: { default: "#131313" },
      secondary: { default: "#1F1F1F" },
      ternary: { default: "#FF0037" },
      quaternary: { default: "#1A1A1A" },
      colorFive: { default: "#1C1B1B" },
      colorSix: { default: "#F8F6F3" },
      colorSeven: { default: "#2C2B83" },
      success: { default: "#008000" },
      danger: { default: "#FF0000" },
      warning: { default: "#FFA500" },
      info: { default: "00008B" },
      primaryButtons: { default: "#EB568F" },
      greyText: { default: "rgba(14, 28, 63, 0.73)" },
      darkGreyText: { default: "rgba(14, 28, 63, 0.88)" },
      lightGreyText: { default: "rgba(14, 28, 63, 0.55)" },
    },
    fontSizes: {
      h1: "48px",
      h2: "24px",
      h3: "20px",
      h4: "16px",
    },
    typography: {
      fontFamily: "MarkOT-Medium",
    },
  };

  return (
    <ThemeProvider theme={theme(themeOptions)}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ marginTop: "6vh", marginLeft: "5vw" }}
        TransitionComponent={Slide}
        autoHideDuration={3000}
      >
        {props.children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Themes;
