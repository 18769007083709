import React from "react";
import Navbar from "../../components/navbar/NavBar"; 
import Footer from "../../components/footer/Footer"; 

function Template({ children }) {
  return (
    <div>
      <Navbar /> {/* Navbar component */}
      <div>{children}</div> {/* Render children */}
      <Footer /> {/* Footer component */}
    </div>
  );
}

export default Template;
