import React, { useState, useMemo,useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  InputBase,
  Link
} from "@mui/material";

import landing1 from "../../images/faq/fag.png";
import landing2 from "../../images/faq/faqMobile.png";
import downarrow from "../../images/faq/downarrow.png";
import redarrow from "../../images/faq/Right Chevron.png";
import search from "../../images/faq/search.png";
import { IoCallOutline } from "react-icons/io5";
import { BsCamera } from "react-icons/bs";
import { CiBatteryCharging } from "react-icons/ci";
import { FiWifi } from "react-icons/fi";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { useTheme } from "@mui/material/styles";
const FAQ = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showMore, setShowMore] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleReadMore = () => {
    setShowMore(true);
  };

  const handleReadLess = () => {
    setShowMore(false);
  };

  // FAQ data
  // FAQ data
  const faqData = [
    {
      question: "How can I get easy EMI?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot make a call after activating data.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot add new contacts to phone book or view phone book.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Copy contacts to another phone.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Reset Lost contact.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Contacts saved on the SIM card may not show up on the phone or may disappear.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Phone cannot display recent calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "The phone displays call barring when dialing.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Call ended or hung up automatically.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot answer or receive new incoming calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "The phone displays ''android process stops '' when saving contacts.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "When saving contacts, the phone displays that it's in Airplane mode and the contact cannot be saved.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "When calling, both sides cannot hear each other..",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "There's no ringtone when calls are incoming.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Some numbers can still be called after they have been set as blacklist.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "When clicking on a certain contact to check the number, the phone always displays ''loading'' first and shows the number after a while.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "When switched on, the phone shows ''emergency call.''",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Blacklist feature.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot receive calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Contacts are not displayed in WhatsApp. ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Setup the contact to blacklist.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Phone does not ring or vibrate when there are new messages/calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "It's very slow loading contacts from SIM card, or cannot load contacts from SIM card.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot make calls.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Activate/turn off Auto Call Recording.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How to set SIM card 2 as default mode when sending messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "The phone is receiving the same messages repeatedly.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot send MMS.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot receive new messages, while can send messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot send messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }, {
      question: "Inbox shows ''insufficient space'' even though all messages have been deleted.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "How to set up my email/official email.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }, {
      question: "Cannot send emails. ",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "Cannot log in to Google email box.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }, {
      question: "The phone usually receives warning messages that require upgrading the Android launcher, or else the phone will be locked.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      question: "LED light does not work when there are unread messages.",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    
  ];
;

  // Filter FAQ data based on search query
  const filteredFaqData = useMemo(() => 
    faqData.filter((faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase())
    ), [searchQuery, faqData]);

  // Determine the number of FAQs to display
  const maxDisplayCount = useMemo(() => (isSmallScreen ? 4 : filteredFaqData.length), [isSmallScreen, filteredFaqData.length]);

  const displayedFaqs = useMemo(() => {
    if (showMore) {
      return filteredFaqData;
    }
    return filteredFaqData.slice(0, maxDisplayCount);
  }, [showMore, filteredFaqData, maxDisplayCount]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={isSmallScreen ? landing2 : landing1}
          alt="Banner"
          sx={{
            width: "100%",
            height: "auto",
            display: "block",
          }}
        />
        <Typography
          variant="h2"
          sx={{
            position: "absolute",
            top: "50%",
            left: { xs: "5%", sm: "10%", md: "9%" },
            transform: "translateY(-50%)",
            color: "white",
            lineHeight:{md:"96px",xs:"43.2px"},
            fontSize: {
              xs: "36px",
              sm: "36px",
              md: "80px",
              lg: "70px",
              xl: "80px",
            },
            mr: "30%",
           
          }}
        >
      
      {isSmallScreen ? (
          <>
            FAQs:
            <br />
            All Your
            <br />
            Burning Qs
            <br />
            Answered!
          </>
        ) : (
          <>
            FAQs:
            <br />
            All Your Burning
            <br />
            Qs Answered!
          </>
        )}
        </Typography>
      </Box>

      <Box sx={{ width: "100%", paddingTop: "5%", paddingBottom: "3%" }}>
        <Box
          sx={{
            position: "relative",
            width: "fit-content",
            height: "53px",
            border: "solid 1.5px grey",
            borderRadius: "12px",
            margin: "10px auto",
            backgroundColor: "rgba(255, 255, 255, 1)",
            display: "flex",
            alignItems: "center",
            paddingLeft: "15px",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* <SearchIcon sx={{ color: "red", marginRight: "10px", fontSize: "36px" }} /> */}
          <img src={search} alt="search"/>
          <InputBase
            placeholder="Enter your query"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              fontSize: "16px",
              color: "#333",
              width: { md: "844px", xs: "350px", sm: "440px" }, // Increased xs width to 320px
              paddingLeft:"1%"
            }}
          />
        </Box>
        <Box sx={{ paddingX: { md: "10%" }, paddingTop: { md: "3%", xs: "3%" } }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={isSmallScreen ? "scrollable" : "fullWidth"}
            scrollButtons="auto"
            aria-label="icon label tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{
              
              "& .MuiTab-root": {
                padding: { md: "16px 24px 16px 20px", xs: "12px 16px 12px 16px" },
                borderRadius: "16px",
                backgroundColor: "#F8F6F3",
                margin: "0 10px",
                textTransform: "none", // Default style for mobile
                [theme.breakpoints.up("sm")]: {
                  textTransform: "uppercase", // Uppercase for desktop
                },

                
                "& svg": {
                  color: "red",
                  fontSize: "24px",
                },
                "&.Mui-selected": {
                  backgroundColor: "black",
                  color: "white",
                  "& svg": {
                    color: "white",
                  },
                },
              },
            }}
          >
            <Tab icon={<IoCallOutline />} label="Calls & Contacts" iconPosition="start" sx={{height:{md:"64px",xs:"48px"},width:{md:"259px"},flex: "none",color:"rgba(0, 0, 0, 1)",fontSize:{md:"16px",xs:"12px"},fontWeight:"700"}}/>
            <Tab icon={<BsCamera />} label="Camera & Pictures" iconPosition="start" sx={{height:{md:"64px",xs:"48px"},width:{md:"268px"},flex: "none",color:"rgba(0, 0, 0, 1)",fontSize:{md:"16px",xs:"12px"},fontWeight:"700"}}/>
            <Tab icon={<CiBatteryCharging />} label="Charging" iconPosition="start" sx={{height:{md:"64px",xs:"48px"},width:{md:"184px"},flex: "none",color:"rgba(0, 0, 0, 1)",fontSize:{md:"16px",xs:"12px"},fontWeight:"700"}}/>
            <Tab icon={<FiWifi />} label="Internet & Network" iconPosition="start" sx={{height:{md:"64px",xs:"48px"},width:{md:"282px"},flex: "none",color:"rgba(0, 0, 0, 1)",fontSize:{md:"16px",xs:"12px"},fontWeight:"700"}}/>
            <Tab icon={<PiDotsThreeCircleLight />} label="Other" iconPosition="start" sx={{ width: {md:"140px"}, height: { md: "64px", xs: "48px" },flex: "none" ,color:"rgba(0, 0, 0, 1)",fontSize:{md:"16px",xs:"12px"},fontWeight:"700" }} />
          </Tabs>
        </Box>
        <Box
          sx={{
            padding: {
              xs: "4% 3% 8% 3%",
              sm: "4% 10% 8% 10%",
              md: "4% 15% 8% 15%",
            },
          }}
        >
          <TabPanel value={value} index={0}>
            <FAQSection
              faqData={displayedFaqs}
              showMore={showMore}
              handleReadMore={handleReadMore}
              handleReadLess={handleReadLess}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FAQSection
              faqData={displayedFaqs}
              showMore={showMore}
              handleReadMore={handleReadMore}
              handleReadLess={handleReadLess}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FAQSection
              faqData={displayedFaqs}
              showMore={showMore}
              handleReadMore={handleReadMore}
              handleReadLess={handleReadLess}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <FAQSection
              faqData={displayedFaqs}
              showMore={showMore}
              handleReadMore={handleReadMore}
              handleReadLess={handleReadLess}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <FAQSection
              faqData={displayedFaqs}
              showMore={showMore}
              handleReadMore={handleReadMore}
              handleReadLess={handleReadLess}
            />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default FAQ;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ border: "none" }}
    >
      {value === index && <Box sx={{ p: 0, border: "none" }}>{children}</Box>}
    </div>
  );
}

function FAQSection({ faqData, showMore, handleReadMore, handleReadLess }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = useState(false);
  const linkText = "Check out all the options.";

  useEffect(() => {
    // Expand the first FAQ item by default if on a small screen
    if (isSmallScreen) {
      setExpanded(0); // Index 0 means the first FAQ item
    }
  }, [isSmallScreen]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "844px", // Restrict the max width for the FAQ content
        mx: "auto", // Center align the FAQ content horizontally
        px: { xs: "2%", sm: "4%", md: "0%" }, // Add padding for smaller screens
        paddingTop: { xs: "3%" },
      }}
    >
      {faqData.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleAccordionChange(index)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={
              <img
                src={downarrow}
                alt="Expand"
                style={{ width: "24px", height: "24px" }}
              />
            }
            sx={{
              borderTop: "1px solid rgba(217, 217, 217, 1)",
              backgroundColor: "white",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              padding: "8px 16px",
              fontSize: { md: "20px" },
              fontWeight: "500",
              lineHeight: "26px",
            }}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "white",
              borderBottom:
                "1px solid var(--Colors-Grey-Grey-2, rgba(217, 217, 217, 1))",
              fontSize: { md: "14px", xs: "12px" },
              padding: { md: "24px, 4px, 24px, 4px" },
            }}
          >
            <Typography>{faq.answer}</Typography>
            {index === 0 && (
              <Link
                href="#"
                underline="always"
                sx={{
                  display: "block",
                  mt: 2,
                  color: "var(--Colors-Brand-Black, rgba(28, 27, 27, 1))",
                  textDecorationColor: "black",
                  fontSize:"14px",
                  fontWeight:"500"
                }}
              >
                {linkText}
              </Link>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {(isSmallScreen || faqData.length > 4) && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            cursor: "pointer",
            // Add responsive design styles if needed
          }}
          onClick={showMore ? handleReadLess : handleReadMore}
        >
          <Typography>{showMore ? "Read Less" : "Read More"}</Typography>
          <img
            src={redarrow}
            alt="arrow"
            style={{
              marginLeft: "5px",
              transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </Box>
      )}
    </Box>
  );
}
