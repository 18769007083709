import React, { useState } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import bannerImage1 from "../../../images/Home/homev2banner.png";
import bannerImage3 from "../../../images/Home/banner3.jpg";
import small1 from "../../../images/Home/bannersmallnew.png";
import small2 from "../../../images/Home/bannersmallnew.png";
import CustomDots from "../../customdots/CustomDots";

const Banner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = React.useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    dots: false, // Disable default dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  };

  const imageStyle = {
    width: "100vw",
    height: "100vh",
    objectFit: "cover",
  };

  const dotsContainerStyle = {
    position: "absolute",
    bottom: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div style={{ position: "relative" }}>
      <Slider {...settings} ref={sliderRef}>
        <div>
          {/* Conditionally render image based on screen size */}
          <img
            src={isSmallScreen ? small1 : bannerImage1}
            style={imageStyle}
            alt="Banner 1"
          />
        </div>
        <div>
          <img
            src={isSmallScreen ? small2 : bannerImage3}
            style={imageStyle}
            alt="Banner 3"
          />
        </div>
      </Slider>
      <div style={dotsContainerStyle}>
        <CustomDots
          activeIndex={activeIndex}
          length={2} // Number of slides
          onClick={(index) => sliderRef.current.slickGoTo(index)}
        />
      </div>
    </div>
  );
};

export default Banner;
