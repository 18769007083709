import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  IconButton,
  Modal,
  // Paper,
  Paper, useTheme, Link
} from "@mui/material";
import close from "../../images/productlisting/close-square.png";
// import CompareIcon from "@mui/icons-material/Compare";
// import FilterListIcon from "@mui/icons-material/FilterList";
import ProductCard from "../../components/productlisting/productcard/ProductCard";
import Filters from "../../components/productlisting/filters/Filters";
import { products } from "../../components/productlisting/data/data";
import banner from "../../images/productlisting/bannerproduct.png";
import compare from "../../images/productlisting/compare.png";
import filter from "../../images/productlisting/Filter.png";
import bannerSmall from "../../images/productlisting/bannerSmall.png";
import left from "../../images/productlisting/Left.png";
import right from "../../images/productlisting/Right.png";
import banner2 from "../../images/productlisting/banner2.png";
import CompareModal from "../../components/productlisting/compare/CompareModal"; // Ensure the correct import path
import smallBanner from "../../images/productlisting/smallbanner.png";
import support1Dark from "../../images/productlisting/support1.png";
import support2Dark from "../../images/productlisting/support2.png";
import support3Dark from "../../images/productlisting/support3.png";

const primaryTextColor = "white";
const supportChannels = [
  {
    id: 1,
    imageDark: support1Dark,
    title: "100 Days Promise",
  },
  {
    id: 2,
    imageDark: support2Dark,
    title: "Screen Replacement",
  },
  {
    id: 3,
    imageDark: support3Dark,
    title: "Service Center",
  },
];
const ProductList = () => {
  const [filters, setFilters] = useState({
    allPrice: false,
    price1000: false,
    price1000to3000: false,
    price3000to5000: false,
    price5000to7500: false,
    priceUpto7500: false,
    camera8: false,
    camera12: false,
    camera16: false,
    camera48: false,
    camera64: false,
    screenSize4_5: false,
    screenSize5: false,
    screenSize5_5: false,
    screenSize5_7: false,
    screenSize6: false,
    storage32: false,
    storage64: false,
    storage128: false,
  });

  const filterProducts = (products) => {
    return products.filter((product) => {
      if (filters.allPrice) return true;
      if (filters.price1000 && product.price >= 1000) return false;
      if (
        filters.price1000to3000 &&
        (product.price < 1000 || product.price > 3000)
      )
        return false;
      if (
        filters.price3000to5000 &&
        (product.price < 3000 || product.price > 5000)
      )
        return false;
      if (
        filters.price5000to7500 &&
        (product.price < 5000 || product.price > 7500)
      )
        return false;
      if (filters.priceUpto7500 && product.price > 7500) return false;
      if (filters.camera8 && product.camera < 8) return false;
      if (filters.camera12 && (product.camera < 12 || product.camera > 15.9))
        return false;
      if (filters.camera16 && (product.camera < 16 || product.camera > 47.9))
        return false;
      if (filters.camera48 && (product.camera < 48 || product.camera > 63.9))
        return false;
      if (filters.camera64 && product.camera < 64) return false;
      if (filters.screenSize4_5 && product.screenSize !== "4.5 in")
        return false;
      if (filters.screenSize5 && product.screenSize !== "5 in") return false;
      if (filters.screenSize5_5 && product.screenSize !== "5.5 in")
        return false;
      if (filters.screenSize5_7 && product.screenSize !== "5.7 in & above")
        return false;
      if (filters.screenSize6 && product.screenSize !== "6 in & above")
        return false;
      if (filters.storage32 && product.storage !== 32) return false;
      if (filters.storage64 && product.storage !== 64) return false;
      if (filters.storage128 && product.storage !== 128) return false;
      return true;
    });
  };

  const [selectedButton, setSelectedButton] = useState('Best Camera');

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredProducts = filterProducts(products);
  const [page, setPage] = useState(1);
  const itemsPerPage = 7; // Update items per page as per your requirement

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < Math.ceil(products.length / itemsPerPage)) {
      setPage(page + 1);
    }
  };

  const displayedProducts = products.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  return (
    // <Box sx={{ bgcolor: "#131313", color: "white", mb: "4%" }}>\
    <Box sx={{ bgcolor: "#131313", color: "white", mb: !isSmallScreen ? "5%" : "0%", height: "auto" }}>
      <Box
        pt={isSmallScreen ? "0" : "4%"}
        pb={"4%"}
        pl={isSmallScreen ? "0" : "2%"}
        pr={isSmallScreen ? "0" : "2%"}
      >
        <img
          src={isSmallScreen ? bannerSmall : banner}
          alt="banner"
          style={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
        {!isSmallScreen && (
          <Box p={2} width={"300px"}>
            <Filters filters={filters} setFilters={setFilters} />
          </Box>
        )}
        <Container>
          <Box sx={{ my: 4 }}>
            {isSmallScreen && (
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
              >
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CompareIcon />}
                  onClick={handleOpenModal}
                  sx={{ mr: 1 }}
                >
                  Compare
                </Button> */}
                <Link
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  sx={{
                    display: 'flex', alignItems: 'center', mr: 1, backgroundColor: '#131313',
                    color: 'white',
                    border: 'none',
                    textDecoration: 'underline', textTransform: "none"
                  }}
                >
                  <img
                    src={compare} // Use the imported image
                    alt="Compare"
                    style={{ paddingRight: "8%" }}
                  />
                  Compare
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  // startIcon={<FilterListIcon />}
                  sx={{ borderRadius: "24px", backgroundColor: "white", color: "black" }}
                  onClick={handleOpenFilterModal}
                >
                  <img
                    src={filter}
                    alt="filter"
                    style={{ paddingRight: "8%" }}
                  />
                  Filter
                </Button>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Tabs */}
              {!isSmallScreen && (
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="product categories"
                  textColor="inherit"
                  TabIndicatorProps={{ sx: { backgroundColor: "red" } }}
                  sx={{ fontFamily: "MarkOT-Medium" }}
                >
                  <Tab label="All" />
                  <Tab label={isSmallScreen ? "S series " : "Style (S) Series"} />
                  <Tab label={isSmallScreen ? "P series " : "Power (P) Series"} />
                  <Tab label={isSmallScreen ? "A series " : "Awesome (A) Series"} />
                </Tabs>
              )}
              {isSmallScreen && (
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="product categories"
                  textColor="inherit"
                  TabIndicatorProps={{ sx: { backgroundColor: "white" } }}
                >
                  <Tab label="All" />
                  <Tab label="S Series" />
                  <Tab label="P Series" />
                  <Tab label="A Series" />
                </Tabs>
              )}
              {!isSmallScreen && (
                // <Button
                <Link
                  variant="contained"
                  color="primary"
                  // startIcon={<CompareIcon />}
                  onClick={handleOpenModal}
                  sx={{
                    backgroundColor: '#131313',
                    color: 'white',
                    border: 'none',
                    textDecoration: 'underline', textTransform: "none", display: "flex"
                  }}
                >
                  <img
                    src={compare}
                    alt="Compare"
                    style={{ paddingRight: "8%" }}
                  />
                  Compare
                  {/* </Button> */}
                </Link>
              )}
            </Box>

            {!isSmallScreen && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  pt: "4%",
                }}
              >
                <Button
                  onClick={() => handleButtonClick('Best Camera')}
                  sx={{
                    backgroundColor: selectedButton === 'Best Camera' ? "#575757" : "#1C1C1C",
                    color: selectedButton === 'Best Camera' ? "#fff" : "#949494",
                    textTransform: "none",
                    borderRadius: "7px",
                    fontFamily: selectedButton === 'Best Camera' ? "MarkOT-Medium" : "MarkOT",
                    ":hover": {
                      fontFamily: "MarkOT-Medium",
                      backgroundColor: "#575757",
                      color: "#fff",
                    }
                  }}>Best Camera
                  {selectedButton === 'Best Camera' && <img style={{ paddingLeft: 8 }} src={close} alt="close mark" />}
                </Button>
                <Button
                  onClick={() => handleButtonClick('Music')}
                  sx={{
                    backgroundColor: selectedButton === 'Music' ? "#575757" : "#1C1C1C",
                    color: selectedButton === 'Music' ? "#fff" : "#949494",
                    ml: "2%",
                    textTransform: "none",
                    borderRadius: "7px",
                    fontFamily: selectedButton === 'Music' ? "MarkOT-Medium" : "MarkOT",
                    ":hover": {
                      fontFamily: "MarkOT-Medium",
                      backgroundColor: "#575757",
                      color: "#fff",
                    }
                  }}>Music
                  {selectedButton === 'Music' && <img style={{ paddingLeft: 8 }} src={close} alt="close mark" />}
                </Button>
                <Button
                  onClick={() => handleButtonClick('Entertainment')}
                  sx={{
                    backgroundColor: selectedButton === 'Entertainment' ? "#575757" : "#1C1C1C",
                    color: selectedButton === 'Entertainment' ? "#fff" : "#949494",
                    ml: "2%",
                    textTransform: "none",
                    borderRadius: "7px",
                    fontFamily: selectedButton === 'Entertainment' ? "MarkOT-Medium" : "MarkOT",
                    ":hover": {
                      fontFamily: "MarkOT-Medium",
                      backgroundColor: "#575757",
                      color: "#fff",
                    }
                  }}>Entertainment
                  {selectedButton === 'Entertainment' && <img style={{ paddingLeft: 8 }} src={close} alt="close mark" />}
                </Button>
                <Button
                  onClick={() => handleButtonClick('Talk Time')}
                  sx={{
                    backgroundColor: selectedButton === 'Talk Time' ? "#575757" : "#1C1C1C",
                    color: selectedButton === 'Talk Time' ? "#fff" : "#949494",
                    textTransform: "none",
                    ml: "2%",
                    borderRadius: "7px",
                    fontFamily: selectedButton === 'Talk Time' ? "MarkOT-Medium" : "MarkOT",
                    ":hover": {
                      fontFamily: "MarkOT-Medium",
                      backgroundColor: "#575757",
                      color: "#fff",
                    }
                  }}>Talk Time
                  {selectedButton === 'Talk Time' && <img style={{ paddingLeft: 8 }} src={close} alt="close mark" />}
                </Button>
              </Box>
            )}

            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* {filteredProducts.map((product) => ( */}
              {filteredProducts.map((product) => (
                <Grid item xs={6} sm={6} md={4} lg={4} sx={{ mt: isSmallScreen ? 0 : 2 }} key={product.id}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ pt: 4 }}>
            {isSmallScreen ? (
              <img
                src={smallBanner}
                style={{ width: "100%" }}
                alt="smallBanner"
              />
            ) : (
              <img src={banner2} style={{ width: "100%" }} alt="banner2" />
            )}
          </Box>

          <Box sx={{ pt: 6 }}>
            {/* <Grid container spacing={2}>
              {displayedProducts.map((product) => (
                <Grid item xs={6} sm={6} md={4} lg={4} sx={{ mt: isSmallScreen ? 0 : 2 }} key={product.id}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid> */}
            <Grid container spacing={4} sx={{ mt: 2 }}>
              {displayedProducts
                .slice(0, isSmallScreen ? 2 : displayedProducts.length) // Show only 2 products on small screens
                .map((product) => (
                  <Grid item xs={6} sm={6} md={4} lg={4} key={product.id}>
                    <ProductCard product={product} />
                  </Grid>
                ))}
            </Grid>
            {/*--------------------------------------------------------- Custom Pagination -----------------------------*/}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 4,
              }}
            >
              <IconButton onClick={handlePrevPage} sx={{ color: "#fff" }}>
                <img src={left} alt="arrow" />
              </IconButton>
              <Typography
                sx={{
                  mx: 2,
                  color: "#fff",
                  fontFamily: "MarkOT-Medium",
                  fontSize: "14px",
                }}
              >
                {`${(page - 1) * itemsPerPage + 1} - ${Math.min(
                  page * itemsPerPage,
                  products.length
                )} of ${products.length}`}
              </Typography>
              <IconButton onClick={handleNextPage} sx={{ color: "#fff" }}>
                <img src={right} alt="arrow" />
              </IconButton>
            </Box>
          </Box>

          {/*----------------------------------------------------------------------- support------------------ */}
          {/* <Box mt={"4%"} mb={"4%"}> */}
          <Box mt={"4%"} mb={{ xs: "20%", md: "20%" }}  >
            <Typography
              sx={{
                color: primaryTextColor,
                // textAlign: "center",
                textAlign: { md: 'center', xs: 'left' },
                fontFamily: "MarkOT-Medium",
                mb: "4%",
                // fontSize: { xs: "16px", md: "36px" },
                fontSize: { xs: "28px", md: "36px" },
              }}
            >
              Support Channels
            </Typography>
            <Grid
              container
              spacing={4} // Adjust spacing to control the gap between images
              justifyContent="center"
            >
              {/* {supportChannels.map((channel) => ( */}
              {supportChannels.map((channel,) => (
                <Grid
                  item
                  // xs={12}
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    justifyContent: { xs: "left", md: "center" },
                    alignItems: "center",
                  }}
                  key={channel.id}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={channel.imageDark}
                      alt={channel.title}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: isSmallScreen ? "260px" : "380px",
                      }}
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: "1rem",
                        fontFamily: "MarkOT-Medium",
                        mt: "8px",
                        color: primaryTextColor,
                      }}
                    >
                      {channel.title}
                    </Typography>
                    <Button
                      disableElevation
                      variant="text"
                      sx={{
                        textTransform: "none",
                        fontFamily: "MarkPro-Medium",
                        color: primaryTextColor,
                        mt: 1,
                        px: { xs: 1, sm: 2 },
                      }}
                    >
                      Read more{" "}
                      <span style={{ marginLeft: "8px", color: "#ff0000" }}>
                        →
                      </span>
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Modal
        open={isFilterModalOpen}
        onClose={handleCloseFilterModal}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Paper
          sx={{
            position: "absolute",
            // top: "10%", // Adjust the top position as needed
            top: "10%", 
            left: "50%",
            // transform: "translateX(-50%)", // Center horizontally
            transform: "translateX(-50%)", 
            //padding: 4,
            // maxWidth: 600, // Adjust the size as needed
            // width: "60vw",
            maxWidth: 500,
            maxHeight: "80vh", 
            // maxHeight: "80vh", // Adjust the height to allow scrolling if content exceeds this height
            backgroundColor: "#1F1F1F",
            // overflowY: "auto", // Enable vertical scrolling
            // borderRadius: 2, // Adjust the border radius as needed
            overflowY: "auto", 
            borderRadius: 2, 
          }}
        >
          <Box p={2}>
            <Filters filters={filters} setFilters={setFilters} />
          </Box>
        </Paper>
      </Modal>

      {/* Support Channels */}

      <CompareModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        productOptions={products}
      />
    </Box>
  );
};

export default ProductList;
