import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import './AboutUs.css';
import Slider from 'react-slick';
import ResponsiveText from "../../components/responsivetext/ResponsiveText"; // Adjust the import path as necessary


const TrophyCarousels = ({ images }) => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isLargeScreen ? Math.min(images.length, 3) : 1, // Show up to 3 slides or less if there are fewer images
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
    };

    return (
        <Box >
            <Slider {...settings}>
                {images.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex!important', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
                        <Box component="img" src={item.image} alt={`slide-${index}`} sx={{ width: { lg: '70%', xs: '90%' }, height: 'auto', justifyContent: 'center' }} />
                        {/* <Box sx={{ display: 'flex', height: '80px' }}>
                            <img src={vector1} alt="" />
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: '300px' }}>
                                <ResponsiveText
                                    xsText={item.text}
                                    lgText={item.text}
                                    xsFontSize="1rem"
                                    lgFontSize="1.25rem"
                                    variant="h6"
                                    style={{

                                        marginTop: "10px",
                                        width: '100%', // Adjust width as needed
                                        // wordBreak: 'break-word' // Ensure long words wrap correctly
                                    }}
                                />

                                <ResponsiveText
                                    xsText={item.bottom_text}
                                    lgText={item.bottom_text}
                                    xsFontSize="14px"
                                    lgFontSize="14px"
                                    variant="h6"
                                    style={{ marginTop: "5px" }}
                                />
                            </Box>
                            <img src={vector2} alt="" />
                        </Box> */}
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};



export default TrophyCarousels;
